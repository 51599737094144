<ngx-spinner [name]="spinnerName" [fullScreen]="true"></ngx-spinner>

<div class="selling-card mt-3" *ngIf="sellingOptionSettings.enabled">
  <div class="border-bottom-1 flex-box-center flex-box-gap-1">
    <img src="/assets/images/connected-apps/homeward/HOMEWARD_LARGE_LOGO.png" alt="Homeward" alt="logo" class="img-logo" />
    <lib-icon
      [libTooltip]="
        'The estimated gross proceeds from your property are based on comparable property sales. This estimate does not account for lien payoffs or prorated property taxes. By accepting Homeward\'s cash offer, you gain fast access to your home equity and can close quickly, receiving the initial proceeds (minus fees and closing costs). After your home sells on the market for top dollar, you will receive any additional proceeds. Please note, this valuation is preliminary and subject to final adjustments.'
      "
      class="line-height-1"
      [tooltipClass]="'width-400'"
      >info
    </lib-icon>
  </div>
  <div class="flex-box-center-between flex-box-gap-3 flex-sm-wrap">
    <div>
      <p class="text-size-16 fw-600 mb-2" *ngIf="sellingOptionSettings.subheading">{{ sellingOptionSettings.subheading }}</p>
      <p class="text-size-28 fw-600" *ngIf="sellingOptionWrapper.data.avm">
        <span class="actual-data">{{ sellingOptionWrapper.data.avm.median | roundOff: 10 | formatPrice }}</span>
        <span class="dummy-data">$XXX,XXX</span>
      </p>
      <p class="text-size-14 fw-500 text-color-light-2 mt-1" *ngIf="sellingOptionSettings.showSubtext && sellingOptionSettings.subText">
        {{ sellingOptionSettings.subText }}
      </p>
    </div>
    <button
      *ngIf="!sellingOptionWrapper.data.isPrelimOfferRequested; else homeardCtaDisabled"
      class="whitespace-nowrap button-mid homeward-cta-button"
      libDashboardTrackButtonClick
      [ngClass]="{'button-primary': sellingOptionSettings.ctaButton.type === DashboardModuleButtonType.SECONDARY}"
      (click)="performCTAAction(sellingOptionSettings.ctaButton.action)"
    >
      {{ sellingOptionSettings.ctaButton.label }}
    </button>
  </div>
</div>

<ng-template #homeardCtaDisabled>
  <div
    class="homeward-cta-button"
    [libTooltip]="
      'A preliminary offer has been requested from Homeward for this property. Please contact one of our agents for further updates.'
    "
  >
    <button
      disabled
      class="whitespace-nowrap button-mid"
      [ngClass]="{'button-primary': sellingOptionSettings.ctaButton.type === DashboardModuleButtonType.SECONDARY}"
    >
      {{ sellingOptionSettings.ctaButton.label }}
    </button>
  </div>
</ng-template>
