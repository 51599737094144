import {Injectable} from "@angular/core";
import {from, Observable} from "rxjs";
import {map} from "rxjs/operators";

export interface AddressSuggestion {
  address: string;
}

@Injectable()
export class AddressAutoCompleteService {
  constructor() {}

  getAddressSuggestion(search: string): Observable<AddressSuggestion[]> {
    const service = new google.maps.places.AutocompleteService();
    return from(
      service.getPlacePredictions({
        input: search,
        componentRestrictions: {
          country: "us"
        }
      })
    ).pipe(
      map(result => {
        return result.predictions.map(data => {
          return {address: data.description};
        });
      }),
      map((addressSuggestions: AddressSuggestion[]) => {
        return addressSuggestions.map(a => ({
          address: this._sanitizeGoogleAddress(a.address)
        }));
      })
    );
  }

  private _sanitizeGoogleAddress(address: string): string {
    if (!address) {
      return address;
    }
    if (address.endsWith("USA")) {
      address = address.slice(0, address.length - 3);
    }
    address = address.trim();
    if (address.endsWith(",")) {
      address = address.slice(0, address.length - 1);
    }
    address = address.trim();
    return address;
  }
}
