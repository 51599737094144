<input
  type="color"
  [(ngModel)]="color"
  [disabled]="disabled"
  (ngModelChange)="_onChange($event)"
  (click)="_onTouched()"
  [ngModelOptions]="{standalone: true}"
/>
<input
  type="text"
  [(ngModel)]="color"
  [disabled]="disabled"
  (ngModelChange)="_onChange($event)"
  (blur)="_onTouched()"
  [ngModelOptions]="{standalone: true}"
/>
