import {Inject, Injectable, Optional} from "@angular/core";
import {NavigationEnd, Router} from "@angular/router";
import {EVENT_TRACKER} from "../tokens";
import {EventTracker, UserIdentifiedExtras} from "../EventTracker";
import {distinctUntilChanged, filter, takeUntil} from "rxjs/operators";
import {filter as _filter, forEach} from "lodash-es";
import {mixinDestroyable} from "../../../lib";

type EventTrackerKeys = keyof EventTracker;
type EventTrackerMethods<T extends EventTrackerKeys> = EventTracker[T] extends (...args: any) => any ? T : never;
export type EventTrackerMethodName = EventTrackerMethods<EventTrackerKeys>;

@Injectable({
  providedIn: "root"
})
export class GlobalTrackingEventService extends mixinDestroyable(class {}) {
  constructor(private router: Router, @Optional() @Inject(EVENT_TRACKER) private eventTrackers: EventTracker[] = []) {
    super();
    this.router.events
      .pipe(
        takeUntil(this.isDestroyed),
        filter(event => event instanceof NavigationEnd),
        distinctUntilChanged()
      )
      .subscribe(navigationEnd => {
        this.handleNavigationEvent(navigationEnd as NavigationEnd);
      });
    this.eventTrackers = _filter(this.eventTrackers);
  }

  private handleNavigationEvent(event: NavigationEnd): void {
    this.trackPageView(event.url);
  }

  trackPageView(path: string, extra?: Record<string, any>): void {
    this.dispatchToTrackers("trackPageView", path, extra);
  }

  userIdentified(email: string, extras: UserIdentifiedExtras): void {
    this.dispatchToTrackers("userIdentified", email, extras);
  }

  trackCustomEvent(eventName: string, eventData?: Record<string, any>): void {
    this.dispatchToTrackers("trackCustomEvent", eventName, eventData);
  }

  protected dispatchToTrackers<K extends EventTrackerMethodName>(method: K, ...args: Parameters<EventTracker[K]>): void {
    forEach(this.eventTrackers, (eventTracker: EventTracker) => {
      try {
        const fn: any = eventTracker[method];
        fn.call(eventTracker, ...args);
      } catch (err) {
        console.error(err);
      }
    });
  }
}
