import {Directive} from "@angular/core";
import {AbstractControl, NG_VALIDATORS, Validator} from "@angular/forms";
import {RegexUtils} from "../constants";

@Directive({
  selector: "[libEmail]",
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: EmailValidatorDirective,
      multi: true
    }
  ],
  standalone: true
})
export class EmailValidatorDirective implements Validator {
  validate(control: AbstractControl): {[key: string]: any} | null {
    if (control.value) {
      const isValid = RegexUtils.EMAIL_REGEXP.test(control.value);

      return isValid ? null : {invalidEmailFormat: true};
    }

    return null;
  }
}
