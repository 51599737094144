/**
 * Decorator to cache a result of a property. This is achieved by replacing the getter by the value returned by it
 * once it is evaluated.
 *
 * Example:
 * class ClassA {
 *
 *    @cachedProperty
 *    get someProperty(): TypeA {
 *      value = someHeavyComputation();
 *      return value;
 *    }
 * }
 */
export function cachedProperty(target: any, propertyKey: string, descriptor: PropertyDescriptor): PropertyDescriptor {
  descriptor.configurable = true;
  const originalGet = descriptor.get!;
  descriptor.get = function () {
    const value = originalGet.apply(this);
    Object.defineProperty(this, propertyKey, {value, writable: false});
    return value;
  };
  return descriptor;
}
