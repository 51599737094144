import {Component, Input, OnInit} from "@angular/core";
import {CompLite, OPDLite} from "fello-model";
import {ComparablesUtil, PropFeatures} from "../ComparablesUtil";
import {FelloUiUtilsModule} from "../../../../fello-ui-utils";
import {CommonModule} from "@angular/common";

@Component({
  selector: "app-comp-label",
  templateUrl: "./comp-label.component.html",
  styleUrls: ["./comp-label.component.scss"],
  imports: [FelloUiUtilsModule, CommonModule],
  standalone: true
})
export class CompLabelComponent implements OnInit {
  @Input() comp: CompLite;
  @Input() sourcePropertyOPDLite: OPDLite;
  compComparators: PropFeatures[] = [];

  ngOnInit(): void {
    this.compComparators = ComparablesUtil.compareWithSource(this.comp, this.sourcePropertyOPDLite);
  }
}
