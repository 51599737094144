import {Component} from "@angular/core";
import {MatDialogRef} from "@angular/material/dialog";

export interface HomeBuyDetails {
  buyingArea: string;
  buyingPriceLow: number | undefined;
  buyingPriceHigh: number | undefined;
}

@Component({
  selector: "app-add-buying-home-dialog",
  templateUrl: "./add-buying-home-dialog.component.html",
  styleUrls: ["./add-buying-home-dialog.component.scss"]
})
export class AddBuyingHomeDialogComponent {
  homeBuyDetails: HomeBuyDetails = {
    buyingArea: "",
    buyingPriceLow: undefined,
    buyingPriceHigh: undefined
  };

  get rangeValid(): boolean {
    if (!this.homeBuyDetails.buyingPriceLow || !this.homeBuyDetails.buyingPriceHigh) {
      return false;
    }
    return this.homeBuyDetails.buyingPriceLow < this.homeBuyDetails.buyingPriceHigh;
  }

  constructor(private dialogRef: MatDialogRef<HomeBuyDetails>) {}

  submit() {
    this.dialogRef.close(this.homeBuyDetails);
  }
}
