export enum ChargebeeStatus {
  paid = "paid",
  posted = "posted",
  payment_due = "payment_due",
  not_paid = "not_paid",
  voided = "voided",
  pending = "pending"
}

export const ChargebeeStatusColor: Record<ChargebeeStatus, string> = {
  [ChargebeeStatus.paid]: "green-light",
  [ChargebeeStatus.not_paid]: "red-light",
  [ChargebeeStatus.payment_due]: "red-light",
  [ChargebeeStatus.voided]: "red-light",
  [ChargebeeStatus.posted]: "blue",
  [ChargebeeStatus.pending]: "blue",
}

export const ChargebeeStatusLabel: Record<ChargebeeStatus, string> = {
  [ChargebeeStatus.paid]: "Paid",
  [ChargebeeStatus.not_paid]: "Not Paid",
  [ChargebeeStatus.payment_due]: "Payment Due",
  [ChargebeeStatus.voided]: "Voided",
  [ChargebeeStatus.posted]: "Posted",
  [ChargebeeStatus.pending]: "Pending",
}
