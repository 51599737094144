import {Observable} from "rxjs";
import {HasMatSnackBar, mixinToast, ShowToastOption} from "./toast";
import {CanShowSpinner, HasSpinnerService, mixinSpinner} from "./spinner";
import {AbstractConstructor, Constructor} from "../types";

export interface CanShowSpinnerAndToast extends CanShowSpinner, CanShowSpinner {
  withSpinnerAndToast<T>(observable: Observable<T>, option?: ShowToastOption): Observable<T>;
}

export interface HasSpinnerServiceAndMatSnackBar extends HasSpinnerService, HasMatSnackBar {}

type CanShowSpinnerAndToastCtor = Constructor<CanShowSpinnerAndToast> & AbstractConstructor<CanShowSpinnerAndToast>;

export function mixinSpinnerAndToast<T extends AbstractConstructor<HasSpinnerServiceAndMatSnackBar>>(
  base: T
): CanShowSpinnerAndToastCtor & T;
export function mixinSpinnerAndToast<T extends Constructor<HasSpinnerServiceAndMatSnackBar>>(base: T): CanShowSpinnerAndToastCtor & T {
  return class extends mixinSpinner(mixinToast(base)) {
    withSpinnerAndToast<T>(observable: Observable<T>, option?: ShowToastOption): Observable<T> {
      return this.withSpinner(this.withToast(observable, option));
    }
  };
}
