<div class="flex-box-center-between mb-3">
  <h2 mat-dialog-title class="m-0 text-size-20 fw-700">OFFER DETAILS</h2>
  <button class="button-icon ml-auto" mat-dialog-close>
    <app-icon class="text-size-28">close</app-icon>
  </button>
</div>
<mat-dialog-content>
  <div class="flex-box-center-between">
    <div class="flex-box-center">
      <lib-icon class="text-color-light-2 mr-2 text-size-24">dollar</lib-icon>
      <p class="text-size-24 fw-700">{{cashOffer ? 'Cash Offer' : listingOffer!.buyerDisplayName}}</p>
    </div>
    <p class="text-size-24 fw-700">
      <ng-container *ngIf="isShowNetProceedsOnly ? pricing.netProceeds : pricing.offerPrice; let amount">
        {{isRange ? (amount! | formatPriceRange) : (amount!.min | formatPrice)}}
      </ng-container>
    </p>
  </div>

  <div class="flex-box-center mt-2" *ngIf="expiry || offerType">
    <span class="dot mr-2"></span>
    <p class="text-size-12" [ngClass]="expiry && (expiry | momentIsInPast) ? 'text-color-red': 'text-color-light-2'">
      <ng-container *ngIf="expiry && (expiry | momentIsInFuture)">
        Expires on {{expiry | momentFormat: "MMM DD, YYYY hh:mm A"}} ({{expiryDaysLeft}}d)
      </ng-container>
      <ng-container *ngIf="expiry && (expiry | momentIsInPast)"> Expired on {{expiry | momentFormat: "MMM DD, YY"}} </ng-container>
      <ng-container *ngIf="offerType && expiry"> · </ng-container>
      <ng-container *ngIf="offerType"> {{offerType | enumToLabel:"CASH_OFFER_TYPE"}} Offer </ng-container>
    </p>
  </div>
  <div class="line-break"></div>
  <ng-container *ngIf="!isShowNetProceedsOnly">
    <div class="flex-box-center-between mb-3" *ngIf="pricing.serviceFeePerc">
      <p class="text-size-14 fw-500">Service Fee ({{pricing.serviceFeePerc}}%)</p>
      <p class="text-size-14 fw-500">
        {{ pricing.serviceFee.min | formatPrice }}
        <ng-container *ngIf="pricing.isRange"> - {{ pricing.serviceFee.max! | formatPrice }} </ng-container>
      </p>
    </div>
    <div class="flex-box-center-between mb-3" *ngIf="pricing.sellerAgentCommissionFeePerc">
      <p class="text-size-14 fw-500">Seller Agent Commission ({{pricing.sellerAgentCommissionFeePerc}}%)</p>
      <p class="text-size-14 fw-500">
        {{ pricing.sellerAgentCommissionFee.min | formatPrice }}
        <ng-container *ngIf="pricing.isRange"> - {{ pricing.sellerAgentCommissionFee.max! | formatPrice }} </ng-container>
      </p>
    </div>
    <div class="flex-box-center-between mb-3" *ngIf="pricing.buyerAgentCommissionFeePerc">
      <p class="text-size-14 fw-500">Buyer Agent Commission ({{pricing.buyerAgentCommissionFeePerc}}%)</p>
      <p class="text-size-14 fw-500">
        {{ pricing.buyerAgentCommissionFee.min | formatPrice }}
        <ng-container *ngIf="pricing.isRange"> - {{ pricing.buyerAgentCommissionFee.max! | formatPrice }} </ng-container>
      </p>
    </div>
    <div class="flex-box-center-between mb-3" *ngIf="pricing.isRepairApplicable && pricing.repairs">
      <p class="text-size-14 fw-500">Repairs</p>
      <p class="text-size-14 fw-500">
        {{ pricing.repairs.min | formatPrice }}
        <ng-container *ngIf="pricing.isRange"> - {{ pricing.repairs.max! | formatPrice }} </ng-container>
      </p>
    </div>
    <div class="flex-box-center-between mb-3" *ngIf="pricing.isClosingApplicable && pricing.closing">
      <p class="text-size-14 fw-500">Closing</p>
      <p class="text-size-14 fw-500">
        {{ pricing.closing.min | formatPrice }}
        <ng-container *ngIf="pricing.isRange"> - {{ pricing.closing.max! | formatPrice }} </ng-container>
      </p>
    </div>
  </ng-container>
  <div class="flex-box-center-between">
    <p class="text-size-16 fw-600">Estimated Net</p>
    <p class="text-size-16 fw-600">{{isRange ? (pricing.netProceeds! | formatPriceRange) : (pricing.netProceeds!.min | formatPrice)}}</p>
  </div>
  <div class="line-break" *ngIf="daysToCloseMin || numInspections || numShowings || priceNegoatiable  || closingCostSplit"></div>
  <div class="flex-box-center-between mb-3" *ngIf="daysToCloseMin">
    <p class="text-size-14 fw-400">Days to Close</p>
    <p class="text-size-14 fw-400">
      {{cashOffer ? (cashOffer.daysToClose.min + ((cashOffer.daysToClose.max && cashOffer.daysToClose.max !== cashOffer.daysToClose.min) ? '
      - ' + cashOffer.daysToClose.max: '')) : listingOffer!.daysToClose!}} days
    </p>
  </div>
  <div class="flex-box-center-between mb-3" *ngIf="numInspections">
    <p class="text-size-14 fw-400">Total Inspections</p>
    <p class="text-size-14 fw-400">{{numInspections}}</p>
  </div>
  <div class="flex-box-center-between mb-3" *ngIf="numShowings">
    <p class="text-size-14 fw-400">Total Showings</p>
    <p class="text-size-14 fw-400">{{numShowings}}</p>
  </div>
  <div class="flex-box-center-between mb-3" *ngIf="priceNegoatiable">
    <p class="text-size-14 fw-400">Price Negotiable</p>
    <p class="text-size-14 fw-400">{{priceNegoatiable ? 'Yes' : 'No'}}</p>
  </div>
  <div class="flex-box-center-between mb-3" *ngIf="closingCostSplit">
    <p class="text-size-14 fw-400">Closing Cost Split</p>
    <p class="text-size-14 fw-400">{{closingCostSplit ? 'Yes' : 'No'}}</p>
  </div>
</mat-dialog-content>
<mat-dialog-actions class="pb-0 mt-3" *ngIf="dashboardService">
  <button class="button-white button-primary w-100" (click)="openContactAgent()">Contact Agent</button>
</mat-dialog-actions>
