<div class="db-card" *ngIf="isLoading">
  <ngx-skeleton-loader class="db-card-title" count="1" [theme]="{height: '57px', width: '100%'}"></ngx-skeleton-loader>
  <ngx-skeleton-loader class="mb-1" count="1" [theme]="{height: '57px', width: '100%'}"></ngx-skeleton-loader>
  <ngx-skeleton-loader class="mb-1" count="2" [theme]="{height: '50px', width: '100%'}"></ngx-skeleton-loader>
</div>
<div *ngIf="!isLoading && (property$ | async); let property">
  <div class="db-card" id="homeDetailsCard" [ngClass]="{collapse: !cardOpen}" *ngIf="property.homeFacts?.opdLite; let opdLite">
    <div class="flex-box-between db-card-header">
      <div>
        <p class="db-card-title flex-box-center">
          {{ moduleSetting.heading }}
          <app-icon
            color="var(--color-primary)"
            [matTooltip]="moduleSetting.infoMessage"
            class="c-pointer ml-2 print-d-none"
            (click)="$event.stopPropagation()"
            >info
          </app-icon>
        </p>
        <p class="db-card-desc">{{ moduleSetting.subheading }}</p>
      </div>
      <button class="button-icon ml-4 card-collapse-button" [ngClass]="{'button-secondary': !cardOpen}" (click)="cardOpen = !cardOpen">
        <app-icon *ngIf="cardOpen" fontSize="36" class="fw-100">up</app-icon>
        <app-icon *ngIf="!cardOpen" fontSize="36" class="fw-100">down</app-icon>
      </button>
    </div>
    <div class="db-card-body">
      <div class="db-stats-list">
        <div>
          <div class="stats-img-c">
            <img src="/assets/images/home-details/light/bedroom.svg" alt="Bed" />
          </div>
          <div>
            <p class="text-size-16 fw-700">
              <span class="actual-data"> {{ opdLite.bedrooms || "-" }}</span>
              <span class="dummy-data">X</span>
            </p>
            <p class="text-size-12">Bedrooms</p>
          </div>
        </div>

        <div>
          <div class="stats-img-c">
            <img src="/assets/images/home-details/light/bathroom.svg" alt="Bathroom" />
          </div>
          <div>
            <p class="text-size-16 fw-700">
              <span class="actual-data">
                {{ opdLite.fullBaths ? opdLite.fullBaths + "f" : "-"
                }}{{ opdLite.partialBaths ? " / " + opdLite.partialBaths + "h" : "" }}</span
              >
              <span class="dummy-data">X</span>
            </p>
            <p class="text-size-12">Bathrooms</p>
          </div>
        </div>

        <div>
          <div class="stats-img-c">
            <img src="/assets/images/home-details/light/square-feet.svg" alt="Resize" />
          </div>
          <div>
            <p class="text-size-16 fw-700">
              <span class="actual-data"> {{ opdLite.aboveGradeSqft ? (opdLite.aboveGradeSqft | number: "1.0-0") : "-" }}</span>
              <span class="dummy-data">X,XXX</span>
            </p>
            <p class="text-size-12">Sqft.</p>
          </div>
        </div>

        <div>
          <div class="stats-img-c">
            <img src="/assets/images/home-details/light/year-built.svg" alt="calender" />
          </div>
          <div>
            <p class="text-size-16 fw-700">
              <span class="actual-data"> {{ opdLite.yearBuilt || "-" }}</span>
              <span class="dummy-data">XXXX</span>
            </p>
            <p class="text-size-12">Build</p>
          </div>
        </div>

        <div>
          <div class="stats-img-c">
            <img src="/assets/images/home-details/light/stories-count.svg" alt="building" />
          </div>
          <div>
            <p class="text-size-16 fw-700">
              <span class="actual-data"> {{ opdLite.floorCount || "-" }}</span>
              <span class="dummy-data">X</span>
            </p>
            <p class="text-size-12">Stories</p>
          </div>
        </div>

        <div *ngIf="opdLite.parkingSpaces">
          <div class="stats-img-c">
            <img src="/assets/images/home-details/light/parking-car-garage.svg" alt="parking" />
          </div>
          <div>
            <p class="text-size-16 fw-700">
              <span class="actual-data"> {{ opdLite.parkingSpaces || "-" }}</span>
              <span class="dummy-data">X</span>
            </p>
            <p class="text-size-12">Parking</p>
          </div>
        </div>
      </div>

      <div class="offer-page-hide" *ngIf="moduleSetting.showCTA">
        <div class="line-break"></div>
        <button class="link text-center w-100 text-size-14 fw-500" libDashboardTrackButtonClick (click)="openImproveEstimate()">
          Edit home facts to improve your home estimate
        </button>
      </div>
      <!--      <button-->
      <!--        class="link text-center w-100 text-size-14 fw-500"-->
      <!--        *ngIf="agentSignature$ | async; let agentSignature"-->
      <!--        (click)="openContactAgent()"-->
      <!--      >-->
      <!--        Seems incorrect? Contact {{agentSignature.type === AgentSignatureType.AGENT ?'me':'us'}} for updated info-->
      <!--      </button>-->
    </div>
  </div>
</div>
