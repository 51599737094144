import {Component, ContentChild, Directive, Input, TemplateRef, TrackByFunction} from "@angular/core";
import {
  SimpleInfiniteScrollComponent,
  SimpleInfiniteScrollItemContext,
  SimpleInfiniteScrollItemDirective
} from "../simple-infinite-scroll/simple-infinite-scroll.component";
import {PagedResult} from "fello-model";
import {find, forEach} from "lodash-es";
import {NgClass, NgForOf, NgIf, NgTemplateOutlet} from "@angular/common";
import {InfiniteScrollModule} from "ngx-infinite-scroll";

export type GroupedInfiniteScrollGroupByKey = string | number;

export type GroupByIteratee<T> = (item: T) => GroupedInfiniteScrollGroupByKey;

export interface ItemGroup<T> {
  groupKey: GroupedInfiniteScrollGroupByKey;
  items: T[];
}

export interface GroupedInfiniteScrollItemContext<T> extends SimpleInfiniteScrollItemContext<T> {
  groupKey: GroupedInfiniteScrollGroupByKey;
}

export interface GroupedInfiniteScrollItemGroupContext<T> {
  group: ItemGroup<T>;
  groups: ItemGroup<T>[];
  index: number;
  count: number;
  first: boolean;
  last: boolean;
  even: boolean;
  odd: boolean;
}

@Directive({
  selector: "[libGroupedInfiniteScrollItem]",
  standalone: true
})
export class GroupedInfiniteScrollItemDirective<T> extends SimpleInfiniteScrollItemDirective<T> {
  static ngTemplateContextGuard<T>(dir: GroupedInfiniteScrollItemDirective<T>, ctx: unknown): ctx is GroupedInfiniteScrollItemContext<T> {
    return true;
  }
  @Input("libGroupedInfiniteScrollItem") trackByFunction: TrackByFunction<T>;
  constructor(public template: TemplateRef<GroupedInfiniteScrollItemContext<T>>) {
    super(template);
  }
}

@Directive({
  selector: "[libGroupedInfiniteScrollGroupSeparator]",
  standalone: true
})
export class GroupedInfiniteScrollGroupSeparatorDirective<T> {
  static ngTemplateContextGuard<T>(
    dir: GroupedInfiniteScrollGroupSeparatorDirective<T>,
    ctx: unknown
  ): ctx is GroupedInfiniteScrollItemGroupContext<T> {
    return true;
  }
  constructor(public template: TemplateRef<GroupedInfiniteScrollItemGroupContext<T>>) {}
}

@Component({
  selector: "lib-grouped-infinite-scroll",
  templateUrl: "./grouped-infinite-scroll.component.html",
  styleUrls: ["./grouped-infinite-scroll.component.scss"],
  imports: [NgClass, InfiniteScrollModule, NgIf, NgForOf, NgTemplateOutlet],
  standalone: true
})
export class GroupedInfiniteScrollComponent<T> extends SimpleInfiniteScrollComponent<T> {
  @Input() groupBy: GroupByIteratee<T>;
  itemGroups: ItemGroup<T>[] = [];

  @ContentChild(GroupedInfiniteScrollItemDirective) itemTemplate: GroupedInfiniteScrollItemDirective<T>;
  @ContentChild(GroupedInfiniteScrollGroupSeparatorDirective) groupSeparatorTemplate: GroupedInfiniteScrollGroupSeparatorDirective<T>;

  protected appendPagedResult(pagedResult: PagedResult<T>) {
    super.appendPagedResult(pagedResult);
    const newItems = pagedResult.data;
    forEach(newItems, newItem => this.addItemToGroup(newItem));
  }

  protected resetList() {
    super.resetList();
    this.itemGroups = [];
  }

  private addItemToGroup(item: T): void {
    const groupKey = this.groupBy(item);
    const existingGroup = find(this.itemGroups, group => group.groupKey === groupKey);
    if (existingGroup) {
      existingGroup.items.push(item);
    } else {
      this.itemGroups.push({
        groupKey,
        items: [item]
      });
    }
  }
}
