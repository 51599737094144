import {CompLite, OPDLite} from "fello-model";

export enum CompLabelIndicator {
  INCREASE = "increase",
  DECREASE = "decrease",
  DUPLICATE_CIRCLE = "duplicate-circle"
}

export enum CompLabelIndicatorValue {
  ONE = 1,
  TWO = 2,
  ZERO = 0
}

export enum CompFeature {
  BEDROOM = "Bedroom",
  BATHROOM = "Bathroom",
  PARKING = "Parking",
  LIVING_SPACE = "Living Space",
  PLOT_SIZE = "Plot Size",
  YEAR_BUILT = "Year Built"
}

export interface PropFeatures {
  indicatorValue: number;
  indicator: string;
  indicatorFor: string;
  indicatorLabel: string;
  count: number;
  label?: string;
}

export interface LabelPrefix {
  More: string;
  Less: string;
  Same: string;
}

export class ComparablesUtil {
  private static labelBuilder(featureInput: PropFeatures): string {
    let label = "";
    switch (featureInput.indicatorFor) {
      case CompFeature.BEDROOM:
      case CompFeature.BATHROOM:
      case CompFeature.PARKING:
        label = `${featureInput.count ? featureInput.count : ""} ${featureInput.indicatorLabel} ${featureInput.indicatorFor}${
          featureInput.count > 1 || featureInput.count === 0 ? "s" : ""
        }`;
        break;
      case CompFeature.LIVING_SPACE:
        label = `${featureInput.count ? featureInput.count : ""} sqft ${featureInput.indicatorLabel} ${featureInput.indicatorFor}`;
        break;
      case CompFeature.PLOT_SIZE:
        label = `${featureInput.count ? featureInput.count.toFixed(2) + " acre" : ""}  ${featureInput.indicatorLabel} ${
          featureInput.indicatorFor
        }`;
        break;
      case CompFeature.YEAR_BUILT:
        label = `${featureInput.count ? featureInput.count + " year" : ""}  ${featureInput.indicatorLabel} Property`;
        break;
      default:
        break;
    }
    return label;
  }

  private static comparator(
    compPropertyFeatureValue: number,
    sourcePropertyFeatureValue: number,
    labelPrefix: LabelPrefix,
    indicatorFor: string
  ): PropFeatures {
    let compFetaures: PropFeatures;
    if (compPropertyFeatureValue > sourcePropertyFeatureValue) {
      compFetaures = {
        indicatorValue: CompLabelIndicatorValue.TWO,
        indicator: CompLabelIndicator.INCREASE,
        indicatorFor,
        indicatorLabel: labelPrefix.More,
        count: compPropertyFeatureValue - sourcePropertyFeatureValue
      };
    } else if (compPropertyFeatureValue < sourcePropertyFeatureValue) {
      compFetaures = {
        indicatorValue: CompLabelIndicatorValue.ONE,
        indicator: CompLabelIndicator.DECREASE,
        indicatorFor,
        indicatorLabel: labelPrefix.Less,
        count: sourcePropertyFeatureValue - compPropertyFeatureValue
      };
    } else {
      compFetaures = {
        indicatorValue: CompLabelIndicatorValue.ZERO,
        indicator: CompLabelIndicator.DUPLICATE_CIRCLE,
        indicatorFor,
        indicatorLabel: labelPrefix.Same,
        count: sourcePropertyFeatureValue - compPropertyFeatureValue
      };
    }
    compFetaures.label = ComparablesUtil.labelBuilder(compFetaures);
    return compFetaures;
  }

  public static compareWithSource(comp: CompLite, sourcePropertyOPDLite: OPDLite): PropFeatures[] {
    if (!comp.opd) {
      return [];
    }
    // bedrooms bathroom parking ===> more/less
    // sqfeet plotsize  ===>  larger/smaller
    // yearbuilt ==> older/newer
    const compComparators: PropFeatures[] = [];
    const labelPrefixes = {
      moreLess: {
        More: "More",
        Less: "Less",
        Same: "Same Number of "
      },
      largerSmaller: {
        More: "Larger",
        Less: "Smaller",
        Same: "Same"
      },
      newerOlder: {
        More: "Newer",
        Less: "Older",
        Same: "Same Year Built"
      }
    };
    // Bedrooms
    if (comp.opd.bedrooms !== undefined && sourcePropertyOPDLite.bedrooms !== undefined) {
      compComparators.push(
        ComparablesUtil.comparator(comp.opd.bedrooms, sourcePropertyOPDLite.bedrooms, labelPrefixes.moreLess, CompFeature.BEDROOM)
      );
    }

    //Bathroooms
    const compPropertyBathrooms = (comp.opd.fullBaths ?? 0) + (comp.opd.partialBaths ?? 0) / 2 ?? 0;
    const sourcePropertyBathrooms = (sourcePropertyOPDLite.fullBaths ?? 0) + (sourcePropertyOPDLite.partialBaths ?? 0) / 2 ?? 0;
    if (comp.opd.fullBaths !== undefined && sourcePropertyOPDLite.fullBaths !== undefined) {
      compComparators.push(
        ComparablesUtil.comparator(compPropertyBathrooms, sourcePropertyBathrooms, labelPrefixes.moreLess, CompFeature.BATHROOM)
      );
    }

    //parkings
    if (comp.opd.parkingSpaces !== undefined && sourcePropertyOPDLite.parkingSpaces !== undefined) {
      compComparators.push(
        ComparablesUtil.comparator(comp.opd.parkingSpaces, sourcePropertyOPDLite.parkingSpaces, labelPrefixes.moreLess, CompFeature.PARKING)
      );
    }

    //yearBuilt
    if (comp.opd.yearBuilt !== undefined && sourcePropertyOPDLite.yearBuilt !== undefined) {
      compComparators.push(
        ComparablesUtil.comparator(comp.opd.yearBuilt, sourcePropertyOPDLite.yearBuilt, labelPrefixes.newerOlder, CompFeature.YEAR_BUILT)
      );
    }

    //livingSpace
    if (comp.opd.aboveGradeSqft && sourcePropertyOPDLite.aboveGradeSqft) {
      compComparators.push(
        ComparablesUtil.comparator(
          comp.opd.aboveGradeSqft,
          sourcePropertyOPDLite.aboveGradeSqft,
          labelPrefixes.largerSmaller,
          CompFeature.LIVING_SPACE
        )
      );
    }

    //plotsize
    if (comp.opd.lotSizeAcres !== undefined && sourcePropertyOPDLite.lotSizeAcres !== undefined) {
      compComparators.push(
        ComparablesUtil.comparator(
          comp.opd.lotSizeAcres,
          sourcePropertyOPDLite.lotSizeAcres,
          labelPrefixes.largerSmaller,
          CompFeature.PLOT_SIZE
        )
      );
    }

    compComparators.sort((a, b) => b.indicatorValue - a.indicatorValue);
    return compComparators;
  }
}
