import {Observable, Subject, throwError} from "rxjs";
import {GoogleUser} from "fello-model";

export enum LOGGING_IN_PROGRESS {
  FAILED_TO_OPEN_NEW_WINDOW,
  WINDOW_CLOSED,
  LOGIN_FAILED
}

export function loginWithGoogle(oauth2Url: string): Observable<GoogleUser | null> {
  const logInWindow = window.open(oauth2Url);
  if (!logInWindow) {
    return throwError({status: LOGGING_IN_PROGRESS.FAILED_TO_OPEN_NEW_WINDOW});
  }
  const result = new Subject<GoogleUser | null>();
  const eventListener = (message: MessageEvent) => {
    if (message && "success" in message?.data) {
      if (message?.data?.success && message?.data?.user) {
        result.next(message.data.user);
        result.complete();
      } else {
        result.error({status: LOGGING_IN_PROGRESS.LOGIN_FAILED});
      }
    }
  };
  window.addEventListener("message", eventListener);
  const checkLogInWindowClosing = () => {
    if (logInWindow.closed) {
      if (!result.closed) {
        result.error({
          status: LOGGING_IN_PROGRESS.WINDOW_CLOSED
        });
      }
    } else {
      setTimeout(checkLogInWindowClosing, 500);
    }
  };
  result.subscribe({
    error: () => window.removeEventListener("message", eventListener),
    complete: () => window.removeEventListener("message", eventListener)
  });
  checkLogInWindowClosing();
  return result;
}
