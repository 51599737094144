import {NitroRef} from "../nitro/NitroRef";
import {FelloAddressStatus} from "./enums/FelloAddressStatus";
import {FelloDashboardSource} from "./enums/FelloDashboardSource";
import {FelloOfferJourneyInfo} from "./interfaces/FelloOfferJourneyInfo";
import {FelloUserContactDetails} from "./interfaces/FelloUserContactDetails";
import {HomeFacts} from "./interfaces/HomeFacts";
import {AddressComponent} from "../address/AddressComponent";
import {FelloDashboardOrigin} from "./enums";
import {FormConfig} from "../forms";
import {AgentContact, AgentLeadType} from "../agent";

export interface FelloDashboardReplacementInfo {
  dashboardId: string;
  date: Date;
}

export enum BuyingProgress {
  THINKING = "THINKING",
  TOURING = "TOURING",
  MAKING_OFFERS = "MAKING_OFFERS",
  CONTRACT_SIGNED = "CONTRACT_SIGNED"
}

export const BuyingProgressLabel: Record<BuyingProgress, string> = {
  [BuyingProgress.THINKING]: "I’m thinking about buying",
  [BuyingProgress.CONTRACT_SIGNED]: "I’ve signed a purchase contract ",
  [BuyingProgress.TOURING]: "Touring open houses",
  [BuyingProgress.MAKING_OFFERS]: "Making offers on a property"
};

export interface FelloDashboard {
  _id: string;
  propertyId: string;
  source: FelloDashboardSource;
  nitroRef?: NitroRef;
  rawAddress?: string;
  addressStatus: FelloAddressStatus;
  addressComponent?: AddressComponent;
  homeOwnerContactDetails?: FelloUserContactDetails;
  agencyId?: string;
  offerJourneyInfo: FelloOfferJourneyInfo;
  homeFacts?: HomeFacts;
  updatedAt?: string;
  createdAt?: string;
  replacementInfo?: FelloDashboardReplacementInfo;
  serviceable?: boolean;
  ioServiceable?: boolean;
  origin: FelloDashboardOrigin;
  contactId?: string;
  agentId?: string;

  // Form Fields
  formId?: string;
  formGroupId?: string;
  leadType?: AgentLeadType;
  form?: FormConfig;
  contact?: AgentContact;
}
