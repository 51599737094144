export enum DealStage {
  ANALYSIS = "ANALYSIS",
  PRELIMINARY_OFFER = "PRELIMINARY_OFFER",
  WALKTHROUGH = "WALKTHROUGH",
  FINAL_OFFER = "FINAL_OFFER",
  PA_SIGNING = "PA_SIGNING",
  ASSESSMENT = "ASSESSMENT",
  ROC = "ROC",
  CLOSING = "CLOSING",
  STRATEGY = "STRATEGY",
  CLOSED_WON = "CLOSED_WON",
  CLOSED_LOST = "CLOSED_LOST",
  REFERRAL = "REFERRAL"
}

export const DealStageDisplayLabel: Record<DealStage, string> = {
  [DealStage.ANALYSIS]: "Analysis",
  [DealStage.PRELIMINARY_OFFER]: "Preliminary Offer",
  [DealStage.WALKTHROUGH]: "Walkthrough",
  [DealStage.FINAL_OFFER]: "Final Offer",
  [DealStage.PA_SIGNING]: "PA Signing",
  [DealStage.ASSESSMENT]: "Assessment",
  [DealStage.ROC]: "ROC",
  [DealStage.CLOSING]: "Closing",
  [DealStage.STRATEGY]: "Strategy",
  [DealStage.CLOSED_WON]: "Closed Won",
  [DealStage.CLOSED_LOST]: "Closed Lost",
  [DealStage.REFERRAL]: "Referral"
};

export const DealStageSequence: Record<DealStage, number> = {
  [DealStage.ANALYSIS]: 0,
  [DealStage.PRELIMINARY_OFFER]: 1,
  [DealStage.WALKTHROUGH]: 2,
  [DealStage.FINAL_OFFER]: 3,
  [DealStage.PA_SIGNING]: 4,
  [DealStage.ASSESSMENT]: 5,
  [DealStage.ROC]: 6,
  [DealStage.STRATEGY]: 6.5,
  [DealStage.CLOSING]: 7,
  [DealStage.CLOSED_WON]: 8,
  [DealStage.CLOSED_LOST]: 9,
  [DealStage.REFERRAL]: 10
};

export function isDealStageBefore(s1: DealStage, s2: DealStage): boolean {
  return DealStageSequence[s1] < DealStageSequence[s2];
}

export function isDealStageSameOrBefore(s1: DealStage, s2: DealStage): boolean {
  return DealStageSequence[s1] <= DealStageSequence[s2];
}

export function isDealStageAfter(s1: DealStage, s2: DealStage): boolean {
  return DealStageSequence[s1] > DealStageSequence[s2];
}

export function isDealStageSameOrAfter(s1: DealStage, s2: DealStage): boolean {
  return DealStageSequence[s1] >= DealStageSequence[s2];
}

export const ACQ_DEAL_STAGES = (Object.keys(DealStageSequence) as DealStage[]).sort(
  (s1, s2) => DealStageSequence[s1] - DealStageSequence[s2]
);
