import {Directive, EventEmitter, HostListener, Input, Output} from "@angular/core";
import accept from "attr-accept";
import {forEach} from "lodash-es";

@Directive({
  selector: "[libPasteFile]",
  standalone: true
})
export class PasteFileDirective {
  @Input() accept: string | string[] = "";
  @Output() pasteFile = new EventEmitter<File[]>();

  constructor() {}

  @HostListener("paste", ["$event"])
  onPaste(event: ClipboardEvent): void {
    const items = event.clipboardData?.items;
    const files: File[] = [];
    forEach(items, item => {
      if (item.kind === "file") {
        const file = item.getAsFile();
        if (file && accept(file, this.accept)) {
          files.push(file);
        }
      }
    });
    if (files.length) {
      event.preventDefault();
      event.stopPropagation();
      this.pasteFile.emit(files);
    }
  }
}
