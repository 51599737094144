export class PriceUtil {
  public static formatPrice(amount: number, currency = "USD", maximumFractionDigits = 0, minimumFractionDigits?: number): string {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      maximumFractionDigits,
      minimumFractionDigits
    }).format(amount);
  }
}
