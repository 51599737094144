export enum FelloEnv {
  PROD = "production",
  LOCAL = "local",
  DEV = "development",
  QA = "qa"
}

export const envConstant: Record<FelloEnv, string> = {
  [FelloEnv.PROD]: "PROD",
  [FelloEnv.LOCAL]: "Local",
  [FelloEnv.DEV]: "DEV",
  [FelloEnv.QA]: "QA"
};
