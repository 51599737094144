import {
  ComparableDetails,
  DataProviderType,
  DataSourceType,
  MlsRawRecord,
  OverridablePropertyVariables,
  PropertyDetails,
  PropertyListingCategory,
  TaxAssessorRecord
} from "fello-model";
import {
  constructionMapping,
  exterior1Code,
  fireplaceMapping,
  flooringMaterialPrimaryMapping,
  hvaccoolingdetailMapping,
  hvacheatingDetailMapping,
  hvacHeatingFuelMapping,
  parkingGarageTypeMapping,
  porchCodeMapping,
  propertyUseStdMapping,
  roofConstructionMapping,
  roofMaterialMapping,
  structureStyleMapping,
  utilitySewageUsage,
  utilityWaterSource
} from "./tesseractMappingConstants";
import {filter, find, forEach, get, isArray, isString, maxBy, orderBy, values} from "lodash-es";
import moment from "moment";
import {cachedProperty, NumberUtils} from "../../lib";

interface FlagMappping {
  name: keyof TaxAssessorRecord;
  label: string;
}

const flagMappingValues: FlagMappping[] = [
  {
    name: "accessabilityhandicapflag",
    label: "Handicap Access"
  },
  {
    name: "arborpergolaflag",
    label: "Arbor pergola"
  },
  {
    name: "arenaflag",
    label: "Arena"
  },
  {
    name: "bathhouseflag",
    label: "Bathhouse"
  },
  {
    name: "boataccessflag",
    label: "Boat Access"
  },
  {
    name: "boathouseflag",
    label: "Boathouse"
  },
  {
    name: "boatliftflag",
    label: "Boatlift"
  },
  {
    name: "cabinflag",
    label: "Cabin"
  },
  {
    name: "canopyflag",
    label: "Canopy"
  },
  {
    name: "contentoverheaddoorflag",
    label: "Overhead door"
  },
  {
    name: "contentsoundsystemflag",
    label: "Soundsystem"
  },
  {
    name: "contentstormshutterflag",
    label: "Stormshutter"
  },
  {
    name: "courtyardflag",
    label: "Courtyard"
  },
  {
    name: "deckflag",
    label: "Deck"
  },
  {
    name: "escalatorflag",
    label: "Escalator"
  },
  {
    name: "featurebalconyflag",
    label: "Feature balcony"
  },
  {
    name: "golfcoursegreenflag",
    label: "Golfcourse green"
  },
  {
    name: "greenhouseflag",
    label: "Greenhouse"
  },
  {
    name: "guesthouseflag",
    label: "Guesthouse"
  },
  {
    name: "kennelflag",
    label: "Kennel"
  },
  {
    name: "loadingplatformflag",
    label: "Loading platform"
  },
  {
    name: "milkhouseflag",
    label: "Milkhouse"
  },
  {
    name: "outdoorkitchenfireplaceflag",
    label: "Outdoor kitchen fireplace"
  },
  {
    name: "parkingrvparkingflag",
    label: "RV parking"
  },
  {
    name: "polestructureflag",
    label: "Polestructure"
  },
  {
    name: "pondflag",
    label: "Pond"
  },
  {
    name: "poolhouseflag",
    label: "Poolhouse"
  },
  {
    name: "poultryhouseflag",
    label: "Poultryhouse"
  },
  {
    name: "quonsetflag",
    label: "Quonset"
  },
  {
    name: "roomsatticflag",
    label: "Attic Room"
  },
  {
    name: "roomsbonusroomflag",
    label: "Bonusroom Room"
  },
  {
    name: "roomsbreakfastnookflag",
    label: "Breakfastnook Room"
  },
  {
    name: "roomscellarflag",
    label: "Cellar Room"
  },
  {
    name: "roomscellarwineflag",
    label: "Cellarwine Room"
  },
  {
    name: "roomsexerciseflag",
    label: "Exercise Room"
  },
  {
    name: "roomsgameflag",
    label: "Game Room"
  },
  {
    name: "roomsgreatflag",
    label: "Great Room"
  },
  {
    name: "roomshobbyflag",
    label: "Hobby Room"
  },
  {
    name: "roomslaundryflag",
    label: "Laundry Room"
  },
  {
    name: "roomsmediaflag",
    label: "Media Room"
  },
  {
    name: "roomsmudflag",
    label: "Mud Room"
  },
  {
    name: "roomsofficeflag",
    label: "Office Room"
  },
  {
    name: "roomssaferoomflag",
    label: "Saferoom Room"
  },
  {
    name: "roomssittingflag",
    label: "Sitting Room"
  },
  {
    name: "roomsstudyflag",
    label: "Study Room"
  },
  {
    name: "roomssunroomflag",
    label: "Sunroom Room"
  },
  {
    name: "safetyfiresprinklersflag",
    label: "Safety Fire Sprinklers"
  },
  {
    name: "siloflag",
    label: "Silo"
  },
  {
    name: "sportscourtflag",
    label: "Sports Court"
  },
  {
    name: "stableflag",
    label: "Stable"
  },
  {
    name: "storagebuildingflag",
    label: "Storage Building"
  },
  {
    name: "tenniscourtflag",
    label: "Tennis Court"
  },
  {
    name: "utilitiesmobilehomehookupflag",
    label: "Utilities Mobile Home Hookup"
  },
  {
    name: "utilitybuildingflag",
    label: "Utility Building"
  },
  {
    name: "waterfeatureflag",
    label: "Water Feature"
  },
  {
    name: "wetbarflag",
    label: "Wetbar"
  },
  {
    name: "roomsfamilycode",
    label: "Family"
  },
  {
    name: "roomsutilitycode",
    label: "Utility"
  },
  {
    name: "shedcode",
    label: "Shed"
  }
];

export class ExtendedPropertyDetail {
  readonly ta?: PropertyDetails;
  readonly ccMLS?: PropertyDetails;

  constructor(
    private readonly propertyDetails: PropertyDetails[],
    public readonly overridablePropertyVariables?: OverridablePropertyVariables
  ) {
    this.propertyDetails = orderBy(
      this.propertyDetails,
      [pd => pd.recentSaleInfo?.saleDate ?? pd.recentListingInfo?.listingDate],
      ["desc"]
    );
    this.ta = find(propertyDetails, {provider: DataProviderType.ATTOM_TA, source: DataSourceType.PUBLIC_RECORDS});
    this.ccMLS = find(propertyDetails, {provider: DataProviderType.CLEAR_CAPITAL, source: DataSourceType.MLS});
  }

  private get rawTAResponse(): TaxAssessorRecord | undefined {
    return this.ta?.providerRawResponse;
  }

  private get rawCCMLSResponse(): MlsRawRecord | undefined {
    return this.ccMLS?.providerRawResponse;
  }

  @cachedProperty
  private get latestPDBySaleDate(): PropertyDetails | undefined {
    return maxBy(
      filter(this.propertyDetails, pd => !!pd.recentSaleInfo?.saleDate),
      pd => pd.recentSaleInfo?.saleDate
    );
  }

  @cachedProperty
  private get latestPDByListDate(): PropertyDetails | undefined {
    return maxBy(
      filter(this.propertyDetails, pd => !!pd.recentListingInfo?.listingDate),
      pd => pd.recentListingInfo?.listingDate
    );
  }

  private getLatest<T>(path: string): T | undefined {
    for (const pd of this.propertyDetails) {
      const value = get(pd, path);
      if (value != null) {
        return value;
      }
    }
    return undefined;
  }

  public static fromComparableDetail(comparableDetail: ComparableDetails): ExtendedPropertyDetail {
    return new ExtendedPropertyDetail(comparableDetail.propertyDetails, comparableDetail.propertyVariables);
  }
  get aboveGradeSqft(): number | undefined {
    return this.overridablePropertyVariables?.aboveGradeSqft?.value ?? this.getLatest<number>("features.aboveGradeSqft");
  }

  get appliancesIncluded(): string | undefined {
    return this.rawCCMLSResponse?.appliances_included;
  }

  get area1stFloor(): number | undefined {
    return this.rawTAResponse?.area1stfloor;
  }

  get area2ndFloor(): number | undefined {
    return this.rawTAResponse?.area2ndfloor;
  }

  get areaBuilding(): number | undefined {
    return this.rawTAResponse?.areabuilding;
  }

  get areaLotAcres(): string | undefined {
    return this.rawTAResponse?.arealotacres;
  }

  get lotSizeSqft(): number | undefined {
    const sizeInAcre = this.lotSizeAcres;
    return sizeInAcre ? NumberUtils.acreToSqft(sizeInAcre) : undefined;
  }

  get areaUpperFloors(): number | undefined {
    return this.rawTAResponse?.areaupperfloors;
  }

  get assessmentValueImprovementPercentage(): string | undefined {
    return this.rawTAResponse?.taxassessedimprovementsperc;
  }

  get assessmentValueImprovements(): number | undefined {
    return this.rawTAResponse?.taxassessedvalueimprovements;
  }

  get assessedValueLand(): number | undefined {
    return this.rawTAResponse?.taxassessedvalueland;
  }

  get assessedValueTotal(): number | undefined {
    const amount = Number(this.rawTAResponse?.taxassessedvaluetotal);
    return isNaN(amount) ? undefined : amount;
  }

  get assessmentYear(): number | undefined {
    return this.rawTAResponse?.taxyearassessed;
  }

  get basementType(): string | undefined {
    return this.rawCCMLSResponse?.basement_type;
  }

  get bathroom(): number | undefined {
    if (this.fullBaths === undefined && this.partialBaths === undefined) {
      return undefined;
    }
    const fullBaths = this.fullBaths ?? 0;
    const partialBaths = this.partialBaths ?? 0;
    return fullBaths + partialBaths;
  }

  get bedrooms(): number | undefined {
    return this.overridablePropertyVariables?.bedrooms?.value ?? this.getLatest("features.bedroomCount");
  }

  get belowGradeSqft(): number | undefined {
    return (
      this.overridablePropertyVariables?.belowGradeSqft?.value ?? this.ta?.features?.belowGradeSqft ?? this.ccMLS?.features?.belowGradeSqft
    );
  }

  get city(): string | undefined {
    return this.ta?.addressComponents?.city ?? this.ccMLS?.addressComponents?.city;
  }

  get constructionType(): string | undefined {
    const constructionType = this.rawTAResponse?.construction;
    return constructionType ? constructionMapping[constructionType] : undefined;
  }

  get coolingMethod(): string | undefined {
    const coolingMethod = this.rawTAResponse?.hvaccoolingdetail;
    return coolingMethod ? hvaccoolingdetailMapping[coolingMethod] : undefined;
  }

  get county(): string | undefined {
    return this.rawTAResponse?.contactownermailingcounty;
  }

  get currentOccupancyType(): string | undefined {
    return this.rawCCMLSResponse?.current_occupancy_type;
  }

  get daysOnMarket(): number | undefined {
    if (this.rawCCMLSResponse?.mls_days_on_market) {
      return this.rawCCMLSResponse?.mls_days_on_market;
    }
    if (this.isActive) {
      return moment().diff(moment(this.listDate), "days");
    }
    if (this.saleDate && this.listDate) {
      const daysOnMarket = moment(this.saleDate).diff(moment(this.listDate), "day");
      return daysOnMarket > 0 ? daysOnMarket : undefined;
    }
    return undefined;
  }

  get deckArea(): number | undefined {
    return this.rawTAResponse?.deckarea;
  }

  get drivewayArea(): number | undefined {
    return this.rawTAResponse?.drivewayarea;
  }

  get effectiveYearBuilt(): number | undefined {
    return this.rawCCMLSResponse?.effective_year_built;
  }

  get exteriorFeatureList(): string | undefined {
    return this.rawCCMLSResponse?.exterior_feature_list;
  }

  get exteriorStyle(): string | undefined {
    const style = this.rawTAResponse?.exterior1code;
    return style ? exterior1Code[style] : undefined;
  }

  get featureList(): string[] {
    const labels: string[] = [];
    flagMappingValues.forEach(value => {
      if (Number(this.rawTAResponse?.[value.name])) {
        labels.push(value.label);
      }
    });
    return labels;
  }

  get fenceArea(): number | undefined {
    return this.rawTAResponse?.fencearea;
  }

  get fencingDescription(): string | undefined {
    return this.rawCCMLSResponse?.fencing_description;
  }

  get fireplaceCount(): number | undefined {
    return this.rawTAResponse?.fireplacecount ?? this.rawCCMLSResponse?.fireplace_count;
  }

  get fireplaceType(): string | undefined {
    const firePlaceType = this.rawTAResponse?.fireplace;
    return firePlaceType ? fireplaceMapping[firePlaceType] : undefined;
  }

  get floorType(): string | undefined {
    const flooringMaterial = this.rawTAResponse?.flooringmaterialprimary;
    return flooringMaterial ? flooringMaterialPrimaryMapping[flooringMaterial.toString()] : undefined;
  }

  get floorCount(): number | undefined {
    return this.overridablePropertyVariables?.floorCount?.value ?? this.getLatest<number>("features.floorCount");
  }

  get floorsInBuilding(): number | undefined {
    return this.rawCCMLSResponse?.floors_in_building;
  }

  get floorsInProperty(): string | undefined {
    return this.rawCCMLSResponse?.floors_in_property;
  }

  get fuel(): string | undefined {
    const fuel = this.rawTAResponse?.hvacheatingfuel;
    return fuel ? hvacHeatingFuelMapping[fuel] : undefined;
  }

  get fullAddress(): string | undefined {
    return this.ta?.addressComponents?.fullAddress ?? this.ccMLS?.addressComponents?.fullAddress;
  }

  get fullBaths(): number | undefined {
    return this.overridablePropertyVariables?.fullBaths?.value ?? this.getLatest("features?.fullBathroomCount");
  }

  get glaSqft(): number | undefined {
    return this.rawCCMLSResponse?.gla_sqft;
  }

  get heatingMethod(): string | undefined {
    const heatingMethod = this.rawTAResponse?.hvacheatingdetail;
    return heatingMethod ? hvacheatingDetailMapping[heatingMethod] : undefined;
  }

  get homeStyle(): string | undefined {
    return this.rawCCMLSResponse?.home_style;
  }

  get homeWarrantyIndication(): string | undefined {
    return this.rawCCMLSResponse?.home_warranty_indicator;
  }

  get isActive(): boolean {
    return (this.ccMLS?.listingCategory ?? this.ta?.listingCategory) === PropertyListingCategory.ACTIVE;
  }

  get legalLotNumber(): string | undefined {
    return this.rawTAResponse?.legallotnumber1;
  }

  get listDate(): string | undefined {
    return this.latestPDByListDate?.recentListingInfo?.listingDate;
  }

  get listPrice(): number | undefined {
    return this.latestPDByListDate?.recentListingInfo?.listingPrice;
  }

  get lotDepthArea(): string | undefined {
    return this.rawTAResponse?.arealotdepth;
  }

  get lotDimensions(): string | undefined {
    return this.rawCCMLSResponse?.lot_dimensions;
  }

  get lotSizeAcres(): number | undefined {
    return this.overridablePropertyVariables?.lotSizeAcres?.value ?? this.ta?.features?.lotSizeAcres ?? this.ccMLS?.features?.lotSizeAcres;
  }

  get lotTotalUnits(): number | undefined {
    return this.rawTAResponse?.unitscount;
  }

  get lotWidthArea(): string | undefined {
    return this.rawTAResponse?.arealotwidth;
  }

  get mlsPropertySubType(): string | undefined {
    return this.rawCCMLSResponse?.mls_property_sub_type;
  }

  get mlsPropertyType(): string | undefined {
    return this.rawCCMLSResponse?.mls_property_type;
  }

  get ownerVestingType(): string | undefined {
    return this.rawCCMLSResponse?.owner_vesting_type;
  }

  get parkingCarPort(): string {
    return this.rawTAResponse?.parkingcarport === "1" ? "Yes" : "No";
  }

  get parkingCarPortArea(): number | undefined {
    return this.rawTAResponse?.parkingcarportarea;
  }

  get parkingFeatures(): string | undefined {
    return this.rawCCMLSResponse?.parking_features;
  }

  get parkingGarageArea(): number | undefined {
    return this.rawTAResponse?.parkinggaragearea;
  }

  get parkingGarageCount(): number | undefined {
    return (
      this.overridablePropertyVariables?.parkingSpaces?.value ??
      this.ta?.features?.parkingSpaceCount ??
      this.ccMLS?.features?.parkingSpaceCount
    );
  }

  get parkingGarageType(): string | undefined {
    return this.parkingGarageTypePR ?? this.parkingGarageTypeMLS;
  }

  get parkingGarageTypePR(): string | undefined {
    const garageType = this.rawTAResponse?.parkinggarage;
    return garageType ? parkingGarageTypeMapping[garageType] : undefined;
  }

  get parkingGarageTypeMLS(): string | undefined {
    return this.rawCCMLSResponse?.garage_type;
  }

  get partialBaths(): number | undefined {
    return (
      this.overridablePropertyVariables?.partialBaths?.value ??
      this.ta?.features?.partialBathroomCount ??
      this.ccMLS?.features?.partialBathroomCount
    );
  }

  get patioArea(): number | undefined {
    return this.rawTAResponse?.patioarea;
  }

  get plumbingFixturesCount(): number | undefined {
    return this.rawTAResponse?.plumbingfixturescount;
  }

  get pool(): number | undefined {
    return this.rawTAResponse?.pool;
  }

  get poolArea(): number | undefined {
    return this.rawTAResponse?.poolarea;
  }

  get porchArea(): number | undefined {
    return this.rawTAResponse?.porcharea;
  }

  get porchOrPatio(): string | undefined {
    return this.rawCCMLSResponse?.porch_or_patio ? "Yes" : "No";
  }

  get porchType(): string | undefined {
    const porchType = this.rawTAResponse?.porchcode;
    return porchType ? porchCodeMapping[porchType] : undefined;
  }

  get price(): number | undefined {
    return (
      this.overridablePropertyVariables?.recentSaleOrListPrice?.value ??
      this.ta?.recentSaleInfo?.salePrice ??
      this.ccMLS?.recentSaleInfo?.salePrice ??
      this.ccMLS?.recentListingInfo?.listingPrice
    );
  }

  get propertyOwnershipType(): string | undefined {
    return this.rawCCMLSResponse?.property_ownership_type;
  }

  get propertyType(): string | undefined {
    return this.overridablePropertyVariables?.propertyType?.value ?? this.ta?.features?.propertyType ?? this.ccMLS?.features?.propertyType;
  }

  get propertyUseGroup(): string | undefined {
    return this.rawTAResponse?.propertyusegroup;
  }

  get propertyUserStandardized(): string | undefined {
    const propertyUserStandardized = this.rawTAResponse?.propertyusestandardized;
    return propertyUserStandardized ? propertyUseStdMapping[propertyUserStandardized] : undefined;
  }

  get roofConstruction(): string | undefined {
    const roofConstruction = this.rawTAResponse?.roofconstruction;
    return roofConstruction ? roofConstructionMapping[roofConstruction] : undefined;
  }

  get roofMaterial(): string | undefined {
    const roofMaterial = this.rawTAResponse?.roofmaterial;
    return roofMaterial ? roofMaterialMapping[roofMaterial] : undefined;
  }

  get roofType(): string | undefined {
    return this.rawCCMLSResponse?.roof_type;
  }

  get roomsAttic(): string {
    return this.rawTAResponse?.roomsatticflag === 1 ? "Yes" : "No";
  }

  get roomsAtticArea(): number | undefined {
    return this.rawTAResponse?.roomsatticarea;
  }

  get roomBasementArea(): number | undefined {
    return this.rawTAResponse?.roomsbasementarea;
  }

  get roomBasementAreaFinished(): number | undefined {
    return this.rawTAResponse?.roomsbasementareafinished;
  }

  get roomBasementAreaUnfinished(): number | undefined {
    return this.rawTAResponse?.roomsbasementareaunfinished;
  }

  get roomsCount(): number | undefined {
    return this.rawTAResponse?.roomscount;
  }

  get saleDate(): string | undefined {
    return this.latestPDBySaleDate?.recentSaleInfo?.saleDate;
  }

  get salePrice(): number | undefined {
    return this.latestPDBySaleDate?.recentSaleInfo?.salePrice;
  }

  get schoolDistrict(): string | undefined {
    return this.schoolDistrictJunior;
  }

  get schoolDistrictElementary(): string | undefined {
    return this.rawCCMLSResponse?.elementary_school_district;
  }

  get schoolDistrictHigh(): string | undefined {
    return this.rawCCMLSResponse?.high_school_district;
  }

  get schoolDistrictJunior(): string | undefined {
    return this.rawCCMLSResponse?.middle_school_district;
  }

  get seniorCommunity(): string | undefined {
    return this.rawCCMLSResponse?.senior_community_indicator;
  }

  get sewageUsage(): string | undefined {
    const sewageUsage = this.rawTAResponse?.utilitiessewageusage;
    return sewageUsage ? utilitySewageUsage[sewageUsage] : undefined;
  }

  get sqft(): number | undefined {
    return this.aboveGradeSqft;
  }

  get standardizedPropertyType(): string | undefined {
    return this.rawCCMLSResponse?.standardized_property_type;
  }

  get structureStyle(): string | undefined {
    const structureStyle = this.rawTAResponse?.structurestyle;
    return structureStyle ? structureStyleMapping[structureStyle] : undefined;
  }

  get subDivision(): string | undefined {
    return this.rawTAResponse?.legalsubdivision;
  }

  get taxAmount(): number | undefined {
    const amount = Number(this.rawTAResponse?.taxbilledamount);
    return isNaN(amount) ? undefined : amount;
  }

  get taxFiscalYear(): number | undefined {
    return this.rawTAResponse?.taxfiscalyear;
  }

  get taxYear(): number | undefined {
    return this.rawTAResponse?.taxyearassessed;
  }

  get totalBathrooms(): number | undefined {
    if (this.partialBaths == null && this.fullBaths == null) {
      const bathCount = this.rawTAResponse?.bathcount;
      return bathCount && !isNaN(Number(bathCount)) ? Number(bathCount) : undefined;
    }
    return (this.partialBaths ?? 0) + (this.fullBaths ?? 0);
  }

  get waterSource(): string | undefined {
    const waterUsage = this.rawTAResponse?.utilitieswatersource;
    return waterUsage ? utilityWaterSource[waterUsage] : undefined;
  }

  get yearBuilt(): number | undefined {
    return this.overridablePropertyVariables?.yearBuilt?.value ?? this.ta?.features?.yearBuilt ?? this.ccMLS?.features?.yearBuilt;
  }

  public static getImages(propertyDetails: PropertyDetails[]): string[] {
    const images = new Set<string>();
    const pathsToLookImagesFor = ["images", "image", "extendedVariables.imagesUrl"];
    forEach(propertyDetails, propertyDetail => {
      const rawResponse = propertyDetail.providerRawResponse;
      forEach(pathsToLookImagesFor, path => {
        const rawImages = get(rawResponse, path);
        if (isArray(rawImages) && rawImages.length && isString(rawImages[0])) {
          forEach(rawImages, rawImage => {
            if (rawImage && isString(rawImage)) {
              images.add(rawImage);
            }
          });
        } else if (rawImages && isString(rawImages)) {
          images.add(rawImages);
        }
      });
      // CC Mls lacks image coverage, backend will give images for older listings as well.
      if (propertyDetail.provider === DataProviderType.CLEAR_CAPITAL && propertyDetail.source === DataSourceType.MLS) {
        this.appendClearCapitalListingHistoryImages(rawResponse, images);
      }
    });
    return Array.from(images);
  }

  private static appendClearCapitalListingHistoryImages(rawResponse: any, images: Set<string>): void {
    if (rawResponse.extendedVariables?.allListingImages?.byListing) {
      const allListingImages = [].concat.apply([], values(rawResponse.extendedVariables?.allListingImages?.byListing));
      forEach(allListingImages, (img: string) => {
        images.add(img.trim());
      });
    }
  }
}
