import {shareReplay} from "rxjs/operators";

export function cacheResponse(target: unknown, propertyKey: string, descriptor: PropertyDescriptor): PropertyDescriptor {
  const originalMethod = descriptor.value;
  descriptor.value = function (...args: unknown[]): unknown {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const self = this as any;
    const cacheKey = JSON.stringify(args);
    if (!self[propertyKey].cache) {
      self[propertyKey].cache = new Map();
    }
    if (!self[propertyKey].cache.has(cacheKey)) {
      self[propertyKey].cache.set(cacheKey, originalMethod.apply(this, args).pipe(shareReplay()));
    }
    return self[propertyKey].cache.get(cacheKey);
  };
  return descriptor;
}
