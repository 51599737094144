import {Component, OnInit} from "@angular/core";

@Component({
  selector: "lib-comparables-map-dummy",
  templateUrl: "./comparables-map-dummy.component.html",
  styleUrls: ["./comparables-map-dummy.component.scss"],

})
export class ComparablesMapDummyComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
