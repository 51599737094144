import {Inject, Injectable, PLATFORM_ID} from "@angular/core";
import {FelloEnv} from "fello-model";
import {EventTracker, UserIdentifiedExtras} from "../EventTracker";
import {MIX_PANEL_API_KEY} from "../tokens";
import {ENVIRONMENT} from "../../../lib";
import {BrowserIdService} from "../../../lib/services/browser-id/browser-id.service";
import mixpanel from "mixpanel-browser";
import {isPlatformBrowser} from "@angular/common";

@Injectable()
export class MixPanelEventTracker implements EventTracker {
  constructor(
    @Inject(MIX_PANEL_API_KEY) apiKey: string,
    @Inject(ENVIRONMENT) private env: FelloEnv,
    private browserIdService: BrowserIdService,
    // eslint-disable-next-line @typescript-eslint/ban-types
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    if (isPlatformBrowser(platformId)) {
      mixpanel.init(apiKey, {
        ip: true
      });
    }
  }

  trackCustomEvent(eventName: string, eventData?: Record<string, any>) {
    if (isPlatformBrowser(this.platformId)) {
      mixpanel.track(eventName, this.addDefaultData(eventData));
    }
  }

  trackPageView(path: string, extra?: Record<string, any>): void {
    return;
  }

  userIdentified(email: string, extras: UserIdentifiedExtras): void {
    if (isPlatformBrowser(this.platformId)) {
      mixpanel.people.set(this.addDefaultData({...extras, email}));
    }
  }

  updateUserData(extra: Record<string, any>): void {
    if (isPlatformBrowser(this.platformId)) {
      mixpanel.people.set(this.addDefaultData(extra));
    }
  }

  setUserId(userId: string): void {
    if (isPlatformBrowser(this.platformId)) {
      mixpanel.identify(userId);
    }
  }

  private addDefaultData(data?: Record<string, any>): Record<string, any> {
    return {...data, env: this.env, browserId: this.browserIdService.getBrowserId()};
  }
}
