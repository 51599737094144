export enum AgentContactEnrollmentStatus {
  ENROLLED = "ENROLLED",
  UNENROLLED = "UNENROLLED",
  UNSUB = "UNSUB",
  PENDING = "PENDING",
  NA = "NA"
}

export const AgentContactEnrollmentStatusLabel: Record<AgentContactEnrollmentStatus, string> = {
  [AgentContactEnrollmentStatus.ENROLLED]: "Active",
  [AgentContactEnrollmentStatus.UNENROLLED]: "Inactive",
  [AgentContactEnrollmentStatus.UNSUB]: "Inactive",
  [AgentContactEnrollmentStatus.PENDING]: "Pending",
  [AgentContactEnrollmentStatus.NA]: "Not Applicable"
};
export enum CampaignType {
  WELCOME = "WELCOME",
  HOME_VALUE = "HOME_VALUE",
  MARKET_UPDATE = "MARKET_UPDATE"
}

export const CampaignTypeLabel: Record<CampaignType, string> = {
  [CampaignType.WELCOME]: "Welcome",
  [CampaignType.HOME_VALUE]: "Home Value",
  [CampaignType.MARKET_UPDATE]: "Market Update"
};

export interface CampaignStatsHolder {
  click?: number;
  email?: number;
  open?: number;
  bounce?: number;
  delivered?: number;
  unsub?: number;
  homeValue: number;
  cma: number;
  callback: number;
  sellerLead: number;
  cashOffer: number;
}

export enum ContactEngageActivityType {
  SENT = "SENT",
  OPENED = "OPENED",
  CLICKED = "CLICKED",
  OPPORTUNITY = "OPPORTUNITY",
  FAILED = "FAILED",
  SCHEDULED = "SCHEDULED",
  HOME_VALUE = "HOME_VALUE"
}
