import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  forwardRef,
  Inject,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
  ViewContainerRef
} from "@angular/core";
import {DashboardService} from "../../dashboard.service";
import {DashboardModuleHostDirective} from "../../directives";
import {FelloWebsites, mixinDestroyable} from "../../../../lib";
import {shareReplay, take, takeUntil, tap} from "rxjs/operators";
import {DASHBOARD_VIEW_TYPE, DashboardViewType} from "../../tokens";
import {DashboardSettingsService} from "../../services/dashboard-settings.service";
import {
  AgentSignatureType,
  AvailableDashboardModules,
  DashboardModuleConfig,
  DashboardModuleType,
  DashboardSettingsResponse,
  FelloUserContactDetails
} from "fello-model";
import {MatDialog} from "@angular/material/dialog";
import {ContactAgentComponent} from "../dialogs";
import {sortBy} from "lodash-es";
import {AbstractDashboardService, AbstractDashboardSettingsService} from "../../services";
import {DOCUMENT} from "@angular/common";

const OFFSET_TOP = 50;

@Component({
  selector: "app-dashboard-wrapper",
  templateUrl: "./dashboard-wrapper.component.html",
  styleUrls: ["./dashboard-wrapper.component.scss"],
  providers: [
    DashboardService,
    {
      provide: AbstractDashboardService,
      useExisting: forwardRef(() => DashboardService)
    },
    {
      provide: AbstractDashboardSettingsService,
      useExisting: forwardRef(() => DashboardSettingsService)
    },
    {provide: DASHBOARD_VIEW_TYPE, useValue: DashboardViewType.HOME_OWNER},
    DashboardSettingsService
  ]
})
export class DashboardWrapperComponent extends mixinDestroyable(class {}) implements AfterViewInit, OnInit {
  homeValueEstimate$ = this.dashboardService.homeEstimate$.pipe(shareReplay(1));
  agentSignature$ = this.dashboardService.agentSignature$;
  private _dashboardSettings: DashboardSettingsResponse | null = null;
  modulesToDisplay: DashboardModuleConfig[] = [];
  isHomeValueModuleDisplayed = false;
  DashboardModuleType = DashboardModuleType;
  felloWebsites = FelloWebsites;

  get dashboardSettings(): DashboardSettingsResponse | null {
    return this._dashboardSettings;
  }

  set dashboardSettings(value: DashboardSettingsResponse | null) {
    this._dashboardSettings = value;
    this.modulesToDisplay =
      sortBy(
        this.dashboardSettings?.config.modules.filter(module => module.isActive),
        each => each.seq
      ) ?? [];
    this.isHomeValueModuleDisplayed = !!this.modulesToDisplay.find(module => module.moduleType === DashboardModuleType.HOME_ESTIMATE);
  }

  @ViewChildren(DashboardModuleHostDirective) listItems: QueryList<DashboardModuleHostDirective<AvailableDashboardModules>>;
  @ViewChild("scrollingContainer") container: ElementRef;
  AgentSignatureType = AgentSignatureType;

  constructor(
    private cd: ChangeDetectorRef,
    private dashboardService: AbstractDashboardService,
    private dialog: MatDialog,
    private viewContainerRef: ViewContainerRef,
    private dashboardSettingsService: DashboardSettingsService,
    @Inject(DOCUMENT) private document: Document
  ) {
    super();
  }

  ngOnInit() {
    this.dashboardSettingsService.settings$
      .pipe(
        takeUntil(this.isDestroyed),
        tap(settings => (this.dashboardSettings = settings))
      )
      .subscribe();
  }

  ngAfterViewInit(): void {
    this.cd.detectChanges();
  }

  openContactAgent() {
    this.dashboardService.contact$
      .pipe(
        take(1),
        tap(contact => {
          this.dialog.open(ContactAgentComponent, {
            maxWidth: 580,
            data: {
              fullName: contact.fullName,
              phone: contact.phone,
              email: contact.emailId
            } as FelloUserContactDetails,
            viewContainerRef: this.viewContainerRef
          });
        })
      )
      .subscribe();
  }

  scrollToModule(moduleId: string) {
    setTimeout(() => {
      const el = this.document.getElementById(moduleId);
      if (el) {
        this.container.nativeElement.scrollTo({behavior: "smooth", top: el.offsetTop - OFFSET_TOP});
        el.classList.add("animate__bounce");
        setTimeout(() => {
          el.classList.remove("animate__bounce");
        }, 1000);
      }
    }, 100);
  }
}
