<input
  type="text"
  [placeholder]="placeholder"
  class="lib-autocomplete-dropdown-input"
  [id]="id + '-input'"
  [(ngModel)]="searchText"
  [ngModelOptions]="{standalone: true}"
  (ngModelChange)="onSearchChange($event?.toString() || '')"
  [matAutocomplete]="auto"
  (blur)="onFocusOut($event);onBlur($event)"
  (focus)="onFocusIn()"
  [disabled]="disabled"
  [ngClass]="class"
  autocomplete="off"
  #trigger="matAutocompleteTrigger"
/>
<mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" (optionSelected)="onAutoCompleteOptionSelect($event)" panelWidth="320">
  <ng-content></ng-content>
</mat-autocomplete>
