export class RegexUtils {
  //Minimum eight characters, at least one letter and one number
  static readonly PASSWORD_REGEXP = /^(?=.*\d)(?=.*[a-z])[\w~`!@#$%^&*()-_+={}|:;"'<>,.?\/]{8,}$/;
  static readonly COMMA_SEPERATED_EMAIL_REGXP = /^([\w\-_+.\/]+\@[A-Za-z0-9]+((\.|-)[A-Za-z0-9]+)*\.[A-Za-z0-9]{2,4}\s*?,?\s*?)+$/;
  static readonly URL_PATTERN = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w.-]+)+[\w\-._~:/?#[\]@!$&'()*+,;=]+$/;
  static readonly URL_PATTERN_WITH_PROTOCOL = /^(?:http(s)?:\/\/)[\w.-]+(?:\.[\w.-]+)+[\w\-._~:/?#[\]@!$&'()*+,;=]+$/;
  static readonly COLOR_HEX_CODE = /^#[A-Fa-f0-9]{6}$/;
  static readonly SUB_DOMAIN_REGEXP = /^[A-Za-z0-9](?:[A-Za-z0-9\-]{0,61}[A-Za-z0-9])?$/;
  static readonly PRIMARY_DOMAIN_REGEXP =
    /^[A-Za-z0-9](?:[A-Za-z0-9\-]{0,61}[A-Za-z0-9])?\.[A-Za-z0-9](?:[A-Za-z0-9\-]{0,61}[A-Za-z0-9])?$/;
  static readonly NUMBER_REGEXP = /^[0-9]+$/;
  static readonly RESTRICT_WHITESPACE_AT_START_REGEXP = /^\S.*/;
  static readonly URL_SLUG_REGEXP = /^[a-zA-Z0-9_-]+$/;
  static readonly EMAIL_REGEXP =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  static readonly URL_START = /^(https?:\/\/)?(www\.)?/i;
}
