<div
  [ngClass]="{'scrollable-container': !infiniteScrollContainer}"
  infiniteScroll
  [infiniteScrollDistance]="infiniteScrollDistance"
  [infiniteScrollThrottle]="infiniteScrollThrottle"
  [infiniteScrollContainer]="infiniteScrollContainer"
  [scrollWindow]="false"
  [horizontal]="horizontal"
  (scrolled)="onScroll()"
>
  <ng-container *ngIf="itemTemplate">
    <ng-container
      *ngFor="let item of items; trackBy: itemTemplate.trackByFunction; let index = index; let first = first; let last = last; let even = even; let odd = odd"
    >
      <ng-container
        *ngTemplateOutlet="itemTemplate.template; context: {item: item, items: items, index: index, count: items.length, first: first, last: last, even: even, odd: odd }"
      ></ng-container>
    </ng-container>
  </ng-container>

  <ng-container *ngIf="isLoading">
    <ng-container [ngTemplateOutlet]="loaderTemplate?.template ?? null"></ng-container>
  </ng-container>

  <ng-container *ngIf="totalCount === 0">
    <ng-container [ngTemplateOutlet]="nDataTemplate?.template ?? null"></ng-container>
  </ng-container>
</div>
