<ng-container *ngIf="sellingOptionWrappers$ | async as sellingOptionWrappers; else loader">
  <div class="db-card page-break-inside-avoid" *ngIf="sellingOptionWrappers.length">
    <div>
      <div class="mb-4">
        <p class="text-size-20 fw-600 db-card-title" *ngIf="moduleSetting.title">{{ moduleSetting.title }}</p>
        <p
          class="text-size-14 fw-500 text-color-light-2 mt-1 db-card-desc"
          *ngIf="moduleSetting.showSubheading && moduleSetting.subheading"
        >
          {{ moduleSetting.subheading }}
        </p>
      </div>
      <ng-container *ngFor="let sellingOptionWrapper of sellingOptionWrappers">
        <ng-container
          libSellingOptionHost
          [sellingOptionType]="sellingOptionWrapper.sellingOptionType"
          [sellingOptionWrapper]="sellingOptionWrapper"
          [sellingOptionSettings]="getSellingOptionModuleSetting(sellingOptionWrapper.sellingOptionType)"
        ></ng-container>
      </ng-container>
      <p *ngIf="moduleSetting.showDisclaimer && moduleSetting.disclaimerMessage" class="text-size-10 text-color-light-2 fw-400 mt-4">
        *{{ moduleSetting.disclaimerMessage }}
      </p>
    </div>
  </div>
</ng-container>

<ng-template #loader>
  <div>
    <ngx-skeleton-loader count="1" [theme]="{height: '200px', width: '100%'}"></ngx-skeleton-loader>
  </div>
</ng-template>
