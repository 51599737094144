import {AcquireOfferRequestStatus} from "./AcquireOfferRequestStatus";
import {AcquireStage} from "./AcquireStage";
import {IOfferPricing, RangedValue} from "./OfferPricing";

export enum AcquireOfferType {
  PRELIM = "PRELIM",
  FINAL = "FINAL",
  NOT_OFFERING = "NOT_OFFERING"
}
export const AcquireOfferTypeLabel: Record<AcquireOfferType, string> = {
  [AcquireOfferType.PRELIM]: "Preliminary",
  [AcquireOfferType.FINAL]: "Final",
  [AcquireOfferType.NOT_OFFERING]: "Not Offering"
};

export enum CashOfferType {
  PRELIM = "PRELIM",
  FINAL = "FINAL"
}

export const CashOfferTypeLabel: Record<CashOfferType, string> = {
  [CashOfferType.PRELIM]: "Preliminary",
  [CashOfferType.FINAL]: "Final"
};

export const AcquireOfferTypeToAgentCashOfferType: Record<Exclude<AcquireOfferType, AcquireOfferType.NOT_OFFERING>, CashOfferType> = {
  [AcquireOfferType.FINAL]: CashOfferType.FINAL,
  [AcquireOfferType.PRELIM]: CashOfferType.PRELIM
};

export interface AgentDealAcquirePauseOfferRequest {
  isPaused: boolean;
  remarks?: string;
  at: string;
}

export interface BaseCashOffer {
  id: string;
  buyerName: string;
  buyerDisplayName: string;
  pricing: IOfferPricing;
  isShowNetOfferOnly: boolean;
  isPriceNegotiable: boolean;
  isClosingSplit: boolean;
  numInspections?: number;
  numShowings?: number;
  daysToClose: Partial<RangedValue>;
  expiresAt?: string;
  remarks?: string;
  createdAt: string;
}

export interface AgentListingOffer {
  id: string;
  buyerDisplayName: string;
  pricing: IOfferPricing;
  isShowNetOfferOnly: boolean;
  isPriceNegotiable: boolean;
  isClosingSplit: boolean;
  numInspections?: number;
  numShowings?: number;
  daysToClose?: number;
  expiresAt?: string;
  isActiveToSeller: boolean;
}

export interface AgentCashOffer extends BaseCashOffer {
  agentOfferType?: CashOfferType;
  isActiveToSeller: boolean;
}

export interface AcquireCashOffer extends BaseCashOffer {
  acquireOfferType: AcquireOfferType;
}

export interface AcquireOfferRequest {
  buyerName: string;
  requestedAt: string;
  status: AcquireOfferRequestStatus;
  publishedOffers: AcquireCashOffer[];
  draftOffer: AcquireCashOffer;
}

export interface AgentDealAcquireOfferInfo {
  requestedAt: string;
  stage: AcquireStage;
  pauseNewOffers: AgentDealAcquirePauseOfferRequest;
  offerRequests: AcquireOfferRequest[];
}

export interface AgentDealOffer {
  cash: AgentCashOffer[];
  listing?: AgentListingOffer;
  acquire?: AgentDealAcquireOfferInfo;
}
