import {Directive, OnInit, TemplateRef, ViewContainerRef} from "@angular/core";
import {GoogleMapsLoadedService} from "../../fello-ui-lib/services";

@Directive({
  selector: "[libIsGoogleMapsLoaded]",
  standalone: true
})
export class IsGoogleMapsLoadedDirective implements OnInit {
  constructor(
    private googleMapsLoadedService: GoogleMapsLoadedService,
    private templateRef: TemplateRef<any>,
    private viewContainerRef: ViewContainerRef
  ) {}

  ngOnInit(): void {
    this.googleMapsLoadedService.isGoogleMapsLoaded$.subscribe(isLoaded => {
      if (isLoaded) {
        this.viewContainerRef.createEmbeddedView(this.templateRef);
      } else {
        this.viewContainerRef.clear();
      }
    });
  }
}
