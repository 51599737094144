import {Component, Inject, OnInit} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {DashboardHomeFactsUpdateRequest} from "fello-model";
import {DashboardHomeFactsRequest} from "../DashboardHomeFactsRequest";

@Component({
  selector: "app-home-details-edit-dialog",
  templateUrl: "./home-details-edit-dialog.component.html",
  styleUrls: ["./home-details-edit-dialog.component.scss"]
})
export class HomeDetailsEditDialogComponent implements OnInit {
  homeFacts: DashboardHomeFactsRequest;
  constructor(@Inject(MAT_DIALOG_DATA) data: DashboardHomeFactsRequest, private dialogRef: MatDialogRef<DashboardHomeFactsUpdateRequest>) {
    this.homeFacts = data;
  }

  ngOnInit(): void {}

  save(): void {
    this.dialogRef.close(this.homeFacts);
  }

  get isValid(): boolean {
    if (
      this.homeFacts.beds! > -1 &&
      this.homeFacts.sqft! > -1 &&
      this.homeFacts.partialBaths! > -1 &&
      this.homeFacts.baths! > -1 &&
      this.homeFacts.storiesCount! > -1 &&
      this.homeFacts.yearBuilt! > 1700 &&
      this.homeFacts.yearBuilt! <= new Date().getFullYear() &&
      this.homeFacts.kitchenCondition &&
      this.homeFacts.bathroomCondition &&
      this.homeFacts.flooringCondition &&
      this.homeFacts.homePaintCondition &&
      this.homeFacts.overallCondition &&
      this.homeFacts.additionalBathroomCondition
    ) {
      return true;
    }
    return false;
  }
}
