/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  AfterContentInit,
  AfterViewInit,
  Component,
  ContentChildren,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  OnDestroy,
  Optional,
  Output,
  QueryList,
  Self,
  ViewChild,
  ViewEncapsulation
} from "@angular/core";
import {MatOption} from "@angular/material/core";
import {MatAutocomplete, MatAutocompleteModule, MatAutocompleteSelectedEvent, MatAutocompleteTrigger} from "@angular/material/autocomplete";
import {ControlValueAccessor, FormsModule, NgControl} from "@angular/forms";
import {startWith, takeUntil} from "rxjs/operators";
import {Subject} from "rxjs";
import {coerceBooleanProperty} from "@angular/cdk/coercion";
import {mixinDestroyable} from "../../../../lib";
import {NgClass} from "@angular/common";

@Component({
  selector: "lib-autocomplete-dropdown",
  templateUrl: "./autocomplete-dropdown.component.html",
  styleUrls: ["./autocomplete-dropdown.component.scss"],
  encapsulation: ViewEncapsulation.None,
  imports: [FormsModule, MatAutocompleteModule, NgClass],
  standalone: true
})
export class AutocompleteDropdownComponent
  extends mixinDestroyable(class {})
  implements AfterViewInit, AfterContentInit, ControlValueAccessor, OnDestroy
{
  searchText = "";
  selectedOption?: MatOption;
  @ContentChildren(MatOption, {descendants: true}) options: QueryList<MatOption>;
  @ViewChild(MatAutocomplete) autoComplete: MatAutocomplete;
  @ViewChild(MatAutocompleteTrigger, {read: MatAutocompleteTrigger}) inputAutoComplete: MatAutocompleteTrigger;

  @Output() searchChange = new EventEmitter<string>();
  @Output() optionSelected = new EventEmitter<MatAutocompleteSelectedEvent>();
  @Input() class: string;

  private _placeholder: string;
  private _disabled = false;
  private _value: any;
  private _required = false;
  @Input() optionToLabel?: (option: any) => string;
  stateChanges = new Subject<void>();
  focused = false;
  touched = false;
  static nextId = 0;

  @HostBinding() id = `lib-auto-complete-dropdown-${AutocompleteDropdownComponent.nextId++}`;

  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input("aria-describedby") userAriaDescribedBy: string;
  @Input()
  get value(): any {
    return this._value;
  }
  set value(newValue: any) {
    // Always re-assign an array, because it might have been mutated.
    if (newValue !== this._value) {
      if (this.options?.length) {
        const selectedOption = this._selectValue(newValue);
        if (!selectedOption) {
          this._selectOption();
          this._value = newValue;
        }
      } else {
        this._selectOption();
        this._value = newValue;
      }
    }
    this.stateChanges.next();
  }

  @Input()
  get disabled(): boolean {
    return this._disabled;
  }
  set disabled(value: boolean) {
    this._disabled = coerceBooleanProperty(value);
    this.stateChanges.next();
  }

  @Input()
  get placeholder(): string {
    return this._placeholder;
  }
  set placeholder(plh: string) {
    this._placeholder = plh;
    this.stateChanges.next();
  }

  @Input()
  get required(): boolean {
    return this._required;
  }
  set required(req: boolean) {
    this._required = coerceBooleanProperty(req);
    this.stateChanges.next();
  }

  get empty(): boolean {
    return !this.searchText;
  }
  get errorState(): boolean {
    return this.ngControl?.invalid || this.touched;
  }

  @HostBinding("class.floating")
  get shouldLabelFloat(): boolean {
    return this.focused || !this.empty;
  }

  _onChange: (value: any) => void = () => {
    return;
  };
  _onTouched = (): void => {
    return;
  };

  constructor(@Optional() @Self() public ngControl: NgControl, private _elementRef: ElementRef) {
    super();
    if (this.ngControl) {
      // Note: we provide the value accessor through here, instead of
      // the `providers` to avoid running into a circular import.
      this.ngControl.valueAccessor = this;
    }
  }

  ngAfterViewInit(): void {
    this.autoComplete.options = this.options;
    this.autoComplete.ngAfterContentInit();
  }

  ngAfterContentInit(): void {
    this.options.changes.pipe(startWith(null), takeUntil(this.isDestroyed)).subscribe(() => {
      Promise.resolve().then(() => {
        this._selectValue(this._value);
      });
    });
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.stateChanges.complete();
  }

  onSearchChange(searchText: string): void {
    this.searchChange.emit(searchText);
  }

  onAutoCompleteOptionSelect(event: MatAutocompleteSelectedEvent): void {
    window.setTimeout(() => {
      this._selectOption(event.option);
      this._onChange(this.value);
      this.optionSelected.emit(event);
    });
  }

  onBlur(event: FocusEvent): void {
    this._onTouched();
    this.touched = true;
    if (
      (event.relatedTarget && (event.relatedTarget as Element).tagName === "MAT-OPTION") ||
      this.getOptionLabel(this.selectedOption) === this.searchText
    ) {
      return;
    }

    if (this.searchText === "") {
      this._selectOption();
    } else if (this.options?.length > 0) {
      this.selectedOption = this.options.first;
      this._selectOption(this.options.first);
    } else {
      this._selectOption(this.selectedOption);
    }
    this._onChange(this.value);
  }

  private _selectOption(option?: MatOption): void {
    this.selectedOption = option;
    this.searchText = this.getOptionLabel(option);
    this._value = option?.value;
  }

  private _selectValue(value: any): MatOption | undefined {
    const correspondingOption = this.options.find((option: MatOption) => {
      // Skip options that are already in the model. This allows us to handle cases
      // where the same primitive value is selected multiple times.
      if (this.selectedOption?.value === option.value) {
        return false;
      }

      return option.value !== undefined && option.value === value;
    });

    if (correspondingOption) {
      this._selectOption(correspondingOption);
    }
    return correspondingOption;
  }

  private getOptionLabel(option?: MatOption): string {
    if (!option) {
      return "";
    }
    if (this.optionToLabel) {
      return this.optionToLabel(option.value);
    }
    return option.viewValue;
  }

  registerOnChange(fn: any): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this._onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  writeValue(newValue: any): void {
    this.value = newValue;
  }

  onFocusIn(): void {
    if (!this.focused) {
      this.focused = true;
      this.stateChanges.next();
    }
  }

  onFocusOut(event: FocusEvent): void {
    if (!this._elementRef.nativeElement.contains(event.relatedTarget as Element)) {
      this.focused = false;
      this.touched = true;
      this._onTouched();
      this.stateChanges.next();
    }
  }
  public focus(): void {
    this.inputAutoComplete.openPanel();
  }
}
