import {Component, Inject, Optional, ViewContainerRef} from "@angular/core";
import {AgentCashOffer, AgentListingOffer, CashOfferType, FelloUserContactDetails, IOfferPricing} from "fello-model";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import moment from "moment";
import {MatSnackBar} from "@angular/material/snack-bar";
import {AbstractDashboardService} from "../../../services";
import {take, tap} from "rxjs/operators";
import {ContactAgentComponent} from "../../dialogs";

@Component({
  selector: "lib-offer-details",
  templateUrl: "./offer-details.component.html",
  styleUrls: ["./offer-details.component.scss"]
})
export class OfferDetailsComponent {
  cashOffer?: AgentCashOffer;
  listingOffer?: AgentListingOffer;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    private data: {
      viewContainerRef: ViewContainerRef;
      cashOffer?: AgentCashOffer;
      listingOffer?: AgentListingOffer;
    },
    private matDialog: MatDialog,
    private snackBar: MatSnackBar,
    private dialogRef: MatDialogRef<unknown>,
    private viewContainerRef: ViewContainerRef,
    @Optional() public dashboardService?: AbstractDashboardService
  ) {
    this.cashOffer = data.cashOffer;
    this.listingOffer = data.listingOffer;
  }

  get isRange(): boolean {
    return this.cashOffer?.pricing.isRange ?? this.listingOffer?.pricing.isRange ?? false;
  }

  get expiry(): string | undefined {
    return this.cashOffer?.expiresAt;
  }

  get offerType(): CashOfferType | undefined {
    return this.cashOffer?.agentOfferType;
  }

  get isShowNetProceedsOnly(): boolean {
    return this.cashOffer?.isShowNetOfferOnly ?? this.listingOffer?.isShowNetOfferOnly ?? false;
  }

  get pricing(): IOfferPricing {
    return (this.cashOffer?.pricing ?? this.listingOffer?.pricing)!;
  }

  get numInspections(): number {
    return this.cashOffer?.numInspections ?? this.listingOffer?.numInspections ?? 0;
  }

  get numShowings(): number {
    return this.cashOffer?.numShowings ?? this.listingOffer?.numShowings ?? 0;
  }

  get closingCostSplit(): boolean {
    return this.cashOffer?.isClosingSplit ?? this.listingOffer?.isClosingSplit ?? false;
  }

  get priceNegoatiable(): boolean {
    return this.cashOffer?.isPriceNegotiable ?? this.listingOffer?.isPriceNegotiable ?? false;
  }

  get daysToCloseMin(): number {
    return this.cashOffer?.daysToClose.min ?? this.listingOffer?.daysToClose ?? 0;
  }

  get expiryDaysLeft(): number {
    return moment(this.expiry).diff(moment(), "days");
  }

  openContactAgent() {
    this.dialogRef.close();
    this.dashboardService?.contact$
      .pipe(
        take(1),
        tap(contact => {
          this.matDialog.open(ContactAgentComponent, {
            maxWidth: 580,
            data: {
              fullName: contact.fullName,
              phone: contact.phone,
              email: contact.emailId
            } as FelloUserContactDetails,
            viewContainerRef: this.data.viewContainerRef
          });
        })
      )
      .subscribe();
  }
}
