export enum AgentFindingStep {
  DECIDING = "DECIDING",
  RESEARCHING = "RESEARCHING",
  INTERVIEWING = "INTERVIEWING",
  HIRED = "HIRED"
}

export const AgentFindingStepLabel: Record<AgentFindingStep, string> = {
  [AgentFindingStep.DECIDING]: "Still deciding",
  [AgentFindingStep.RESEARCHING]: "Started researching",
  [AgentFindingStep.INTERVIEWING]: "Interviewing agents",
  [AgentFindingStep.HIRED]: "Hired an agent",
};
