import {APP_INITIALIZER, ErrorHandler, NgModule} from "@angular/core";
import {BrowserModule} from "@angular/platform-browser";
import {
  AGENT_API_SERVICE_BASE_URL,
  AllowCookiesInterceptor,
  APPLE_AUTH_CLIENT_ID,
  ENVIRONMENT,
  FELLO_API_SERVICE_BASE_URL,
  FELLO_CONNECT_BASE_URL,
  FelloApiResponseInterceptor,
  FelloApiService,
  GOOGLE_AUTH_CLIENT_ID,
  GOOGLE_MAPS_API_KEY,
  GOOGLE_STREET_VIEW_API_KEY,
  GoogleMapsLoadedService,
  HUBSPOT_TRACKING_SCRIPT_SRC,
  MEDIA_SERVICE_BASE_URL,
  MIX_PANEL_API_KEY,
  MixPanelEventTracker,
  PAYMENT_API_SERVICE_BASE_URL,
  SMARTY_STREETS_BASE_URL,
  TitleService
} from "fello-common";
import {environment} from "../environments/environment";
import {AppRoutingModule} from "./app-routing.module";
import {AppComponent} from "./app.component";
import {SharedModule} from "./modules/shared/shared.module";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {HTTP_INTERCEPTORS, HttpClientModule} from "@angular/common/http";
import {Router, TitleStrategy} from "@angular/router";
import * as Sentry from "@sentry/angular";
import {MatSnackBarModule} from "@angular/material/snack-bar";
import {MatAutocompleteModule} from "@angular/material/autocomplete";

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule.withServerTransition({appId: "serverApp"}),
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    MatSnackBarModule,
    MatAutocompleteModule
  ],
  providers: [
    FelloApiService,
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false
      })
    },
    {
      provide: Sentry.TraceService,
      deps: [Router]
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService, GoogleMapsLoadedService],
      multi: true
    },
    {
      provide: FELLO_API_SERVICE_BASE_URL,
      useValue: environment.felloApiBaseUrl
    },
    {
      provide: SMARTY_STREETS_BASE_URL,
      useValue: environment.smartyStreetsBaseURL
    },
    {
      provide: PAYMENT_API_SERVICE_BASE_URL,
      useValue: environment.paymentApiBaseUrl
    },
    {
      provide: AGENT_API_SERVICE_BASE_URL,
      useValue: environment.agentServiceBaseURL
    },
    {
      provide: GOOGLE_AUTH_CLIENT_ID,
      useValue: environment.googleClientId
    },
    {
      provide: APPLE_AUTH_CLIENT_ID,
      useValue: environment.appleAuthClientId
    },
    {
      provide: FELLO_CONNECT_BASE_URL,
      useValue: environment.felloConnectBaseUrl
    },
    {
      provide: ENVIRONMENT,
      useValue: environment.env
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AllowCookiesInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: FelloApiResponseInterceptor,
      multi: true
    },
    {
      provide: HUBSPOT_TRACKING_SCRIPT_SRC,
      useValue: environment.hubspotTrackingScriptSrc
    },
    {
      provide: GOOGLE_STREET_VIEW_API_KEY,
      useValue: environment.googleStreetViewApiKey
    },
    {
      provide: MEDIA_SERVICE_BASE_URL,
      useValue: environment.mediaServiceBaseURL
    },
    {
      provide: GOOGLE_MAPS_API_KEY,
      useValue: environment.googleMapsApiKey
    },
    {
      provide: MIX_PANEL_API_KEY,
      useValue: environment.mixPanelApiKey
    },
    MixPanelEventTracker,
    {
      provide: TitleStrategy,
      useExisting: TitleService
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
