export enum PropertyTypeStandardised {
  SFR = "SFR",
  CONDO = "CONDO",
  APRT = "APRT",
  DUP = "DUP",
  COMMERCIAL = "COMMERCIAL",
  MISCELLANEOUS = "MISCELLANEOUS",
  HOTEL_MOTEL = "HOTEL_MOTEL",
  COMMERCIAL_CONDOMINIUM = "COMMERCIAL_CONDOMINIUM",
  RETAIL = "RETAIL",
  SERVICE = "SERVICE",
  OFFICE_BUILDING = "OFFICE_BUILDING",
  WAREHOUSE = "WAREHOUSE",
  FINANCIAL_INSTITUTION = "FINANCIAL_INSTITUTION",
  HOSPITAL = "HOSPITAL",
  PARKING = "PARKING",
  AMUSEMENT_RECREATION = "AMUSEMENT_RECREATION",
  INDUSTRIAL = "INDUSTRIAL",
  INDUSTRIAL_LIGHT = "INDUSTRIAL_LIGHT",
  INDUSTRIAL_HEAVY = "INDUSTRIAL_HEAVY",
  TRANSPORT = "TRANSPORT",
  UTILITIES = "UTILITIES",
  AGRICULTURAL = "AGRICULTURAL",
  VACANT = "VACANT",
  EXEMPT = "EXEMPT"
}

export const PropertyTypeStandardisedLabel: Record<PropertyTypeStandardised, string> = {
  AGRICULTURAL: "Agricultural",
  AMUSEMENT_RECREATION: "Amusement Recreation",
  APRT: "Apartment",
  COMMERCIAL: "Commercial",
  COMMERCIAL_CONDOMINIUM: "Commercial Condominium",
  CONDO: "Condominium",
  DUP: "Duplex",
  EXEMPT: "Exempt",
  FINANCIAL_INSTITUTION: "Financial Institution",
  HOSPITAL: "Hospital",
  HOTEL_MOTEL: "Hotel Motel",
  INDUSTRIAL: "Industrial",
  INDUSTRIAL_HEAVY: "Industrial Heavy",
  INDUSTRIAL_LIGHT: "Industrial Light",
  MISCELLANEOUS: "Miscellaneous",
  OFFICE_BUILDING: "Office Building",
  PARKING: "Parking",
  RETAIL: "Retail",
  SERVICE: "Service",
  SFR: "Single Family Residence",
  TRANSPORT: "Transport",
  UTILITIES: "Utilities",
  VACANT: "Vacant",
  WAREHOUSE: "Warehouse"
}
