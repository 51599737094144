import {first, isEmpty, join, last, trim} from "lodash-es";
import {v4 as uuidv4} from "uuid";
import {Agent} from "fello-model";

export class StringUtils {
  static splitFullName(fullName: string): {
    first: string;
    last: string;
  } {
    const defaultResult = {first: "", last: ""};

    if (!fullName) {
      return defaultResult;
    }

    const splitBySpaces = fullName.split(" ");

    if (splitBySpaces.length === 1) {
      return {first: splitBySpaces[0], last: ""};
    }

    const [first, ...rest] = splitBySpaces;
    const last = rest.join(" ").trim();

    return {first, last};
  }
  static mergeFirstAndLastName(first: string, last: string): string {
    if (last) {
      return first + " " + last;
    }
    return first;
  }

  static customStartCase(input: string): string {
    input = input.trim();
    if (!input.length) {
      return input;
    }

    const words = input.split(/\s+/);
    const capitalizedWords = words.map(word => (word.length > 0 ? word[0].toUpperCase() + word.slice(1) : ""));
    return capitalizedWords.join(" ");
  }

  static capitalizeFirstLetter(word: string): string {
    word = word.trim();
    if (!word.length) {
      return word;
    }
    return word[0].toUpperCase() + word.slice(1).toLowerCase();
  }
  static getInitials(name?: string): string {
    if (name?.trim()) {
      const words = name
        .split(" ")
        .map(word => word.trim())
        .filter(Boolean);
      if (words.length === 1) {
        return words[0][0].toUpperCase();
      }
      return [first(words), last(words)].map(word => word![0].toUpperCase()).join("");
    }
    return "-";
  }

  static uuid(): string {
    return uuidv4();
  }

  static convertBase64ToBlob(Base64Image: string) {
    // split into two parts
    const parts = Base64Image.split(";base64,");
    // hold the content type
    const imageType = parts[0].split(":")[1];
    // decode base64 string
    const decodedData = window.atob(parts[1]);
    // create unit8array of size same as row data length
    const uInt8Array = new Uint8Array(decodedData.length);
    // insert all character code into uint8array
    for (let i = 0; i < decodedData.length; ++i) {
      uInt8Array[i] = decodedData.charCodeAt(i);
    }
    // return blob image after conversion
    return new Blob([uInt8Array], {type: imageType});
  }

  static removeExtraSpaces(strings: TemplateStringsArray, ...values: (string | number | null | undefined)[]): string {
    // TagFunction
    const parts: string[] = [];
    for (let i = 0; i < strings.length; i++) {
      parts.push(trim(strings[i], " "));
      if (i < values.length && values[i] != undefined && values[i] != null) {
        parts.push(trim(values[i]?.toString(), " "));
      }
    }
    return join(parts, " ");
  }

  static buildFullName(data: {firstName?: string; lastName?: string} | undefined | null): string | undefined {
    if (isEmpty(data) || (isEmpty(data!.firstName) && isEmpty(data!.lastName))) {
      return "";
    } else if (isEmpty(data!.firstName)) {
      return data!.lastName!.trim();
    } else if (isEmpty(data!.lastName)) {
      return data!.firstName!.trim();
    }
    return data!.firstName!.trim() + " " + data!.lastName!.trim();
  }
}
