import {Pipe, PipeTransform} from "@angular/core";
import {PriceUtil} from "../../../lib";

@Pipe({
  name: "formatPrice",
  standalone: true
})
export class FormatPricePipe implements PipeTransform {
  transform(amount: number, currency = "USD", maximumFractionDigits = 0): string {
    return PriceUtil.formatPrice(amount, currency, maximumFractionDigits);
  }
}
