<input
  [ngModel]="search$ | async"
  name="propertyAddress"
  id="propertyAddress"
  #trigger="matAutocompleteTrigger"
  [placeholder]="placeholder ?? 'Search for address'"
  maxlength="100"
  [required]="true"
  (blur)="onTouched()"
  (ngModelChange)="search$.next($event); onChange($event)"
  [matAutocomplete]="auto"
  (keydown.backspace)="selectedAddress$.next(undefined)"
  [disabled]="disabled"
  (paste)="selectedAddress$.next(undefined)"
  autocomplete="one-time-code"
/>
<mat-autocomplete #auto="matAutocomplete">
  <ng-container *ngFor="let suggestion of (suggestions | async)">
    <mat-option
      [value]="suggestion.street_line + ' ' + suggestion.secondary"
      *ngIf="suggestion.entries > 1; else singleEntryOption"
      (click)="entrySelected($event,suggestion);trigger.openPanel()"
    >
      <span class="flex-box-center">
        <span class="text-overflow-ellipsis pr-1">{{ buildAddress(suggestion) }}</span>
        <span class="link flex-box-center ml-auto hover-no-underline"
          >+ {{suggestion.entries}} addresses
          <lib-icon [fontSize]="20">right</lib-icon>
        </span>
      </span>
    </mat-option>
    <ng-template #singleEntryOption>
      <mat-option [value]="buildAddress(suggestion)" (onSelectionChange)="$event.source.selected ? delayOptionSelection(suggestion) : null">
        {{ buildAddress(suggestion) }}
      </mat-option>
    </ng-template>
  </ng-container>
</mat-autocomplete>
