import {Injectable} from "@angular/core";
import {NgFlowchart} from "../model/flow.model";

@Injectable({
  providedIn: "root"
})
export class DropDataService {
  dragStep: NgFlowchart.PendingStep | NgFlowchart.MoveStep | null;
  dragConnector: NgFlowchart.Connector | null;

  public setDragStep(ref: NgFlowchart.PendingStep | null) {
    this.dragStep = ref;
  }

  public getDragStep() {
    return this.dragStep;
  }

  public setDragConnector(ref: NgFlowchart.Connector | null) {
    this.dragConnector = ref;
  }

  public getDragConnector() {
    return this.dragConnector;
  }
}
