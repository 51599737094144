import {Directive, ElementRef, HostListener, Input} from "@angular/core";
import {AbstractDashboardService} from "../services";
import {DashboardModuleDirective} from "./dashboard-module.directive";

@Directive({
  selector: "[libDashboardTrackButtonClick]",
  standalone: true
})
export class DashboardTrackButtonClickDirective {
  @Input() trackLabel?: string;

  constructor(
    private dashboardService: AbstractDashboardService,
    private el: ElementRef,
    private dashboardModule: DashboardModuleDirective<any>
  ) {}

  @HostListener("click")
  onClick(): void {
    const innerText = this.el.nativeElement.innerText.trim();
    if (innerText.length || this.trackLabel?.length) {
      this.dashboardService.trackDashboardClick(this.trackLabel || innerText, this.dashboardModule.moduleId).subscribe();
    }
  }
}
