<ngx-skeleton-loader count="1" *ngIf="isLoading$ | async" [theme]="{height: '48px', width: '100%'}"></ngx-skeleton-loader>

<div class="db-card" *ngIf="currentProperty$ | async; let currentProperty">
  <ng-container *ngIf="currentProperty.addressComponents; else noAddress">
    <!--    <img [src]="img" alt="" *ngIf="(currentProperty.homeFacts?.img ?? [])[0]; let img; else streetViewImage" />-->
    <ng-container *ngTemplateOutlet="streetViewImage"></ng-container>
    <div class="w-100">
      <p class="fw-500 text-size-18">{{ currentProperty.addressComponents.doorNumber }} {{ currentProperty.addressComponents.street }}</p>
      <p class="fw-500 text-size-14 text-color-light-2">
        {{ currentProperty.addressComponents.city }}, {{ currentProperty.addressComponents.state }}
        {{ currentProperty.addressComponents.zip }}
      </p>
      <div class="line-break"></div>
      <button class="link text-size-14 fw-400" (click)="openManageAddress()">Manage Your Homes</button>
    </div>
  </ng-container>
</div>

<ng-template #noAddress>
  <div>
    <p class="fw-500 text-size-18">No address found.</p>
    <button class="link mt-2" (click)="openManageAddress()">Manage Your Homes</button>
  </div>
</ng-template>

<ng-template #streetViewImage>
  <ng-container *ngIf="currentProperty$ | async; let currentProperty">
    <ng-container *ngIf="currentProperty.propertyId | propertyExternalImg; let image; else: noImage">
      <img [src]="image" alt="" *ngIf="image" libFallbackImage="/assets/images/svg/no_property_image_old.svg" />
    </ng-container>
  </ng-container>
</ng-template>

<ng-template #noImage>
  <img src="/assets/images/svg/no_property_image_sq.svg" alt="house" />
</ng-template>
