import {Component, Inject} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: "lib-home-value-trend-calculation-v2",
  templateUrl: "./home-value-trend-calculation-v2.component.html",
  styleUrls: ["./home-value-trend-calculation-v2.component.scss"]
})
export class HomeValueTrendCalculationV2Component {
  isFaqOpen = true;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      showCTA: boolean;
      isCTAClickable: boolean;
    },
    private dialogRef: MatDialogRef<boolean>
  ) {}

  close() {
    this.dialogRef.close(true);
  }
}
