<div class="flex-box-center-between mb-4">
  <h2 mat-dialog-title class="m-0">Tell us about the condition of your {{ areaName }}</h2>
  <button class="button-icon close ml-auto" mat-dialog-close>
    <app-icon class="text-size-28">close</app-icon>
  </button>
</div>
<mat-dialog-content>
  <div class="form-field mt-4">
    <div class="radio-option-arrow" *ngFor="let option of areaConditionOptions">
      <input type="radio" [id]="option.name" [name]="areaName" [value]="option.value" (click)="selectOption(option)" />
      <label [for]="option.name">
        <img [src]="'/assets/images/area-condition/' + option.image + '.svg'" [alt]="'condition-' + option.image" />
        <div>
          {{ option.name }}
          <p class="mt-1">{{ option.description }}</p>
        </div>
        <app-icon class="ml-auto" color="var(--color-primary)" fontWeight="bold" fontSize="28">right </app-icon>
      </label>
    </div>
  </div>
</mat-dialog-content>
