import {Inject, Injectable, Optional} from "@angular/core";
import {Title} from "@angular/platform-browser";
import {FelloEnv, envConstant} from "fello-model";
import {ENVIRONMENT} from "../../tokens";
import {RouterStateSnapshot, TitleStrategy} from "@angular/router";

@Injectable({
  providedIn: "root"
})
export class TitleService extends TitleStrategy {
  constructor(private title: Title, @Optional() @Inject(ENVIRONMENT) private turboEnv?: FelloEnv) {
    super();
  }

  setTitle(title: string): void {
    this.title.setTitle(
      `${this.turboEnv !== FelloEnv.PROD && this.turboEnv !== undefined ? `[${envConstant[this.turboEnv]}] ` : ""}${title}`
    );
  }

  getTitle(): string {
    return this.title.getTitle();
  }

  updateTitle(snapshot: RouterStateSnapshot): void {
    const title = this.buildTitle(snapshot);
    if (title !== undefined) {
      this.setTitle(title);
    }
  }
}
