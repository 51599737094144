import {Inject, Injectable, InjectionToken} from "@angular/core";
import {BehaviorSubject} from "rxjs";
import {DOCUMENT} from "@angular/common";
import {LoadScriptService} from "./load-script.service";
import {DestroyableBase} from "../../../lib/mixins/helpers";
import {takeUntil} from "rxjs/operators";

export const GOOGLE_MAPS_API_KEY = new InjectionToken<string>("GOOGLE_MAPS_API_KEY");

@Injectable({
  providedIn: "root"
})
export class GoogleMapsLoadedService extends DestroyableBase {
  isGoogleMapsLoaded$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    @Inject(DOCUMENT) private document: Document,
    @Inject(GOOGLE_MAPS_API_KEY) public googleMapsApiKey: string,
    private scriptService: LoadScriptService
  ) {
    super();
    this.loadGoogleMaps();
  }

  private loadGoogleMaps(): void {
    if (this.isGoogleMapsLoaded$.value) {
      return;
    }

    this.scriptService
      .load(`https://maps.googleapis.com/maps/api/js?key=${this.googleMapsApiKey}&libraries=places&callback=console.log`)
      .pipe(takeUntil(this.isDestroyed))
      .subscribe(() => {
        this.isGoogleMapsLoaded$.next(true);
      });
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.isGoogleMapsLoaded$.complete();
  }
}
