import {Inject, Injectable, InjectionToken} from "@angular/core";
import {Observable} from "rxjs";
import {map} from "rxjs/operators";
import {HttpClient, HttpParams} from "@angular/common/http";

export const SMARTY_STREETS_BASE_URL = new InjectionToken<string>("SMARTY_STREETS_BASE_URL");

export interface SmartyStreetsResults {
  suggestions: SmartyStreetsSuggestion[];
}

export interface SmartyStreetsSuggestion {
  street_line: string;
  secondary: string;
  city: string;
  state: string;
  zipcode: string;
  entries: number;
}

@Injectable()
export class SmartyStreetsAutocompleteService {
  constructor(private http: HttpClient, @Inject(SMARTY_STREETS_BASE_URL) public smartyStreetsBaseUrl: string) {}

  getAddressSuggestion(search: string, selectedAddress?: SmartyStreetsSuggestion): Observable<SmartyStreetsSuggestion[]> {
    const params = {
      search: search,
      selected: selectedAddress ? this.buildSelected(selectedAddress, search) : "",
      source: "all"
    };
    return this.http.get<SmartyStreetsResults>(`${this.smartyStreetsBaseUrl}`, {params: params as unknown as HttpParams}).pipe(
      map((addressSuggestions: SmartyStreetsResults) => {
        return addressSuggestions.suggestions;
      })
    );
  }

  private buildSelected(selected: SmartyStreetsSuggestion, search: string): string {
    return search + " (" + selected.entries + ") " + selected.city + ", " + selected.state + " " + selected.zipcode;
  }
}
