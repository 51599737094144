import {Component, Input, OnInit} from "@angular/core";
import {MatDialog} from "@angular/material/dialog";
import {HomeConditionLevel, HomeConditionLevelLabel} from "fello-model";
import {find} from "lodash-es";
import {tap} from "rxjs/operators";
import {HomeDetailsConditionDialogComponent} from "./home-details-condition-dialog/home-details-condition-dialog.component";
import {DashboardHomeFactsRequest} from "../DashboardHomeFactsRequest";

export interface AreaCondition {
  name: string;
  value: HomeConditionLevel | null;
  reference:
    | "kitchenCondition"
    | "bathroomCondition"
    | "flooringCondition"
    | "homePaintCondition"
    | "overallCondition"
    | "additionalBathroomCondition";
}

@Component({
  selector: "app-home-details-condition",
  templateUrl: "./home-details-condition.component.html",
  styleUrls: ["./home-details-condition.component.scss"]
})
export class HomeDetailsConditionComponent implements OnInit {
  @Input() homeFacts: DashboardHomeFactsRequest;
  HomeConditionLevelLabel = HomeConditionLevelLabel;

  areaConditions: AreaCondition[] = [
    {
      name: "Kitchen",
      value: null,
      reference: "kitchenCondition"
    },
    {
      name: "Bathroom",
      value: null,
      reference: "bathroomCondition"
    },
    {
      name: "Flooring",
      value: null,
      reference: "flooringCondition"
    },
    {
      name: "Interior Paint",
      value: null,
      reference: "homePaintCondition"
    },
    {
      name: "Overall Paint",
      value: null,
      reference: "overallCondition"
    },
    {
      name: "Additional Bathroom",
      value: null,
      reference: "additionalBathroomCondition"
    }
  ];

  selectOption(area: AreaCondition, value: HomeConditionLevel): void {
    area.value = value;
  }

  constructor(private dialog: MatDialog) {}

  ngOnInit(): void {
    if (this.homeFacts) {
      this.updateFieldValue("Kitchen", this.homeFacts.kitchenCondition);
      this.updateFieldValue("Bathroom", this.homeFacts.bathroomCondition);
      this.updateFieldValue("Flooring", this.homeFacts.flooringCondition);
      this.updateFieldValue("Interior Paint", this.homeFacts.homePaintCondition);
      this.updateFieldValue("Overall Paint", this.homeFacts.overallCondition);
      this.updateFieldValue("Additional Bathroom", this.homeFacts.additionalBathroomCondition);
    }
  }

  updateFieldValue(fieldName: string, value?: HomeConditionLevel | null): void {
    if (value) {
      const field = find(this.areaConditions, {name: fieldName})!;
      field.value = value;
    }
  }

  getFieldValue(fieldName: string): HomeConditionLevel | undefined | null {
    const field = find(this.areaConditions, {name: fieldName});
    return field?.value;
  }

  editArea(area: AreaCondition): void {
    const dialogRef = this.dialog.open(HomeDetailsConditionDialogComponent, {
      data: area.name,
      panelClass: ["dialog-full-screen"]
    });

    dialogRef
      .afterClosed()
      .pipe(
        tap(level => {
          if (level) {
            area.value = level;
            this.homeFacts[area.reference] = level;
          }
        })
      )
      .subscribe();
  }
}
