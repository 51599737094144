import {Pipe, PipeTransform, SecurityContext} from "@angular/core";
import {DomSanitizer} from "@angular/platform-browser";
import {escapeRegExp} from "../constants/regExp";

@Pipe({
  name: "highlightSearch",
  standalone: true
})
export class HighlightSearchPipe implements PipeTransform {
  constructor(protected sanitizer: DomSanitizer) {}

  transform(value?: string, search?: string | null): string | null {
    let markup = value ?? "";
    if (search && value) {
      const searchRegex = new RegExp(escapeRegExp(search), "gi");
      markup = value.replace(searchRegex, match => `<span class="search-highlight">${match}</span>`);
    }
    return this.sanitizer.sanitize(SecurityContext.HTML, markup);
  }
}
