import {Inject, Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {
  AgencyQuoteCheckoutRequest,
  CoSponsorQuoteCheckoutRequest,
  QuoteBillingAddress,
  QuoteCheckoutEstimateRequest,
  QuoteCheckoutEstimateResponse,
  QuoteCheckoutRequest,
  QuoteCheckoutResponse,
  QuoteCheckoutResponseV2,
  QuoteDetailResponse
} from "fello-model";
import {Observable, of} from "rxjs";
import {PAYMENT_API_SERVICE_BASE_URL} from "../payment";

@Injectable({
  providedIn: "root"
})
export class PublicQuoteApiService {
  baseUrl: string;
  constructor(private http: HttpClient, @Inject(PAYMENT_API_SERVICE_BASE_URL) private paymentBaseUrl: string) {
    this.baseUrl = `${this.paymentBaseUrl}/payment/public/quote`;
  }
  getQuoteDetails(quoteId: string, trackView: boolean): Observable<QuoteDetailResponse> {
    return this.http.get<QuoteDetailResponse>(`${this.baseUrl}/${quoteId}`, {
      params: {
        trackView
      }
    });
  }

  getCoSponsorQuoteDetails(coSponsorQuoteId: string, trackView: boolean): Observable<QuoteDetailResponse> {
    return this.http.get<QuoteDetailResponse>(`${this.baseUrl}/co-sponsor/${coSponsorQuoteId}`, {
      params: {
        trackView
      }
    });
  }
  checkoutQuote(quoteId: string, checkoutRequest: QuoteCheckoutRequest): Observable<QuoteCheckoutResponse> {
    return this.http.post<QuoteCheckoutResponse>(`${this.baseUrl}/${quoteId}/checkout`, checkoutRequest);
  }

  quickCheckoutQuote(quoteId: string, checkoutRequest: AgencyQuoteCheckoutRequest): Observable<QuoteCheckoutResponseV2> {
    return this.http.post<QuoteCheckoutResponseV2>(`${this.baseUrl}/${quoteId}/quick-checkout`, checkoutRequest);
  }

  agencySplitCheckout(quoteId: string, checkoutRequest: AgencyQuoteCheckoutRequest): Observable<QuoteCheckoutResponseV2> {
    return this.http.post<QuoteCheckoutResponseV2>(`${this.baseUrl}/${quoteId}/agency/checkout`, checkoutRequest);
  }

  coSponsorSplitCheckout(quoteId: string, checkoutRequest: CoSponsorQuoteCheckoutRequest): Observable<QuoteCheckoutResponseV2> {
    return this.http.post<QuoteCheckoutResponseV2>(`${this.baseUrl}/${quoteId}/co-sponsor/checkout`, checkoutRequest);
  }

  getAgencyTaxEstimate(quoteId: string, checkoutEstimateRequest: QuoteCheckoutEstimateRequest): Observable<QuoteCheckoutEstimateResponse> {
    return this.http.post<QuoteCheckoutEstimateResponse>(`${this.baseUrl}/${quoteId}/agency/tax`, {checkoutEstimateRequest});
  }

  getCoSponsorTaxEstimate(quoteId: string, billingAddress: QuoteBillingAddress): Observable<QuoteCheckoutEstimateResponse> {
    return this.http.post<QuoteCheckoutEstimateResponse>(`${this.baseUrl}/${quoteId}/co-sponsor/tax`, {billingAddress});
  }

  markCheckoutClick(quoteId: string): Observable<void> {
    return this.http.post<void>(`${this.baseUrl}/${quoteId}/agency/checkout/click`, {});
  }

  markCoSponsorCheckoutClick(quoteId: string): Observable<void> {
    return this.http.post<void>(`${this.baseUrl}/${quoteId}/co-sponsor/checkout/click`, {});
  }

  onBoardingCallScheduled(quoteId: string): Observable<void> {
    return this.http.post<void>(`${this.baseUrl}/${quoteId}/agency/onboarding-call-scheduled`, {});
  }

  getInvitationLink(quoteId: string): Observable<{inviteLink: string}> {
    return this.http.post<{inviteLink: string}>(`${this.baseUrl}/${quoteId}/agency/invitation-link`, {});
  }

  getCoSponsorCheckoutLink(quoteId: string): Observable<{coSponsorCheckoutLink: string}> {
    return this.http.get<{coSponsorCheckoutLink: string}>(`${this.baseUrl}/${quoteId}/co-sponsor/checkout-link`, {});
  }

  sendCoSponsorReminder(quoteId: string): Observable<void> {
    return this.http.post<void>(`${this.baseUrl}/${quoteId}/co-sponsor/reminder`, {});
  }
}
