import {Component, OnInit} from "@angular/core";
import {FelloApiService} from "../../../../../lib/services/fello-api/fello-api.service";
import {AbstractPublicLandingPageService} from "../../../services";
import {takeUntil, tap} from "rxjs/operators";
import {LandingPageLayoutType, LandingPageSettingsResponse} from "fello-model";
import {DestroyableToastBase} from "../../../../../lib/mixins/helpers";
import {MatSnackBar} from "@angular/material/snack-bar";

@Component({
  selector: "lib-lp-journey-address",
  templateUrl: "./lp-journey-address.component.html",
  styleUrls: ["./lp-journey-address.component.scss"]
})
export class LpJourneyAddressComponent extends DestroyableToastBase implements OnInit {
  address: string;
  settings: LandingPageSettingsResponse<LandingPageLayoutType.JOURNEY>;
  isLoading = false;

  constructor(
    private felloApiService: FelloApiService,
    private publicLandingPageService: AbstractPublicLandingPageService<LandingPageLayoutType.JOURNEY>,
    snackBar: MatSnackBar
  ) {
    super(snackBar);
  }

  ngOnInit(): void {
    this.publicLandingPageService.landingPagePublicSetting$
      .pipe(
        takeUntil(this.isDestroyed),
        tap(settings => (this.settings = settings))
      )
      .subscribe();
  }

  submitForm(): void {
    this.isLoading = true;
    this.withToast(this.publicLandingPageService.performCTAAction(this.settings.config.layoutSettings.action, this.address), {
      failureMsg: "Unable to submit form. please try again later."
    }).subscribe(
      ({url}) => {
        window.location.href = url;
      },
      () => {
        this.isLoading = false;
      }
    );
  }
}
