import {Component, OnInit} from "@angular/core";
import {AbstractDashboardService} from "../../services";
import {distinctUntilChanged, switchMap} from "rxjs/operators";

@Component({
  selector: "lib-dashboard-view-tracker",
  standalone: true,
  template: ""
})
export class DashboardViewTrackerComponent implements OnInit {
  constructor(private dashboardService: AbstractDashboardService) {}

  ngOnInit(): void {
    this.dashboardService.property$
      .pipe(
        distinctUntilChanged(),
        switchMap(() => {
          return this.dashboardService.trackDashboardView();
        })
      )
      .subscribe();
  }
}
