import {Pipe, PipeTransform} from "@angular/core";
import {NumberUtils} from "../../../lib";

@Pipe({
  name: "formatNumber",
  standalone: true
})
export class FormatNumberPipe implements PipeTransform {
  transform(value?: number | null, maximumFractionDigits = 0, minimumFractionDigits = 0, minimumIntegerDigits?: number): string {
    return Number(value).toLocaleString(undefined, {
      maximumFractionDigits,
      minimumFractionDigits,
      minimumIntegerDigits
    });
  }
}

@Pipe({
  name: "roundUp",
  standalone: true
})
export class RoundUpPipe implements PipeTransform {
  transform(value?: number | null, toNearest?: number): number {
    if (!value) {
      return 0;
    }
    return NumberUtils.roundUp(value, toNearest);
  }
}

@Pipe({
  name: "roundDown",
  standalone: true
})
export class RoundDownPipe implements PipeTransform {
  transform(value?: number | null, toNearest?: number): number {
    if (!value) {
      return 0;
    }
    return NumberUtils.roundDown(value, toNearest);
  }
}

@Pipe({
  name: "roundOff",
  standalone: true
})
export class RoundOffPipe implements PipeTransform {
  transform(value?: number | null, toNearest?: number): number {
    if (!value) {
      return 0;
    }
    return NumberUtils.round(value, toNearest);
  }
}
