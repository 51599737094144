import {NgModule} from "@angular/core";
import {RouterModule, Routes} from "@angular/router";
import {PageNotFoundComponent} from "fello-common";
import {UnsubscribeEmailResolver} from "./modules/unsubscribe-email/resolvers/unsubscribe-email.resolver";

const routes: Routes = [
  {path: "offer", loadChildren: () => import("./modules/offer-flow/offer-flow.module").then(m => m.OfferFlowModule)},
  {
    path: "widget",
    loadChildren: () => import("./modules/offer-widgets/offer-widgets.module").then(m => m.OfferWidgetsModule)
  },
  {
    path: "dashboard",
    loadChildren: () => import("./modules/fello-dashboard/fello-dashboard.module").then(m => m.FelloDashboardModule)
  },
  {
    path: "postcard",
    loadChildren: () => import("./modules/postcard/postcard-routing.module").then(m => m.PostcardRoutingModule)
  },
  {
    path: "lp",
    loadChildren: () => import("./modules/public-landing-page/public-landing-page.module").then(m => m.PublicLandingPageModule)
  },
  {
    path: "e/:jobId/unsubscribe",
    providers: [UnsubscribeEmailResolver],
    resolve: {
      unsubscribeEmailDetails: UnsubscribeEmailResolver
    },
    loadComponent: () => import("./modules/unsubscribe-email/unsubscribe-email.component").then(m => m.UnsubscribeEmailComponent)
  },
  {
    path: "privacy-policy",
    loadComponent: () => import("./modules/privacy-policy/privacy-policy.component").then(m => m.PrivacyPolicyComponent),
    title: "Privacy Policy"
  },
  {
    path: "terms-and-conditions",
    loadComponent: () => import("./modules/terms-and-conditions/terms-and-conditions.component").then(m => m.TermsAndConditionsComponent),
    title: "Terms and conditions"
  },
  {
    path: "p",
    loadChildren: () => import("./modules/purl/purl.module").then(m => m.PurlModule)
  },
  {path: "**", component: PageNotFoundComponent}
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: "enabledBlocking"
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
