import {AgencyBranding, ImageMeta} from "fello-model";
import {isEmpty} from "lodash-es";
import {RegexUtils} from "../../modules/fello-ui-utils/constants/RegexUtils";

export class AgentBrandingUtils {
  static isImageValidForPrint(logoMeta: ImageMeta): boolean {
    return logoMeta.height >= 1000 && logoMeta.width >= 1000;
  }
  static isPrintSettingConfigured(branding: AgencyBranding): boolean {
    const setting = branding.value.settings;
    return (
      !isEmpty(setting.printSettings.returnName) && !isEmpty(setting.printSettings.returnAddress) && !isEmpty(setting.printSettings.logo)
    );
  }
  static getAgencyWebsiteDisplayText(website?: string): string {
    return (website ?? "").replace(RegexUtils.URL_START, "").split("?")[0];
  }
}
