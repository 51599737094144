import {AgencyThemeSetting, ButtonStyle} from "fello-model";

let customThemedElemId = 1;

export const ButtonStyleToBorderRadiusMap: Record<ButtonStyle, number> = {
  [ButtonStyle.ROUNDED]: 8,
  [ButtonStyle.SEMI_ROUNDED]: 4,
  [ButtonStyle.SQUARED]: 0,
  [ButtonStyle.FULLY_ROUNDED]: 999
};

export class CustomThemeManager {
  private _styleElement: HTMLStyleElement | null = null;
  private _element: HTMLElement;

  constructor(private _document: Document, element?: HTMLElement) {
    this._element = element ?? this._document.body;
  }

  applyTheme(theme?: AgencyThemeSetting, divId?: string): void {
    const divContainer = this._document.getElementById(divId ?? "");
    if (divContainer) {
      this._element = divContainer;
    }
    this._rebuildStyles(theme);
  }

  private _rebuildStyles(theme?: AgencyThemeSetting): void {
    if (this._styleElement) {
      this._styleElement.remove();
      this._styleElement = null;
    }
    this._ensureElementId(this._element);
    if (theme) {
      const head = this._document.getElementsByTagName("head")[0];
      this._styleElement = this._document.createElement("style");
      this._styleElement.type = "text/css";
      this._styleElement.appendChild(this._document.createTextNode(this._buildStyleSheet(theme)));
      head?.appendChild(this._styleElement);
      this._element.classList.add("custom-theme");
    } else {
      this._element.classList.remove("custom-theme");
    }
  }

  private _buildStyleSheet(theme: AgencyThemeSetting): string {
    return `#${this._element.id}.custom-theme, #${this._element.id}.custom-theme * {
      --color-primary: ${theme.primaryColor};
      --primary: ${theme.primaryColor};
      --theme-border-radius: ${ButtonStyleToBorderRadiusMap[theme.button.style]}px;
      --btn-text-color: ${theme.button.textColor};
    }`;
  }

  private _ensureElementId(elem: HTMLElement): string {
    if (!elem.id) {
      elem.id = `custom-themed-element-${customThemedElemId++}`;
    }
    return elem.id;
  }
}
