import {Component, Inject, OnInit, PLATFORM_ID} from "@angular/core";
import {ActivatedRoute} from "@angular/router";
import {DOCUMENT, isPlatformBrowser, NgIf} from "@angular/common";
import {PropertyPublicDashboardApiService} from "../../../../lib";
import {delay, retryWhen, tap} from "rxjs/operators";
import {NgxSpinnerModule, NgxSpinnerService} from "ngx-spinner";

@Component({
  selector: "lib-page-not-found",
  templateUrl: "./page-not-found.component.html",
  styleUrls: ["./page-not-found.component.scss"],
  imports: [NgxSpinnerModule, NgIf],
  standalone: true
})
export class PageNotFoundComponent implements OnInit {
  spinnerName = "404-page";
  isLoading = true;
  constructor(
    private activatedRoute: ActivatedRoute,
    @Inject(DOCUMENT) document: Document,
    private propertyPublicDashboardApiService: PropertyPublicDashboardApiService,
    @Inject(PLATFORM_ID) private platformId: Object,
    private spinnerService: NgxSpinnerService
  ) {
    this.spinnerService.show(this.spinnerName);
  }

  ngOnInit(): void {
    if (!isPlatformBrowser(this.platformId)) {
      return;
    }
    this.propertyPublicDashboardApiService
      .get404PageUrl()
      .pipe(
        retryWhen(err => err.pipe(delay(4000))),
        tap(({url}) => {
          if (isPlatformBrowser(this.platformId)) {
            if (url) {
              const currentURL = new URL(document.location.href);
              const pageUrl = new URL(url);
              currentURL.host = pageUrl.host;
              currentURL.port = pageUrl.port;
              currentURL.protocol = pageUrl.protocol;
              document.location.href = currentURL.toString();
            } else {
              this.spinnerService.hide(this.spinnerName);
              this.isLoading = false;
            }
          }
        })
      )
      .subscribe();
  }
}
