<div class="flex-box-center-between mb-4 flex-box-gap-3">
  <p class="fw-700 text-size-18">How do we calculate your home value estimate</p>
  <button class="button-icon close ml-auto" aria-label="Close Dialog" mat-dialog-close>
    <app-icon class="text-size-28">close</app-icon>
  </button>
</div>

<mat-dialog-content>
  <div class="db-card flex-box-lg-center flex-box-direction-column flex-box-lg-direction-row flex-box-gap-5">
    <div>
      <p class="text-size-20 fw-600">We estimate a home’s value with multiple third-party valuations</p>
      <p class="text-size-14 fw-500 text-color-light-2">
        These home valuations are obtained from computer-driven mathematical models that use basic property characteristics, local market
        information, and price trends to produce an estimate of what a home’s market value could be.
      </p>
      <button
        aria-label="Get a Professional Valuation"
        class="mt-4"
        (click)="close()"
        *ngIf="data.showCTA"
        [ngClass]="{'pointer-events-none': !data.isCTAClickable}"
      >
        Get a Professional Valuation
      </button>
    </div>
    <img src="/assets/images/home-value-trend/home-value-trend-graph.png" alt="" class="graph" />
  </div>
  <div class="db-card flex-box-direction-column flex-box-gap-4">
    <div class="w-100 flex-box flex-box-gap-3">
      <div class="logo-c">
        <img src="/assets/images/home-value-trend/Quantarium.png" alt="logo" />
      </div>
      <div>
        <p class="text-size-24 fw-700">Quantarium</p>
        <p class="text-size-14 fw-500 mt-2 text-color-light-2">
          Quantarium is a Seattle based AI research, data and differentiated IP portfolio company with a focus on real estate data
          analytics. Quantarium has been validated by independent third-party testing and provides the #1 ranked accuracy and national
          footprint coverage for AVM values for over 158 million U.S. properties.
        </p>
      </div>
    </div>
    <div class="w-100 flex-box flex-box-gap-3">
      <div class="logo-c">
        <img src="/assets/images/home-value-trend/CoreLogic.png" alt="logo" />
      </div>
      <div>
        <p class="text-size-24 fw-700">CoreLogic</p>
        <p class="text-size-14 fw-500 mt-2 text-color-light-2">
          CoreLogic® is an industry leader in automated valuations and provides AVMs tailored to specific use cases. CoreLogic® Total Home
          Value for Marketing is an AVM designed specifically to help consumers gain a better understanding of the value of a home.
        </p>
      </div>
    </div>
    <div class="w-100 flex-box flex-box-gap-3">
      <div class="logo-c">
        <img src="/assets/images/home-value-trend/Attom.png" alt="logo" />
      </div>
      <div>
        <p class="text-size-24 fw-700">ATTOM</p>
        <p class="text-size-14 fw-500 mt-2 text-color-light-2">
          ATTOM is the premier provider of property and real estate data. ATTOM multi-sources property tax, deed, mortgage, foreclosure,
          environmental risk, natural hazard, and neighborhood data for more than 155 million U.S. residential and commercial properties
          covering 99 percent of the nation’s population.
        </p>
      </div>
    </div>
  </div>
  <div class="line-break"></div>
  <div class="flex-box-center c-pointer mb-3" (click)="isFaqOpen = !isFaqOpen">
    <p class="text-size-18 fw-600">FAQ’s</p>
    <lib-icon class="ml-auto text-size-22">{{ isFaqOpen ? "up" : "down" }}</lib-icon>
  </div>
  <div class="flex-box-direction-column flex-box-gap-3" *ngIf="isFaqOpen">
    <div>
      <p class="text-size-14 fw-600 mb-1">What goes into calculating home valuation data?</p>
      <p class="text-size-12 fw-400 text-color-light-2">
        These models factor in essential property characteristics, local market trends, and pricing data to provide a possible estimate of a
        home's current market value.
      </p>
    </div>
    <div>
      <p class="text-size-14 fw-600 mb-1">Is this an appraisal?</p>
      <p class="text-size-12 fw-400 text-color-light-2">
        The home valuation data points provided are not formal appraisals, but rather estimates. They aim to offer insight into the
        potential value of a home, serving as a jumping-off point. To gain a deeper understanding of a home's value in today's market,
        please
        <button
          class="link text-size-12 fw-700 d-contents"
          aria-label="Contact Us"
          (click)="close()"
          *ngIf="data.showCTA; else contactUsText"
          [ngClass]="{'pointer-events-none': !data.isCTAClickable}"
        >
          contact us.
        </button>
        <ng-template #contactUsText>contact us.</ng-template>
      </p>
    </div>
    <div>
      <p class="text-size-14 fw-600 mb-1">How often is the home valuation information updated?</p>
      <p class="text-size-12 fw-400 text-color-light-2">The home valuation estimates are updated frequently as market conditions change.</p>
    </div>
    <div>
      <p class="text-size-14 fw-600 mb-1">
        I believe the estimates on my property are incorrect. Is there anything I can do to change that?
      </p>
      <p class="text-size-12 fw-400 text-color-light-2">
        No, these are automated valuation data models provided by three different companies – Quantarium, CoreLogic® and ATTOM. Please
        <button
          class="link text-size-12 fw-700 d-contents"
          aria-label="Contact Us"
          (click)="close()"
          *ngIf="data.showCTA; else contactUsValuation"
          [ngClass]="{'pointer-events-none': !data.isCTAClickable}"
        >
          contact us
        </button>
        for a discussion and we can provide an updated valuation.
        <ng-template #contactUsValuation>contact us</ng-template>
      </p>
    </div>
    <div>
      <p class="text-size-14 fw-600 mb-1">Why do we provide multiple estimates?</p>
      <p class="text-size-12 fw-400 text-color-light-2">
        Since many input sources go into each calculation, property value estimates can vary from one source to the next. Although no
        automated model is 100 percent accurate, viewing data from multiple sources gives you a more complete picture of a home's potential
        value.
      </p>
    </div>
  </div>
</mat-dialog-content>
