import {Inject, Injectable, InjectionToken, OnDestroy} from "@angular/core";
import {BehaviorSubject} from "rxjs";
import {DOCUMENT} from "@angular/common";
import {LoadScriptService} from "./load-script.service";
import {DestroyableBase} from "../../../lib/mixins/helpers";
import {takeUntil} from "rxjs/operators";
import {Chargebee, ChargebeeInstance} from "@chargebee/chargebee-js-types";

export const CHARGEBEE_SITE = new InjectionToken<string>("CHARGEBEE_SITE");
export const CHARGEBEE_PUBLISHABLE_KEY = new InjectionToken<string>("CHARGEBEE_PUBLISHABLE_KEY");

@Injectable({
  providedIn: "root"
})
export class ChargebeeJsLoaderService extends DestroyableBase implements OnDestroy {
  isChargebeeLoaded$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  constructor(
    @Inject(DOCUMENT) private document: Document,
    private scriptService: LoadScriptService,
    @Inject(CHARGEBEE_SITE) public site: string,
    @Inject(CHARGEBEE_PUBLISHABLE_KEY) public publishableKey: string
  ) {
    super();
    this.loadChargebee();
  }

  private loadChargebee(): void {
    if (this.isChargebeeLoaded$.value) {
      return;
    }

    this.scriptService
      .load(`https://js.chargebee.com/v2/chargebee.js`)
      .pipe(takeUntil(this.isDestroyed))
      .subscribe(() => {
        if (this.chargebeeWindowObj) {
          this.chargebeeWindowObj.init({site: this.site, publishableKey: this.publishableKey});
          this.isChargebeeLoaded$.next(true);
        } else {
          this.isChargebeeLoaded$.next(false);
          console.warn("Chargebee.js is not available. Make sure Chargebee is loaded");
        }
      });
  }
  get chargebeeWindowObj(): typeof Chargebee | undefined {
    return (window as any).Chargebee;
  }

  getChargebeeInstance(): ChargebeeInstance | undefined {
    if (this.isChargebeeLoaded$.value && this.chargebeeWindowObj) {
      return this.chargebeeWindowObj.getInstance();
    }
    return;
  }
  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.isChargebeeLoaded$.complete();
  }
}
