export enum PreBuiltMarketingAutomationCategory {
  LEAD_GENERATION = "LEAD_GENERATION",
  LEAD_NURTURING = "LEAD_NURTURING",
  FARMING = "FARMING"
}

export enum PreBuiltWorkflowCategory {
  EVENT_BASED_TRIGGER = "EVENT_BASED_TRIGGER",
  FILTER_CRITERIA_BASED_TRIGGER = "FILTER_CRITERIA_BASED_TRIGGER"
}

export type PreBuiltAutomationCategory = PreBuiltWorkflowCategory | PreBuiltMarketingAutomationCategory;

export const PreBuiltAutomationCategoryOrder: Record<PreBuiltAutomationCategory, number> = {
  [PreBuiltMarketingAutomationCategory.LEAD_GENERATION]: 0,
  [PreBuiltMarketingAutomationCategory.LEAD_NURTURING]: 1,
  [PreBuiltMarketingAutomationCategory.FARMING]: 2,
  [PreBuiltWorkflowCategory.EVENT_BASED_TRIGGER]: 0,
  [PreBuiltWorkflowCategory.FILTER_CRITERIA_BASED_TRIGGER]: 1
};

export const PreBuiltAutomationCategoryLabel: Record<PreBuiltAutomationCategory, string> = {
  [PreBuiltMarketingAutomationCategory.LEAD_GENERATION]: "Lead Generation",
  [PreBuiltMarketingAutomationCategory.LEAD_NURTURING]: "Lead Nurturing",
  [PreBuiltMarketingAutomationCategory.FARMING]: "Farming",
  [PreBuiltWorkflowCategory.EVENT_BASED_TRIGGER]: "Event-Based Trigger",
  [PreBuiltWorkflowCategory.FILTER_CRITERIA_BASED_TRIGGER]: "Criteria-Based Trigger"
};

export const PreBuiltAutomationCategoryDesc: Record<PreBuiltAutomationCategory, string> = {
  [PreBuiltMarketingAutomationCategory.LEAD_GENERATION]:
    "Maximize your contact database to engage and convert new leads with precision. Our automations are designed to tap into your existing network, unlocking its full potential.",
  [PreBuiltMarketingAutomationCategory.LEAD_NURTURING]:
    "Advance your leads through the sales funnel with targeted automations. Focus on deepening relationships and guiding potential clients closer to a decision.",
  [PreBuiltMarketingAutomationCategory.FARMING]:
    "Expand your reach and uncover new prospects with our farming automations. Set your criteria and let the system find fresh opportunities to grow your database.",
  [PreBuiltWorkflowCategory.EVENT_BASED_TRIGGER]:
    "Trigger workflows automatically based on events that take place in Fello, such as changes to leads, tags, or email subscription status.",
  [PreBuiltWorkflowCategory.FILTER_CRITERIA_BASED_TRIGGER]:
    "Trigger workflows automatically when a contact or property’s details match the filter criteria that you've set."
};

export const PreBuiltAutomationCategoryImage: Record<PreBuiltAutomationCategory, string> = {
  [PreBuiltMarketingAutomationCategory.LEAD_GENERATION]: "/assets/images/automations/lead-generation.svg",
  [PreBuiltMarketingAutomationCategory.LEAD_NURTURING]: "/assets/images/automations/lead-nurturing.svg",
  [PreBuiltMarketingAutomationCategory.FARMING]: "/assets/images/automations/farming.svg",
  [PreBuiltWorkflowCategory.EVENT_BASED_TRIGGER]: "/assets/images/workflows/event_based_trigger.svg",
  [PreBuiltWorkflowCategory.FILTER_CRITERIA_BASED_TRIGGER]: "/assets/images/workflows/filter_criteria_based_trigger.svg"
};

export const PreBuiltAutomationCategoryColorMapping: Record<string, string> = {
  [PreBuiltMarketingAutomationCategory.LEAD_GENERATION]: "green-light",
  [PreBuiltMarketingAutomationCategory.LEAD_NURTURING]: "orange-1",
  [PreBuiltMarketingAutomationCategory.FARMING]: "blue-light",
  [PreBuiltWorkflowCategory.EVENT_BASED_TRIGGER]: "green-light",
  [PreBuiltWorkflowCategory.FILTER_CRITERIA_BASED_TRIGGER]: "orange-1"
};
