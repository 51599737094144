import {Inject, Injectable} from "@angular/core";
import {HttpClient, HttpParams} from "@angular/common/http";
import {Observable} from "rxjs";
import {AGENT_API_SERVICE_BASE_URL} from "./agent-api.service";
import {
  AgencyWidgetInfo,
  AgencyWidgetSettingResponse,
  AvailableWidgetTypes,
  DashboardSearchParams,
  FelloDashboard,
  PagedResult,
  WidgetSettingsResponse
} from "fello-model";

@Injectable({
  providedIn: "root"
})
export class WidgetApiService {
  baseUrl: string;
  constructor(private http: HttpClient, @Inject(AGENT_API_SERVICE_BASE_URL) private agentBaseUrl: string) {
    this.baseUrl = `${this.agentBaseUrl}/agent/widget`;
  }

  generateWidget(): Observable<unknown> {
    return this.http.post(`${this.baseUrl}`, {});
  }

  getWidgetSettings(widgetId: string): Observable<AgencyWidgetSettingResponse> {
    return this.http.get<AgencyWidgetSettingResponse>(`${this.baseUrl}/${widgetId}/settings`);
  }

  getWidgetSettings2<WidgetType extends AvailableWidgetTypes>(widgetId: string): Observable<WidgetSettingsResponse<WidgetType>> {
    return this.http.get<WidgetSettingsResponse<WidgetType>>(`${this.baseUrl}/${widgetId}/settings/v2`);
  }

  updateWidgetSettings(widget: Omit<AgencyWidgetInfo, "widgetId">): Observable<unknown> {
    return this.http.patch(`${this.baseUrl}`, {widget});
  }

  searchVisitors(params: DashboardSearchParams): Observable<PagedResult<FelloDashboard>> {
    return this.http.get<PagedResult<FelloDashboard>>(`${this.baseUrl}/visitors`, {params: params as HttpParams});
  }
}
