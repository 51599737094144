import {NgModule} from "@angular/core";
import {AbsPipe} from "./pipes/abs.pipe";
import {EnumToLabelPipe} from "./pipes/enumToLable.pipe";
import {IconComponent} from "./components/icon/icon.component";
import {PageNotFoundComponent} from "./components/page-not-found/page-not-found.component";
import {FormatPhoneDirective} from "./directives/format-phone-directive.directive";
import {
  EnumToColorPipe,
  HighlightSearchPipe,
  LocalSearchPipe,
  MediaWithTypePipe,
  MomentAddPipe,
  MomentDiffPipe,
  MomentFormatPipe,
  MomentFromPipe,
  MomentIsInFuturePipe,
  MomentIsInPastPipe,
  MomentSubtractPipe,
  NumberShorthandPipe,
  NumberToLetterPipe,
  PhonePipe,
  ReplaceStringPipe,
  SafeResourceUrlPipe,
  SafeUrlPipe,
  ShortNamePipe,
  TimeAgoPipe,
  UnixMomentFormatPipe
} from "./pipes";
import {FormatPricePipe} from "./pipes/format-price.pipe";
import {ToMediaAccessUrlPipe} from "./pipes/to-media-access-url.pipe";
import {TagComponent} from "./components/tag/tag.component";
import {AutocompleteDropdownComponent} from "./components/autocomplete-dropdown/autocomplete-dropdown.component";
import {
  AutoSizeVirtualScrollDirective,
  FallbackImageDirective,
  IframeResizeDirective,
  InputMaxValueDirective,
  IsGoogleMapsLoadedDirective,
  MatSelectResetDirective,
  PasteFileDirective
} from "./directives";
import {DisableATagsDirective} from "./directives/disable-atags.directive";
import {AsExtendedChangelogPipe} from "./pipes/as-extended-changelog.pipe";
import {FormatPriceRangePipe} from "./pipes/format-price-range.pipe";
import {CustomThemeDirective} from "./directives/custom-theme.directive";
import {SafeHtmlPipe} from "./pipes/safe.pipe";
import {IsPlatformBrowserDirective} from "./directives/is-platform-browser.directive";
import {FormatNumberPipe} from "./pipes/format-number.pipe";
import {FormatReferrerUrlPipe} from "./pipes/format-referrer-url.pipe";
import {MaxValidatorDirective} from "./directives/max-validator.directive";
import {MinValidatorDirective} from "./directives/min-validator.directive";
import {CopyWithToastDirective} from "./directives/copy-with-toast.directive";

const standAloneItems = [
  PageNotFoundComponent,
  EnumToLabelPipe,
  IconComponent,
  FormatPricePipe,
  MomentFormatPipe,
  MomentFromPipe,
  TimeAgoPipe,
  IsGoogleMapsLoadedDirective,
  NumberToLetterPipe,
  NumberShorthandPipe,
  MediaWithTypePipe,
  EnumToColorPipe,
  TagComponent,
  UnixMomentFormatPipe,
  MomentDiffPipe,
  MomentSubtractPipe,
  MomentAddPipe,
  MomentIsInPastPipe,
  MomentIsInFuturePipe,
  FormatPriceRangePipe,
  MaxValidatorDirective,
  MinValidatorDirective,
  AbsPipe,
  ToMediaAccessUrlPipe,
  ShortNamePipe,
  HighlightSearchPipe,
  LocalSearchPipe,
  AsExtendedChangelogPipe,
  SafeHtmlPipe,
  SafeUrlPipe,
  PhonePipe,
  FormatNumberPipe,
  FormatReferrerUrlPipe,
  SafeResourceUrlPipe,
  FormatPhoneDirective,
  PasteFileDirective,
  FallbackImageDirective,
  DisableATagsDirective,
  CustomThemeDirective,
  IframeResizeDirective,
  IsPlatformBrowserDirective,
  InputMaxValueDirective,
  MatSelectResetDirective,
  AutocompleteDropdownComponent,
  CopyWithToastDirective,
  ReplaceStringPipe,
  AutoSizeVirtualScrollDirective
];

@NgModule({
  imports: standAloneItems,
  exports: standAloneItems
})
export class FelloUiUtilsModule {}
