import {Directive, ElementRef, Inject, Input} from "@angular/core";
import {AgencyThemeSetting} from "fello-model";
import {DOCUMENT} from "@angular/common";
import {CustomThemeManager} from "../utils/custom-theme-manager";

@Directive({
  selector: "[libCustomTheme]",
  standalone: true
})
export class CustomThemeDirective {
  private _theme: AgencyThemeSetting | undefined;

  @Input("libCustomTheme")
  get theme(): AgencyThemeSetting | undefined {
    return this._theme;
  }

  set theme(value: AgencyThemeSetting | undefined) {
    this._theme = value;
    this._themeManager.applyTheme(this._theme);
  }

  private _themeManager: CustomThemeManager;

  constructor(@Inject(DOCUMENT) document: Document, private elemRef: ElementRef) {
    this._themeManager = new CustomThemeManager(document, this.elemRef.nativeElement);
  }
}
