<div [ngClass]="{'mt-3' : compComparators.length}">
  <ng-container *ngFor="let feature of compComparators; let i=index">
    <div class="flex-box-center mb-2" *ngIf="i<3 || comp?.expanded ">
      <app-icon
        class="text-size-18 mr-2"
        color="{{feature.indicatorValue===2?'var(--green)':(feature.indicatorValue===1?'var(--red)':'')}}"
        fontSize="18"
        >{{feature.indicator}}</app-icon
      >
      <p class="text-size-14 fw-400 text-color-slate-5">{{feature?.label}}</p>
    </div>
  </ng-container>
</div>
<ng-container *ngIf="compComparators.length > 3">
  <a class="show-more link print-d-none" *ngIf="!comp.expanded" (click)="comp.expanded=!comp.expanded">Show More</a>
  <a class="show-more link print-d-none" *ngIf="comp.expanded" (click)="comp.expanded=!comp.expanded">Show Less</a>
</ng-container>
