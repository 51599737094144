<img src="/assets/images/comparables/map-dummy.png" alt="map" class="map" />
<svg width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg" class="marker">
  <g filter="url(#filter0_d_10966_4363)">
    <circle cx="19" cy="15" r="15" fill="var(--color-primary)" />
  </g>
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M16.9173 21.0829V17.3329C16.9173 16.1821 17.8498 15.2496 19.0007 15.2496V15.2496C20.1515 15.2496 21.084 16.1821 21.084 17.3329V21.0829H25.6673V13.9279C25.6673 13.4862 25.4915 13.0621 25.179 12.7496L19.5898 7.16039C19.264 6.83456 18.7365 6.83456 18.4115 7.16039L12.8223 12.7496C12.5098 13.0621 12.334 13.4862 12.334 13.9279V21.0829H16.9173Z"
    stroke="white"
    stroke-width="1.5"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
  <defs>
    <filter id="filter0_d_10966_4363" x="0" y="0" width="38" height="38" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
      <feFlood flood-opacity="0" result="BackgroundImageFix" />
      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
      <feOffset dy="4" />
      <feGaussianBlur stdDeviation="2" />
      <feComposite in2="hardAlpha" operator="out" />
      <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_10966_4363" />
      <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_10966_4363" result="shape" />
    </filter>
  </defs>
</svg>
