import {Inject, Injectable} from "@angular/core";
import {ActivatedRoute} from "@angular/router";
import {catchError, map, switchMap, take, takeUntil, tap} from "rxjs/operators";
import {Observable, of} from "rxjs";
import {AgencyThemeSetting, AvailableLandingPageLayouts, DashboardModuleButtonAction, LandingPageSettingsResponse} from "fello-model";
import {AbstractPublicLandingPageService} from "./abstract-landing-page.service";
import {CustomThemeManager} from "../../fello-ui-utils";
import {DOCUMENT} from "@angular/common";
import {FelloApiService} from "../../../lib/services/fello-api/fello-api.service";
import {MediaService} from "../../../lib/services/media/media.service";
import {Meta} from "@angular/platform-browser";
import {SocialSettingsService} from "../../fello-ui-lib/services/social-settings.service";
import {TrackingCodeManagerService} from "../../fello-ui-lib/services/tracking-code-manager-service";
import {DestroyableBase} from "../../../lib";
import {some} from "lodash-es";

@Injectable()
export class PublicLandingPageService<LayoutType extends AvailableLandingPageLayouts>
  extends DestroyableBase
  implements AbstractPublicLandingPageService<LayoutType>
{
  protected landingPageConfigId$ = this.activatedRoute.params.pipe(map(params => params.landingPageConfigId as string));
  landingPagePublicSetting$: Observable<LandingPageSettingsResponse<LayoutType>> = this.activatedRoute.data.pipe(
    map(data => data.settings as LandingPageSettingsResponse<LayoutType>)
  );
  private _customThemeManager: CustomThemeManager;

  constructor(
    protected activatedRoute: ActivatedRoute,
    @Inject(DOCUMENT) _document: Document,
    protected felloApiService: FelloApiService,
    protected mediaService: MediaService,
    private socialSettingsService: SocialSettingsService,
    private _trackingCodeManager: TrackingCodeManagerService,
    meta: Meta
  ) {
    super();
    this._customThemeManager = new CustomThemeManager(_document);
    this.landingPagePublicSetting$
      .pipe(
        takeUntil(this.isDestroyed),
        tap(settings => {
          this._applyTheme(settings?.theme);
          this._trackingCodeManager.addTrackingCode(settings.globalTrackingSetting?.trackingCode);
          this._trackingCodeManager.addTrackingCode(settings?.config.generalSettings?.trackingCode);
        }),
        switchMap(settings => this._applySocialSettings(settings))
      )
      .subscribe();
  }

  private _applyTheme(theme?: AgencyThemeSetting): void {
    this._customThemeManager.applyTheme(theme);
  }

  private _applySocialSettings(settings?: LandingPageSettingsResponse): Observable<unknown> {
    const lpConfig = settings?.config;
    const lpGeneralSettings = lpConfig?.generalSettings;
    if (!lpGeneralSettings?.featuredImage) {
      if (lpConfig?.layoutSettings) {
        if ("backgroundImage" in lpConfig.layoutSettings && lpConfig.layoutSettings.backgroundImage) {
          return this.mediaService.getAccessURL(lpConfig.layoutSettings.backgroundImage).pipe(
            catchError(() => of(null)),
            tap(mediaUrl => {
              this.socialSettingsService.applySocialSettings({
                title: lpGeneralSettings?.mediaTitle ?? settings?.accountName ?? "Sell My Home",
                image: mediaUrl ?? undefined
              });
            })
          );
        }
      }
      this.socialSettingsService.applySocialSettings({
        title: lpGeneralSettings?.mediaTitle ?? settings?.accountName ?? "Sell My Home",
        description: lpGeneralSettings?.mediaDescription
      });
      return of(null);
    }
    return this.mediaService.getAccessURL(lpGeneralSettings.featuredImage).pipe(
      catchError(() => of(null)),
      tap(mediaUrl => {
        this.socialSettingsService.applySocialSettings({
          title: lpGeneralSettings.mediaTitle ?? "Sell My Home",
          description: lpGeneralSettings.mediaDescription,
          image: mediaUrl ?? undefined
        });
      })
    );
  }

  performCTAAction(action: DashboardModuleButtonAction, address?: string): Observable<{url: string}> {
    return this.landingPageConfigId$.pipe(
      take(1),
      switchMap(landingPageConfigId =>
        this.felloApiService.performLandingPageCTAAction(landingPageConfigId, action, address, this.getReferrerUrl()).pipe(
          map(({url}: {url: string}) => {
            const redirectURL = new URL(url);
            const referrerUrl = this.getReferrerUrl();
            if (referrerUrl) {
              const parsedRefererURL = new URL(referrerUrl);
              parsedRefererURL.searchParams.forEach((value, key) => {
                redirectURL.searchParams.set(key, value);
              });
            }
            const currentURL = new URL(window.location.href);
            currentURL.searchParams.forEach((value, key) => {
              redirectURL.searchParams.set(key, value);
            });
            url = redirectURL.toString();
            return {url};
          })
        )
      )
    );
  }

  getReferrerUrl(): string | undefined {
    const blockedDomains = ["consumer.hifello.com", "cashoffer.hifello.com", "consumer-dev.hifello.com", "cashoffer-dev.hifello.com"];
    if (!document.referrer) {
      return undefined;
    }
    const referrerURL = new URL(document.referrer);
    if (some(blockedDomains, domain => referrerURL.host.includes(domain))) {
      return undefined;
    }
    return document.referrer;
  }
}
