export enum TrackedEvents {
  HOME_CONDITION_RECEIVED = "home-condition",
  HOME_FACTS_RECEIVED = "home-facts",
  ADDRESS_CONFIRMED = "confirm-address",
  CONTACT_RECEIVED = "contact-shared",
  HOME_VALUES_ESTIMATE_REQUESTED = "home-value-requested",
  INSTANT_OFFER_REQUESTED = "instant-offer-requested",
  LIST_WITH_AGENT_REQUESTED = "list-with-fello-requested",
  VIEW_DASHBOARD = "viewed-dashboard",
  NOT_SERVICEABLE = "not-serviceable"
}
