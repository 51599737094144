<div class="flex-box-center-between mb-4">
  <h2 mat-dialog-title class="m-0">Edit Home Details</h2>
  <button class="button-icon close ml-auto" mat-dialog-close>
    <app-icon class="text-size-28">close</app-icon>
  </button>
</div>
<mat-dialog-content>
  <form input-options #homeDetails="ngForm">
    <app-home-details-stats [homeFacts]="homeFacts"></app-home-details-stats>
    <div class="line-break"></div>
    <app-home-details-condition [homeFacts]="homeFacts"></app-home-details-condition>
    <div class="line-break"></div>
    <app-home-details-additional-info [homeFacts]="homeFacts"></app-home-details-additional-info>
  </form>
</mat-dialog-content>
<button class="w-100 mt-3" [disabled]="!isValid" (click)="save()">Save</button>
