<ngx-spinner [name]="spinnerName" [fullScreen]="true" xmlns="http://www.w3.org/1999/html"></ngx-spinner>

<ng-container *libIsPlatformBrowser>
  <ng-container *ngIf="offer$ | async as offer; else loader">
    <ng-container *ngIf="templateRef">
      <ng-container *ngTemplateOutlet="templateRef"></ng-container>
    </ng-container>
  </ng-container>
</ng-container>

<ng-template #noOffer> </ng-template>

<ng-template #offersReady>
  <div class="db-card">
    <div>
      <p class="text-size-20 fw-600">Your Home Selling Options Are Ready</p>
      <p class="text-size-14 fw-500 text-color-light-2 mt-1">Discover the selling options available for your property today.</p>
    </div>
    <button class="whitespace-nowrap ml-auto button-mid print-d-none" routerLink="offers">Check Options</button>
  </div>
</ng-template>

<ng-template #loader>
  <div>
    <ngx-skeleton-loader count="1" [theme]="{height: '100px', width: '100%'}"></ngx-skeleton-loader>
  </div>
</ng-template>
