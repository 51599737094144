import {Pipe, PipeTransform} from "@angular/core";
import {
  AccessRoleTypeLabel,
  AcquireOfferTypeLabel,
  AgencyPlanTypeLabel,
  AgentContactEmailStatusLabel,
  AgentContactEnrollmentStatus,
  AgentContactEnrollmentStatusLabel,
  AgentContactStatusLabel,
  AgentDealOriginLabel,
  AgentDealStageLabel,
  AgentDeleteContactActionTypeLabel,
  AgentFindingStepLabel,
  AgentLeadFormTypeLabel,
  AgentLeadTypeLabel,
  AgentPermissionsLabel,
  AppCrmEventsActionType,
  AppCrmSyncLogFailureReason,
  AppCrmUpdateFieldsInFubFieldType,
  AppCrmUpdateFieldsInFubFieldValueTemplateType,
  AutomationComponentSetupStatusLabel,
  AutomationComponentTypeLabel,
  AutomationExternalTaskProcessingQueueReasonLabel,
  AutomationExternalTaskProcessingSkipReasonToLabelMapping,
  AutomationStatusLabel,
  BackgroundTaskContactImportStatusLabel,
  BackgroundTaskStatusLabel,
  BillingPeriodUnitLabel,
  BillingPeriodUnitPayLabel,
  BillingPeriodUnitSecondaryLabel,
  BillingPeriodUnitShortLabel,
  BuyingProgressLabel,
  BuyingWithSellingOptionsLabel,
  CampaignTypeLabel,
  CashOfferTypeLabel,
  ChangelogEventType,
  ChangelogEventTypeToLabelMapping,
  ChargebeeStatusLabel,
  CMSCreditReasonLabel,
  CMSDebitReason,
  ContactEngageActivityType,
  ContactSegmentTypeDescriptionLabel,
  ContactSegmentTypeIcon,
  ContactSegmentTypeLabel,
  ContentIntentType,
  ContentMediumLabel,
  ContentMediumLabelIcon,
  ContentStatus,
  CRMNameLabel,
  DashboardModuleButtonActionTypeLabel,
  DashboardModuleButtonType,
  DashboardModuleTypeLabel,
  DashboardModuleVisibilityLevelLabel,
  DealStageDisplayLabel,
  DispositionDealStageLabel,
  DurationUnitBaseLabel,
  FeatureNameLabel,
  FelloConnectedAppType,
  FelloConnectOriginLabel,
  FelloConnectReferralStatusLabel,
  FelloVisitorWorkflowTask,
  FQLFieldOperatorLabel,
  HomeConditionLevelLabel,
  HomeEstimateConfidenceLabel,
  InspectionPointGroupTypeLabel,
  ListingPresentationStatusLabel,
  MlsStatusToLabel,
  MortgageLoanTypeShorthandLabel,
  OfferStatusDisplayLabel,
  PreBuiltAutomationCategoryDesc,
  PreBuiltAutomationCategoryImage,
  PreBuiltAutomationCategoryLabel,
  PropertyTypeStandardisedLabel,
  providerTypeLabel,
  providerTypeTooltip,
  RelativeDateOpOptionLabel,
  SaleTimelineLabel,
  SaleTimelineShortLabel,
  SearchWidgetStyleLabel,
  SellingOptionType,
  TransactionSourceToLabel,
  TransactionStatusToLabel,
  WallPaintColorIntensityLabel,
  WorkflowComponentTypeLabel
} from "fello-model";
import {get, noop} from "lodash-es";

noop(AgentContactStatusLabel, DealStageDisplayLabel, InspectionPointGroupTypeLabel);

const enumValuesToLabelMapping = {
  HomeEstimateConfidence: {
    VERY_HIGH: "Very High",
    HIGH: "High",
    MEDIUM: "Medium",
    LOW: "Low"
  },
  INSPECTION_POINT_TYPE: InspectionPointGroupTypeLabel,
  FELLO_USER_CURRENT_TASK: FelloVisitorWorkflowTask,
  AGENT_CONTACT_STATUS: AgentContactStatusLabel,
  DEAL_STAGE: DealStageDisplayLabel,
  DEAL_REFERRAL_STATUS: FelloConnectReferralStatusLabel,
  FELLO_SALE_TIMELINE: SaleTimelineLabel,
  FELLO_SALE_TIMELINE_SHORT: SaleTimelineShortLabel,
  FELLO_HOME_CONDITION_LEVEL: HomeConditionLevelLabel,
  DISPOSITION_DEAL_STAGE: DispositionDealStageLabel,
  LISTING_PRESENTATION_STATUS: ListingPresentationStatusLabel,
  OFFER_STATUS: OfferStatusDisplayLabel,
  AGENT_FINDING_STEP: AgentFindingStepLabel,
  AGENT_CONTACT_EMAIL_STATUS: AgentContactEmailStatusLabel,
  AGENT_CONTACT_ENROLLMENT_STATUS: {
    [AgentContactEnrollmentStatus.ENROLLED]: "Enrolled",
    [AgentContactEnrollmentStatus.UNSUB]: "Unsubscribed",
    [AgentContactEnrollmentStatus.UNENROLLED]: "Unenrolled",
    [AgentContactEnrollmentStatus.PENDING]: "Pending",
    [AgentContactEnrollmentStatus.NA]: "Not Applicable"
  },
  CAMPAIGN_TYPE: CampaignTypeLabel,
  AGENT_CONTACT_ACTIVITY_TYPE: {
    [ContactEngageActivityType.SENT]: "Sent",
    [ContactEngageActivityType.SCHEDULED]: "Scheduled",
    [ContactEngageActivityType.OPENED]: "Opened",
    [ContactEngageActivityType.CLICKED]: "Clicked",
    [ContactEngageActivityType.HOME_VALUE]: "Home Value Lead",
    [ContactEngageActivityType.FAILED]: "Failed",
    [ContactEngageActivityType.OPPORTUNITY]: "Seller Lead"
  },
  AGENT_DELETE_CONTACT_ACTION_TYPE: AgentDeleteContactActionTypeLabel,
  WALL_PAINT_COLOR_INTENSITY: WallPaintColorIntensityLabel,
  ACCESS_ROLE_TYPE: AccessRoleTypeLabel,
  AGENT_PERMISSION_TYPE: AgentPermissionsLabel,
  AGENT_DEAL_STAGE: AgentDealStageLabel,
  AGENT_DEAL_ORIGIN: AgentDealOriginLabel,
  PLAN_TYPE: AgencyPlanTypeLabel,
  FEATURE_NAME: FeatureNameLabel,
  CHANGELOG_EVENT_TYPE: ChangelogEventTypeToLabelMapping,
  CAMPAIGN_ENROLL_STATUS: AgentContactEnrollmentStatusLabel,
  AGENT_CONTACT_ORIGIN: FelloConnectOriginLabel,
  CRM_NAME: CRMNameLabel,
  ACQUIRE_OFFER_TYPE: AcquireOfferTypeLabel,
  CASH_OFFER_TYPE: CashOfferTypeLabel,
  WIDGET_STYLE: SearchWidgetStyleLabel,
  BUTTON_ACTION_TYPE: DashboardModuleButtonActionTypeLabel,
  DASHBOARD_MODULE_TYPE: DashboardModuleTypeLabel,
  DASHBOARD_ORIGIN_LABEL: FelloConnectOriginLabel,
  AGENT_LEAD_TYPE: AgentLeadTypeLabel,
  AGENT_LEAD_FORM_TYPE: AgentLeadFormTypeLabel,
  LEAD_ORIGIN_LABEL: FelloConnectOriginLabel,
  BUY_WITH_SELL_LABEL: BuyingWithSellingOptionsLabel,
  BILLING_PERIOD_UNIT: BillingPeriodUnitLabel,
  BILLING_PERIOD_UNIT_PAY: BillingPeriodUnitPayLabel,
  BILLING_PERIOD_UNIT_SHORT: BillingPeriodUnitShortLabel,
  BILLING_PERIOD_UNIT_SECONDARY: BillingPeriodUnitSecondaryLabel,
  CHARGEBEE_STATUS: ChargebeeStatusLabel,
  BACKGROUND_TASK_CONTACT_IMPORT_STATUS: BackgroundTaskContactImportStatusLabel,
  FQL_FIELD_OP: FQLFieldOperatorLabel,
  BACKGROUND_TASK_STATUS: BackgroundTaskStatusLabel,
  BUYING_PROGRESS: BuyingProgressLabel,
  AVM_CONFIDENCE: HomeEstimateConfidenceLabel,
  MLS_STATUS: MlsStatusToLabel,
  TRANSACTION_STATUS: TransactionStatusToLabel,
  TRANSACTION_SOURCE: TransactionSourceToLabel,
  PROPERTY_TYPE: PropertyTypeStandardisedLabel,
  LAST_ENGAGEMENT_TYPE: {
    [ChangelogEventType.MAIL_CLICK]: "Email Clicked",
    [ChangelogEventType.MAIL_OPEN]: "Email Opened",
    [ChangelogEventType.MAIL_DELIVERED]: "Email Delivered",
    [ChangelogEventType.POSTCARD_SCANNED_PRIMARY]: "Postcard Scanned",
    [ChangelogEventType.POSTCARD_SCANNED_SECONDARY]: "Postcard Scanned",
    [ChangelogEventType.AGENT_LEAD_CREATED]: "Form Submitted"
  },
  EVENT_USER_ACTION_LABEL: {
    [ChangelogEventType.MAIL_CLICK]: "Clicked",
    [ChangelogEventType.MAIL_OPEN]: "Opened",
    [ChangelogEventType.MAIL_DELIVERED]: "Delivered",
    [ChangelogEventType.POSTCARD_SCANNED_PRIMARY]: "Scanned",
    [ChangelogEventType.POSTCARD_SCANNED_SECONDARY]: "Scanned",
    [ChangelogEventType.AGENT_LEAD_CREATED]: "Submitted",
    [ChangelogEventType.POSTCARD_SENT]: "Sent",
    [ChangelogEventType.POSTCARD_DELIVERED]: "Delivered",
    [ChangelogEventType.AUTOMATION_ENTITY_ENROLLED]: "Enrolled",
    [ChangelogEventType.AUTOMATION_ENTITY_REENROLLED]: "Reenrolled",
    [ChangelogEventType.AUTOMATION_ENTITY_UNENROLLED]: "Unenrolled",
    [ChangelogEventType.TP_MODULE_BTN_CLICK]: "CTA Clicked"
  },
  PROPERTY_CONDITION: {
    "000": "None",
    "001": "Type Unknown",
    "999": "Bypass",
    AVE: "Average",
    DMG: "Physical Damage",
    EXC: "Excellent",
    FAI: "Fair",
    GOO: "Good",
    GTE: "Good to Excellent",
    POO: "Poor",
    UCN: "Under Construction",
    UNS: "Unsound",
    VGO: "Very Good"
  },
  DASHBOARD_MODULE_VISIBILITY_LEVEL: DashboardModuleVisibilityLevelLabel,
  RELATIVE_DATE_OPTION: RelativeDateOpOptionLabel,
  DURATION_UNIT: DurationUnitBaseLabel,
  CONTENT_MEDIUM: ContentMediumLabel,
  AUTOMATION_STATUS: AutomationStatusLabel,
  AUTOMATION_COMPONENT_TYPE: AutomationComponentTypeLabel,
  WORKFLOW_COMPONENT_TYPE: WorkflowComponentTypeLabel,
  AUTOMATION_COMPONENT_SETUP_STATUS: AutomationComponentSetupStatusLabel,
  CONTENT_INTENT_TYPE: {
    [ContentIntentType.WELCOME]: "Introduction",
    [ContentIntentType.HOME_VALUE]: "Home Value",
    [ContentIntentType.MARKET_UPDATE]: "Market Update",
    [ContentIntentType.CASH_OFFER]: "Cash Offer"
  },
  CMS_DEBIT_REASON: {
    [CMSDebitReason.ENGAGE]: "Engage",
    [CMSDebitReason.POSTCARD]: "Postcard",
    [CMSDebitReason.WEBHOOK]: "Webhook",
    [CMSDebitReason.ENRICH]: "Enrich"
  },
  CMS_CREDIT_REASON: CMSCreditReasonLabel,
  MORTGAGE_TYPE_SHORTHAND: MortgageLoanTypeShorthandLabel,
  PRE_BUILT_AUTOMATION_CATEGORY: PreBuiltAutomationCategoryLabel,
  PRE_BUILT_AUTOMATION_CATEGORY_DESC: PreBuiltAutomationCategoryDesc,
  PRE_BUILT_AUTOMATION_CATEGORY_IMAGE: PreBuiltAutomationCategoryImage,
  CONTENT_MEDIUM_LABEL_ICON: ContentMediumLabelIcon,
  AUTOMATION_QUEUE_REASON: AutomationExternalTaskProcessingQueueReasonLabel,
  CRM_INTEGRATION_EVENT: {
    [ChangelogEventType.AGENT_LEAD_CREATED]: "New Lead in Fello",
    [ChangelogEventType.MAIL_CLICK]: "New Home Value View in Fello"
  },
  CRM_FUB_INTEGRATION_APP_RESPONSE: {
    [AppCrmSyncLogFailureReason.DELETED_IN_CRM]: "Deleted in FUB",
    [AppCrmSyncLogFailureReason.DUPLICATE_IN_CRM]: "Duplicate in FUB",
    [AppCrmSyncLogFailureReason.DELETED_IN_FELLO]: "Deleted in Fello",
    [AppCrmSyncLogFailureReason.PRIMARY_EMAIL_DUP]: "Primary email is duplicate",
    UPDATED_IN_FUB: "Updated in FUB"
  },
  CRM_SIERRA_INTEGRATION_APP_RESPONSE: {
    [AppCrmSyncLogFailureReason.DELETED_IN_CRM]: "Deleted in Sierra",
    [AppCrmSyncLogFailureReason.DUPLICATE_IN_CRM]: "Duplicate in Sierra",
    [AppCrmSyncLogFailureReason.DELETED_IN_FELLO]: "Deleted in Fello",
    [AppCrmSyncLogFailureReason.PRIMARY_EMAIL_DUP]: "Primary email is duplicate",
    UPDATED_IN_SIERRA: "Updated in Sierra"
  },
  CRM_EVENT_ACTION_FUB: {
    [AppCrmUpdateFieldsInFubFieldType.SOURCE]: "Source",
    [AppCrmUpdateFieldsInFubFieldType.EVENT_MESSAGE]: "Message",
    [AppCrmUpdateFieldsInFubFieldType.STAGE]: "Stage",
    [AppCrmUpdateFieldsInFubFieldType.FORM_SELLING_TIMELINE]: "Selling Timeline",
    [AppCrmEventsActionType.UPDATE_FIELDS_IN_FUB]: "Update Fields in FUB",
    [AppCrmEventsActionType.SELLER_INQUIRY_IN_FUB]: "Seller Inquiry in FUB"
  },
  CRM_EVENT_ACTION_SIERRA: {
    [AppCrmUpdateFieldsInFubFieldType.SOURCE]: "Source",
    [AppCrmUpdateFieldsInFubFieldType.EVENT_MESSAGE]: "Message",
    [AppCrmUpdateFieldsInFubFieldType.STAGE]: "Stage",
    [AppCrmUpdateFieldsInFubFieldType.FORM_SELLING_TIMELINE]: "Selling Timeline",
    [AppCrmEventsActionType.UPDATE_FIELDS_IN_SIERRA]: "Update Fields in Sierra",
    [AppCrmEventsActionType.UPDATE_LEADS_IN_SIERRA]: "Update Leads in Sierra",
    [AppCrmEventsActionType.ADD_NOTE_IN_SIERRA]: "Add Note in Sierra"
  },
  CRM_INTEGRATION_FUB: {
    [AppCrmUpdateFieldsInFubFieldValueTemplateType.FELLO_ORIGIN]: "Fello <Origin>",
    [AppCrmUpdateFieldsInFubFieldValueTemplateType.FORM_SUMMARY]: "Form Summary",
    [AppCrmUpdateFieldsInFubFieldValueTemplateType.FORM_SELLING_TIMELINE]: "Form Selling Timeline"
  },
  AUTOMATION_ACTION_SKIP_REASON: AutomationExternalTaskProcessingSkipReasonToLabelMapping,
  PROVIDER_TYPE: providerTypeLabel,
  PROVIDER_TYPE_TOOLTIP: providerTypeTooltip,
  CONTACT_SEGMENT: ContactSegmentTypeLabel,
  CONTACT_SEGMENT_DESCRIPTION: ContactSegmentTypeDescriptionLabel,
  CONTACT_SEGMENT_ICON: ContactSegmentTypeIcon,
  CONTENT_STATUS_LABEL: {
    [ContentStatus.PUBLISHED]: "Published",
    [ContentStatus.DRAFT]: "Draft",
    [ContentStatus.ARCHIVED]: "Archived"
  },
  DASHBOARD_BUTTON_TYPE_LABEL: {
    [DashboardModuleButtonType.PRIMARY]: "Primary",
    [DashboardModuleButtonType.SECONDARY]: "Secondary"
  },
  SELLING_OPTIONS_LABEL: {
    [SellingOptionType.CUSTOM_OPTION_1]: "Custom Option 1",
    [SellingOptionType.CUSTOM_OPTION_2]: "Custom Option 2",
    [SellingOptionType.HOMEWARD]: "Homeward",
    [SellingOptionType.CUSTOM_OPTION_2]: "Custom Option 2"
  },
  FELLO_CONNECTED_APP_TYPE: {
    [FelloConnectedAppType.FUB]: "Follow Up Boss",
    [FelloConnectedAppType.SIERRA]: "Sierra Interactive",
    [FelloConnectedAppType.HOMEWARD]: "Homeward"
  }
};
export type LabelEnumType = keyof typeof enumValuesToLabelMapping;

export function getEnumLabel(value: string | number, enumType: LabelEnumType): string {
  return get(enumValuesToLabelMapping[enumType], value) ?? value;
}

@Pipe({
  name: "enumToLabel",
  standalone: true
})
export class EnumToLabelPipe implements PipeTransform {
  transform(value: string | number, enumType: LabelEnumType): string {
    return getEnumLabel(value, enumType);
  }
}
