import {ComponentRef, Directive, Input, OnChanges, OnDestroy, SimpleChanges, Type, ViewContainerRef} from "@angular/core";
import {AvailableSellingOptionTypes, SellingOptionSetting, SellingOptionType, SellingOptionWrapper} from "fello-model";

import {BaseSellingOptionDirective} from "./base-selling-option-directive";
import {CustomSellingOptionComponent} from "../custom-selling-option/custom-selling-option.component";
import {DestroyableBase} from "../../../../../lib/mixins";
import {HomewardSellingOptionComponent} from "../homeward-selling-option/homeward-selling-option.component";

const SellingOptionTypeToComponentMapping: {
  [P in SellingOptionType]: Type<BaseSellingOptionDirective<P>>;
} = {
  [SellingOptionType.CUSTOM_OPTION_1]: CustomSellingOptionComponent,
  [SellingOptionType.CUSTOM_OPTION_2]: CustomSellingOptionComponent,
  [SellingOptionType.HOMEWARD]: HomewardSellingOptionComponent
};

@Directive({
  selector: "[libSellingOptionHost]",
  standalone: true
})
export class SellingOptionHostDirective<OptionType extends AvailableSellingOptionTypes>
  extends DestroyableBase
  implements OnChanges, OnDestroy
{
  @Input() sellingOptionType: OptionType;
  @Input()
  sellingOptionSettings: SellingOptionSetting<OptionType>;
  @Input()
  sellingOptionWrapper: SellingOptionWrapper<OptionType>;

  private _componentRef?: ComponentRef<BaseSellingOptionDirective<OptionType>>;

  get sellingOptionComponent(): BaseSellingOptionDirective<OptionType> | undefined {
    return this._componentRef?.instance;
  }

  constructor(public viewContainerRef: ViewContainerRef) {
    super();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes.sellingOptionType?.firstChange ||
      changes.sellingOptionType?.previousValue !== changes.sellingOptionType?.currentValue ||
      changes.sellingOptionSettings?.firstChange ||
      changes.sellingOptionSettings?.previousValue !== changes.sellingOptionSettings?.currentValue ||
      changes.sellingOption?.firstChange ||
      changes.sellingOption?.previousValue !== changes.sellingOption?.currentValue
    ) {
      this.renderModule();
    }
  }

  ngOnDestroy() {
    this.viewContainerRef.clear();
  }

  private renderModule(): void {
    const sellingOptionType = this.sellingOptionType;
    const sellingOptionSettings = this.sellingOptionSettings;
    const sellingOptionWrapper = this.sellingOptionWrapper;
    const moduleToRender = SellingOptionTypeToComponentMapping[sellingOptionType];
    if (moduleToRender) {
      if (this._componentRef?.instance.constructor !== moduleToRender) {
        this.viewContainerRef.clear();
        this._componentRef = this.viewContainerRef.createComponent<BaseSellingOptionDirective<OptionType>>(moduleToRender);
      }
      this._componentRef.setInput("sellingOptionSettings", sellingOptionSettings);
      this._componentRef.setInput("sellingOptionWrapper", sellingOptionWrapper);
    } else {
      this.viewContainerRef.clear();
    }
  }
}
