import {InjectionToken} from "@angular/core";
import {FelloEnv, HubspotScheduleOnboardingConfig} from "fello-model";

export const ENVIRONMENT = new InjectionToken<FelloEnv>("ENVIRONMENT");
export const GOOGLE_AUTH_CLIENT_ID = new InjectionToken<string>("Google Auth Client Id");
export const APPLE_AUTH_CLIENT_ID = new InjectionToken<string>("Apple Auth Client Id");
export const WIDGET_DEFAULT_SUBDOMAIN = new InjectionToken<string>("Widgets Default subdomain");
export const WIDGET_BASE_URL = new InjectionToken<string>("Widgets base url");
export const CONSUMER_DEFAULT_SUBDOMAIN = new InjectionToken<string>("Consumers base url");
export const THINKFIC_SSO_URL = new InjectionToken<string>("Thinkfic SSO URL");
export const APP_VERSION = new InjectionToken<string>("App Version");

export const HUBSPOT_SCHEDULE_ONBOARDING_CONFIG = new InjectionToken<HubspotScheduleOnboardingConfig>(
  "Hubspot schedule onboarding url config"
);
