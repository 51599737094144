import {Component} from "@angular/core";
import {isOfferStatusBefore} from "fello-model";
import {AbstractDashboardService} from "../../services";

@Component({
  selector: "app-user-info",
  templateUrl: "./user-info.component.html",
  styleUrls: ["./user-info.component.scss"]
})
export class UserInfoComponent {
  isLoading$ = this.dashboardService.isPropertyLoading;
  isOfferStatusBefore = isOfferStatusBefore;
  constructor(private dashboardService: AbstractDashboardService) {}
}
