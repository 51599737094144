import {AddressComponent} from "../address";
import {DealOrigin, DealPipeline, DealStage} from "./enums";
import {DealOfferInfo} from "./DealOfferInfo";
import {WalkthroughInfo} from "./WalkthroughInfo";
import {DealClosingInfo} from "./DealClosingInfo";
import {OPDLite} from "../bulk/OPDLite";
import {DataProviderType} from "./enums/DataProviderType";

export interface SellerDetails {
  id: string;
  firstName: string;
  lastName: string;
  fullName: string;
  email?: string;
  phone?: string;
}

export enum DealFelloConnectRefOrigin {
  AGENT = "AGENT",
  SELLER_INVITED_AGENT = "SELLER_INVITED_AGENT",
  REFERRAL = "REFERRAL"
}

export interface DealFelloConnectInfo {
  agentId: string;
  agencyId: string;
  contact?: {
    contactId: string;
    fullName: string;
    emailId: string;
    phone?: string;
  };
  origin: DealFelloConnectRefOrigin;
}
export enum AvmCondifence {
  VERY_LOW = "VERY LOW",
  LOW = "LOW",
  MEDIUM = "MEDIUM",
  HIGH = "HIGH",
  VERY_HIGH = "VERY HIGH"
}

export enum DealType {
  IO = "IO",
  LWF = "LWF"
}

export enum FelloConnectReferralStatus {
  REFER_TO = "REFER_TO",
  SENT = "SENT",
  REFERRED = "REFERRED",
  LISTED = "LISTED"
}

const FelloConnectReferralStatusSequence: Record<FelloConnectReferralStatus, number> = {
  [FelloConnectReferralStatus.REFER_TO]: 0,
  [FelloConnectReferralStatus.SENT]: 1,
  [FelloConnectReferralStatus.REFERRED]: 2,
  [FelloConnectReferralStatus.LISTED]: 3
};

export function isFelloConnectReferralStatusSameOrAfter(s1: FelloConnectReferralStatus, s2: FelloConnectReferralStatus): boolean {
  return FelloConnectReferralStatusSequence[s1] >= FelloConnectReferralStatusSequence[s2];
}

export const FelloConnectReferralStatusLabel: Record<FelloConnectReferralStatus, string> = {
  [FelloConnectReferralStatus.REFER_TO]: "To Refer",
  [FelloConnectReferralStatus.SENT]: "Sent for Approval",
  [FelloConnectReferralStatus.REFERRED]: "Referred",
  [FelloConnectReferralStatus.LISTED]: "Listed"
};

export interface LockOwner {
  name: string;
  id: string;
  image: string;
  roles?: string[];
}

export interface DealAssigneeInfo {
  assigneeId: string;
  assignedBy: string;
  assumedRole: string;
  assignDate: string;
  additional?: {
    remarks?: string;
  };
}

export interface DealSourcePropertyImage {
  type: "url" | "media";
  data: string;
}

export interface DealSourcePropertyMetaImages {
  mls: DealSourcePropertyImage[];
  listing: DealSourcePropertyImage[];
}

export interface DealAvmHolderMeta {
  median: number;
  low: number;
  high: number;
  confidence: AvmCondifence;
  valueDate: Date;
}

export interface DealSourcePropertyMeta {
  opd: OPDLite;
  img: DealSourcePropertyMetaImages;
  avm?: Record<DataProviderType, DealAvmHolderMeta>;
}

export interface DealRealSellerInfo {
  flag: boolean;
}

export interface DealOwnerInfo {
  owner: LockOwner;
  assignDate: Date;
}

export interface TeamWiseDealOwnerInfo {
  teams: Record<string, DealOwnerInfo>;
}

export enum ListingPresentationStatus {
  FOR_SALE = "FOR_SALE",
  SOLD = "SOLD",
  NA = "NA"
}

export const ListingPresentationStatusLabel: Record<ListingPresentationStatus, string> = {
  [ListingPresentationStatus.FOR_SALE]: "FOR SALE",
  [ListingPresentationStatus.SOLD]: "SOLD",
  [ListingPresentationStatus.NA]: "NA"
};

export interface DealListingPresentationInfo {
  presentationStatus: ListingPresentationStatus;
  presentationId: string;
}

export interface DealListingInfo {
  price?: number;
  listDate?: string;
  presentation?: DealListingPresentationInfo;
  appraisalReportMediaId?: string;
  meta?: any;
}

export enum DispositionDealStage {
  // Renovation Pipeline
  RENOVATION_UPCOMING = "RENOVATION_UPCOMING",
  RENOVATION = "RENOVATION",
  QA_INSPECTION = "QA_INSPECTION",
  QA_CALLBACK = "QA_CALLBACK",
  READY_TO_LIST = "READY_TO_LIST",

  // Sale Pipeline
  RESALE_UPCOMING = "RESALE_UPCOMING",
  CLEAN = "CLEAN",
  PHOTOS = "PHOTOS",
  PRE_LISTING = "PRE_LISTING",
  LISTING = "LISTING",
  UNDER_CONTRACT_INSPECTION = "UNDER_CONTRACT_INSPECTION",
  ROC_REPAIRS_IN_PROGRESS = "ROC_REPAIRS_IN_PROGRESS",
  CLOSING = "CLOSING",
  SOLD = "SOLD",
  RENTAL = "RENTAL"
}

export const DispositionDealStageLabel: Record<DispositionDealStage, string> = {
  [DispositionDealStage.RENOVATION_UPCOMING]: "RENOVATION UPCOMING",
  [DispositionDealStage.RENOVATION]: "RENOVATION",
  [DispositionDealStage.QA_INSPECTION]: "QA INSPECTION",
  [DispositionDealStage.QA_CALLBACK]: "QA CALLBACK",
  [DispositionDealStage.READY_TO_LIST]: "READY TO LIST",

  [DispositionDealStage.RESALE_UPCOMING]: "RESALE UPCOMING",
  [DispositionDealStage.CLEAN]: "CLEAN",
  [DispositionDealStage.PHOTOS]: "PHOTOS",
  [DispositionDealStage.PRE_LISTING]: "PRE LISTING",
  [DispositionDealStage.LISTING]: "LISTING",
  [DispositionDealStage.UNDER_CONTRACT_INSPECTION]: "UNDER CONTRACT INSPECTION",
  [DispositionDealStage.ROC_REPAIRS_IN_PROGRESS]: "ROC REPAIRS IN PROGRESS",
  [DispositionDealStage.CLOSING]: "CLOSING",
  [DispositionDealStage.SOLD]: "SOLD",
  [DispositionDealStage.RENTAL]: "RENTAL"
};
export interface DispositionInfo {
  dealId: string;
  stage: DispositionDealStage;
  pipeline: DealPipeline;
  generatedDate: string;
  listing?: DealListingInfo;
  closeDate: string;
}

export interface Deal {
  dealId: string;
  offerInfo: DealOfferInfo;
  dealType: DealType;
  walkthroughInfo?: WalkthroughInfo;
  closingInfo?: DealClosingInfo;
  dashboardInfo: {dashboardId: string};
  stage: DealStage;
  generatedDate: string;
  rawAddress: string;
  addressComponents: AddressComponent;
  updatedAt: string;
  archived?: boolean;
  allSellerDetails: SellerDetails[];
  origin: DealOrigin;
  tesseractId?: {refId: string};
  sourcePropMeta?: DealSourcePropertyMeta;
  ownerInfo?: TeamWiseDealOwnerInfo;
  assigneeInfo?: DealAssigneeInfo[];
  dispositionInfo?: DispositionInfo;
  felloConnect?: DealFelloConnectInfo;

  /**
   * Stores meaningful info regarding referrals
   */
  referral?: {
    status: FelloConnectReferralStatus;
    referredAt?: string;
    acceptedAt?: string;
    remarks?: string;
  };
}

export interface DealAssigneeDetails {
  name: string;
  email: string;
  phone?: string;
  photoUrl?: string;
}
export interface DealAssignees {
  homeAdvisor?: DealAssigneeDetails;
  transactionManager?: DealAssigneeDetails;
}
