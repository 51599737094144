import {Component, ViewContainerRef} from "@angular/core";
import {GoogleStreetViewImageService} from "../../../../lib";
import {MatDialog} from "@angular/material/dialog";
import {ManageAddressDialogComponent} from "./manage-address-dialog/manage-address-dialog.component";
import {AbstractDashboardService} from "../../services";

@Component({
  selector: "lib-manage-address",
  templateUrl: "./manage-address.component.html",
  styleUrls: ["./manage-address.component.scss"]
})
export class ManageAddressComponent {
  isLoading$ = this.dashboardService.isPropertyLoading;
  currentProperty$ = this.dashboardService.property$;

  constructor(
    private dashboardService: AbstractDashboardService,
    public streetView: GoogleStreetViewImageService,
    private dialog: MatDialog,
    private viewContainer: ViewContainerRef
  ) {}

  openManageAddress() {
    this.dialog.open(ManageAddressDialogComponent, {
      viewContainerRef: this.viewContainer,
      panelClass: ["mobile-bottom-sheet-2"]
    });
  }
}
