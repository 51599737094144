import {Inject, Injectable} from "@angular/core";
import {HttpClient, HttpParams} from "@angular/common/http";
import {Observable} from "rxjs";
import {
  AddContactOrPropertyRequestWeb,
  AgentContact,
  AgentContactBulkSelection,
  AgentContactDenormalizedSearchParams,
  AgentContactFilterConfigType,
  AgentContactPropertyWithContact,
  AgentContactSearchParams,
  AgentContactSearchResultV2,
  AgentContactTagCountResponse,
  AgentDeal,
  AgentSignature,
  BulkContactCreationBackground,
  ChangelogStats,
  ChangelogStatsRequest,
  ContactAISummary,
  ContactCreationErrorCode,
  ContactIQScore,
  ContactProperty,
  CRMAgentInfo,
  FieldFilterConfig,
  HomeValueUpdateRequest,
  PagedResult,
  UploadMediaResponse
} from "fello-model";
import {AGENT_API_SERVICE_BASE_URL} from "./agent-api.service";

@Injectable({providedIn: "root"})
export class AgentContactApiService {
  private baseUrl: string;

  constructor(private http: HttpClient, @Inject(AGENT_API_SERVICE_BASE_URL) private agentBaseUrl: string) {
    this.baseUrl = `${this.agentBaseUrl}/agent/contact`;
  }

  addContactOrProperty(addContactOrPropertyRequestWeb: AddContactOrPropertyRequestWeb): Observable<{
    isValid: boolean;
    contactId?: string;
    propertyId?: string;
    errorMsg?: string;
    errorCode?: ContactCreationErrorCode;
  }> {
    return this.http.post<{
      isValid: boolean;
      contactId?: string;
      propertyId?: string;
      errorMsg?: string;
      errorCode?: ContactCreationErrorCode;
    }>(`${this.baseUrl}/add`, addContactOrPropertyRequestWeb);
  }

  addProperty(
    contactId: string,
    rawAddress: string
  ): Observable<{
    isValid: boolean;
    propertyId?: string;
    errorMsg?: string;
    errorCode?: ContactCreationErrorCode;
  }> {
    return this.http.post<{
      isValid: boolean;
      propertyId?: string;
      errorMsg?: string;
      errorCode?: ContactCreationErrorCode;
    }>(`${this.baseUrl}/${contactId}/add-property`, {rawAddress});
  }

  bulkCreateContactsBackground(contactCreationBackground: BulkContactCreationBackground): Observable<unknown> {
    return this.http.post<unknown>(`${this.baseUrl}/bulk-background`, contactCreationBackground);
  }

  requestExport(selection: AgentContactBulkSelection, reportFileName?: string): Observable<void> {
    return this.http.post<void>(`${this.baseUrl}/export-request`, {selection, reportFileName});
  }

  getContactFileUploadMediaResponse(): Observable<UploadMediaResponse> {
    return this.http.post<UploadMediaResponse>(`${this.baseUrl}/get-pre-signed-url`, {});
  }

  searchContact(params: AgentContactSearchParams): Observable<PagedResult<AgentContact>> {
    return this.http.post<PagedResult<AgentContact>>(`${this.baseUrl}/`, params);
  }

  searchContactV2(params: AgentContactDenormalizedSearchParams, src?: string): Observable<AgentContactSearchResultV2> {
    return this.http.post<AgentContactSearchResultV2>(`${this.baseUrl}/v2`, params, {
      params: src ? {src} : undefined
    });
  }

  searchProperty(params: AgentContactSearchParams): Observable<PagedResult<AgentContactPropertyWithContact>> {
    return this.http.post<PagedResult<AgentContactPropertyWithContact>>(`${this.baseUrl}/property/v2/search`, params);
  }

  countProperty(params: AgentContactSearchParams, src?: string): Observable<{count: number}> {
    return this.http.post<{count: number}>(`${this.baseUrl}/property/v2/count`, params, {
      params: src ? {src} : undefined
    });
  }

  countContact(selection: AgentContactBulkSelection, src?: string): Observable<{count: number}> {
    return this.http.post<{count: number}>(`${this.baseUrl}/contact/v2/count`, selection, {
      params: src ? {src} : undefined
    });
  }

  searchPropertyMarkers(params: AgentContactSearchParams, src?: string): Observable<PagedResult<AgentContactPropertyWithContact>> {
    return this.http.post<PagedResult<AgentContactPropertyWithContact>>(`${this.baseUrl}/property/v2/markers`, params, {
      params: src ? {src} : undefined
    });
  }

  getTotalRecordsCount(src?: string): Observable<{records: number; contacts: number; properties: number}> {
    return this.http.get<{records: number; contacts: number; properties: number}>(`${this.baseUrl}/records/count`, {
      params: src ? {src} : undefined
    });
  }

  tagWiseCounts(params: AgentContactSearchParams): Observable<AgentContactTagCountResponse> {
    return this.http.post<AgentContactTagCountResponse>(`${this.baseUrl}/tag/counts`, params);
  }

  getCrmNames(params: AgentContactSearchParams): Observable<string[]> {
    return this.http.post<string[]>(`${this.baseUrl}/crm/name`, params);
  }

  getCrmFields(params: AgentContactSearchParams, field: "name" | "source" | "stage"): Observable<string[]> {
    return this.http.post<string[]>(`${this.baseUrl}/crm/fields/${field}`, params);
  }

  getCrmAgentNames(): Observable<CRMAgentInfo[]> {
    return this.http.get<CRMAgentInfo[]>(`${this.baseUrl}/crm/fields/agentNames`);
  }

  getContact(contactId: string): Observable<AgentContact> {
    return this.http.get<AgentContact>(`${this.baseUrl}/${contactId}`);
  }

  updateContact(
    contactId: string,
    contact: {
      phone?: string;
      fullName: string;
      emailId?: string;
    }
  ): Observable<AgentContact> {
    return this.http.post<AgentContact>(`${this.baseUrl}/${contactId}/update-contact`, contact);
  }

  deleteProperty(contactId: string, propertyId: string): Observable<AgentContact> {
    return this.http.delete<AgentContact>(`${this.baseUrl}/${contactId}/property/${propertyId}`);
  }

  unclaimProperty(contactId: string, propertyId: string): Observable<unknown> {
    return this.http.post(`${this.baseUrl}/${contactId}/property/${propertyId}/un-claim`, {});
  }

  getContactSignature(contactId: string): Observable<AgentSignature | null> {
    return this.http.get<AgentSignature | null>(`${this.baseUrl}/${contactId}/agent-signature`);
  }

  getContactStats(contactId: string, statsRequest: ChangelogStatsRequest): Observable<ChangelogStats[]> {
    return this.http.post<ChangelogStats[]>(`${this.baseUrl}/${contactId}/stats`, {statsRequest});
  }

  bulkUpdateContactOwner(selection: AgentContactBulkSelection, newOwnerId: string | null): Observable<unknown> {
    return this.http.post(`${this.baseUrl}/owner/bulk-update`, {selection, newOwnerId});
  }

  bulkAddTag(selection: AgentContactBulkSelection, tags: string[]): Observable<unknown> {
    return this.http.post(`${this.baseUrl}/tags/bulk-add`, {selection, tags});
  }

  bulkRemoveTag(selection: AgentContactBulkSelection, tags: string[]): Observable<unknown> {
    return this.http.post(`${this.baseUrl}/tags/bulk-remove`, {selection, tags});
  }

  bulkUpdateContactMarketingStatus(selection: AgentContactBulkSelection, isNonMarketing: boolean): Observable<unknown> {
    return this.http.post(`${this.baseUrl}/marketing-status/bulk-update`, {selection, isNonMarketing});
  }

  updateContactOwner(contactId: string, newOwnerId: string | null, notifyAssignedAgent?: boolean): Observable<unknown> {
    return this.http.post(`${this.baseUrl}/${contactId}/owner/update`, {newOwnerId, notifyAssignedAgent});
  }

  updateContactOwnerById(contactId: string, newOwnerId: string): Observable<unknown> {
    return this.http.post(`${this.baseUrl}/contact-owner/update`, {contactId, newOwnerId});
  }

  bulkDeleteContact(selection: AgentContactBulkSelection): Observable<{
    deletedCount: number;
    skippedCount: number;
  }> {
    return this.http.post<{
      deletedCount: number;
      skippedCount: number;
    }>(`${this.baseUrl}/bulk-delete`, {selection});
  }

  getDeal(contactId: string, propertyId: string): Observable<AgentDeal | null> {
    return this.http.get<AgentDeal | null>(`${this.baseUrl}/${contactId}/dashboard/${propertyId}/deal`);
  }

  searchContactAndProperty(params: AgentContactSearchParams): Observable<PagedResult<ContactProperty>> {
    return this.http.get<PagedResult<ContactProperty>>(`${this.baseUrl}/property/search`, {params: params as HttpParams});
  }

  saveHomeValue(contactId: string, propertyId: string, agentHomeValueUpdateRequest: HomeValueUpdateRequest): Observable<unknown> {
    return this.http.patch(`${this.baseUrl}/${contactId}/${propertyId}/home-value`, agentHomeValueUpdateRequest);
  }

  deleteAgentHomeValue(contactId: string, propertyId: string): Observable<unknown> {
    return this.http.delete(`${this.baseUrl}/${contactId}/${propertyId}/home-value`, {});
  }

  getFilterConfig(configType?: AgentContactFilterConfigType): Observable<FieldFilterConfig[]> {
    const params: Record<string, string> = {};
    if (configType) {
      params.type = configType;
    }
    return this.http.get<FieldFilterConfig[]>(`${this.baseUrl}/filter-config`, {params});
  }

  replacePrimaryEmail(contactId: string, emailId: string): Observable<unknown> {
    return this.http.patch(`${this.baseUrl}/${contactId}/primary-email`, {emailId});
  }

  makeEmailPrimary(contactId: string, emailId: string): Observable<unknown> {
    return this.http.patch(`${this.baseUrl}/${contactId}/make-email-primary`, {emailId});
  }

  addAdditionalEmail(contactId: string, emailId: string): Observable<unknown> {
    return this.http.post(`${this.baseUrl}/${contactId}/additional-email`, {emailId});
  }

  removeAdditionalEmail(contactId: string, emailId: string): Observable<unknown> {
    return this.http.delete(`${this.baseUrl}/${contactId}/additional-email`, {body: {emailId}});
  }

  subscribeContact(contactId: string): Observable<unknown> {
    return this.http.post<unknown>(`${this.baseUrl}/${contactId}/resubscribe`, {});
  }

  markEmailAsValid(contactId: string): Observable<unknown> {
    return this.http.post<unknown>(`${this.baseUrl}/${contactId}/mark-email-valid`, {});
  }

  replaceAdditionalEmail(contactId: string, emailId: string, updatedEmailId: string): Observable<unknown> {
    return this.http.patch(`${this.baseUrl}/${contactId}/additional-email`, {
      existingContactEmail: {emailId},
      updatedContactEmail: {emailId: updatedEmailId}
    });
  }

  getContactEmailsStatus(contactId: string): Observable<{[email: string]: {valid: boolean}}> {
    return this.http.get<{[email: string]: {valid: boolean}}>(`${this.baseUrl}/${contactId}/emails-status`);
  }

  getContactIQScore(contactId: string): Observable<ContactIQScore> {
    return this.http.get<ContactIQScore>(`${this.baseUrl}/${contactId}/score`);
  }

  getContactAISummary(contactId: string): Observable<ContactAISummary> {
    return this.http.get<ContactAISummary>(`${this.baseUrl}/${contactId}/summary`);
  }

  generateContactAISummary(contactId: string): Observable<ContactAISummary> {
    return this.http.post<ContactAISummary>(`${this.baseUrl}/${contactId}/summary/generate`, {});
  }
}
