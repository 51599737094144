import {Component, Inject} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {LeadRequestFromDashboard, PopupLeadFormRequest, SaleTimeline} from "fello-model";
import {MatSnackBar} from "@angular/material/snack-bar";
import {NgxSpinnerService} from "ngx-spinner";
import {FelloWebsites, StringUtils, ToastSpinnerBase} from "../../../../../lib";
import {tap} from "rxjs/operators";
import {phoneNumberPattern} from "../../../../fello-ui-utils";
import {AbstractDashboardService, DashboardSettingsService} from "../../../services";

@Component({
  selector: "lib-popup-lead-form",
  templateUrl: "./popup-lead-form.component.html",
  styleUrls: ["./popup-lead-form.component.scss"]
})
export class PopupLeadFormComponent extends ToastSpinnerBase {
  protected readonly SaleTimeline = SaleTimeline;
  data: LeadRequestFromDashboard = {
    firstName: "",
    lastName: "",
    phone: "",
    message: ""
  };
  phoneNumberPattern = phoneNumberPattern;
  settings$ = this.dashboardSettingsService.settings$;
  felloWebsites = FelloWebsites;
  contactEmail = "";
  constructor(
    private dialogRef: MatDialogRef<boolean>,
    @Inject(MAT_DIALOG_DATA) public inputData: PopupLeadFormRequest,
    private dashboardService: AbstractDashboardService,
    snackBar: MatSnackBar,
    spinner: NgxSpinnerService,
    private dashboardSettingsService: DashboardSettingsService
  ) {
    super(spinner, snackBar);

    this.dashboardService.contact$
      .pipe(
        tap(contact => {
          this.data.firstName = contact.fullName ? StringUtils.splitFullName(contact.fullName).first : "";
          this.data.lastName = contact.fullName ? StringUtils.splitFullName(contact.fullName).last : "";
          this.data.phone = contact.phone ?? "";
          this.contactEmail = contact.emailId ?? "";
        })
      )
      .subscribe();
  }

  submitCTAPopupLeadForm(): void {
    this.withSpinnerAndToast(
      this.dashboardService.submitCTAPopupLeadForm(this.inputData.action, this.data, this.inputData.connectedAppType),
      {
        successMsg: "Message received, we'll get back to you shortly",
        failureMsg: "Something went wrong.Please Try Again.",
        snackBarConfig: {duration: 4000}
      }
    ).subscribe(
      () => this.dialogRef.close(),
      () => this.dialogRef.close()
    );
  }
}
