import {Inject, Injectable, InjectionToken, Optional} from "@angular/core";
import introJs, {IntroJs, Options} from "intro.js";
import {IntroDirective} from "../directives";
import {orderBy} from "lodash-es";
import {Observable} from "rxjs";

export interface IntroOptions extends Options {
  group?: string;
}

export const DEFAULT_INTRO_OPTIONS = new InjectionToken<IntroOptions>("Default intro options");

@Injectable({
  providedIn: "root"
})
export class IntroService {
  private steps = new Set<IntroDirective>();
  private intro: IntroJs;
  constructor(@Optional() @Inject(DEFAULT_INTRO_OPTIONS) private defaultOptions: IntroOptions) {
    this.intro = introJs();
  }

  addStep(step: IntroDirective): void {
    this.steps.add(step);
  }

  removeStep(step: IntroDirective): void {
    this.steps.delete(step);
  }

  start(options?: IntroOptions): void {
    options = {
      ...this.defaultOptions,
      ...options
    };
    const group = options?.group ?? "default";
    this.intro
      .setOptions({
        ...options,
        steps: orderBy(
          Array.from(this.steps.values())
            .filter(step => step.group === group)
            .map(step => step.asStep()),
          "step",
          "asc"
        )
      })
      .start();
  }

  onComplete(): Observable<void> {
    return new Observable<void>(subscriber => {
      this.intro.oncomplete(() => subscriber.next());
    });
  }
  onExit(): Observable<void> {
    return new Observable<void>(subscriber => {
      this.intro.onexit(() => subscriber.next());
    });
  }
}
