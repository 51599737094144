import {Component, Input} from "@angular/core";

@Component({
  selector: "lib-icon,app-icon",
  template:
    '<span class="fh-icon" [style.color]="color" [style.fontSize.px]="fontSize" [style.fontWeight]="fontWeight"><ng-content></ng-content></span>',
  styleUrls: ["./icon.component.scss"],
  standalone: true
})
export class IconComponent {
  @Input() color = "inherit";
  @Input() fontSize: string | number = "inherit";
  @Input() fontWeight = "normal";
}
