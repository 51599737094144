export enum DataSourceType {
  MLS = "MLS",
  PUBLIC_RECORDS = "PR",
  AVM = "AVM",
  SELLER = "SELLER",
  OVERRIDE = "OVERRIDE"
}

export const sourceTypeLabel: Record<DataSourceType, string> = {
  [DataSourceType.MLS]: "MLS",
  [DataSourceType.PUBLIC_RECORDS]: "PR",
  [DataSourceType.AVM]: "AVM",
  [DataSourceType.SELLER]: "",
  [DataSourceType.OVERRIDE]: ""
};
