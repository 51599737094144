import {Component, ElementRef, forwardRef, ViewChild, ViewContainerRef} from "@angular/core";
import {tap} from "rxjs/operators";
import {mixinDestroyable} from "../../../../lib";
import {DashboardModuleDirective, DashboardModuleDirectiveInputs} from "../../directives/dashboard-module.directive";
import {DashboardModuleType, DataProviderType} from "fello-model";
import {HomeValueTrendCalculationV2Component, ImproveHomeEstimateComponent} from "../dialogs";
import {MatDialog} from "@angular/material/dialog";
import {HomeValueGraphComponent} from "./home-value-graph/home-value-graph.component";
import {AbstractDashboardService} from "../../services";

@Component({
  selector: "lib-home-value-trend-v2",
  templateUrl: "./home-value-trend-v2.component.html",
  styleUrls: ["./home-value-trend-v2.component.scss"],
  // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
  inputs: DashboardModuleDirectiveInputs,
  providers: [
    {
      provide: DashboardModuleDirective,
      useExisting: forwardRef(() => HomeValueTrendV2Component)
    }
  ]
})
export class HomeValueTrendV2Component extends mixinDestroyable(
  class extends DashboardModuleDirective<DashboardModuleType.HOME_VALUE_TREND> {}
) {
  protected readonly DataProviderType = DataProviderType;
  @ViewChild("homeValueGraph") homeValueGraph?: HomeValueGraphComponent;

  constructor(
    elementRef: ElementRef,
    private dialog: MatDialog,
    private viewContainerRef: ViewContainerRef,
    private dashboardService: AbstractDashboardService
  ) {
    super(elementRef);
  }

  totalHistories = 0;

  ngOnInit() {
    super.ngOnInit();
    this.dashboardService.multiAvmHistory$.pipe().subscribe(response => {
      this.totalHistories = response.history.length;
    });
  }

  getHomeValueInfo() {
    const dialogRef = this.dialog.open(HomeValueTrendCalculationV2Component, {
      viewContainerRef: this.viewContainerRef,
      panelClass: ["mobile-bottom-sheet-2", "bg-slate"],
      data: {
        showCTA: this.moduleSetting.showCTA
      }
    });
    dialogRef
      .afterClosed()
      .pipe(
        tap(result => {
          if (result) {
            this.openImproveEstimate();
          }
        })
      )
      .subscribe();
  }

  openImproveEstimate(): void {
    this.dialog.open(ImproveHomeEstimateComponent, {
      viewContainerRef: this.viewContainerRef,
      panelClass: ["mobile-bottom-sheet"]
    });
  }
}
