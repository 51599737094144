<p class="text-size-18 fw-700 mb-2">Home Conditions</p>
<div class="form-field">
    <div class="area-option" *ngFor="let area of areaConditions" (click)="editArea(area)">
        <p>{{ area.name }}</p>
        <p class="text-color-primary text-capitalize">
            <span *ngIf="area.value; else noValue">
                {{ HomeConditionLevelLabel[area.value] }}
            </span>
        </p>
    </div>
</div>
<ng-template #noValue>-</ng-template>
