import {Pipe, PipeTransform} from "@angular/core";
import {DomSanitizer, SafeHtml, SafeResourceUrl, SafeUrl} from "@angular/platform-browser";

@Pipe({
  name: "safeHtml",
  standalone: true
})
export class SafeHtmlPipe implements PipeTransform {
  constructor(private domSanitizer: DomSanitizer) {}

  transform(html: string): SafeHtml {
    return this.domSanitizer.bypassSecurityTrustHtml(html);
  }
}

@Pipe({
  name: "safeUrl",
  standalone: true
})
export class SafeUrlPipe implements PipeTransform {
  constructor(private domSanitizer: DomSanitizer) {}

  transform(url: string): SafeUrl {
    return this.domSanitizer.bypassSecurityTrustUrl(url);
  }
}

@Pipe({
  name: "safeResourceUrl",
  standalone: true
})
export class SafeResourceUrlPipe implements PipeTransform {
  constructor(private domSanitizer: DomSanitizer) {}

  transform(resourceUrl: string): SafeResourceUrl {
    return this.domSanitizer.bypassSecurityTrustResourceUrl(resourceUrl);
  }
}
