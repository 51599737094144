import {Directive, ElementRef, AfterViewInit} from "@angular/core";

@Directive({
  standalone: true,
  selector: "[dynamicTooltipDirective]"
})
export class DynamicTooltipDirective implements AfterViewInit {
  constructor(private elementRef: ElementRef) {}

  ngAfterViewInit(): void {
    const targetElement = this.elementRef.nativeElement;

    if (targetElement?.hasChildNodes()) {
      this.positionTooltip();
    }
  }

  positionTooltip() {
    const tooltipElements: NodeList = this.elementRef.nativeElement.querySelectorAll("[data-fello-tooltip]");
    if (tooltipElements.length) {
      tooltipElements.forEach((tooltipElement: Node) => {
        const parentElement = tooltipElement.parentElement;
        if (!parentElement) {
          return;
        }
        const parentRect = parentElement.getBoundingClientRect();
        const tooltipRect = (tooltipElement as HTMLElement).getBoundingClientRect();
        const tooltipWidth = 250;

        const leftDiff = tooltipRect.left - parentRect.left;
        const rightDiff = parentRect.width - leftDiff;
        let left = 0;

        if (leftDiff <= tooltipWidth / 2) {
          left = tooltipWidth / 2 - leftDiff - tooltipRect.width / 2;
        } else if (rightDiff <= tooltipWidth / 2) {
          left = parentRect.width - leftDiff - tooltipRect.width / 2 - tooltipWidth / 2;
        }

        (tooltipElement as HTMLElement).style.setProperty("--tooltip-left", `${left}px`);
      });
    }
  }
}
