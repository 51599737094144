import {Component, Input} from "@angular/core";
import {CommonModule} from "@angular/common";
import {AvmCondifence} from "fello-model";
import {TooltipDirective} from "../../directives";

@Component({
  selector: "lib-avm-confidence-status",
  standalone: true,
  imports: [CommonModule, TooltipDirective],
  templateUrl: "./avm-confidence-status.component.html",
  styleUrls: ["./avm-confidence-status.component.scss"]
})
export class AvmConfidenceStatusComponent {
  @Input() confidence: AvmCondifence;
  protected readonly AvmCondifence = AvmCondifence;
}
