import {Inject, Injectable, InjectionToken} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";

export const FIRST_PROMOTER_SERVICE_BASE_URL = new InjectionToken<string>("FIRST_PROMOTER_SERVICE_BASE_URL", {
  providedIn: "root",
  factory: () => "https://affiliate.hifello.com"
});

@Injectable({
  providedIn: "root"
})
export class FirstPromoterApiService {
  constructor(private http: HttpClient, @Inject(FIRST_PROMOTER_SERVICE_BASE_URL) private baseUrl: string) {}

  logout(): Observable<unknown> {
    return this.http.get(`${this.baseUrl}/logout`, {withCredentials: true});
  }

  getIframeBaseUrl(): string {
    return `${this.baseUrl}`;
  }

  getLogoutBaseUrl(): string {
    return `${this.baseUrl}/logout`;
  }
}
