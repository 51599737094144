import {AutomationComponent, AutomationComponentLink, AutomationComponentType, AutomationDefinition, AutomationType} from "fello-model";
import {isEmpty} from "lodash-es";

export class AutomationUtil {
  public static getComponentAndLinksOfSubtree(
    rootComponentId: string,
    components: AutomationComponent<AutomationComponentType>[],
    links: AutomationComponentLink[],
    skipRoot?: boolean
  ): {components: AutomationComponent<AutomationComponentType>[]; links: AutomationComponentLink[]} {
    const visited: Set<string> = new Set();
    let subtreeComponents: AutomationComponent<AutomationComponentType>[] = [];
    let subtreeLinks: AutomationComponentLink[] = [];

    const dfs = (componentId: string) => {
      if (visited.has(componentId)) {
        return;
      }
      visited.add(componentId);

      // Find the component by its ID and add it to the subtree
      const component = components.find(comp => comp._id === componentId);
      if (component) {
        subtreeComponents.push(component);

        // Find all links where this component is the source
        const outgoingLinks = links.filter(link => link.fromCompId === componentId);
        for (const link of outgoingLinks) {
          subtreeLinks.push(link);
          dfs(link.toCompId); // Explore the child component (the "to" component in the link)
        }
      }
    };

    // Start the search from the rootId
    dfs(rootComponentId);

    if (skipRoot) {
      subtreeComponents = subtreeComponents.filter(comp => comp._id !== rootComponentId);
      subtreeLinks = subtreeLinks.filter(link => link.fromCompId !== rootComponentId && link.toCompId !== rootComponentId);
    }

    return {
      components: subtreeComponents,
      links: subtreeLinks
    };
  }

  public static getWorkflowTriggerMode(workflow: AutomationDefinition): WorklowTriggerMode | undefined {
    if (workflow.type === AutomationType.WORKFLOW) {
      if (!isEmpty(workflow.enrollmentInfo.filterCriteria.userCriteria)) {
        return WorklowTriggerMode.FILTER_CRITERIA;
      } else if (!isEmpty(workflow.enrollmentInfo.events) && workflow.enrollmentInfo.events!.length > 0) {
        return WorklowTriggerMode.EVENT;
      }
    }
    return undefined;
  }
}
export enum WorklowTriggerMode {
  FILTER_CRITERIA = "FILTER_CRITERIA",
  EVENT = "EVENT"
}
