<div class="flex-box-center-between mb-4">
  <h2 mat-dialog-title class="m-0">What are you looking to buy?</h2>
  <button class="button-icon ml-auto" mat-dialog-close>
    <app-icon class="text-size-28">close</app-icon>
  </button>
</div>
<mat-dialog-content>
  <p class="mb-3 text-size-16">Please enter the city or zip code you're most interested in.</p>
  <form input-options #buyHome="ngForm">
    <div class="form-field mb-3">
      <input
        type="text"
        placeholder="Enter city or zip code"
        name="buyingArea"
        required
        [(ngModel)]="homeBuyDetails.buyingArea"
        [maxLength]="64"
      />
    </div>
    <div class="flex-box-center mb-3">
      <div class="form-field prefix" input-options data-prefix="$">
        <lib-amount-comma-separated
          class="text-right"
          placeholder="min-price"
          required
          name="low"
          [(ngModel)]="homeBuyDetails.buyingPriceLow"
          [libMin]="0"
          [libMax]="100000000"
        ></lib-amount-comma-separated>
      </div>
      <p class="px-10">-</p>
      <div class="form-field prefix" input-options data-prefix="$">
        <lib-amount-comma-separated
          class="text-right"
          placeholder="max-price"
          required
          name="high"
          [(ngModel)]="homeBuyDetails.buyingPriceHigh"
          [libMin]="0"
          [libMax]="100000000"
        ></lib-amount-comma-separated>
      </div>
    </div>
  </form>
</mat-dialog-content>
<mat-dialog-actions class="mt-3">
  <button class="w-100" [disabled]="buyHome.invalid || !rangeValid" (click)="submit()">Continue</button>
</mat-dialog-actions>
