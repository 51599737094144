import {Component, ViewContainerRef} from "@angular/core";
import {CommonModule} from "@angular/common";
import {
  AvmDisplayValueType,
  CustomSellingOptionType,
  DashboardModuleButtonAction,
  DashboardModuleButtonActionType,
  DashboardModuleButtonType
} from "fello-model";
import {NgxSpinnerModule, NgxSpinnerService} from "ngx-spinner";
import {MatSnackBar} from "@angular/material/snack-bar";
import {map} from "rxjs/operators";
import {FormatPricePipe, NumberShorthandPipe, RoundOffPipe} from "../../../../fello-ui-utils";
import {DashboardService} from "../../../dashboard.service";
import {AbstractDashboardSettingsService, DashboardSettingsService} from "../../../services";
import {BaseSellingOptionDirective} from "../directives/base-selling-option-directive";
import {MatDialog} from "@angular/material/dialog";
import {PopupLeadFormComponent} from "../../dialogs";
import {DashboardTrackButtonClickDirective} from "../../../directives/dashboard-track-button-click.directive";

@Component({
  selector: "lib-custom-selling-option",
  standalone: true,
  imports: [CommonModule, FormatPricePipe, NumberShorthandPipe, NgxSpinnerModule, RoundOffPipe, DashboardTrackButtonClickDirective],
  templateUrl: "./custom-selling-option.component.html",
  styleUrls: ["./custom-selling-option.component.scss"],
  providers: [DashboardSettingsService, DashboardService]
})
export class CustomSellingOptionComponent<T extends CustomSellingOptionType> extends BaseSellingOptionDirective<T> {
  protected readonly AvmDisplayValueType = AvmDisplayValueType;
  protected readonly DashboardModuleButtonType = DashboardModuleButtonType;

  accountLogo$ = this.dashboardSettingsService.settings$.pipe(
    map(settings => {
      return settings?.logo;
    })
  );

  constructor(
    public spinnerService: NgxSpinnerService,
    public snackBar: MatSnackBar,
    private dashboardService: DashboardService,
    private dashboardSettingsService: AbstractDashboardSettingsService,
    private viewContainerRef: ViewContainerRef,
    private dialog: MatDialog
  ) {
    super(spinnerService, snackBar);
  }

  performCTAAction(action: DashboardModuleButtonAction): void {
    if (action.actionType === DashboardModuleButtonActionType.POPUP_LEAD_FORM) {
      this.dialog.open(PopupLeadFormComponent, {
        maxWidth: 580,
        data: {
          action
        },
        viewContainerRef: this.viewContainerRef,
        panelClass: ["mobile-bottom-sheet"]
      });
    } else {
      this.withSpinner(this.dashboardService.performCTAAction(action)).subscribe();
    }
  }
}
