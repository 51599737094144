import pino from "pino";
import {Logger} from "./Logger";

const logger = pino({
  formatters: {
    level: (label: string) => ({level: label}),
    bindings: () => ({})
  }
});

export class PinoLogger implements Logger {
  constructor(private defaultMeta: Record<string | number, any>) {}
  debug(msg: Record<string | number, any>): void {
    logger.debug({
      ...this.defaultMeta,
      ...msg
    });
  }

  error(msg: Record<string | number, any>): void {
    logger.error({
      ...this.defaultMeta,
      ...msg
    });
  }

  info(msg: Record<string | number, any>): void {
    logger.info({
      ...this.defaultMeta,
      ...msg,
      level: "info"
    });
  }

  warn(msg: Record<string | number, any>): void {
    logger.warn({
      ...this.defaultMeta,
      ...msg
    });
  }
}
