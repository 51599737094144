<mat-select
  [placeholder]="placeholder"
  [panelClass]="panelClass"
  [class]="matSelectClass"
  (openedChange)="dropdownSearch.focus()"
  [(ngModel)]="value"
  (blur)="onTouched()"
  (selectionChange)="onChange($event.value)"
  [disabled]="disabled"
  [compareWith]="compareWith"
  disableOptionCentering
>
  <div class="dropdown-search-c">
    <div class="form-field prefix prefix-icon">
      <lib-icon color="var(--color-primary)" fontSize="14">search</lib-icon>
      <lib-icon color="var(--plum-4)" class="close c-pointer" fontSize="20" *ngIf="(search$ | async)?.length" (click)="search$.next('')">
        close
      </lib-icon>
      <input
        [ngModel]="search$ | async"
        (keydown.space)="$event.stopPropagation()"
        (ngModelChange)="search$.next($event)"
        #dropdownSearch
        type="text"
        placeholder="Search"
      />
    </div>
  </div>
  <mat-option *ngFor="let option of filteredOptions$ | async" [value]="option.value">{{ option.label }}</mat-option>
  <mat-option class="pointer-events-none" (click)="$event.stopPropagation()" *ngIf="!(filteredOptions$ | async)?.length"
    >No results found
  </mat-option>
</mat-select>
