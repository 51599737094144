import {Agent, AgentOnboardingStep} from "fello-model";
import _ from "lodash-es";
import {StringUtils} from "../../../lib";

export class AgentStringUtils {
  static labelAgentsByOnboardingStep(agent: Agent | Partial<Agent>): string {
    const stepWiseLabel = {
      [AgentOnboardingStep.INVITED]: "(Invited)",
      [AgentOnboardingStep.ACCOUNT_ONBOARDING_DETAILS]: "(Invited)",
      [AgentOnboardingStep.PERSONAL_DETAILS]: "(Invited)",
      [AgentOnboardingStep.COMPLETED]: ""
    };

    const labelAgent = (currentAgent: Agent | Partial<Agent>): string => {
      const {email, onboardingStep} = currentAgent;
      const name = StringUtils.buildFullName(currentAgent);
      if (onboardingStep !== undefined) {
        if (name && !name.includes(stepWiseLabel[onboardingStep])) {
          return `${name} ${stepWiseLabel[onboardingStep]}`;
        } else if (email && !email.includes(stepWiseLabel[onboardingStep])) {
          return `${email} ${stepWiseLabel[onboardingStep]}`;
        } else {
          return name || email || "";
        }
      } else {
        return name || email || "";
      }
    };
    return labelAgent(agent);
  }

  static sortAgentsByInvite<T extends Partial<Agent>>(members: T[]): T[] {
    const [invitedUsers, platformAgents] = _.partition(members, m => m.onboardingStep !== AgentOnboardingStep.COMPLETED);
    return [...platformAgents, ...invitedUsers];
  }
}
