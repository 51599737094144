<ng-container *ngIf="confidence === AvmCondifence.HIGH || confidence === AvmCondifence.VERY_HIGH">
  <img src="/assets/images/svg/confidence-high.svg" alt="High Confidence" class="img-confidence" [libTooltip]="confidenceHigh" />
</ng-container>
<ng-container *ngIf="confidence === AvmCondifence.MEDIUM">
  <img src="/assets/images/svg/confidence-medium.svg" alt="Medium Confidence" class="img-confidence" [libTooltip]="confidenceMedium" />
</ng-container>
<ng-container *ngIf="confidence === AvmCondifence.LOW || confidence === AvmCondifence.VERY_LOW">
  <img src="/assets/images/svg/confidence-low.svg" alt="Low Confidence" class="img-confidence" [libTooltip]="confidenceLow" />
</ng-container>

<ng-template #confidenceHigh>
  High Confidence: Your home value estimate utilizes a comprehensive range of public data for accuracy. However, it doesn't factor in unique
  elements such as the specific condition of your home or any distinctive improvements made. For a more personalized evaluation, contact
  your real estate agent.
</ng-template>

<ng-template #confidenceMedium>
  Medium Confidence: Due to varying reliability of data sources or potential limitations in available data, this home value estimate carries
  a medium level of confidence. While it uses a comprehensive range of public data for accuracy, it doesn't account for unique elements like
  the specific condition of your home or any distinct modifications made. For a more personalized evaluation, we recommend contacting your
  real estate agent.
</ng-template>

<ng-template #confidenceLow>
  Low Confidence: The lack of extensive data or comparable sales in your area significantly influences this home value estimate, resulting
  in a low level of confidence. While it makes use of available public data, it doesn't include unique aspects such as the specific
  condition of your home or any distinctive improvements. We strongly advise contacting your real estate agent for a more personalized and
  accurate evaluation.
</ng-template>
