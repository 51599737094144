import {Component, Inject, OnInit} from "@angular/core";
import {MatDialogRef, MAT_DIALOG_DATA} from "@angular/material/dialog";
import {HomeConditionLevel} from "fello-model";

export interface AreaConditionOption {
  name: string;
  value: HomeConditionLevel;
  description: string;
  image: string;
}

@Component({
  selector: "app-home-details-condition-dialog",
  templateUrl: "./home-details-condition-dialog.component.html",
  styleUrls: ["./home-details-condition-dialog.component.scss"]
})
export class HomeDetailsConditionDialogComponent implements OnInit {
  areaName: string;
  areaConditionOptions: AreaConditionOption[] = [
    {
      name: "Perfect",
      value: HomeConditionLevel.PERFECT,
      description: "Like new condition",
      image: "perfect"
    },
    {
      name: "Excellent",
      value: HomeConditionLevel.EXCELLENT,
      description: "Excellent & well maintained",
      image: "excellent"
    },
    {
      name: "Good",
      value: HomeConditionLevel.GOOD,
      description: "In good shape & well maintained",
      image: "good"
    },
    {
      name: "Minor Work",
      value: HomeConditionLevel.MINOR_WORK,
      description: "Needs a few minor renovations",
      image: "minor-work"
    },
    {
      name: "Major Work",
      value: HomeConditionLevel.MAJOR_WORK,
      description: "Needs major renovations for all",
      image: "major-work"
    }
  ];

  constructor(@Inject(MAT_DIALOG_DATA) areaName: string, private dialogRef: MatDialogRef<HomeConditionLevel>) {
    if (areaName) {
      this.areaName = areaName;
    }
  }

  ngOnInit(): void {}

  selectOption(option: AreaConditionOption): void {
    this.dialogRef.close(option.value);
  }
}
