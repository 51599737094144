<svg
  #connectorPad
  *ngIf="!hidden && !canvas.disabled"
  [attr.height]="padRadius * 2 + strokeWidth"
  [attr.width]="padRadius * 2 + strokeWidth"
  xmlns="http://www.w3.org/2000/svg"
  class="ngflowchart-connector-pad"
>
  <circle
    [attr.cx]="padRadius + strokeWidth / 2"
    [attr.cy]="padRadius + strokeWidth / 2"
    [attr.r]="padRadius"
    stroke="grey"
    [attr.stroke-width]="strokeWidth"
    fill="none"
  />
</svg>
