import {AvailableLandingPageLayouts, LandingPageLayoutType} from "fello-model";
import {Type} from "@angular/core";
import {LandingPageJourneyTemplateComponent} from "./components/landing-page-journey-template/landing-page-journey-template.component";
import {LandingPageTabbedAComponent, LandingPageTemplateAComponent} from "./components";

export const LandingPageLayoutTypeComponentMap: {[P in AvailableLandingPageLayouts]: Type<any>} = {
  [LandingPageLayoutType.JOURNEY]: LandingPageJourneyTemplateComponent,
  [LandingPageLayoutType.WEBSITE_1]: LandingPageTemplateAComponent,
  [LandingPageLayoutType.TABBED_WEBSITE_1]: LandingPageTabbedAComponent
};
