<header class="journey-template">
  <ng-container *ngIf="landingPageSetting; let settings">
    <ng-container *ngIf="settings.logo; let logo">
      <ng-container *ngIf="settings.website; else justLogo">
        <a [href]="settings.website" target="_blank" class="flex-box">
          <img [src]="logo" alt="logo" class="img-logo" />
        </a>
      </ng-container>
      <ng-template #justLogo>
        <img [src]="logo" alt="logo" class="img-logo" />
      </ng-template>
    </ng-container>
  </ng-container>
  <div class="flex-box-center ml-auto">
    <p class="text-size-8 whitespace-nowrap">Powered by</p>
    <img src="/assets/images/svg/fello.svg" alt="fello" class="img-fello ml-1" />
  </div>
</header>
<ng-container *ngIf="landingPageSetting?.website; let website">
  <a [href]="website" class="link text-size-14 fw-500 flex-box-center line-height-1-2 quick-link">
    <lib-icon color="var(--color-primary)">left-big</lib-icon>
    <span>{{websiteURLValue}}</span>
  </a>
</ng-container>

<div class="offer-body journey-template">
  <div class="wrapper journey-template">
    <lib-lp-journey-address *ngIf="landingPageSetting; else noSubscription"></lib-lp-journey-address>
  </div>
</div>
<ng-template #noSubscription>
  <div class="p-4" *ngIf="landingPageSetting?.signature; let signature">
    <p class="text-size-18 fw-600">Please contact your Agent,</p>
    <lib-agent-signature [agentSignature]="signature"></lib-agent-signature>
  </div>
</ng-template>
