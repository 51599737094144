<p class="text-size-18 fw-700 mb-2">Additional Details</p>
<div class="form-field">
  <div class="checkbox-option mb-3" *ngFor="let option of homeFeatureOptions">
    <input
      type="checkbox"
      [id]="option.name"
      name="home-buy"
      [value]="option.value"
      [(ngModel)]="option.selected"
      (change)="addValue(option)"
    />
    <label [for]="option.name">
      <app-icon class="tick">tick</app-icon>
      {{ option.value }}
    </label>
  </div>
</div>
