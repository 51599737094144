import {Component, Input} from "@angular/core";

@Component({
  selector: "lib-tag",
  template: "<ng-content></ng-content>",
  host: {
    "[class]": "'tag tag-' + color"
  },
  standalone: true
})
export class TagComponent {
  @Input() color = "primary";
}
