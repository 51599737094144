<ngx-skeleton-loader *ngIf="isLoading$ | async; else isLoaded" count="1" [theme]="{height: '200px', width: '100%'}"></ngx-skeleton-loader>
<ng-template #isLoaded>
  <div class="db-card header-highlight page-break-inside-avoid" *ngIf="property$ | async">
    <ng-container *ngIf="felloHvEstimates$ | async; let multiAvmResponse; else: loader">
      <div class="db-card-body">
        <ng-container>
          <div class="mb-3" *ngIf="(lastAdjustment$ | async) || multiAvmResponse.length">
            <p class="db-card-title word-break">{{ moduleSetting.mainHeading }}</p>
            <p class="db-card-desc mt-1 word-break" *ngIf="moduleSetting.subHeading">{{ moduleSetting.subHeading }}</p>
          </div>
          <ng-container *ngIf="lastAdjustment$ | async; let lastAdjustment">
            <ng-container *ngIf="agentSignature$ | async; let signature">
              <lib-avm-updated-by-agent-strip
                *ngIf="settings$ | async; let settings"
                [lastAdjustment]="lastAdjustment"
                [signature]="signature"
                [agencyName]="settings.name"
                class="mt-4"
              >
                <ng-container *ngIf="moduleSetting.showButtonLink">
                  <div class="line-break small"></div>
                  <button class="link text-center w-100 fw-500 text-size-14 print-d-none" (click)="openImproveEstimate()">
                    {{ moduleSetting.buttonLinkText }}
                  </button>
                </ng-container>
              </lib-avm-updated-by-agent-strip>
            </ng-container>
          </ng-container>
          <ng-container *ngIf="multiAvmResponse.length; else noHomeValue">
            <div class="flex-box flex-wrap flex-box-gap-3 mt-4">
              <ng-container *ngFor="let avmResponse of multiAvmResponse">
                <div *ngIf="avmResponse.median" class="flex-item-auto-sizing w-mobile-100">
                  <p class="text-size-32 fw-600">
                    <span class="actual-data"> {{ avmResponse.median | currency: "USD":"symbol":"1.0-0" }} </span>
                    <span class="dummy-data">$XXX,XXX</span>
                  </p>
                  <div class="flex-box-center flex-box-gap-1">
                    <p class="text-size-16 fw-400 text-color-light-2 actual-data" *ngIf="avmResponse.low && avmResponse.high">
                      ${{ avmResponse.low | numberShorthand }} - ${{ avmResponse.high | numberShorthand }}
                    </p>
                    <p class="text-size-16 fw-400 text-color-light-2 dummy-data" *ngIf="avmResponse.low && avmResponse.high">
                      $XXXK - $XXXK
                    </p>
                    <lib-avm-confidence-status
                      *ngIf="avmResponse.confidence"
                      [confidence]="avmResponse.confidence"
                    ></lib-avm-confidence-status>
                  </div>
                  <p class="text-size-14 fw-500 text-color-light-2 mt-1 flex-box-center" *ngIf="avmResponse.provider">
                    {{ avmResponse.provider | enumToLabel: "PROVIDER_TYPE" }}
                    <ng-container *ngIf="settings$ | async; let settings">
                      <lib-icon
                        [libTooltip]="dataProviderTooltip"
                        [tooltipContext]="{provider: avmResponse.provider}"
                        tooltipClass="max-width-220-imp"
                        class="line-height-1 ml-1"
                        >info
                      </lib-icon>
                      <ng-template #dataProviderTooltip let-provider="provider">
                        <ng-container *ngIf="provider === DataProviderType.CL_BULK">
                          This estimate is provided by CoreLogic, a third-party Automated Valuation Model (AVM) provider that is independent
                          of {{ settings.name }}. It can serve as a helpful starting point for discussions with a real estate agent.
                        </ng-container>
                        <ng-container *ngIf="provider === DataProviderType.ATTOM_BULK">
                          This estimate is provided by ATTOM, a third-party Automated Valuation Model (AVM) provider that is independent of
                          {{ settings.name }}. It can serve as a helpful starting point for discussions with a real estate agent.
                        </ng-container>
                        <ng-container *ngIf="provider === DataProviderType.QUANTARIUM">
                          This estimate is provided by Quantarium, a third-party Automated Valuation Model (AVM) provider that is
                          independent of {{ settings.name }}. It can serve as a helpful starting point for discussions with a real estate
                          agent.
                        </ng-container>
                      </ng-template>
                    </ng-container>
                  </p>
                </div>
              </ng-container>
            </div>
            <ng-container *ngIf="moduleSetting.showAvmTrendChart">
              <div class="line-break"></div>
              <div *ngIf="dataProviders$ | async; let dataProviders">
                <lib-home-value-graph
                  #homeValueGraph
                  [graphTitle]="moduleSetting.trendChartHeading"
                  [dataProviders]="dataProviders"
                  [showCTAInDialog]="moduleSetting.showButtonLink"
                ></lib-home-value-graph>
              </div>
            </ng-container>
            <ng-container *ngIf="moduleSetting.showAvmDisclaimer">
              <div class="box-c flex-box-align-start border-1 bg-blue-00 p-2 mt-4">
                <lib-icon class="text-size-16 mr-2 text-color-blue mw-16">info-filled</lib-icon>
                <p class="text-size-14 fw-500">{{ moduleSetting.avmDisclaimerText }}</p>
              </div>
            </ng-container>
          </ng-container>
        </ng-container>
        <button
          class="mt-4 link text-center w-100 fw-500 text-size-14 print-d-none"
          libDashboardTrackButtonClick
          (click)="openImproveEstimate()"
          *ngIf="!(lastAdjustment$ | async) && moduleSetting.showButtonLink"
        >
          {{ moduleSetting.buttonLinkText }}
        </button>
      </div>
    </ng-container>
  </div>
</ng-template>

<ng-template #loader>
  <div class="db-card-body">
    <ngx-skeleton-loader count="1" [theme]="{height: '90px', width: '100%'}"></ngx-skeleton-loader>
  </div>
</ng-template>

<ng-template #noHomeValue>
  <ng-container *ngIf="!(lastAdjustment$ | async)">
    <p class="text-size-20 fw-600">{{ moduleSetting.noAvmHeading }}</p>
    <p class="text-size-14 fw-500 text-color-light-2 mt-1">{{ moduleSetting.noAvmSubHeading }}</p>
  </ng-container>
</ng-template>

<ng-template #lineBreak>
  <div class="line-break" *ngIf="(dataProviders$ | async) && moduleSetting.showAvmTrendChart"></div>
</ng-template>
