export enum ButtonStyle {
  ROUNDED = "ROUNDED",
  SEMI_ROUNDED = "SEMI_ROUNDED",
  SQUARED = "SQUARED",
  FULLY_ROUNDED = "FULLY_ROUNDED"
}

export const SearchWidgetStyleLabel: Record<ButtonStyle, string> = {
  [ButtonStyle.ROUNDED]: "Rounded Corners (8px)",
  [ButtonStyle.SEMI_ROUNDED]: "Rounded Corners (4px)",
  [ButtonStyle.SQUARED]: "Squared Corners",
  [ButtonStyle.FULLY_ROUNDED]: "Pilled"
};

export interface AgencyThemeButtonSetting {
  style: ButtonStyle;
  textColor: string;
}

export interface AgencyThemeSetting {
  primaryColor: string;
  button: AgencyThemeButtonSetting;
}

export const WidgetStyleToClassMap: Record<ButtonStyle, string> = {
  [ButtonStyle.ROUNDED]: "rounded",
  [ButtonStyle.SEMI_ROUNDED]: "semi-rounded",
  [ButtonStyle.SQUARED]: "squared",
  [ButtonStyle.FULLY_ROUNDED]: "fully-rounded"
};
