<p class="text-size-12 fw-600 mb-2 text-color-secondary">UPDATE HOME DETAILS</p>
<form #form="ngForm">
  <div *ngFor="let field of homeInfoFields">
    <div class="field-c">
      <div class="flex-box-center">
        <img [src]="'/assets/images/home-details/light/'+ field.icon +'.svg'" alt="Bed" />
        <p>{{ field.name }}</p>
      </div>
      <div class="input-number-c">
        <input
          type="number"
          [name]="field.name"
          [value]="field.value"
          [required]="field.required"
          [(ngModel)]="field.value"
          [min]="field.min"
          [max]="field.max ?? null"
        />
      </div>
    </div>
  </div>
</form>
<ng-template #noValue>-</ng-template>
