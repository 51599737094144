import {Inject, Injectable, InjectionToken} from "@angular/core";
import {ActivatedRouteSnapshot, ActivationStart, Router} from "@angular/router";

export type IsPrivateGuard = (guards: any[]) => boolean;
export const IS_PRIVATE_GUARD = new InjectionToken<IsPrivateGuard>("IS_PRIVATE_GUARD");
@Injectable({providedIn: "root"})
export class ActivatedRouteTypeService {
  isPrivate: boolean;
  activationRoute: ActivationStart;

  constructor(private router: Router, @Inject(IS_PRIVATE_GUARD) private isPrivateGuard: IsPrivateGuard) {
    this.router.events.subscribe(event => {
      if (event instanceof ActivationStart) {
        this.activationRoute = event;
        if (this.activationRoute.snapshot.routeConfig?.canActivate) {
          let guards = this.activationRoute.snapshot.routeConfig?.canActivate;
          let routeSnapshot: ActivatedRouteSnapshot | null = this.activationRoute.snapshot;
          do {
            this.isPrivate = this.isPrivateGuard(guards);
            if (this.isPrivate) return;
            guards = routeSnapshot?.routeConfig?.canActivate ?? [];
            routeSnapshot = routeSnapshot?.parent;
          } while (routeSnapshot?.routeConfig);
        }
      }
    });
  }
}
