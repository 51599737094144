import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from "@angular/common/http";
import {Inject, Injectable, Optional} from "@angular/core";
import {Observable} from "rxjs";
import {AGENT_API_SERVICE_BASE_URL, FELLO_API_SERVICE_BASE_URL} from "../services";
import {some} from "lodash-es";

@Injectable()
export class AllowCookiesInterceptor implements HttpInterceptor {
  private baseUrls: string[] = [];
  constructor(
    @Optional() @Inject(AGENT_API_SERVICE_BASE_URL) agentApiBaseUrl: string,
    @Optional() @Inject(FELLO_API_SERVICE_BASE_URL) felloApiBaseUrl: string
  ) {
    if (agentApiBaseUrl) {
      this.baseUrls.push(agentApiBaseUrl);
    }
    if (felloApiBaseUrl) {
      this.baseUrls.push(felloApiBaseUrl);
    }
  }
  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const requestUrl = request.url;
    if (some(this.baseUrls, baseUrl => requestUrl.includes(baseUrl))) {
      request = request.clone({
        withCredentials: true
      });
    }

    return next.handle(request);
  }
}
