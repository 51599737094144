<div class="flex-box-center-between mb-3">
  <p class="text-size-22 fw-700 mr-2">
    {{ currentStep === AddAddressSteps.Contact_Details ? "One last step, let’s verify owner information." : "Add new address" }}
  </p>
  <button class="button-icon close ml-auto" mat-dialog-close>
    <app-icon class="text-size-28">close</app-icon>
  </button>
</div>

<mat-dialog-content class="flex-item-auto-sizing">
  <ng-container *ngIf="currentStep === AddAddressSteps.Address_Entry">
    <ng-container *ngTemplateOutlet="addressEntry"></ng-container>
  </ng-container>

  <ng-container *ngIf="currentStep === AddAddressSteps.Address_Confirmation">
    <ng-container *ngTemplateOutlet="confirmAddress"></ng-container>
  </ng-container>

  <ng-container *ngIf="currentStep === AddAddressSteps.Contact_Details">
    <ng-container *ngTemplateOutlet="contactDetails"></ng-container>
  </ng-container>
</mat-dialog-content>

<ng-template #addressEntry>
  <div class="overflow-auto flex-item-auto-sizing visible-scrollbar">
    <p class="text-size-16 fw-400 mb-2 mb-4">
      Your home dashboard includes an AI-based home value estimate and other valuable information to help you make informed decisions about
      your home.
    </p>
    <div class="form-field">
      <label class="mb-2 text-size-16 fw-600">Home Address</label>
      <lib-smarty-address-auto-complete
        [(ngModel)]="address"
        name="propertyAddress"
        id="propertyAddress"
        #propertyAddress="ngModel"
        placeholder="Search for address"
        maxlength="100"
        (ngModelChange)="addrComp = null; image = null"
        [disabled]="isLoading"
        [required]="true"
      ></lib-smarty-address-auto-complete>
    </div>

    <ngx-skeleton-loader count="1" *ngIf="this.isLoading" [theme]="{  height: '85px', width: '100%' , margin: '16px 0 0'}">
    </ngx-skeleton-loader>
  </div>
  <div class="actions">
    <button (click)="validateAddress()" class="ml-auto" [disabled]="address.length <= 0 || isLoading">Continue</button>
  </div>
</ng-template>
<ng-template #confirmAddress>
  <div class="overflow-auto flex-item-auto-sizing visible-scrollbar">
    <p class="text-size-16 fw-400 mb-2 mb-4">
      To show your home dashboard, we need to confirm your address. Your home dashboard includes an AI-based home value estimate and other
      valuable information to help you make informed decisions about your home.
    </p>
    <div class="address-c mt-3" *ngIf="addrComp && !isLoading">
      <ng-container>
        <div class="flex-box-center">
          <img [src]="image ?? '/assets/images/svg/no_property_image.svg'" alt="house-img" class="house-img" />
          <div>
            <p class="text-size-18 fw-500">{{ addrComp.doorNumber }} {{ addrComp.street }}</p>
            <p class="text-size-14 fw-400 mt-1">{{ addrComp.city }}, {{ addrComp.state }} {{ addrComp.zip }}</p>
          </div>
        </div>
        <a class="link flex-box-center c-pointer" (click)="currentStep = AddAddressSteps.Address_Entry">Edit</a>
      </ng-container>
    </div>
    <ngx-skeleton-loader count="1" *ngIf="this.isLoading" [theme]="{  height: '45px', width: '100%' }"></ngx-skeleton-loader>
  </div>
  <div class="actions">
    <button (click)="currentStep = AddAddressSteps.Contact_Details" class="ml-auto" [disabled]="address.length <= 0 || isLoading">
      Continue
    </button>
  </div>
</ng-template>

<ng-template #contactDetails>
  <div class="overflow-auto flex-item-auto-sizing visible-scrollbar">
    <p class="text-size-16 fw-400 mb-2 mb-4">
      By confirming your details, you'll gain access to your personalized home dashboard, which includes an AI-based home value estimate and
      other valuable information to help you make informed decisions about your home
    </p>

    <form #form="ngForm">
      <div class="flex-box-direction-column flex-box-lg-direction-row flex-box-gap-2">
        <div class="form-field mb-3">
          <label class="mb-1 d-block text-size-14"
            >First Name <span class="fw-400" *ngIf="!settings?.firstNameMandatory">(Optional)</span></label
          >
          <input
            type="text"
            placeholder="First Name"
            [(ngModel)]="homeValueFromDashboardRequest.firstName"
            [minlength]="2"
            [maxLength]="64"
            name="firstName"
            [required]="settings?.firstNameMandatory ?? true"
            #firstNameField="ngModel"
          />
          <div *ngIf="firstNameField.invalid && firstNameField.touched" class="error-field">
            <div *ngIf="firstNameField.errors!">Enter a valid first name.</div>
          </div>
        </div>
        <div class="form-field mb-3">
          <label class="mb-1 d-block text-size-14"
            >Last Name <span class="fw-400" *ngIf="!settings?.lastNameMandatory">(Optional)</span></label
          >
          <input
            type="text"
            placeholder="Last Name"
            [(ngModel)]="homeValueFromDashboardRequest.lastName"
            [maxLength]="64"
            [minlength]="1"
            [required]="settings?.lastNameMandatory ?? true"
            #lastNameInput="ngModel"
            name="lastName"
          />
          <div *ngIf="lastNameInput.invalid && lastNameInput.touched" class="error-field">
            <div *ngIf="lastNameInput.errors!">Enter a valid last name.</div>
          </div>
        </div>
      </div>
      <div class="flex-box-direction-column flex-box-lg-direction-row flex-box-gap-2 mb-3">
        <div class="form-field">
          <label class="mb-1 d-block text-size-14">Email</label>
          <input
            type="text"
            placeholder="Email"
            name="email"
            class="disabled-grey"
            disabled
            [value]="homeValueFromDashboardRequest.emailId"
            required
          />
        </div>

        <div class="form-field">
          <label class="mb-1 d-block text-size-14"> Phone <span class="fw-400" *ngIf="!settings?.phoneMandatory">(Optional)</span></label>
          <input
            type="tel"
            placeholder="Phone number"
            name="phone"
            libFormatPhone
            [required]="settings?.phoneMandatory ?? false"
            [pattern]="phoneNumberPattern"
            [(ngModel)]="homeValueFromDashboardRequest.phone"
            #phone="ngModel"
          />
          <div *ngIf="phone.invalid && phone.touched" class="error-field">
            <div *ngIf="phone.errors!">Enter a valid phone.</div>
          </div>
        </div>
      </div>
      <div class="flex-box-direction-row" *ngIf="settings?.includeSellingTimeline">
        <div class="form-field w-100 mb-4">
          <label>Are you considering selling your home? If yes, what is your potential timeframe? </label>
          <div class="flex-box-direction-column flex-box-gap-2">
            <div class="flex-box-center">
              <input
                type="radio"
                id="option0"
                class="mr-2 w-auto"
                name="home-buy"
                [value]="SaleTimeline.ASAP"
                [(ngModel)]="homeValueFromDashboardRequest.saleTimeline"
                required
                #saleTimeline="ngModel"
              />
              <label class="text-size-14 fw-500 m-0 whitespace-nowrap c-pointer" for="option0">
                {{ SaleTimeline.ASAP | enumToLabel:"FELLO_SALE_TIMELINE_SHORT" }}
              </label>
            </div>
            <div class="flex-box-center">
              <input
                type="radio"
                id="option1"
                class="mr-2 w-auto"
                name="home-buy"
                [value]="SaleTimeline.LT3M"
                [(ngModel)]="homeValueFromDashboardRequest.saleTimeline"
                required
                #saleTimeline="ngModel"
              />
              <label class="text-size-14 fw-500 m-0 whitespace-nowrap c-pointer" for="option1">
                {{ SaleTimeline.LT3M | enumToLabel:"FELLO_SALE_TIMELINE_SHORT" }}
              </label>
            </div>
            <div class="flex-box-center">
              <input
                type="radio"
                id="option2"
                class="mr-2 w-auto"
                name="home-buy"
                [value]="SaleTimeline.B3TO6M"
                [(ngModel)]="homeValueFromDashboardRequest.saleTimeline"
                required
                #saleTimeline="ngModel"
              />
              <label class="text-size-14 fw-500 m-0 whitespace-nowrap c-pointer" for="option2">
                {{ SaleTimeline.B3TO6M | enumToLabel:"FELLO_SALE_TIMELINE_SHORT" }}
              </label>
            </div>
            <div class="flex-box-center">
              <input
                type="radio"
                id="option3"
                class="mr-2 w-auto"
                name="home-buy"
                [value]="SaleTimeline.B6TO12M"
                [(ngModel)]="homeValueFromDashboardRequest.saleTimeline"
                required
                #saleTimeline="ngModel"
              />
              <label class="text-size-14 fw-500 m-0 whitespace-nowrap c-pointer" for="option3">
                {{ SaleTimeline.B6TO12M | enumToLabel:"FELLO_SALE_TIMELINE_SHORT" }}
              </label>
            </div>
            <div class="flex-box-center">
              <input
                type="radio"
                id="option4"
                class="mr-2 w-auto"
                name="home-buy"
                [value]="SaleTimeline.NA"
                [(ngModel)]="homeValueFromDashboardRequest.saleTimeline"
                required
                #saleTimeline="ngModel"
              />
              <label class="text-size-14 fw-500 m-0 whitespace-nowrap c-pointer" for="option4">
                {{ SaleTimeline.NA | enumToLabel:"FELLO_SALE_TIMELINE_SHORT" }}</label
              >
            </div>
          </div>
          <div *ngIf=" saleTimeline.control.touched && saleTimeline.errors?.required " class="error-field text-size-12 text-color-red mt-1">
            <div>Please select an option.</div>
          </div>
        </div>
      </div>
      <p class="text-size-12 text-color-light-2" *ngIf="settings$ | async; let settings">
        <ng-container *ngIf="settings.consentText">
          <span dynamicTooltipDirective class="link-underline" [innerHTML]="settings.consentText | safeHtml"></span>
        </ng-container>
        <ng-container *ngIf="!settings.consentText">
          By Proceeding, you agree to our
          <a target="_blank" [href]="settings.termsAndConditionsUrl ?? felloWebsites.TERMS_AND_CONDITIONS" class="text-underline"
            >Terms of Service</a
          >, and to our
          <a target="_blank" [href]="settings.privacyPolicyUrl ?? felloWebsites.PRIVACY_POLICY" class="text-underline">Privacy Policy</a>
          regarding the information relating to you. Msg/data rates may apply. This consent applies even if you are on a corporate, state or
          national Do Not Call list.
        </ng-container>
      </p>
    </form>
    <ngx-spinner [name]="spinnerName" [showSpinner]="isLoading" [fullScreen]="true"></ngx-spinner>
  </div>
  <div class="actions">
    <button class="button-primary" (click)="currentStep = AddAddressSteps.Address_Confirmation">Go Back</button>
    <button (click)="form.valid ? addAddress(): form.control.markAllAsTouched();scrollToError()" [disabled]=" isLoading" class="ml-auto">
      Continue
    </button>
  </div>
</ng-template>
