import {Component, Inject, OnInit} from "@angular/core";
import {MatDialogRef, MAT_DIALOG_DATA} from "@angular/material/dialog";

export interface successDialogData {
  title?: string;
  desc?: string;
  buttonText?: string;
  redirectLink?: string;
}

@Component({
  selector: "app-success-dialog",
  templateUrl: "./success-dialog.component.html",
  styleUrls: ["./success-dialog.component.scss"]
})
export class SuccessDialogComponent implements OnInit {
  successDialogData: successDialogData = {};
  constructor(@Inject(MAT_DIALOG_DATA) data: successDialogData, private dialogRef: MatDialogRef<boolean>) {
    this.successDialogData.title = data?.title ?? "Successful";
    this.successDialogData.desc = data?.desc ?? "";
    this.successDialogData.buttonText = data?.buttonText ?? "Go to Dashboard";
    this.successDialogData.redirectLink = data?.redirectLink ?? "";
  }

  ngOnInit(): void {}

  redirect(): void {
    if (this.successDialogData.redirectLink) {
      window.location.replace(this.successDialogData.redirectLink);
    } else {
      this.dialogRef.close();
    }
  }
}
