import {Component, Inject} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialogModule, MatDialogRef} from "@angular/material/dialog";
import {IconComponent} from "../../../fello-ui-utils";
import {NgClass, NgIf} from "@angular/common";
import {FormControl, ReactiveFormsModule} from "@angular/forms";
import {_MatCheckboxRequiredValidatorModule, MatCheckboxModule} from "@angular/material/checkbox";

export interface ConfirmationDialogData {
  title: string;
  description?: string;
  okButtonText?: string;
  infoBoxText?: string;
  cancelButtonText?: string;
  okButtonClasses?: string[];
  confirmationText?: string;
}

@Component({
  selector: "lib-confirmation-dialog",
  templateUrl: "./confirmation-dialog.component.html",
  styleUrls: ["./confirmation-dialog.component.scss"],
  imports: [MatDialogModule, IconComponent, NgClass, NgIf, MatCheckboxModule, ReactiveFormsModule, _MatCheckboxRequiredValidatorModule],
  standalone: true
})
export class ConfirmationDialogComponent {
  title: string;
  description: string;
  infoBoxText: string;
  okButtonText?: string;
  cancelButtonText?: string;
  okButtonClasses?: string[];
  confirmationText: string;
  confirmInput = new FormControl<boolean>(false);

  constructor(
    protected dialogRef: MatDialogRef<boolean>,
    @Inject(MAT_DIALOG_DATA)
    data: ConfirmationDialogData
  ) {
    this.title = data.title;
    this.description = data.description ?? "";
    this.infoBoxText = data.infoBoxText ?? "";
    this.okButtonText = data.okButtonText;
    this.cancelButtonText = data.cancelButtonText;
    this.okButtonClasses = data.okButtonClasses ?? [];
    this.confirmationText = data.confirmationText ?? "";
  }

  confirm(): void {
    this.confirmInput.markAsDirty();
    if (!this.confirmationText || (this.confirmationText && this.confirmInput.valid)) {
      this.dialogRef.close(true);
    }
  }

  cancel(): void {
    this.dialogRef.close(false);
  }
}
