<div class="flex-box-center-between">
  <h2 mat-dialog-title class="m-0 text-size-18 fw-700">{{ title }}</h2>
  <button class="button-icon button-primary-dark ml-4" mat-dialog-close *ngIf="!cancelButtonText">
    <app-icon fontSize="24">close</app-icon>
  </button>
</div>
<div class="box-c flex-box-align-start bg-blue-00 p-2 mb-3 mt-4" *ngIf="infoBoxText">
  <lib-icon class="text-size-16 mr-2 text-color-blue mw-16">info-filled</lib-icon>
  <p class="text-size-14 fw-500">{{infoBoxText}}</p>
</div>
<p class="mt-2 text-size-16 word-break" *ngIf="description" [innerHTML]="description"></p>

<ng-container *ngIf="confirmationText">
  <mat-checkbox required [formControl]="confirmInput" class="checkbox-custom mt-4">
    <p class="text-size-16 line-height-1-2 fw-500 word-break" [innerHTML]="confirmationText"></p>
  </mat-checkbox>
  <p *ngIf="!confirmInput.pristine && confirmInput.invalid" class="error-field mt-2 fw-400">Please select the checkbox before continuing</p>
</ng-container>

<div
  class="buttons-c flex-box-gap-3 mt-4"
  [ngClass]="{'dual' : (okButtonText && cancelButtonText)}"
  *ngIf="okButtonText || cancelButtonText"
>
  <button (click)="cancel()" class="button-white" *ngIf="cancelButtonText">{{ cancelButtonText }}</button>
  <button [ngClass]="okButtonClasses ?? []" (click)="confirm()" *ngIf="okButtonText">{{ okButtonText }}</button>
</div>
