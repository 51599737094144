<app-dashboard-header></app-dashboard-header>
<ng-container *ngIf="dashboardSettings; let settings">
  <lib-dashboard-easy-link *ngIf="settings.website" [website]="settings.website"></lib-dashboard-easy-link>
</ng-container>

<div class="container" #scrollingContainer>
  <!-- Left Panel -->
  <div class="panel left" #leftPanel>
    <app-user-info class="d-block"></app-user-info>
    <div class="cards-index">
      <ng-container *ngFor="let item of listItems">
        <a
          [ngClass]="{active: item.dashboardItem.active}"
          *ngIf="item.dashboardItem && item.dashboardItem?.isVisible"
          (click)="$event.preventDefault(); scrollToModule('center' + item.moduleSettings.name)"
        >
          {{ item.dashboardItem.titleText }}
        </a>
      </ng-container>
    </div>
  </div>

  <!-- Center Section  -->
  <div class="cards-wrapper">
    <lib-manage-address></lib-manage-address>
    <lib-dashboard-view-tracker></lib-dashboard-view-tracker>
    <lib-offer-availability></lib-offer-availability>
    <div [id]="'center' + module.settings.name" *ngFor="let module of modulesToDisplay">
      <ng-template
        libDashboardModuleHost
        [moduleSettings]="module.settings"
        [moduleType]="module.moduleType"
        [moduleId]="module.moduleId"
      ></ng-template>
    </div>
    <lib-own-another-home></lib-own-another-home>
    <div class="footer-desktop">
      <ng-container *ngTemplateOutlet="footerText"></ng-container>
    </div>
  </div>

  <!-- Right Panel -->
  <div class="panel right" #rightPanel>
    <div *ngIf="agentSignature$ | async; let agentSignature">
      <p class="text-size-20 fw-700 mb-3">Need help? Contact {{ agentSignature.type === AgentSignatureType.AGENT ? "me" : "Us" }}</p>
      <div class="db-card contact m-0">
        <lib-agent-signature [agentSignature]="agentSignature"></lib-agent-signature>
        <a class="button button-mid button-white button-primary mt-mobile-16 mt-3" target="_blank" (click)="openContactAgent()">Contact</a>
      </div>
    </div>
  </div>

  <div class="footer-mobile mt-3">
    <ng-container *ngTemplateOutlet="footerText"></ng-container>
  </div>
</div>

<ng-template #footerText>
  <ng-container *ngIf="isHomeValueModuleDisplayed && (homeValueEstimate$ | async)?.median">
    <p class="text-size-12 text-color-light-2 fw-400 mb-3 text-center" id="estimateTerms">
      *The estimate is based on information from independent valuation providers whose solutions are used by financial institutions and real
      estate agents. The estimate is not a formal appraisal or substitute for the in-person expertise of a real estate agent or professional
      appraiser. This should be used a starting point for valuation discussions with real estate agent.
    </p>
  </ng-container>
  <div class="eho-c mt-4 mb-3 text-center" *ngIf="dashboardSettings; let settings">
    <p class="text-color-light fw-400">
      ©
      <span *ngIf="settings.name">{{ settings.name }}</span>
      <a target="_blank" [href]="settings.privacyPolicyUrl ?? felloWebsites.PRIVACY_POLICY" class="text-color-light fw-400 d-inline">
        | Privacy Policy</a
      >
      <a
        target="_blank"
        [href]="settings.termsAndConditionsUrl ?? felloWebsites.TERMS_AND_CONDITIONS"
        rel="nofollow"
        class="text-color-light fw-400 d-inline"
      >
        | Terms of Service</a
      >
    </p>
    <p class="text-color-light fw-400 whitespace-pre-wrap" [innerHTML]="settings.footerText"></p>
    <img src="https://www.hifello.com/hubfs/equal-housing-opportunity.png" alt="equal-housing-opportunity" class="img-eho" />
  </div>
</ng-template>
