export enum SaleTimeline {
  ASAP = "ASAP",
  LT3M = "LT3M",
  B3TO6M = "B3TO6M",
  B6TO12M = "B6TO12M",
  NA = "NA"
}

export const SaleTimelineLabel: Record<SaleTimeline, string> = {
  [SaleTimeline.ASAP]: "As soon as possible",
  [SaleTimeline.LT3M]: "Less than 3 months",
  [SaleTimeline.B3TO6M]: "3 - 6 months",
  [SaleTimeline.B6TO12M]: "6 - 12 months",
  [SaleTimeline.NA]: "I don’t know"
};

export const SaleTimelineShortLabel: Record<SaleTimeline, string> = {
  [SaleTimeline.ASAP]: "ASAP",
  [SaleTimeline.LT3M]: "0 - 3 mos",
  [SaleTimeline.B3TO6M]: "3 - 6 mos",
  [SaleTimeline.B6TO12M]: "6 - 12 mos",
  [SaleTimeline.NA]: "Not sure yet"
};
