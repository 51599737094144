import {ChangeDetectorRef, OnDestroy, Pipe, PipeTransform} from "@angular/core";
import {AsyncPipe} from "@angular/common";
import {Observable} from "rxjs";
import {map} from "rxjs/operators";
import {DomSanitizer, SafeResourceUrl} from "@angular/platform-browser";
import {MediaService} from "../../../lib/services/media/media.service";
import {forEach} from "lodash-es";

@Pipe({
  name: "toMediaAccessUrl",
  pure: false,
  standalone: true
})
export class ToMediaAccessUrlPipe implements PipeTransform, OnDestroy {
  // eslint-disable-next-line @typescript-eslint/naming-convention,no-underscore-dangle,id-blacklist,id-match
  private _mediaId: string | null;
  // eslint-disable-next-line @typescript-eslint/naming-convention, no-underscore-dangle, id-blacklist, id-match
  private _resolvedMediaUrl: Observable<SafeResourceUrl> | null;
  private asyncPipe: AsyncPipe;

  constructor(protected mediaService: MediaService, protected cdf: ChangeDetectorRef, protected sanitizer: DomSanitizer) {
    this.asyncPipe = new AsyncPipe(this.cdf);
  }

  transform(mediaId: string | null, extraOptions?: any): SafeResourceUrl | null {
    if (mediaId !== this._mediaId) {
      this._mediaId = mediaId;
      this._resolvedMediaUrl = mediaId
        ? this.mediaService.getMediaAccessURL(mediaId).pipe(
            map(url => {
              const newUrl = new URL(url);
              const options = {...extraOptions};
              if (!options?.skipCompress) {
                newUrl.searchParams.set("auto", "compress");
              }
              if (options) {
                if ("skipCompress" in options) {
                  delete options.skipCompress;
                }
                forEach(options, (value, key) => {
                  newUrl.searchParams.set(key, value);
                });
              }
              url = newUrl.toString();
              return this.sanitizer.bypassSecurityTrustResourceUrl(url);
            })
          )
        : null;
    }
    return this.asyncPipe.transform(this._resolvedMediaUrl);
  }

  ngOnDestroy(): void {
    this.asyncPipe.ngOnDestroy();
  }
}
