import {Pipe, PipeTransform} from "@angular/core";

@Pipe({
  name: "numberToLetter",
  standalone: true
})
export class NumberToLetterPipe implements PipeTransform {
  transform(value: number): string {
    return String.fromCharCode(value + 65);
  }
}
