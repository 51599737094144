import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {KanbanBoardComponent} from "./components/kanban-board/kanban-board.component";
import {KanbanColumnComponent} from "./components/kanban-column/kanban-column.component";
import {
  KanbanColumnCardDefDirective,
  KanbanColumnDefDirective,
  KanbanColumnFooterDefDirective,
  KanbanColumnHeaderDefDirective
} from "./directives/kanban-column-def.directive";
import {DragDropModule} from "@angular/cdk/drag-drop";
import {MatButtonModule} from "@angular/material/button";
import {FelloUiUtilsModule} from "../fello-ui-utils";
import {FelloUiLibModule} from "../fello-ui-lib";

const declarations = [
  KanbanBoardComponent,
  KanbanColumnComponent,
  KanbanColumnDefDirective,
  KanbanColumnHeaderDefDirective,
  KanbanColumnFooterDefDirective,
  KanbanColumnCardDefDirective
];

@NgModule({
  declarations,
  imports: [CommonModule, DragDropModule, MatButtonModule, FelloUiUtilsModule, FelloUiLibModule],
  exports: declarations
})
export class KanbanModule {}
