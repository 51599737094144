import {Component, Inject, ViewChild} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialogModule, MatDialogRef} from "@angular/material/dialog";
import {CropperPosition, ImageCropperComponent, ImageCropperModule, ImageTransform} from "ngx-image-cropper";
import {IconComponent} from "../../../fello-ui-utils";

export class ImageCropperInput {
  file: File;
  roundCropper?: boolean;
  maintainAspectRatio?: boolean;
}
export class ImageCropperOutput {
  croppedFile?: File;
}

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: "app-image-cropper-modal",
  templateUrl: `image-cropper-modal.component.html`,
  styleUrls: [`image-cropper-modal.component.scss`],
  imports: [IconComponent, MatDialogModule, ImageCropperModule],
  standalone: true
})
export class ImageCropperModalComponent {
  canvasRotation = 0;
  rotation = 0;
  scale = 1;
  transform: ImageTransform = {};
  scaleStep = 0.1;
  cropperPosition: CropperPosition = {
    x1: 0,
    x2: 0,
    y1: 0,
    y2: 0
  };

  originalFile: File;
  roundCropper: boolean;
  maintainAspectRatio: boolean;

  @ViewChild(ImageCropperComponent) imageCropper: ImageCropperComponent;

  constructor(public dialogRef: MatDialogRef<ImageCropperModalComponent>, @Inject(MAT_DIALOG_DATA) public data: ImageCropperInput) {
    this.originalFile = data.file;
    this.roundCropper = data.roundCropper ?? false;
    this.maintainAspectRatio = (data.maintainAspectRatio || data.roundCropper) ?? false;
  }

  submit() {
    const event = this.imageCropper.crop();
    const croppedBlob = this.dataURLtoBlob(event?.base64 ?? "");
    const originalFileName = this.originalFile.name;

    const croppedFile = new File([croppedBlob], originalFileName, {
      type: this.originalFile.type
    });
    const output: ImageCropperOutput = {
      croppedFile
    };
    this.dialogRef.close(output);
  }

  dataURLtoBlob(dataURL: string) {
    const parts = dataURL.split(",");
    const contentType = parts[0].split(":")[1].split(";")[0];
    const raw = window.atob(parts[1]);
    const rawLength = raw.length;
    const uInt8Array = new Uint8Array(rawLength);
    for (let i = 0; i < rawLength; ++i) {
      uInt8Array[i] = raw.charCodeAt(i);
    }
    return new Blob([uInt8Array], {type: contentType});
  }

  rotateLeft() {
    this.canvasRotation--;
    this.flipAfterRotate();
  }

  rotateRight() {
    this.canvasRotation++;
    this.flipAfterRotate();
  }

  private flipAfterRotate() {
    const flippedH = this.transform.flipH;
    const flippedV = this.transform.flipV;
    this.transform = {
      ...this.transform,
      flipH: flippedV,
      flipV: flippedH
    };
  }

  flipHorizontal() {
    this.transform = {
      ...this.transform,
      flipH: !this.transform.flipH
    };
  }

  flipVertical() {
    this.transform = {
      ...this.transform,
      flipV: !this.transform.flipV
    };
  }

  resetImage() {
    this.scale = 1;
    this.rotation = 0;
    this.canvasRotation = 0;
    this.transform = {};
  }

  updateZoom(updatedScale: number) {
    this.scale = updatedScale;
    this.transform = {
      ...this.transform,
      scale: updatedScale
    };
  }

  cropperReady(): void {
    this.setCropperPosition();
  }

  private setCropperPosition(): void {
    const event = this.imageCropper.crop();
    if (!this.roundCropper && event) {
      this.cropperPosition = {
        ...event.cropperPosition,
        x1: event.cropperPosition.x2 * 0.1,
        x2: event.cropperPosition.x2 - event.cropperPosition.x2 * 0.1
      };
    }
  }
}
