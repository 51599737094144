import {DataProviderType} from "../../property";
import {AvmCondifence} from "../../deal";

export interface FelloMultiHvEstimate {
  isPrimary?: boolean;
  provider?: DataProviderType.ATTOM_BULK | DataProviderType.CL_BULK | DataProviderType.QUANTARIUM;
  low?: number;
  high?: number;
  confidence?: AvmCondifence;
  median?: number;
  valueDate?: Date;
  _m?: string; // meta?
}

export enum FelloMultiAvmResponseAdjustedAvmType {
  AGENT = "AGENT",
  ACCOUNT = "ACCOUNT"
}

export interface FelloMultiAvmResponseAdjustedAvm {
  type: FelloMultiAvmResponseAdjustedAvmType;
  agentAdjustedDate?: Date;
  agentAdjustedOwnerId?: string;
  avm: FelloMultiHvEstimate;
}

export interface FelloMultiAvmResponse {
  allAvms: FelloMultiHvEstimate[];
  adjustment?: FelloMultiAvmResponseAdjustedAvm;
}
