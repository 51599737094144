import {FelloEnv} from "fello-model";
import {Environment} from "./environment-type";

export const environment: Environment = {
  production: true,
  env: FelloEnv.PROD,
  felloApiBaseUrl: "https://api.hifello.com/api/v2",
  paymentApiBaseUrl: "https://api.hifello.com/api/v2",
  agentServiceBaseURL: "https://api.hifello.com/api/v2",
  hiFelloBaseUrl: "https://www.hifello.com",
  felloConnectBaseUrl: "https://connect.hifello.com/",
  googleClientId: "213610769932-s1g4a69abctor9o7ngvtk24gqli4j6fa.apps.googleusercontent.com",
  appleAuthClientId: "com.hifello.login",
  hubspotTrackingScriptSrc: "https://js.hs-scripts.com/21635735.js",
  mixPanelApiKey: "a74da96f5f30adfd4488b6d5833c46f1",
  googleStreetViewApiKey: "AIzaSyCn2MqSX57zjt_txnWwqGlp51FgP94t_Ts",
  mediaServiceBaseURL: "https://api.hifello.com/api/v2",
  googleMapsApiKey: "AIzaSyBfEwYR5QelpCR6y03Zk7o0decOcyV2mAY",
  enableSubdomainFixing: true,
  allowedSubdomains: ["consumer", "cashoffer"],
  smartyStreetsBaseURL: "https://us-autocomplete-pro.api.smarty.com/lookup?key=10196474929056213"
};
