import {Pipe, PipeTransform} from "@angular/core";
import {FormatNumberShorthandUtil, NumberShorthandMode} from "../../../lib";

@Pipe({
  name: "numberShorthand",
  standalone: true
})
export class NumberShorthandPipe implements PipeTransform {
  transform(value: number, mode = NumberShorthandMode.MONETARY, prefix = ""): string {
    return FormatNumberShorthandUtil.transform(value, prefix, mode);
  }
}
