import {ChangeDetectorRef, Pipe, PipeTransform} from "@angular/core";
import {DomSanitizer, SafeResourceUrl} from "@angular/platform-browser";
import {ToMediaAccessUrlPipe} from "./to-media-access-url.pipe";
import {MediaType, MediaWithType} from "fello-model";
import {MediaService} from "../../../lib";

@Pipe({
  name: "mediaWithType",
  pure: false,
  standalone: true
})
export class MediaWithTypePipe implements PipeTransform {
  private toMediaAccessUrlPipe: ToMediaAccessUrlPipe;
  constructor(protected media: MediaService, protected cdf: ChangeDetectorRef, protected sanitizer: DomSanitizer) {
    this.toMediaAccessUrlPipe = new ToMediaAccessUrlPipe(media, cdf, sanitizer);
  }

  transform(media?: MediaWithType, extraOptions?: any & {fallback?: string}): SafeResourceUrl | null {
    let response: SafeResourceUrl | null = null;
    if (media?.type === MediaType.MEDIA_ID) {
      response = this.toMediaAccessUrlPipe.transform(media.value, extraOptions);
    } else if (media?.type === MediaType.LINK) {
      response = media.value;
    }
    if (!response) {
      response = extraOptions?.fallback ?? null;
    }
    return response;
  }
}
