export enum DurationUnitBase {
  year = "year",
  years = "years",
  y = "y",
  month = "month",
  months = "months",
  M = "M",
  week = "week",
  weeks = "weeks",
  w = "w",
  day = "day",
  days = "days",
  d = "d",
  hour = "hour",
  hours = "hours",
  h = "h",
  minute = "minute",
  minutes = "minutes",
  m = "m",
  second = "second",
  seconds = "seconds",
  s = "s",
  millisecond = "millisecond",
  milliseconds = "milliseconds",
  ms = "ms"
}
export const DurationUnitBaseLabel: Record<DurationUnitBase, string> = {
  [DurationUnitBase.year]: "year",
  [DurationUnitBase.years]: "years",
  [DurationUnitBase.y]: "years",
  [DurationUnitBase.month]: "month",
  [DurationUnitBase.months]: "months",
  [DurationUnitBase.M]: "months",
  [DurationUnitBase.week]: "week",
  [DurationUnitBase.weeks]: "weeks",
  [DurationUnitBase.w]: "weeks",
  [DurationUnitBase.day]: "day",
  [DurationUnitBase.days]: "days",
  [DurationUnitBase.d]: "days",
  [DurationUnitBase.hour]: "hour",
  [DurationUnitBase.hours]: "hours",
  [DurationUnitBase.h]: "hours",
  [DurationUnitBase.minute]: "minute",
  [DurationUnitBase.minutes]: "minutes",
  [DurationUnitBase.m]: "minutes",
  [DurationUnitBase.second]: "second",
  [DurationUnitBase.seconds]: "seconds",
  [DurationUnitBase.s]: "seconds",
  [DurationUnitBase.millisecond]: "millisecond",
  [DurationUnitBase.milliseconds]: "milliseconds",
  [DurationUnitBase.ms]: "milliseconds"
};

export interface TimeDuration {
  duration: number;
  unit: DurationUnitBase;
}
