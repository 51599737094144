import {Directive, Renderer2} from "@angular/core";
import {MatSelect} from "@angular/material/select";

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: "mat-select[multiple]",
  standalone: true
})
export class MatSelectResetDirective {
  constructor(private matSelect: MatSelect, private renderer: Renderer2) {
    this.matSelect.openedChange.subscribe(opened => {
      if (opened && matSelect.multiple) {
        this.addResetButton();
      }
    });
  }

  addResetButton() {
    this.matSelect.panelClass = ["mat-select-reset"];
    const panel = this.matSelect.panel.nativeElement;
    const resetButton = this.renderer.createElement("button");

    this.renderer.addClass(resetButton, "mat-select-reset-bttn");
    this.renderer.setProperty(resetButton, "innerText", "Reset All");
    this.renderer.listen(resetButton, "click", () => {
      this.matSelect.options.forEach(option => {
        if (option.selected) {
          option.deselect();
        }
      });
      this.matSelect.close();
    });

    this.renderer.appendChild(panel, resetButton);
  }
}
