import {Pipe, PipeTransform} from "@angular/core";
import {StringUtils} from "../../../lib";
import {first, last} from "lodash-es";

@Pipe({
  name: "shortName",
  standalone: true
})
export class ShortNamePipe implements PipeTransform {
  transform(name: string): string {
    return StringUtils.getInitials(name);
  }
}
