export enum FelloDashboardOrigin {
  WEB = "WEB",
  FC = "FC",
  T = "T",
  ENGAGE = "EN",
  WIDGET = "WGT",
  LP = "LP",
  PURL = "PURL",
  REFERRAL = "REFERRAL"
}
export const FelloDashboardOriginLabel: Record<FelloDashboardOrigin, string> = {
  [FelloDashboardOrigin.WEB]: "Property Dashboard",
  [FelloDashboardOrigin.FC]: "Connect",
  [FelloDashboardOrigin.T]: "Turbo",
  [FelloDashboardOrigin.ENGAGE]: "Engage",
  [FelloDashboardOrigin.WIDGET]: "Widget",
  [FelloDashboardOrigin.REFERRAL]: "Referral",
  [FelloDashboardOrigin.LP]: "Landing Page",
  [FelloDashboardOrigin.PURL]: "PURL"
};
