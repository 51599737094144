import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Inject,
  InjectionToken,
  OnDestroy,
  OnInit,
  Optional,
  Output,
  TemplateRef,
  ViewChild
} from "@angular/core";
import {BaseExprComponent} from "../BaseExprComponent";
import {AbstractControl, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validator} from "@angular/forms";
import {
  DurationUnitBase,
  FieldFilterConfig,
  FilterFieldDataType,
  FQLFieldExpr,
  FQLFieldOperator,
  FQLFieldOpr,
  RelativeDateOpOption,
  RelativeDateOpOptionLabel,
  SupportedDurationUnitInFQL
} from "fello-model";
import {AbstractFilterConfigService} from "../../services/AbstractFilterConfigService";
import {BehaviorSubject, Observable, of} from "rxjs";
import {map, switchMap, takeUntil, tap} from "rxjs/operators";
import {FieldExprContainerService} from "../../services/field-expr-container.service";
import {fqlFieldExprValidator} from "../../validators";
import {Moment} from "moment";
import {DropdownOption} from "../../../fello-ui-lib";
import {BaseDynamicList} from "../../services/fql-base-dynamic-list-factory.service";
import {AbstractFqlDynamicListFactoryService} from "../../services/abstract-fql-dynamic-list-factory.service";

const UnsupportedOpsForShowIncludeEmptyOption = new Set<FQLFieldOperator>([FQLFieldOperator.NOT_PRESENT, FQLFieldOperator.PRESENT]);

export const MAX_MULTI_TEXT_SEARCH_LIMIT = new InjectionToken<number>("MAX_MULTI_TEXT_SEARCH_LIMIT", {factory: () => 50});

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: "app-fql-field-expr",
  templateUrl: "./fql-field-expr.component.html",
  styleUrls: ["./fql-field-expr.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FqlFieldExprComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => FqlFieldExprComponent),
      multi: true
    }
  ]
})
export class FqlFieldExprComponent extends BaseExprComponent implements OnInit, AfterViewInit, OnDestroy, Validator {
  isEditMode$ = new BehaviorSubject(true);
  includeEmptyValues: boolean;
  unsupportedOpsForShowIncludeEmptyOption = UnsupportedOpsForShowIncludeEmptyOption;

  get isEditMode(): boolean {
    return this.isEditMode$.value;
  }

  set isEditMode(value: boolean) {
    this.isEditMode$.next(value);
    if (value) {
      this.scrollIntoView();
    }
  }

  selectedFilterConfig$ = new BehaviorSubject<FieldFilterConfig | undefined>(undefined);
  selectedOp$ = new BehaviorSubject<FQLFieldOperator | undefined>(undefined);
  op?: FQLFieldOperator;
  dropdownOptions: DropdownOption[] = [];
  selectedConfigDropdownOptions: DropdownOption[] = [];
  selectedConfigDynamicDropdownOptions$: Observable<DropdownOption[]>;

  lastAppliedExpr?: FQLFieldExpr;
  filterConfigCompareWith = (a?: FieldFilterConfig, b?: FieldFilterConfig) => a?.name === b?.name;
  selectedDynamicList: BaseDynamicList;
  relativeDateOpOptions: DropdownOption[] = Object.values(RelativeDateOpOption).map(option => {
    return {
      value: option,
      label: RelativeDateOpOptionLabel[option]
    };
  });
  SupportedDurationUnitInFQL = Object.values(SupportedDurationUnitInFQL);

  @Output() remove = new EventEmitter<void>();

  /**************** opr related stuff **************/
  opr$ = new BehaviorSubject<FQLFieldOpr[]>([]);
  multipleSelectOpr$ = this.opr$.pipe(
    map(opr => {
      if (this.selectedFilterConfig$.value?.allowNull) {
        return opr.map(opr => opr ?? "");
      }
      return opr;
    })
  );

  oprTemplate: TemplateRef<unknown> | null;
  @ViewChild("multiselectOprDropdown") multiSelectOprDropdown: TemplateRef<unknown>;
  @ViewChild("singleSelectOprDropdown") singleSelectOprDropdown: TemplateRef<unknown>;

  @ViewChild("multiSelectOprDynamicDropdown") multiSelectOprDynamicDropdown: TemplateRef<unknown>;
  @ViewChild("singleSelectOprDynamicDropdown") singleSelectOprDynamicDropdown: TemplateRef<unknown>;

  @ViewChild("betweenDateOpr") betweenDateOpr: TemplateRef<unknown>;
  @ViewChild("betweenNumberOpr") betweenNumberOpr: TemplateRef<unknown>;

  @ViewChild("dateSelector") dateSelector: TemplateRef<unknown>;
  @ViewChild("numberField") numberField: TemplateRef<unknown>;
  @ViewChild("textField") textField: TemplateRef<unknown>;

  @ViewChild("isRelativeDateOprDropdown") isRelativeDateOprDropdown: TemplateRef<unknown>;
  @ViewChild("isRelativeDateGTLTOpr") isRelativeDateGTLTOpr: TemplateRef<unknown>;

  constructor(
    private filterConfigService: AbstractFilterConfigService,
    private container: FieldExprContainerService,
    private el: ElementRef,
    private cdr: ChangeDetectorRef,
    private dynamicListFactoryService: AbstractFqlDynamicListFactoryService,
    @Optional() @Inject(MAX_MULTI_TEXT_SEARCH_LIMIT) readonly maxMultiTextSearchLimit: number
  ) {
    super();
  }

  setDisabledState(isDisabled: boolean) {
    super.setDisabledState(isDisabled);
    if (this.isDisabled && this.isEditMode) {
      this.cancelEditing();
    }
  }

  ngOnInit(): void {
    this.container.registerExpr(this);
    this.filterConfigService.filterConfig$
      .pipe(
        takeUntil(this.isDestroyed),
        tap(filterConfig => {
          filterConfig.forEach(config => {
            this.dropdownOptions.push({
              value: config,
              label: config.label
            });
          });
        })
      )
      .subscribe();
    this.selectedFilterConfig$
      .pipe(
        takeUntil(this.isDestroyed),
        tap(selectedConfig => {
          this.selectedOp$.next(undefined);
          this.scrollIntoView();
          if (selectedConfig?.dynamicListType) {
            this.selectedDynamicList = this.dynamicListFactoryService.getDynamicList(selectedConfig.dynamicListType);
            this.selectedConfigDynamicDropdownOptions$ = this.selectedDynamicList!.allowedValues$.pipe(
              takeUntil(this.isDestroyed),
              map(allowedValues => {
                return allowedValues.map(option => {
                  return {
                    value: this.selectedFilterConfig$.value?.allowNull ? option.value ?? "" : option.value,
                    label: option.label
                  } as DropdownOption;
                });
              })
            );
          }
          if (selectedConfig?.allowedValues) {
            this.selectedConfigDropdownOptions = [];
            selectedConfig.allowedValues.forEach(item => {
              this.selectedConfigDropdownOptions.push({
                value: this.selectedFilterConfig$.value?.allowNull ? item.value ?? "" : item.value,
                label: item.label
              });
            });
          }
        })
      )
      .subscribe();
    this.selectedFilterConfig$
      .pipe(
        takeUntil(this.isDestroyed),
        switchMap(selectedConfig => {
          if (!selectedConfig) {
            return of(null);
          }
          return this.selectedOp$.pipe(
            tap(selectedOp => {
              if (!selectedOp) {
                return;
              }
              if (selectedOp === FQLFieldOperator.IS_RELATIVE_DATE_GT || selectedOp === FQLFieldOperator.IS_RELATIVE_DATE_LT) {
                this.opr$.next([undefined, SupportedDurationUnitInFQL[DurationUnitBase.days]]);
              } else {
                this.opr$.next([]);
              }
              this.includeEmptyValues = false; // reset checkbox on operator update
              this.scrollIntoView();
            })
          );
        })
      )
      .subscribe();
  }

  ngAfterViewInit() {
    this.selectedFilterConfig$
      .pipe(
        takeUntil(this.isDestroyed),
        switchMap(selectedConfig => {
          if (!selectedConfig) {
            return of(null);
          }
          return this.selectedOp$.pipe(
            tap(selectedOp => {
              if (!selectedOp) {
                return;
              }
              this.oprTemplate = this.getOprTemplateFor(selectedConfig, selectedOp);
              this.cdr.detectChanges();
            })
          );
        })
      )
      .subscribe();
  }

  private getOprTemplateFor(selectedConfig: FieldFilterConfig, selectedOp: FQLFieldOperator): TemplateRef<unknown> | null {
    if ([FQLFieldOperator.PRESENT, FQLFieldOperator.NOT_PRESENT].includes(selectedOp)) {
      return null;
    }
    if (selectedConfig.allowedValues) {
      if ([FQLFieldOperator.IN, FQLFieldOperator.NOT_IN].includes(selectedOp)) {
        return this.multiSelectOprDropdown;
      }
      return this.singleSelectOprDropdown;
    }
    if (selectedConfig.dynamicListType) {
      if ([FQLFieldOperator.IN, FQLFieldOperator.NOT_IN].includes(selectedOp)) {
        return this.multiSelectOprDynamicDropdown;
      }
      return this.singleSelectOprDynamicDropdown;
    }

    if (selectedOp === FQLFieldOperator.BETWEEN) {
      if (selectedConfig.fieldDataType === FilterFieldDataType.DATE) {
        return this.betweenDateOpr;
      }
      if (selectedConfig.fieldDataType === FilterFieldDataType.NUMBER) {
        return this.betweenNumberOpr;
      }
      throw new Error(`Invalid op: "${FQLFieldOperator.BETWEEN}" for data type "${selectedConfig.fieldDataType}"`);
    }
    if (selectedOp === FQLFieldOperator.IS_RELATIVE_DATE) {
      return this.isRelativeDateOprDropdown;
    }
    if (selectedOp === FQLFieldOperator.IS_RELATIVE_DATE_GT || selectedOp === FQLFieldOperator.IS_RELATIVE_DATE_LT) {
      return this.isRelativeDateGTLTOpr;
    }
    if (selectedConfig.fieldDataType === FilterFieldDataType.DATE) {
      return this.dateSelector;
    }
    if (selectedConfig.fieldDataType === FilterFieldDataType.NUMBER) {
      return this.numberField;
    }
    if (selectedConfig.fieldDataType === FilterFieldDataType.STRING) {
      return this.textField;
    }
    throw new Error(`Invalid op: "${selectedOp}" for data type "${selectedConfig.fieldDataType}" for field "${selectedConfig.name}"`);
  }

  writeValue(obj: FQLFieldExpr): void {
    this.applyExpr(obj);
  }

  cancelEditing(): void {
    if (!this.lastAppliedExpr) {
      this.remove.emit();
    } else if (this.isEditMode) {
      this.applyExpr(this.lastAppliedExpr);
      this.isEditMode = false;
    }
  }

  applyExpr(expr?: FQLFieldExpr): void {
    const selectedConfig = this.dropdownOptions.find(config => config.value.name === expr?.fieldName)?.value;
    this.selectedFilterConfig$.next(selectedConfig);
    if (!expr || !selectedConfig) {
      this.isEditMode = !this.isDisabled;
      this.includeEmptyValues = false;
      this.cdr.detectChanges();
      return;
    }
    this.selectedOp$.next(expr.op);
    this.includeEmptyValues = !!expr.includeEmptyValues;
    this.handleMultipleSelectDropdownModelChange(expr.opr);
    this.lastAppliedExpr = expr;
    this.isEditMode = false;
    this.cdr.detectChanges();
  }

  applyChanges(): void {
    this._onTouchedFn();
    const expr = {
      fieldName: this.selectedFilterConfig$.value!.name,
      includeEmptyValues: this.includeEmptyValues,
      op: this.selectedOp$.value!,
      opr: this.opr$.value
    } as FQLFieldExpr;
    this._onChangeFn(expr);
    this.isEditMode = false;
    this.lastAppliedExpr = expr;
  }

  getAllowedValuesLabel(filterConfig: FieldFilterConfig, opr: FQLFieldOpr[]): string {
    if (!filterConfig.allowedValues?.length) {
      return "";
    }
    return filterConfig.allowedValues
      .filter(a => opr.includes(a.value))
      .map(a => a.label)
      .join(", ");
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.container.deregisterExpr(this);
  }

  scrollIntoView() {
    setTimeout(() => {
      if (this.isDisabled) {
        return;
      }
      this.el.nativeElement.scrollIntoView({behavior: "smooth", block: "start", inline: "end"});
    }, 100);
  }

  validate(control: AbstractControl): ValidationErrors | null {
    return fqlFieldExprValidator(this.selectedFilterConfig$.value!)(control);
  }

  endOfDay(m: Moment): Moment {
    return m.endOf("day");
  }

  handleMultipleSelectDropdownModelChange(value: FQLFieldOpr[]): void {
    if (
      (this.selectedFilterConfig$.value?.dynamicListType || this.selectedFilterConfig$.value?.allowedValues?.length) &&
      this.selectedFilterConfig$.value?.allowNull
    ) {
      value = value.map(val => (val === "" ? null : val));
    }
    this.opr$.next(value);
  }
}
