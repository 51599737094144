import {Component, HostBinding, Input} from "@angular/core";
import {NgClass, NgIf} from "@angular/common";

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: "button[lib-loading-button]",
  standalone: true,
  templateUrl: "./loading-button.component.html",
  imports: [NgClass, NgIf],
  styleUrls: ["./loading-button.component.scss"]
})
export class LoadingButtonComponent {
  @HostBinding("class.loading")
  @Input()
  isLoading: boolean;
  @Input() disabled: boolean;

  @HostBinding("attr.disabled")
  get isDisabled(): boolean | null {
    return this.isLoading || this.disabled ? true : null;
  }
}
