import {FelloVisitorVariables} from "../../dashboard";
import {AddressComponent} from "../../address";
import {AgentDealOffer} from "./offer";
import {ChangelogEvent} from "../../deal";

export enum AgentDealOrigin {
  FC = "FC",
  ENGAGE = "ENGAGE",
  WIDGET = "WGT",
  REFERRAL = "REFERRAL"
}

export const AgentDealOriginLabel: Record<AgentDealOrigin, string> = {
  [AgentDealOrigin.FC]: "Connect",
  [AgentDealOrigin.ENGAGE]: "Engage",
  [AgentDealOrigin.REFERRAL]: "Referral",
  [AgentDealOrigin.WIDGET]: "Cash Offer Widget"
};

export interface AgentDealContactInfo {
  contactId: string;
  name?: string;
  phone?: string;
  email: string;
}

export interface AgentDealDashboard {
  dashboardId: string;
  variables: FelloVisitorVariables;
  journeyCompleted?: boolean;
}

export enum AgentDealStage {
  NEW = "NEW",
  ACTIVE = "ACTIVE",
  WON = "WON",
  LOST = "LOST"
}

export const AgentDealStageLabel: Record<AgentDealStage, string> = {
  [AgentDealStage.NEW]: "New",
  [AgentDealStage.ACTIVE]: "Active",
  [AgentDealStage.WON]: "Won",
  [AgentDealStage.LOST]: "Lost"
};

export const AgentDealStageSequence: Record<AgentDealStage, number> = {
  [AgentDealStage.NEW]: 1,
  [AgentDealStage.ACTIVE]: 2,
  [AgentDealStage.WON]: 3,
  [AgentDealStage.LOST]: 4
};

export interface AgentDeal {
  _id: string;
  agentId: string;
  agencyId: string;
  contact: AgentDealContactInfo;
  origin: AgentDealOrigin;
  propertyId: string;
  addressComponent: AddressComponent;
  rawAddress: string;
  stage: AgentDealStage;
  offer?: AgentDealOffer;
  createdAt: Date;
  updatedAt: Date;
}
