import {Component, Inject, OnInit} from "@angular/core";
import {MAT_DIALOG_DATA} from "@angular/material/dialog";
import {AgentSignature, AgentSignatureType} from "fello-model";

@Component({
  selector: 'lib-contact-dialog',
  templateUrl: './contact-dialog.component.html',
  styleUrls: ['./contact-dialog.component.scss']
})
export class ContactDialogComponent implements OnInit {
  AgentSignatureType = AgentSignatureType;

  constructor(
    @Inject(MAT_DIALOG_DATA) public agentSignature: AgentSignature
  ) { }

  ngOnInit(): void {
  }

}
