import {ChangeDetectorRef, Pipe, PipeTransform} from "@angular/core";
import {AsyncPipe} from "@angular/common";
import {Observable} from "rxjs";
import {DomSanitizer, SafeResourceUrl} from "@angular/platform-browser";
import {map} from "rxjs/operators";
import {PropertyPublicDashboardApiService} from "../../../lib";

@Pipe({
  name: "propertyExternalImg",
  pure: false,
  standalone: true
})
export class PropertyExternalImgPipe implements PipeTransform {
  private asyncPipe: AsyncPipe;
  private _propertyId: string | null;

  private _propertyImage: Observable<(SafeResourceUrl & string) | null> | null;
  constructor(
    protected propertyPublicDashboardApiService: PropertyPublicDashboardApiService,
    protected cdf: ChangeDetectorRef,
    protected sanitizer: DomSanitizer
  ) {
    this.asyncPipe = new AsyncPipe(this.cdf);
  }
  transform(propertyId: string): (SafeResourceUrl & string) | null {
    if (propertyId != this._propertyId) {
      this._propertyId = propertyId;
      this._propertyImage = propertyId
        ? this.propertyPublicDashboardApiService.getImage(propertyId).pipe(
            map(({url: imgUrl}) => {
              if (imgUrl) {
                return this.sanitizer.bypassSecurityTrustResourceUrl(imgUrl) as SafeResourceUrl & string;
              }
              return null;
            })
          )
        : null;
    }
    return this.asyncPipe.transform(this._propertyImage);
  }
}
