import {enableProdMode} from "@angular/core";
import {platformBrowserDynamic} from "@angular/platform-browser-dynamic";

import {AppModule} from "./app/app.module";
import {environment} from "./environments/environment";
import {BrowserTracing} from "@sentry/tracing";
import * as Sentry from "@sentry/angular";
import {FelloEnv} from "fello-model";
import versionInfo from "../version.json";
import {SentryUtils} from "fello-common";

if (environment.production && environment.env === FelloEnv.PROD) {
  Sentry.init({
    dsn: "https://85adaf2a00c9406ea8dcdc68af46d5be@o1276079.ingest.sentry.io/6471369",
    integrations: [
      new BrowserTracing({
        tracingOrigins: [],
        routingInstrumentation: Sentry.routingInstrumentation
      })
    ],
    environment: environment.env === FelloEnv.PROD ? "production" : "development",
    release: `consumer-${environment.env}@${versionInfo.version}`,
    beforeSend: SentryUtils.beforeSend,

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.5
  });
  enableProdMode();
}

function bootstrap() {
  platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .catch(err => console.error(err));
}

if (document.readyState === "complete") {
  bootstrap();
} else {
  document.addEventListener("DOMContentLoaded", bootstrap);
}
