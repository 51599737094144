import {Component, forwardRef, Input} from "@angular/core";
import {ControlValueAccessor, FormsModule, NG_VALUE_ACCESSOR} from "@angular/forms";
import {BehaviorSubject} from "rxjs";
import {map} from "rxjs/operators";
import {IconComponent} from "../../../fello-ui-utils";
import {MatSelectModule} from "@angular/material/select";
import {AsyncPipe, NgForOf, NgIf} from "@angular/common";
import {DropdownOption} from "../dropdown-with-search/dropdown-with-search.component";

@Component({
  selector: "lib-multi-select-dropdown-with-search",
  templateUrl: "./multi-select-dropdown-with-search.component.html",
  styleUrls: ["./multi-select-dropdown-with-search.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MultiSelectDropdownWithSearchComponent),
      multi: true
    }
  ],
  imports: [MatSelectModule, IconComponent, FormsModule, NgForOf, NgIf, AsyncPipe],
  standalone: true
})
export class MultiSelectDropdownWithSearchComponent implements ControlValueAccessor {
  search$ = new BehaviorSubject<string>("");
  filteredOptions$ = this.search$.pipe(
    map(search => {
      if (!search) {
        return this.options;
      }
      search = search.toLowerCase();
      return this.options.filter(option => {
        return option.label.toLowerCase().includes(search);
      });
    })
  );

  hiddenOptions$ = this.search$.pipe(
    map(search => {
      if (!search) {
        return [];
      }
      search = search.toLowerCase();
      return this.options.filter(option => {
        return !option.label.toLowerCase().includes(search);
      });
    })
  );

  @Input() options: DropdownOption[] = [];
  @Input() placeholder: string;
  @Input() disableOptionCentering = false;
  @Input() panelClass: string | string[] | Set<string> | {[p: string]: any};
  @Input() compareWith: (o1: any, o2: any) => boolean = (a: any, b: any) => a === b;

  value: any;
  disabled: boolean;

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onChange: any = () => {};
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onTouched: any = () => {};

  writeValue(value: any): void {
    this.value = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}
