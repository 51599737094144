import {Inject, Injectable, InjectionToken} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {
  DashboardModuleButtonAction,
  FelloDashboard,
  FellOfferJourneyPushDataResponse,
  FelloVisitorVariables,
  JourneySettingResponse,
  MortgageLeadRequest
} from "fello-model";

export const FELLO_API_SERVICE_BASE_URL = new InjectionToken<string>("FELLO_API_SERVICE_BASE_URL");

@Injectable({providedIn: "root"})
export class FelloApiService {
  constructor(private http: HttpClient, @Inject(FELLO_API_SERVICE_BASE_URL) private baseUrl: string) {}

  getDashboard(dashboardId: string): Observable<FelloDashboard> {
    return this.http.get<FelloDashboard>(`${this.baseUrl}/fello/j/${dashboardId}`);
  }

  getWidgetFormSubmitUrl(): string {
    return `${this.baseUrl}/fello/j/init/widget/r`;
  }

  getLandingPageCTAActionUrl(): string {
    return `${this.baseUrl}/fello/j/init/lp/cta/r`;
  }

  performLandingPageCTAAction(
    landingPageConfigId: string,
    action: DashboardModuleButtonAction,
    address?: string,
    referrer?: string
  ): Observable<{url: string}> {
    const body: any = {landingPageConfigId, action};
    if (address) {
      body.address = address;
    }
    if (referrer) {
      body.referrer = referrer;
    }
    return this.http.post<{url: string}>(`${this.baseUrl}/fello/j/init/lp/cta`, body);
  }

  performWidgetCTAAction(
    widgetId: string,
    action: DashboardModuleButtonAction,
    referrer: string,
    address?: string
  ): Observable<{url: string}> {
    const body: any = {widgetId, action, referrer};
    if (address) {
      body.address = address;
    }
    return this.http.post<{url: string}>(`${this.baseUrl}/fello/j/init/widget/cta`, body);
  }

  performMortgageLeadAction(widgetId: string, referrer: string, leadRequest: MortgageLeadRequest): Observable<{url: string}> {
    const body: any = {widgetId, referrer, leadRequest};
    return this.http.post<{url: string}>(`${this.baseUrl}/fello/j/init/widget/mortgage`, body);
  }

  performPURLCTAAction(
    purlId: string,
    action: DashboardModuleButtonAction,
    address?: string,
    referrer?: string,
    landingPageConfigId?: string
  ): Observable<{url: string}> {
    const body: any = {purlId, action};
    if (landingPageConfigId) {
      body.landingPageConfigId = landingPageConfigId;
    }
    if (address) {
      body.address = address;
    }
    if (referrer) {
      body.referrer = referrer;
    }
    return this.http.post<{url: string}>(`${this.baseUrl}/fello/j/init/lp/p/cta`, body);
  }

  initForWidget(widgetId: string, address: string, referrer?: string): Observable<{url: string}> {
    return this.http.post<{url: string}>(
      `${this.baseUrl}/fello/j/init/widget`,
      {
        widgetId,
        address,
        referrer
      },
      {
        withCredentials: true
      }
    );
  }

  locateOfferJourney(dashboardId: string): Observable<FellOfferJourneyPushDataResponse> {
    return this.http.get<FellOfferJourneyPushDataResponse>(`${this.baseUrl}/fello/j/${dashboardId}/where`);
  }

  pushOfferJourneyTask(dashboardId: string, taskId: string, vars: FelloVisitorVariables): Observable<FellOfferJourneyPushDataResponse> {
    return this.http.put<FellOfferJourneyPushDataResponse>(`${this.baseUrl}/fello/j/${dashboardId}/push/${taskId}`, {
      vars
    });
  }

  getStartedOffer(): Observable<{url: string}> {
    return this.http.post<{url: string}>(`${this.baseUrl}/fello/j/init/ho`, {});
  }

  getJourneySettings(journeyId: string): Observable<JourneySettingResponse | null> {
    return this.http.get<JourneySettingResponse | null>(`${this.baseUrl}/fello/j/${journeyId}/settings`);
  }
}
