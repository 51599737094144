<header class="template-a">
  <ng-container *ngIf="landingPageSetting; let settings">
    <ng-container *ngIf="settings.logo; let logo">
      <ng-container *ngIf="settings.website; else justLogo">
        <a [href]="settings.website" target="_blank" class="flex-box">
          <img [src]="logo" alt="logo" class="img-logo" />
        </a>
      </ng-container>
      <ng-template #justLogo>
        <img [src]="logo" alt="logo" class="img-logo" />
      </ng-template>
    </ng-container>
  </ng-container>
</header>

<div class="wrapper flex-item-auto-sizing" *ngIf="landingPageSetting">
  <img
    *ngIf="landingPageSetting.config.layoutSettings.backgroundImage"
    [src]="landingPageSetting.config.layoutSettings.backgroundImage | mediaWithType:{auto: 'compress', fit: 'clip', w: ((isDesktop$ | async) ?? false) ? 3000 : 1000}"
    alt="bg-img"
    class="bg-img"
  />
  <div class="container template-a h-100">
    <div class="grid-c container-type-inline">
      <div class="flex-box flex-direction-column flex-box-justify-center flex-1">
        <h1 class="title mw-580">{{landingPageSetting.config.layoutSettings.heading}}</h1>
        <p class="sub-heading mt-4 mw-580" *ngIf="landingPageSetting.config.layoutSettings.subHeading">
          {{landingPageSetting.config.layoutSettings.subHeading}}
        </p>
        <ng-container *ngIf="landingPageSetting.theme?.button; let button">
          <ng-container *ngIf="landingPageSetting.config.layoutSettings; let settings">
            <form
              class="card-wrapper"
              #form="ngForm"
              (ngSubmit)="form.invalid ? form.control.markAllAsTouched() : submitForm()"
              *ngIf="settings"
            >
              <div class="widget-input-c w-100 mt-5">
                <lib-smarty-address-auto-complete
                  name="address"
                  id="propertyAddress"
                  [required]="true"
                  [(ngModel)]="address"
                  #propertyAddress="ngModel"
                  [placeholder]="settings.searchBarText"
                  maxlength="100"
                  [disabled]="isLoading"
                ></lib-smarty-address-auto-complete>
                <button type="submit" lib-loading-button [isLoading]="isLoading">{{settings.buttonText}}</button>
              </div>
            </form>
          </ng-container>
        </ng-container>
      </div>
      <div class="video-c" *ngIf="landingPageSetting.config.layoutSettings.videoEnabled">
        <ng-container *ngIf="!landingPageSetting.config.layoutSettings.videoEmbedCode">
          <lib-icon class="text-size-45">video-player</lib-icon>
          <p class="text-size-18 fw-600">Video</p>
          <p class="text-size-14 fw-500 text-color-light">
            You can add your video embed code on the panel on the right to preview the video here
          </p>
        </ng-container>
        <ng-container *ngIf="landingPageSetting.config.layoutSettings.videoEmbedCode">
          <iframe [srcdoc]="iframeSrc | safeHtml" frameborder="0" libAutoResize></iframe>
        </ng-container>
      </div>
    </div>
    <div class="eho-c mt-4">
      <img src="https://www.hifello.com/hubfs/equal-housing-logo-grey.svg" alt="equal-housing-opportunity" class="img-eho white" />
      <img src="https://www.hifello.com/hubfs/equal-housing-opportunity.png" alt="equal-housing-opportunity" class="img-eho black" />
      <div>
        <p class="text-size-14 fw-400 text-color-slate-00 opacity-50 terms">
          ©
          <span *ngIf="landingPageSetting.accountName">{{landingPageSetting.accountName}}</span>
          <a
            target="_blank"
            [href]="landingPageSetting.privacyPolicyUrl ?? felloWebsites.PRIVACY_POLICY"
            class="d-inline text-color-slate-00"
            rel="nofollow"
          >
            | Privacy Policy</a
          >
          <a
            target="_blank"
            [href]="landingPageSetting.termsAndConditionsUrl ?? felloWebsites.TERMS_AND_CONDITIONS"
            class="d-inline text-color-slate-00"
            rel="nofollow"
          >
            | Terms of Service</a
          >
        </p>
        <p
          class="text-size-14 fw-400 text-color-slate-00 opacity-50 terms whitespace-pre-wrap"
          [innerHTML]="landingPageSetting.footerText"
        ></p>
      </div>
    </div>
  </div>
</div>

<button class="fixed-bottom-right contact-button" (click)="openContactBlock()" *ngIf="landingPageSetting?.signature">
  <lib-icon *ngIf="!isContactDialogOpen" [fontSize]="30" color="var(--white)"> user-card</lib-icon>
  <lib-icon *ngIf="isContactDialogOpen" [fontSize]="40" color="var(--white)"> close</lib-icon>
</button>
