export enum AgentLeadType {
  CALLBACK = "CALLBACK",
  CMA = "CMA",
  CO = "CO",
  HV = "HV",
  SL = "SL",
  ML = "ML",
  AL = "AL"
}

export const AgentLeadTypeLabel: Record<AgentLeadType, string> = {
  [AgentLeadType.CALLBACK]: "Callback Lead",
  [AgentLeadType.CMA]: "CMA Lead",
  [AgentLeadType.CO]: "Cash Offer Lead",
  [AgentLeadType.HV]: "Home Value Lead",
  [AgentLeadType.SL]: "Seller Lead",
  [AgentLeadType.ML]: "Mortgage Lead",
  [AgentLeadType.AL]: "Affordability Lead"
};

export const AgentLeadFormTypeLabel: Record<AgentLeadType, string> = {
  [AgentLeadType.CALLBACK]: "Callback form",
  [AgentLeadType.CMA]: "CMA form",
  [AgentLeadType.CO]: "Cash Offer Lead form",
  [AgentLeadType.HV]: "Home Value form",
  [AgentLeadType.SL]: "Seller Lead form",
  [AgentLeadType.ML]: "Mortgage Lead form",
  [AgentLeadType.AL]: "Affordability Lead form"
};
