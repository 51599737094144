import {Component, Input, OnInit} from "@angular/core";
import {AgencyLender, LenderType} from "fello-model";

@Component({
  selector: 'lib-lender-details',
  templateUrl: './lender-details.component.html',
  styleUrls: ['./lender-details.component.scss']
})
export class LenderDetailsComponent implements OnInit {
  @Input() lender: AgencyLender;
  LenderType = LenderType;

  constructor() { }

  ngOnInit(): void {
  }

}
