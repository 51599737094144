import {Directive, Input} from "@angular/core";
import {AbstractControl, NG_VALIDATORS, ValidationErrors, Validator} from "@angular/forms";

@Directive({
  selector: "[libMin]",
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: MinValidatorDirective,
      multi: true
    }
  ],
  standalone: true
})
export class MinValidatorDirective implements Validator {
  private _minValue: number | null = null;
  @Input("libMin") get minValue(): number | null {
    return this._minValue;
  }

  set minValue(value: number | null) {
    this._minValue = value;
    if (this._onChange) {
      this._onChange();
    }
  }

  private _onChange: () => void;

  registerOnValidatorChange(fn: () => void): void {
    this._onChange = fn;
  }
  validate(control: AbstractControl): ValidationErrors | null {
    const value = control.value as number;
    if (value != null && !isNaN(value) && this.minValue != null) {
      if (value < this.minValue) {
        return {min: true};
      }
    }
    return null;
  }
}
