export enum TrackerUsers {
  AGENT = "agent",
  HOME_OWNER = "homeOwner"
}

export interface UserIdentifiedExtras extends Record<string, any> {
  type: TrackerUsers;
}

export interface EventTracker {
  trackPageView(path: string, extra?: Record<string, any>): void;
  userIdentified(email: string, extras: UserIdentifiedExtras): void;
  trackCustomEvent(eventName: string, eventData?: Record<string, any>): void;
}
