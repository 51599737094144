<ng-container *ngIf="lastAdjustment">
  <div class="header">
    <p class="fw-400 text-color-light-2 flex-box-center flex-box-gap-1 flex-wrap flex-box-justify-center">
      <ng-container *ngIf="lastAdjustment.type === FelloMultiAvmResponseV2AdjustedAvmType.AGENT; else accountTypeUpdate">
        <img
          class="border-radius-50 avatar"
          *ngIf="signature.type === AgentSignatureType.AGENT && signature.photo"
          [src]="signature.photo | mediaWithType:{auto: 'compress', fit: 'clip', w: 30, fallback:'/assets/images/common/user.svg'}"
          alt="avatar"
        />
        <span class="fw-500 text-color-secondary text-overflow-ellipsis max-width-300">
          <span *ngIf="signature.signatureElementsSettings[0].type !== AgentSignatureElement.WEBSITE; else websiteTemplate">
            {{ signature.signatureElementsSettings[0].value }}</span
          >
        </span>
        <ng-template #websiteTemplate>
          {{ AgentBrandingUtils.getAgencyWebsiteDisplayText(signature.signatureElementsSettings[0].value.toString()) }}
        </ng-template>
        adjusted your value on
        <span class="fw-500 text-color-secondary">{{ lastAdjustment.agentAdjustedDate | momentFormat:'MMM DD, YYYY' }}</span>
      </ng-container>
      <ng-template #accountTypeUpdate>
        <span class="fw-500 text-color-secondary text-overflow-ellipsis max-width-180"> {{ agencyName }} </span>
      </ng-template>
    </p>
  </div>
  <div class="p-3 border-top-1">
    <div class="flex-box-center-between flex-box-gap-4 flex-sm-wrap">
      <p class="text-size-55 fw-600">{{ lastAdjustment.avm.median | currency :'USD' : 'symbol' : '1.0-0' }}</p>
      <div>
        <p class="text-size-16 fw-600 flex-box-all-center text-color-light-2">Estimated sale price range</p>
        <div class="graph-c" *ngIf="lastAdjustment.avm">
          <lib-home-value-range-graph class="graph"></lib-home-value-range-graph>
          <p class="low text-size-16 fw-600">
            <span class="actual-data"> ${{ lastAdjustment.avm.low ?? 0 | numberShorthand }} </span>
            <span class="dummy-data">$XXXK</span>
          </p>
          <p class="high text-size-16 fw-600">
            <span class="actual-data"> ${{ lastAdjustment.avm.high ?? 0 | numberShorthand }} </span>
            <span class="dummy-data">$XXXK</span>
          </p>
        </div>
      </div>
    </div>
    <ng-content></ng-content>
  </div>
</ng-container>
