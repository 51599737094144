import {Component, Inject} from "@angular/core";
import {ConfirmationDialogComponent, ConfirmationDialogData} from "../confirmation-dialog/confirmation-dialog.component";
import {MAT_DIALOG_DATA, MatDialogModule, MatDialogRef} from "@angular/material/dialog";
import {IconComponent} from "../../../fello-ui-utils";
import {FormsModule} from "@angular/forms";
import {NgClass, NgIf} from "@angular/common";

export interface ConfirmationWithTextDialogData extends ConfirmationDialogData {
  text?: string;
  fieldLabel: string;
  placeholder?: string;
  textRequired?: boolean;
  minLength?: number;
  maxLength?: number;
}

@Component({
  selector: "lib-confirmation-with-text-dialog",
  templateUrl: "./confirmation-with-text-dialog.component.html",
  styleUrls: ["./confirmation-with-text-dialog.component.scss"],
  imports: [IconComponent, FormsModule, NgIf, NgClass, MatDialogModule],
  standalone: true
})
export class ConfirmationWithTextDialogComponent extends ConfirmationDialogComponent {
  constructor(
    dialogRef: MatDialogRef<boolean>,
    @Inject(MAT_DIALOG_DATA)
    public data: ConfirmationWithTextDialogData
  ) {
    super(dialogRef, data);
    this.title = data.title;
    this.description = data.description ?? "";
    this.okButtonText = data.okButtonText;
    this.cancelButtonText = data.cancelButtonText;
    this.okButtonClasses = data.okButtonClasses ?? [];
  }
}
