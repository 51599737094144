import {Inject, Injectable, InjectionToken, Optional} from "@angular/core";
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from "@angular/common/http";
import {Observable} from "rxjs";
import {some} from "lodash-es";

export const SKIP_SW_BASE_URLS = new InjectionToken<string[]>("List of base urls to which the request should not be routed through SW");

@Injectable()
export class SkipSwInterceptor implements HttpInterceptor {
  constructor(@Optional() @Inject(SKIP_SW_BASE_URLS) private skipSWBaseUrls?: string[]) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (request.reportProgress) {
      next.handle(
        request.clone({
          headers: request.headers.set("ngsw-bypass", ``)
        })
      );
    }
    if (some(this.skipSWBaseUrls, baseUrl => request.url.startsWith(baseUrl))) {
      return next.handle(
        request.clone({
          headers: request.headers.set("ngsw-bypass", ``)
        })
      );
    }
    return next.handle(request);
  }
}
