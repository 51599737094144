<div class="img-c">
  <img
    [src]="img"
    alt="house-img"
    *ngIf="compImage | async; let img else fallbackImg"
    libFallbackImage="/assets/images/svg/no_property_image.svg"
  />
  <ng-template #fallbackImg>
    <img src="/assets/images/svg/no_property_image.svg" alt="house-img" class="fallback" />
  </ng-template>
  <p class="addr text-size-12">
    <span class="fw-700">{{index |numberToLetter}} / </span>
    {{comp.addressComponent.doorNumber}} {{comp.addressComponent.street}}
  </p>
</div>
<div class="desc">
  <p class="flex-box-center">
    <span class="fw-600 text-size-18 actual-data"> ${{comp.opd.recentSaleOrListPrice | number : '1.0-0'}} </span>
    <span class="fw-600 text-size-18 dummy-data"> $XXX,XXX </span>
    <span class="text-size-14 ml-2 text-color-slate-5 actual-data">
      {{comp.listingCategory === PropertyListingCategory.ACTIVE ? 'Listed' : 'Sold'}} · {{comp.opd.recentSaleOrListDate | timeAgo}}
    </span>
  </p>
  <p class="text-size-16 mt-2 fw-400 actual-data" *ngIf="comp.opd.bedrooms || comp.opd.fullBaths || comp.opd.aboveGradeSqft">
    <span *ngIf="comp.opd.bedrooms">{{comp.opd.bedrooms}} bedrooms ·</span>
    <span *ngIf="comp.opd.fullBaths"> {{comp.opd.fullBaths + (comp.opd.partialBaths ?? 0) / 2 }} Baths</span>
    <span *ngIf="comp.opd.aboveGradeSqft"> · {{comp.opd.aboveGradeSqft}} sqft</span>
  </p>
  <div class="dummy-data mt-2">
    <ngx-skeleton-loader [animation]="false" count="1" [theme]="{height: '20px', width: '30%'}"></ngx-skeleton-loader>
    <ngx-skeleton-loader [animation]="false" count="1" [theme]="{height: '20px', width: '80%'}"></ngx-skeleton-loader>
    <ngx-skeleton-loader [animation]="false" count="1" [theme]="{height: '20px', width: '60%'}"></ngx-skeleton-loader>
    <ngx-skeleton-loader [animation]="false" count="1" [theme]="{height: '20px', width: '80%'}"></ngx-skeleton-loader>
    <ngx-skeleton-loader [animation]="false" count="1" [theme]="{height: '20px', width: '60%'}"></ngx-skeleton-loader>
  </div>
  <div class="actual-data">
    <app-comp-label [comp]="comp" [sourcePropertyOPDLite]="sourcePropertyOPDLite"></app-comp-label>
  </div>
</div>
