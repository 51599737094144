import {Observable, Subject} from "rxjs";
import {AbstractConstructor, Constructor} from "../types";
import {OnDestroy} from "@angular/core";

export interface CanDestroy extends OnDestroy {
  isDestroyed: Observable<void>;
}

type CanDestroyCtor = Constructor<CanDestroy> & AbstractConstructor<CanDestroy>;

// eslint-disable-next-line @typescript-eslint/ban-types
export function mixinDestroyable<T extends AbstractConstructor<{}>>(base: T): CanDestroyCtor & T;
// eslint-disable-next-line @typescript-eslint/ban-types
export function mixinDestroyable<T extends Constructor<{} & {ngOnDestroy?(): void}>>(base: T): CanDestroyCtor & T {
  return class extends base {
    isDestroyed = new Subject<void>();

    ngOnDestroy(): void {
      if (super.ngOnDestroy) {
        super.ngOnDestroy();
      }
      this.isDestroyed.next();
      this.isDestroyed.complete();
    }
  };
}
