import {AfterViewInit, Directive, ElementRef, Inject, OnDestroy, PLATFORM_ID} from "@angular/core";
import {IFrameComponent, iframeResizer} from "iframe-resizer";
import {isPlatformBrowser} from "@angular/common";

@Directive({
  selector: "iframe[libAutoResize]",
  standalone: true
})
export class IframeResizeDirective implements AfterViewInit, OnDestroy {
  component: IFrameComponent | null;

  // eslint-disable-next-line @typescript-eslint/ban-types
  constructor(public element: ElementRef<HTMLIFrameElement>, @Inject(PLATFORM_ID) private platformId: Object) {}

  ngAfterViewInit() {
    if (isPlatformBrowser(this.platformId)) {
      const components = iframeResizer(
        {
          checkOrigin: false,
          heightCalculationMethod: "documentElementOffset",
          log: false
        },
        this.element.nativeElement
      );
      /* save component reference so we can close it later */
      this.component = components && components.length > 0 ? components[0] : null;
    }
  }

  ngOnDestroy(): void {
    if (this.component && this.component.iFrameResizer) {
      this.component.iFrameResizer.close();
    }
  }
}
