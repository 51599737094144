import {Inject, Injectable, PLATFORM_ID} from "@angular/core";
import {makeStateKey, TransferState} from "@angular/platform-browser";
import {Observable, of} from "rxjs";
import {isPlatformServer} from "@angular/common";
import {tap} from "rxjs/operators";

@Injectable({
  providedIn: "root"
})
export class StateTransferHelperService {
  // eslint-disable-next-line @typescript-eslint/ban-types
  constructor(private transferState: TransferState, @Inject(PLATFORM_ID) private platformId: Object) {}

  transferOnce<T>(obs: Observable<T>, key: string): Observable<T> {
    const stateKey = makeStateKey<T>(key);
    const existingState = this.transferState.get<T | null>(stateKey, null);
    if (existingState) {
      if (!isPlatformServer(this.platformId)) {
        this.transferState.remove(stateKey);
      }
      return of(existingState);
    }
    return obs.pipe(
      tap(state => {
        if (isPlatformServer(this.platformId)) {
          this.transferState.set(stateKey, state);
        } else {
          this.transferState.remove(stateKey);
        }
      })
    );
  }
}
