<svg
  xmlns="http://www.w3.org/2000/svg"
  [ngStyle]="{
    height: containerHeight + 'px',
    width: containerWidth + 'px',
    left: containerLeft + 'px',
    top: containerTop + 'px',
    opacity: opacity
  }"
  class="ngflowchart-arrow"
>
  <defs>
    <marker id="arrowhead" viewBox="0 0 10 10" refX="3" refY="5" markerWidth="5" markerHeight="5" orient="auto" fill="#ABB1BD">
      <path d="M 0 0 L 10 5 L 0 10 z" />
    </marker>
  </defs>
  <g id="arrowpath" fill="none" stroke="#ABB1BD" stroke-width="2">
    <path id="arrow" #arrow />
  </g>
  <g *ngIf="labelText">
    <!-- Add the background rectangle with padding -->
    <rect
      x="50%"
      y="35%"
      width="36px"
      height="16px"
      rx="4"
      ry="4"
      [attr.fill]="backgroundColor"
      [attr.stroke]="textColor"
      stroke-width="1"
      style="transform: translateX(-17px)"
    />

    <!-- Add the text inside the group with padding -->
    <text x="50%" y="50%" width="36px" font-size="10" [attr.fill]="textColor" dominant-baseline="middle" text-anchor="middle">
      {{ labelText }}
    </text>
  </g>
</svg>
