import {Directive, Input} from "@angular/core";
import {AvailableSellingOptionTypes, SellingOptionSetting, SellingOptionWrapper} from "fello-model";
import {NgxSpinnerService} from "ngx-spinner";
import {MatSnackBar} from "@angular/material/snack-bar";
import {DestroyableToastSpinnerBase} from "../../../../../lib/mixins/";

@Directive({
  selector: "[libBaseSellingOption]",
  standalone: true
})
export class BaseSellingOptionDirective<OptionType extends AvailableSellingOptionTypes> extends DestroyableToastSpinnerBase {
  @Input()
  sellingOptionSettings: SellingOptionSetting<OptionType>;

  @Input()
  sellingOptionWrapper: SellingOptionWrapper<OptionType>;

  constructor(public spinnerService: NgxSpinnerService, public snackBar: MatSnackBar) {
    super(spinnerService, snackBar);
  }
}
