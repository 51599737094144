import {Component, Input} from "@angular/core";
import {CommonModule} from "@angular/common";
import {
  AgentSignature,
  AgentSignatureElement,
  AgentSignatureType,
  FelloMultiAvmResponseAdjustedAvm,
  FelloMultiAvmResponseAdjustedAvmType
} from "fello-model";
import {FormatPricePipe, IconComponent, MediaWithTypePipe, MomentFormatPipe, NumberShorthandPipe} from "../../../fello-ui-utils";
import moment from "moment";
import {AgentBrandingUtils} from "../../../../lib";
import {HomeValueRangeGraphComponent} from "../home-value-with-steps/home-value-range-graph/home-value-range-graph.component";

@Component({
  selector: "lib-avm-updated-by-agent-strip",
  standalone: true,
  imports: [
    CommonModule,
    MediaWithTypePipe,
    FormatPricePipe,
    MomentFormatPipe,
    IconComponent,
    NumberShorthandPipe,
    HomeValueRangeGraphComponent
  ],
  templateUrl: "./avm-updated-by-agent-strip.component.html",
  styleUrls: ["./avm-updated-by-agent-strip.component.scss"]
})
export class AvmUpdatedByAgentStripComponent {
  moment = moment;
  @Input() lastAdjustment: FelloMultiAvmResponseAdjustedAvm;
  @Input() signature: AgentSignature;
  @Input() agencyName: string;

  protected readonly AgentBrandingUtils = AgentBrandingUtils;
  protected readonly AgentSignatureElement = AgentSignatureElement;
  protected readonly AgentSignatureType = AgentSignatureType;
  protected readonly FelloMultiAvmResponseV2AdjustedAvmType = FelloMultiAvmResponseAdjustedAvmType;
}
