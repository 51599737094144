import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from "@angular/core";
import {debounceTime, delay, distinctUntilChanged, map, retryWhen, switchMap} from "rxjs/operators";
import {MatAutocomplete, MatAutocompleteModule, MatAutocompleteSelectedEvent} from "@angular/material/autocomplete";
import {BehaviorSubject, Observable, of} from "rxjs";
import {AddressAutoCompleteService, AddressSuggestion} from "./address-auto-complete.service";
import {mixinDestroyable} from "../../../../lib/mixins/destroy";
import {AsyncPipe, NgForOf} from "@angular/common";

@Component({
  selector: "lib-address-auto-complete",
  templateUrl: "./address-auto-complete.component.html",
  styleUrls: ["./address-auto-complete.component.css"],
  providers: [AddressAutoCompleteService],
  imports: [MatAutocompleteModule, AsyncPipe, NgForOf],
  standalone: true
})
export class AddressAutoCompleteComponent extends mixinDestroyable(class {}) implements OnInit {
  suggestions: Observable<AddressSuggestion[]>;
  search$ = new BehaviorSubject<string>("");

  @Input() set search(value: string) {
    this.search$.next(value ?? "");
  }

  @Input()
  maxSuggestionCount: number | undefined;

  @Output() optionSelected = new EventEmitter<MatAutocompleteSelectedEvent>();

  @ViewChild(MatAutocomplete, {static: true}) autocomplete: MatAutocomplete;

  constructor(private addressAutoCompleteService: AddressAutoCompleteService) {
    super();
  }

  ngOnInit() {
    this.suggestions = this.search$.pipe(
      distinctUntilChanged(),
      debounceTime(200),
      switchMap(search => {
        if (!search) {
          return of([]);
        }
        return this.addressAutoCompleteService.getAddressSuggestion(search).pipe(
          retryWhen(err => err.pipe(delay(5000))),
          map(suggestions => {
            if (this.maxSuggestionCount) {
              return suggestions.slice(0, this.maxSuggestionCount);
            }
            return suggestions;
          })
        );
      })
    );
  }

  onAutoCompleteOptionSelect(event: MatAutocompleteSelectedEvent): void {
    this.optionSelected.emit(event);
  }
}
