import {AutomationComponentType} from "fello-model";

export const POSTCARD_CREDITS = 900;
export const ENRICHMENT_CREDITS = 100;
export const EMAIL_CREDITS = 1;
export const CREDIT_VALUE = 0.001; // 1 credit = 0.001 usd

export const ActionableComponentTypeCredits: Record<string, number> = {
  [AutomationComponentType.POSTCARD]: POSTCARD_CREDITS,
  [AutomationComponentType.EMAIL]: EMAIL_CREDITS,
  [AutomationComponentType.CALL_WEBHOOK]: ENRICHMENT_CREDITS
};
