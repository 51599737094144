<div [ngClass]="{'flex-box' : lender.lenderType === LenderType.INDIVIDUAL}">
  <ng-container *ngIf="lender.lenderType === LenderType.INDIVIDUAL; else agencyLogo">
    <img
      [src]="lender.logoMedia | mediaWithType:{auto: 'compress', fit: 'clip', fallback:'/assets/images/svg/user.png'}"
      alt="Agent"
      class="lender-logo"
    />
  </ng-container>
  <ng-template #agencyLogo>
    <img
      [src]="lender.logoMedia | mediaWithType:{auto: 'compress', fit: 'clip', fallback:'/assets/images/svg/no_property_image_old.svg'}"
      alt="company"
      class="company-logo"
    />
  </ng-template>

  <div class="flex-item-auto-sizing">
    <ng-container *ngIf="lender.lenderType === LenderType.INDIVIDUAL;">
      <h3 *ngIf="lender.name" class="text-size-16 fw-600 m-0">{{lender.name}}</h3>
      <span *ngIf="lender.companyName" class="text-color-light-2"> {{lender.companyName}} • NMLS# {{lender.nmlsId}} </span>
    </ng-container>
    <ng-container *ngIf="lender.lenderType === LenderType.COMPANY;">
      <h3 *ngIf="lender.companyName" class="text-size-16 fw-600 m-0">{{lender.companyName}}</h3>
      <span *ngIf="lender.nmlsId" class="text-color-light-2">NMLS# {{lender.nmlsId}}</span>
    </ng-container>
    <span *ngIf="lender.phone" class="text-color-grey-secondary grey mb-0 d-block">{{lender.phone | phone}}</span>
    <span *ngIf="lender.email" class="text-color-grey-secondary text-overflow-ellipsis d-block mw-400">{{lender.email}}</span>
  </div>
</div>
