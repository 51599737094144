import {
  Component,
  ContentChild,
  Directive,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
  TrackByFunction
} from "@angular/core";
import {BehaviorSubject, combineLatest, Observable, ReplaySubject, Subject} from "rxjs";
import {debounceTime, filter, startWith, switchMap, takeUntil, tap} from "rxjs/operators";
import {PagedResult, PaginationParams} from "fello-model";
import {mixinDestroyable} from "../../../../lib";
import {NgClass, NgForOf, NgIf, NgTemplateOutlet} from "@angular/common";
import {InfiniteScrollModule} from "ngx-infinite-scroll";

export type InfiniteScrollDataSource<T> = (params: PaginationParams) => Observable<PagedResult<T>>;

export interface SimpleInfiniteScrollItemContext<T> {
  item: T;
  items: T[];
  index: number;
  count: number;
  first: boolean;
  last: boolean;
  even: boolean;
  odd: boolean;
}

@Directive({
  selector: "[libSimpleInfiniteScrollItem]",
  standalone: true
})
export class SimpleInfiniteScrollItemDirective<T> {
  static ngTemplateContextGuard<T>(dir: SimpleInfiniteScrollItemDirective<T>, ctx: unknown): ctx is SimpleInfiniteScrollItemContext<T> {
    return true;
  }
  @Input("libSimpleInfiniteScrollItem") trackByFunction: TrackByFunction<T>;
  constructor(public template: TemplateRef<SimpleInfiniteScrollItemContext<T>>) {}
}

@Directive({
  selector: "[libInfiniteScrollLoader]",
  standalone: true
})
export class InfiniteScrollLoaderDirective<T> {
  constructor(public template: TemplateRef<T>) {}
}

@Directive({
  selector: "[libInfiniteScrollNoData]",
  standalone: true
})
export class InfiniteScrollNoDataDirective<T> {
  constructor(public template: TemplateRef<T>) {}
}

@Component({
  selector: "lib-simple-infinite-scroll",
  templateUrl: "./simple-infinite-scroll.component.html",
  styleUrls: ["./simple-infinite-scroll.component.scss"],
  imports: [NgClass, InfiniteScrollModule, NgIf, NgForOf, NgTemplateOutlet],
  standalone: true
})
export class SimpleInfiniteScrollComponent<T> extends mixinDestroyable(class {}) implements OnInit {
  @Input() infiniteScrollDistance = 2;
  @Input() infiniteScrollThrottle = 50;
  @Input() horizontal: boolean;
  @Input() infiniteScrollContainer: any;

  @Input() set dataSource(value: InfiniteScrollDataSource<T>) {
    this.dataSource$.next(value);
  }

  @Input() set pageSize(value: number) {
    this.pageSize$.next(value);
  }
  public get pageSize(): number {
    return this.pageSize$.value;
  }

  @Input() set extraParams(params: Record<string, any> | null | undefined) {
    this.extraParams$.next(params);
  }

  @Input() dataFetchDebounceTime = 300;

  @ContentChild(SimpleInfiniteScrollItemDirective) itemTemplate: SimpleInfiniteScrollItemDirective<T>;
  @ContentChild(InfiniteScrollLoaderDirective) loaderTemplate?: InfiniteScrollLoaderDirective<T>;
  @ContentChild(InfiniteScrollNoDataDirective) nDataTemplate?: InfiniteScrollLoaderDirective<T>;

  private dataSource$ = new ReplaySubject<InfiniteScrollDataSource<T>>();
  private pageSize$ = new BehaviorSubject<number>(20);
  private extraParams$ = new BehaviorSubject<Record<string, unknown> | null | undefined>({});
  private scrolled$ = new Subject<void>();
  private refresh$ = new Subject<void>();
  @Output() newListItems = new EventEmitter<T[]>();

  items: T[] = [];
  isLoading = true;
  isLoading$ = new BehaviorSubject<boolean>(true);
  totalCount: number | null = null;

  public get allLoaded(): boolean {
    return this.totalCount != null && this.totalCount === this.items.length;
  }

  constructor(public elementRef: ElementRef) {
    super();
  }

  ngOnInit(): void {
    combineLatest([
      this.dataSource$.pipe(tap(() => this.resetList())),
      this.pageSize$,
      this.extraParams$.pipe(tap(() => this.resetList())),
      this.scrolled$.pipe(startWith(undefined)),
      this.refresh$.pipe(
        tap(() => this.resetList()),
        startWith(undefined)
      )
    ])
      .pipe(
        debounceTime(this.dataFetchDebounceTime ?? 300),
        filter(() => this.totalCount === null || this.items.length < this.totalCount),
        switchMap(([dataSource, pageSize, extraParams]) => {
          this.isLoading = true;
          this.isLoading$.next(true);
          return dataSource({...extraParams, ...this.getPaginationParams(pageSize)});
        }),
        tap(() => {
          this.isLoading = false;
        }),
        takeUntil(this.isDestroyed)
      )
      .subscribe(
        pagedResult => {
          this.appendPagedResult(pagedResult);
          this.isLoading$.next(false);
        },
        err => {
          console.error(err);
          this.isLoading$.next(false);
        }
      );
  }

  protected appendPagedResult(pagedResult: PagedResult<T>): void {
    this.items = [...this.items, ...pagedResult.data];
    this.totalCount = pagedResult.total;
    this.newListItems.emit(pagedResult.data);
  }

  onScroll(): void {
    this.scrolled$.next();
  }

  public loadMore(): void {
    this.onScroll();
  }

  public refreshList(): void {
    this.refresh$.next();
  }

  public refreshFromIndex(index: number): void {
    this.items = this.items.slice(0, index);
    this.scrolled$.next();
  }

  protected resetList(): void {
    this.items = [];
    this.totalCount = null;
  }

  private getPaginationParams(pageSize: number): PaginationParams {
    return {
      pageSize,
      skip: this.items.length
    };
  }
}
