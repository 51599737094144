import {HttpClient} from "@angular/common/http";
import {Inject, Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {GoogleUser} from "fello-model";
import {FELLO_API_SERVICE_BASE_URL} from "../fello-api/fello-api.service";
import {loginWithGoogle} from "../../utils";

@Injectable({providedIn: "root"})
export class FelloAuthAPIService {
  constructor(private http: HttpClient, @Inject(FELLO_API_SERVICE_BASE_URL) private baseUrl: string) {}

  loginWithGoogle(): Observable<GoogleUser | null> {
    return loginWithGoogle(`${this.baseUrl}/fello/auth/google`);
  }
}
