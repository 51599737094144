export class UrlUtils {
  public static updateSubdomain(url: string, newSubdomain: string): string {
    const currentUrl = new URL(url);
    const hostname = currentUrl.hostname;
    const parts = hostname.split(".");
    if (parts.length >= 3) {
      const currentSubdomain = parts.slice(0, parts.length - 2).join(".");
      const domain = `${parts[parts.length - 2]}.${parts[parts.length - 1]}`;
      if (currentSubdomain !== newSubdomain) {
        currentUrl.hostname = `${newSubdomain}.${domain}`;
        return currentUrl.toString();
      }
    }
    return url;
  }

  public static getSubdomain(url: string): string {
    const currentUrl = new URL(url);
    const hostname = currentUrl.hostname;
    const parts = hostname.split(".");
    const currentSubdomain = parts.slice(0, parts.length - 2).join(".");
    return currentSubdomain;
  }
}
