import {Component, OnInit} from "@angular/core";
import {AbstractPublicLandingPageService} from "../../services";
import {DestroyableBase} from "../../../../lib";
import {takeUntil, tap} from "rxjs/operators";
import {LandingPageLayoutType, LandingPageSettingsResponse} from "fello-model";

@Component({
  selector: "lib-landing-page-journey-template",
  templateUrl: "./landing-page-journey-template.component.html",
  styleUrls: ["./landing-page-journey-template.component.scss"]
})
export class LandingPageJourneyTemplateComponent extends DestroyableBase implements OnInit {
  landingPageSetting?: LandingPageSettingsResponse<LandingPageLayoutType.JOURNEY>;
  get websiteURLValue(): string {
    return this.landingPageSetting?.website?.replace(/^(https?:\/\/)?(www\.)?/i, "").split("?")[0] ?? "";
  }
  constructor(private publicLandingPageService: AbstractPublicLandingPageService<LandingPageLayoutType.JOURNEY>) {
    super();
  }

  ngOnInit(): void {
    this.publicLandingPageService.landingPagePublicSetting$
      .pipe(
        takeUntil(this.isDestroyed),
        tap(landingPageSetting => (this.landingPageSetting = landingPageSetting))
      )
      .subscribe();
  }
}
