<mat-dialog-content>
    <div class="svg-wrapper">
        <svg width="70" height="70" viewBox="0 0 70 70" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M32.0833 61.25H23.3333C15.2792 61.25 8.75 54.7208 8.75 46.6667V23.3333C8.75 15.2792 15.2792 8.75 23.3333 8.75H46.6667C54.7208 8.75 61.25 15.2792 61.25 23.3333V32.0833"
                stroke="var(--text-color)" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
            <ellipse cx="52.5007" cy="52.5002" rx="11.6667" ry="11.6667" fill="#55A83A" stroke="#55A83A" stroke-width="2.6"
                stroke-linecap="round" stroke-linejoin="round" />
            <path d="M48.8574 52.1702L51.7741 55.0869L56.1491 50.7119" stroke="white" stroke-width="3"
                stroke-linecap="round" stroke-linejoin="round" />
            <path
                d="M26.2507 45.2083C24.6398 45.2083 23.334 43.9025 23.334 42.2916V31.9711C23.334 30.642 23.9382 29.385 24.9761 28.5548L32.1277 22.8337C33.7255 21.5554 35.9959 21.5554 37.5937 22.8337L45.0252 28.7787C46.0631 29.609 46.6673 30.866 46.6673 32.1952"
                stroke="var(--text-color)" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M33.5417 45.2083H26.25" stroke="var(--text-color)" stroke-width="3" stroke-linecap="round"
                stroke-linejoin="round" />
            <path d="M46.6673 32.195V33.4297" stroke="var(--text-color)" stroke-width="3" stroke-linecap="round"
                stroke-linejoin="round" />
        </svg>
    </div>
    <h2 mat-dialog-title class="fw-700 mb-3">{{successDialogData.title}}</h2>
    <p *ngIf="successDialogData.desc">{{successDialogData.desc}}</p>
</mat-dialog-content>
<mat-dialog-actions class="mt-3">
    <button class="w-100" mat-dialog-close>{{successDialogData.buttonText}}</button>
</mat-dialog-actions>