<ngx-spinner [name]="spinnerName" [fullScreen]="true"></ngx-spinner>

<ng-container *libIsPlatformBrowser>
  <div class="db-card">
    <div>
      <p class="text-size-20 fw-600" *ngIf="moduleSetting.heading">{{ moduleSetting.heading }}</p>
      <p class="text-size-14 fw-500 text-color-light-2 mt-1" *ngIf="moduleSetting.subheading">{{ moduleSetting.subheading }}</p>
    </div>
    <button
      class="whitespace-nowrap ml-auto button-mid"
      libDashboardTrackButtonClick
      (click)="performCTAAction(moduleSetting.ctaButton.action)"
    >
      {{ moduleSetting.ctaButton.label }}
    </button>
  </div>
</ng-container>
