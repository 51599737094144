import {Inject, Injectable, PLATFORM_ID} from "@angular/core";
import {DOCUMENT, isPlatformServer} from "@angular/common";

@Injectable({
  providedIn: "root"
})
export class TrackingCodeManagerService {
  // eslint-disable-next-line @typescript-eslint/ban-types
  constructor(@Inject(DOCUMENT) private document: Document, @Inject(PLATFORM_ID) private platformId: Object) {}

  addTrackingCode(code?: string) {
    if (isPlatformServer(this.platformId)) {
      if (code) {
        this.document.head.innerHTML = `${this.document.head.innerHTML} \n ${code}\n`;
      }
    }
  }
}
