import {Pipe, PipeTransform} from "@angular/core";

@Pipe({
  name: "replaceString",
  standalone: true
})
export class ReplaceStringPipe implements PipeTransform {
  transform(value: string, search: RegExp, replacement: string): string {
    return value ? value.replace(new RegExp(search, "g"), replacement) : value;
  }
}
