export enum EmailCRMMappingCreationErrorCode {
  EMAIL_ADDRESS_INVALID = "EMAIL_ADDRESS_INVALID",
  CRM_ID_MISSING = "CRM_ID_MISSING",
  CRM_NAME_INVALID = "CRM_NAME_INVALID"
}
export interface EmailCRMMappingCreationError {
  code: EmailCRMMappingCreationErrorCode;
  message: string;
}
export interface EmailCRMMappingCreation extends EmailCRMMapping {
  error?: EmailCRMMappingCreationError;
}
export interface EmailCRMMapping {
  emailId: string;
  crmId: string;
  crmAgentName?: string;
  crmEmailId?: string;
  crmName?: CRMName;
  crmSubdomain?: string;
}

export interface AgentCrmPlatformPresenceInfo {
  isOnPlatform: boolean;
  crmAgentName?: string;
  crmAgentEmailId?: string;
  crmName?: string;
}

export enum CRMName {
  SIERRA = "SIERRA",
  FOLLOWUP_BOSS = "FOLLOWUP_BOSS",
  BRIVITY = "BRIVITY"
}
export const CRMNameLabel: Record<CRMName, string> = {
  [CRMName.SIERRA]: "Sierra",
  [CRMName.FOLLOWUP_BOSS]: "Followup Boss",
  [CRMName.BRIVITY]: "Brivity"
};
