<div class="db-card" *libIsPlatformBrowser>
  <div>
    <p class="text-size-20 fw-600" *ngIf="moduleSetting.heading">{{ moduleSetting.heading }}</p>
    <p class="text-size-14 fw-500 text-color-light-2 mt-1" *ngIf="moduleSetting.subheading">{{ moduleSetting.subheading }}</p>
  </div>
  <iframe [srcdoc]="iframeSrc | safeHtml" frameborder="0" libAutoResize class="mt-3"></iframe>
  <button
    class="whitespace-nowrap ml-auto button-mid mt-3"
    [ngClass]="{'button-primary': moduleSetting.ctaButton?.type === DashboardModuleButtonType.SECONDARY}"
    libDashboardTrackButtonClick
    *ngIf="moduleSetting.showCTAButton"
    (click)="performCTAAction(moduleSetting.ctaButton!.action)"
  >
    {{ moduleSetting.ctaButton!.label }}
  </button>
</div>
