import {Directive, EventEmitter, OnInit, Output, TemplateRef, ViewContainerRef} from "@angular/core";
import {Observable, of, ReplaySubject} from "rxjs";
import {NgxSkeletonLoaderConfig} from "ngx-skeleton-loader/lib/ngx-skeleton-loader-config.types";
import {switchMap, takeUntil} from "rxjs/operators";
import {NgxSkeletonLoaderComponent} from "ngx-skeleton-loader";
import {merge} from "lodash-es";
import {mixinDestroyable} from "../../../lib";

export interface IdToObjDirectiveContext<T> {
  $implicit: T;
}

@Directive({})
export abstract class IdToObjDirective<T> extends mixinDestroyable(class {}) implements OnInit {
  private idSubject = new ReplaySubject<string | null | undefined>();
  private _isLoading = false;

  @Output() loadingChange = new EventEmitter<boolean>();
  private _loaderTemplate?: TemplateRef<unknown>;
  private _loaderConfig: Partial<NgxSkeletonLoaderConfig>;

  public obj: T | null = null;

  get isLoading(): boolean {
    return this._isLoading;
  }

  set isLoading(value: boolean) {
    this._isLoading = value;
    this.loadingChange.emit(value);
  }

  set id(agentId: string | null | undefined) {
    this.idSubject.next(agentId);
  }

  get loaderTemplate(): TemplateRef<unknown> | undefined {
    return this._loaderTemplate;
  }

  set loaderTemplate(value: TemplateRef<unknown> | undefined) {
    this._loaderTemplate = value;
    this.updateLoadingView();
  }

  get loaderConfig(): Partial<NgxSkeletonLoaderConfig> {
    return this._loaderConfig;
  }

  set loaderConfig(value: Partial<NgxSkeletonLoaderConfig>) {
    this._loaderConfig = value;
    this.updateLoadingView();
  }

  constructor(private templateRef: TemplateRef<any>, private viewContainer: ViewContainerRef) {
    super();
  }

  ngOnInit(): void {
    this.idSubject
      .pipe(
        takeUntil(this.isDestroyed),
        switchMap(id => {
          if (id) {
            this.isLoading = true;
            this.updateLoadingView();
            return this.getObj(id);
          }
          return of(null);
        })
      )
      .subscribe(
        obj => {
          this.obj = obj;
          this.isLoading = false;
          this.viewContainer.clear();
          if (obj) {
            this.viewContainer.createEmbeddedView(this.templateRef, {$implicit: obj});
          }
        },
        () => {
          this.isLoading = false;
          this.viewContainer.clear();
        }
      );
  }

  private updateLoadingView(): void {
    if (this.isLoading) {
      this.viewContainer.clear();
      if (this.loaderTemplate) {
        this.viewContainer.createEmbeddedView(this.loaderTemplate);
      } else {
        const componentRef = this.viewContainer.createComponent(NgxSkeletonLoaderComponent);
        merge(componentRef.instance, this.loaderConfig);
      }
    }
  }

  abstract getObj(id: string): Observable<T | null>;
}
