export enum AgentDeleteContactActionType {
  RE_ASSIGN = "RE_ASSIGN",
  UN_ASSIGN = "UN_ASSIGN",
  DELETE = "DELETE"
}

export const AgentDeleteContactActionTypeLabel: Record<AgentDeleteContactActionType, string> = {
  [AgentDeleteContactActionType.RE_ASSIGN]: "Reassign to Another Agent",
  [AgentDeleteContactActionType.UN_ASSIGN]: "Keep Contacts Unassigned",
  [AgentDeleteContactActionType.DELETE]: "Delete Contacts"
};

export interface AgentDeleteRequest {
  toAssignAgentId: string | null;
  contactActionType: AgentDeleteContactActionType;
}
