import {FQLFieldExpr} from "../../../mvc";
import {DashboardModuleButton} from "../DashboardModuleButton";
import {BaseDashboardModuleSettings} from "../BaseDashboardModuleSettings";

export enum SellingOptionType {
  CUSTOM_OPTION_1 = "CUSTOM_OPTION_1",
  CUSTOM_OPTION_2 = "CUSTOM_OPTION_2",
  HOMEWARD = "HOMEWARD"
}
export type CustomSellingOptionType = SellingOptionType.CUSTOM_OPTION_1 | SellingOptionType.CUSTOM_OPTION_2;
export enum AvmDisplayValueType {
  VALUE = "VALUE",
  RANGE = "RANGE"
}

export interface BaseOptionsModuleOption {
  seq: number;
  sellingOptionType: SellingOptionType;
  enabled: boolean;
  filters?: FQLFieldExpr;
  showAvm: boolean;
  avmDisplayValueType: AvmDisplayValueType;
  avmPriceAdjustmentPercentage: number;
  ctaButton: DashboardModuleButton;
  subheading: string;
  showSubtext: boolean;
  subText?: string;
}
export interface SellingOptionsDashboardModuleSettings extends BaseDashboardModuleSettings {
  title: string;
  showSubheading: boolean;
  subheading?: string;
  customSellingOption1: CustomSellingOptionsModuleOption;
  customSellingOption2: CustomSellingOptionsModuleOption;
  homeward: HomewardSellingOptionsModuleOption;
  showDisclaimer: boolean;
  disclaimerMessage?: string;
}

export type CustomSellingOptionsModuleOption = BaseOptionsModuleOption;

export type HomewardSellingOptionsModuleOption = BaseOptionsModuleOption;

interface SellingOptionTypeToSettingMap {
  [SellingOptionType.CUSTOM_OPTION_1]: CustomSellingOptionsModuleOption;
  [SellingOptionType.CUSTOM_OPTION_2]: CustomSellingOptionsModuleOption;
  [SellingOptionType.HOMEWARD]: HomewardSellingOptionsModuleOption;
}

export const SellingOptionTypeToKeyMap: Record<SellingOptionType, keyof SellingOptionsDashboardModuleSettings> = {
  [SellingOptionType.CUSTOM_OPTION_1]: "customSellingOption1",
  [SellingOptionType.CUSTOM_OPTION_2]: "customSellingOption2",
  [SellingOptionType.HOMEWARD]: "homeward"
};

export type AvailableSellingOptionTypes = keyof SellingOptionTypeToSettingMap;
export type SellingOptionSetting<OptionType extends AvailableSellingOptionTypes> = SellingOptionTypeToSettingMap[OptionType];

export interface SellingOptionAvm {
  high: number;
  low: number;
  median: number;
}

interface HomewardPrimaryAvm {
  high: number;
  low: number;
  median: number;
}

export interface CustomSellingOptionData {
  avm: SellingOptionAvm | null;
}

export interface HomewardSellingOptionData {
  avm: HomewardPrimaryAvm | null;
  isPrelimOfferRequested: boolean;
}

interface SellingOptionTypeToDataMap {
  [SellingOptionType.CUSTOM_OPTION_1]: CustomSellingOptionData;
  [SellingOptionType.CUSTOM_OPTION_2]: CustomSellingOptionData;
  [SellingOptionType.HOMEWARD]: HomewardSellingOptionData;
}

export type AvailableSellingOptions = keyof SellingOptionTypeToDataMap;
export type SellingOptionsData<OptionType extends AvailableSellingOptions> = SellingOptionTypeToDataMap[OptionType];

export interface SellingOptionWrapper<T extends SellingOptionType> {
  sellingOptionType: T;
  data: SellingOptionsData<T>;
}

export interface SellingOptionsResponse {
  customSellingOption1?: SellingOptionWrapper<SellingOptionType.CUSTOM_OPTION_1>;
  customSellingOption2?: SellingOptionWrapper<SellingOptionType.CUSTOM_OPTION_2>;
  homeward?: SellingOptionWrapper<SellingOptionType.HOMEWARD>;
}
