import {Inject, Injectable, InjectionToken} from "@angular/core";
import {Observable, Observer, of} from "rxjs";
import {delay, map, retryWhen, switchMap, tap} from "rxjs/operators";
import {HttpClient, HttpEvent, HttpEventType} from "@angular/common/http";
import {cacheResponse} from "../../utils";
import {ImageMeta, MediaType, MediaWithType, UploadMediaResponse} from "fello-model";
import LRUCache from "lru-cache";
import {StateTransferHelperService} from "../../../modules/fello-ui-lib/services/state-transfer-helper.service";
import {saveAs} from "file-saver";

export const MEDIA_SERVICE_BASE_URL = new InjectionToken<string>("MEDIA_SERVICE_BASE_URL");

@Injectable({
  providedIn: "root"
})
export class MediaService {
  private cache = new LRUCache<string, string>({
    max: 1000,
    ttl: 1000 * 60 * 60 * 24 * 365,
    updateAgeOnGet: false
  });

  constructor(
    private http: HttpClient,
    @Inject(MEDIA_SERVICE_BASE_URL) private baseUrl: string,
    private stateTransferHelperService: StateTransferHelperService
  ) {}

  @cacheResponse
  private resolveMedia(mediaId: string): Observable<{
    accessUrl: string;
  }> {
    return this.http.post<{
      accessUrl: string;
    }>(`${this.baseUrl}/media/get`, {
      mediaId
    });
  }

  setMediaAccessUrl(mediaId: string, accessURL: string): void {
    this.cache.set(mediaId, accessURL);
  }

  getMediaAccessURL(mediaId: string): Observable<string> {
    const accessURL = this.cache.get(mediaId);
    if (accessURL) {
      return of(accessURL);
    }
    return this.stateTransferHelperService.transferOnce(this.resolveMedia(mediaId), `media:${mediaId}`).pipe(
      map(({accessUrl}) => accessUrl),
      tap(accessUrl => this.setMediaAccessUrl(mediaId, accessUrl))
    );
  }

  getAccessURL(media: MediaWithType): Observable<string> {
    if (media.type === MediaType.LINK) {
      return of(media.value);
    }
    return this.getMediaAccessURL(media.value);
  }
  uploadMedia(
    mediaFile: File,
    getUploadMediaResponse: () => Observable<UploadMediaResponse>
  ): Observable<{event: HttpEvent<unknown>; mediaId: string; accessUrl: string}> {
    return getUploadMediaResponse().pipe(
      retryWhen(err => err.pipe(delay(3000))),
      switchMap(({uploadUrl, accessUrl, mediaId}) => {
        return this.http.put(uploadUrl, mediaFile, {reportProgress: true, observe: "events"}).pipe(
          tap(event => {
            if (event.type === HttpEventType.Response) {
              this.setMediaAccessUrl(mediaId, accessUrl);
            }
          }),
          map(event => ({event, mediaId, accessUrl}))
        );
      })
    );
  }

  downloadMedia(accessUrl: string, fileName: string | undefined): Observable<unknown> {
    return this.http.get(accessUrl, {responseType: "blob"}).pipe(tap(resp => saveAs(resp, (fileName || "Fello_Connect") + ".csv")));
  }

  getImageMetadata(media: MediaWithType): Observable<ImageMeta> {
    return this.getAccessURL(media).pipe(
      switchMap(
        url =>
          new Observable((observer: Observer<ImageMeta>) => {
            const img = new Image();
            img.src = url;
            img.onload = () => {
              observer.next({width: img.width, height: img.height});
              observer.complete();
            };
            img.onerror = err => {
              observer.error(err);
            };
          })
      )
    );
  }
}
