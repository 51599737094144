import {NgModule} from "@angular/core";
import {AgentSignatureComponent} from "./components/agent-signature/agent-signature.component";
import {CommonModule} from "@angular/common";
import {FelloUiUtilsModule} from "../fello-ui-utils";
import {LenderDetailsComponent} from "./components/lender-details/lender-details.component";

@NgModule({
  declarations: [AgentSignatureComponent, LenderDetailsComponent],
  imports: [CommonModule, FelloUiUtilsModule],
  exports: [AgentSignatureComponent, LenderDetailsComponent],
  providers: []
})
export class SignatureModule {}
