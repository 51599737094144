import {Injectable} from "@angular/core";
import {ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot} from "@angular/router";
import {NEVER, Observable, throwError} from "rxjs";
import {AgentContactDashboardApiService} from "fello-common";
import {UnsubscribePageSettingResponse} from "fello-model";
import {catchError} from "rxjs/operators";
import {HttpErrorResponse} from "@angular/common/http";

@Injectable()
export class UnsubscribeEmailResolver implements Resolve<UnsubscribePageSettingResponse> {
  constructor(private agentContactApiService: AgentContactDashboardApiService, private router: Router) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<UnsubscribePageSettingResponse> {
    return this.agentContactApiService.findUnsubscribeEmailDetails(route.paramMap.get("jobId")).pipe(
      catchError((err: HttpErrorResponse) => {
        if (err.status === 404) {
          this.router.navigate(["/not-found"]);
          return NEVER;
        }
        return throwError(err);
      })
    );
  }
}
