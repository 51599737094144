import {Component, OnInit, ViewContainerRef} from "@angular/core";
import {MatDialog} from "@angular/material/dialog";
import {OfferDetailsComponent} from "./offer-details/offer-details.component";
import {AgentCashOffer, AgentCashOfferWrapper, AgentListingOffer, CashOfferType} from "fello-model";
import {takeUntil} from "rxjs/operators";
import {DestroyableBase} from "../../../../lib";
import {isEmpty} from "lodash-es";
import {ActivatedRoute, Router} from "@angular/router";
import moment from "moment";
import {AbstractDashboardService} from "../../services";

@Component({
  selector: "lib-cash-offer-list",
  templateUrl: "./cash-offer-list.component.html",
  styleUrls: ["./cash-offer-list.component.scss"]
})
export class CashOfferListComponent extends DestroyableBase implements OnInit {
  offer: AgentCashOfferWrapper;
  CashOfferType = CashOfferType;

  isLoading = true;

  constructor(
    private dashboardService: AbstractDashboardService,
    private dialog: MatDialog,
    private router: Router,
    private activateRoute: ActivatedRoute,
    private viewContainerRef: ViewContainerRef
  ) {
    super();
  }

  ngOnInit(): void {
    this.dashboardService.offer$.pipe(takeUntil(this.isDestroyed)).subscribe(offerWrapper => {
      this.offer = offerWrapper;
      this.isLoading = false;
      if (!offerWrapper.listingOffer && isEmpty(offerWrapper.offers)) {
        this.router.navigate(["../"], {
          skipLocationChange: false,
          relativeTo: this.activateRoute
        });
      }
    });
  }

  openCashOfferDetails(offer: AgentCashOffer) {
    this.dialog.open(OfferDetailsComponent, {
      viewContainerRef: this.viewContainerRef,
      panelClass: ["mobile-bottom-sheet"],
      data: {
        viewContainerRef: this.viewContainerRef,
        cashOffer: offer
      }
    });
  }

  openListingOfferDetails(offer: AgentListingOffer) {
    this.dialog.open(OfferDetailsComponent, {
      viewContainerRef: this.viewContainerRef,
      panelClass: ["mobile-bottom-sheet"],
      data: {
        viewContainerRef: this.viewContainerRef,
        listingOffer: offer
      }
    });
  }

  expiryInDays(expiry: string): number {
    return moment(expiry).diff(moment(), "days");
  }
}
