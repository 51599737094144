import {Inject, Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {Observable, of, switchMap, throwError} from "rxjs";
import {
  AddressComponent,
  AgentContactProperty,
  ContactDashboardSettingsResponse,
  HomeValueFromDashboardRequest,
  UnsubscribePageSettingResponse
} from "fello-model";
import {AGENT_API_SERVICE_BASE_URL} from "../agent";

@Injectable({
  providedIn: "root"
})
export class AgentContactDashboardApiService {
  baseUrl: string;

  constructor(private http: HttpClient, @Inject(AGENT_API_SERVICE_BASE_URL) private agentBaseUrl: string) {
    this.baseUrl = `${this.agentBaseUrl}/agent/contact-dashboard`;
  }

  getContactDashboardSettings(contactId: string): Observable<ContactDashboardSettingsResponse> {
    return this.http.get<ContactDashboardSettingsResponse>(`${this.baseUrl}/${contactId}/settings`);
  }

  validateAddress(contactId: string, rawAddress: string): Observable<AddressComponent | null> {
    return this.http
      .post<AddressComponent | null>(`${this.baseUrl}/${contactId}/validate-address`, {
        rawAddress
      })
      .pipe(
        switchMap(addr => {
          if (!addr) {
            return throwError(addr);
          }
          return of(addr);
        })
      );
  }

  addAddress(
    contactId: string,
    rawAddress: string,
    hvRequestFromDashboard: HomeValueFromDashboardRequest
  ): Observable<AgentContactProperty> {
    return this.http.post<AgentContactProperty>(`${this.baseUrl}/${contactId}/add-address`, {
      rawAddress,
      hvRequestFromDashboard
    });
  }

  addAddressToDashboard(
    propertyId: string,
    rawAddress: string,
    hvRequestFromDashboard: HomeValueFromDashboardRequest
  ): Observable<AgentContactProperty> {
    return this.http.post<AgentContactProperty>(`${this.baseUrl}/dashboard/${propertyId}/add-address`, {
      rawAddress,
      hvRequestFromDashboard
    });
  }

  validateAddressDirect(rawAddress: string): Observable<AddressComponent | null> {
    return this.http
      .post<AddressComponent | null>(`${this.baseUrl}/validate-address`, {
        rawAddress
      })
      .pipe(
        switchMap(addr => {
          if (!addr) {
            return throwError(addr);
          }
          return of(addr);
        })
      );
  }

  claimAddressFromDashboard(propertyId: string, hvRequestFromDashboard: HomeValueFromDashboardRequest): Observable<void> {
    return this.http.post<void>(`${this.baseUrl}/dashboard/${propertyId}/claim`, {
      hvRequestFromDashboard
    });
  }

  unClaimAddressFromDashboard(propertyId: string): Observable<void> {
    return this.http.post<void>(`${this.baseUrl}/dashboard/${propertyId}/un-claim`, {});
  }

  unClaimAddressAndAddAddressToDashboard(
    propertyId: string,
    rawAddress: string,
    hvRequestFromDashboard: HomeValueFromDashboardRequest
  ): Observable<AgentContactProperty> {
    return this.http.post<AgentContactProperty>(`${this.baseUrl}/dashboard/${propertyId}/un-claim-and-add-new`, {
      rawAddress,
      hvRequestFromDashboard
    });
  }
  addEmailAndAddressFromDashboard(
    propertyId: string,
    rawAddress: string,
    hvRequestFromDashboard: HomeValueFromDashboardRequest
  ): Observable<AgentContactProperty> {
    return this.http.post<AgentContactProperty>(`${this.baseUrl}/dashboard/${propertyId}/add-email-and-address`, {
      rawAddress,
      hvAndEmailRequestFromDashboard: hvRequestFromDashboard
    });
  }
  findUnsubscribeEmailDetails(jobId: string | null): Observable<UnsubscribePageSettingResponse> {
    return this.http.get<UnsubscribePageSettingResponse>(`${this.baseUrl}/unsubscribe/${jobId}`);
  }
}
