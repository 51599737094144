import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  forwardRef,
  HostListener,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
  ViewContainerRef
} from "@angular/core";
import {MatDialog} from "@angular/material/dialog";
import {startWith, switchMap, take, takeUntil, tap} from "rxjs/operators";
import {merge} from "rxjs";
import {AgentSignatureType, DashboardModuleSetting, DashboardModuleType, FelloUserContactDetails} from "fello-model";
import {HEADER_HEIGHT} from "../dashboard-wrapper-contants";
import {mixinDestroyable} from "../../../../../lib";
import {DashboardItemDirective} from "../../../directives";
import {DashboardService} from "../../../dashboard.service";
import {AbstractDashboardService, DashboardSettingsService} from "../../../services";
import {DASHBOARD_VIEW_TYPE, DashboardViewType} from "../../../tokens";
import {ContactAgentComponent} from "../../dialogs";

@Component({
  selector: "lib-offers-dashboard-wrapper",
  templateUrl: "./offers-dashboard-wrapper.component.html",
  styleUrls: ["./offers-dashboard-wrapper.component.scss", "../dashboard-wrapper.component.scss"],
  providers: [
    DashboardService,
    {
      provide: AbstractDashboardService,
      useExisting: forwardRef(() => DashboardService)
    },
    {provide: DASHBOARD_VIEW_TYPE, useValue: DashboardViewType.HOME_OWNER},
    DashboardSettingsService
  ]
})
export class OffersDashboardWrapperComponent extends mixinDestroyable(class {}) implements AfterViewInit, OnInit {
  agentSignature$ = this.dashboardService.agentSignature$;
  AgentSignatureType = AgentSignatureType;
  moduleSettings: DashboardModuleSetting<DashboardModuleType.HOME_DETAILS>;

  @ViewChildren(DashboardItemDirective) listItems: QueryList<DashboardItemDirective>;
  @ViewChild("scrollingContainer") container: ElementRef;

  constructor(
    private cd: ChangeDetectorRef,
    private dashboardService: AbstractDashboardService,
    private dialog: MatDialog,
    private viewContainerRef: ViewContainerRef,
    private dashboardSettingsService: DashboardSettingsService
  ) {
    super();
  }

  ngOnInit() {
    this.dashboardSettingsService.settings$.pipe(takeUntil(this.isDestroyed)).subscribe(settings => {
      this.moduleSettings = (settings?.config.modules.find(module => module.moduleType === DashboardModuleType.HOME_DETAILS) || {})
        .settings as unknown as DashboardModuleSetting<DashboardModuleType.HOME_DETAILS>;
    });
  }

  ngAfterViewInit(): void {
    this.listItems.changes
      .pipe(
        takeUntil(this.isDestroyed),
        startWith(null),
        switchMap(() => merge(...this.listItems.map(item => item.visibilityChanged)))
      )
      .subscribe(
        v => {
          this.updateItemActiveStatus();
        },
        v => {
          this.updateItemActiveStatus();
        }
      );
    this.cd.detectChanges();
  }

  goToItem(item: DashboardItemDirective): void {
    setTimeout(() => {
      if (item.element) {
        this.container.nativeElement.scrollTo({behavior: "smooth", top: item.offsetTop - HEADER_HEIGHT});
        item.element.classList.add("animate__bounce");
        setTimeout(() => {
          item.element.classList.remove("animate__bounce");
        }, 1000);
      }
    }, 100);
  }

  elementInViewport(el: HTMLElement | null): boolean {
    if (el) {
      let top = el.offsetTop;
      const height = el.offsetHeight;

      while (el.offsetParent) {
        el = el.offsetParent as HTMLElement;
        top += el.offsetTop;
      }

      return top < window.pageYOffset + window.innerHeight && top + height > window.pageYOffset + HEADER_HEIGHT;
    }
    return false;
  }

  @HostListener("window:scroll")
  scrollHandler(): void {
    this.updateItemActiveStatus();
  }

  private updateItemActiveStatus(): void {
    if (this.listItems) {
      const itemsInViewport = this.listItems.filter(item => {
        item.active = false;
        return this.elementInViewport(item.element) && item.isVisible;
      });
      if (itemsInViewport.length) {
        itemsInViewport[0].active = true;
      }
    }
    this.cd.detectChanges();
  }

  openContactAgent() {
    this.dashboardService.contact$
      .pipe(
        take(1),
        tap(contact => {
          this.dialog.open(ContactAgentComponent, {
            maxWidth: 580,
            data: {
              fullName: contact.fullName,
              phone: contact.phone,
              email: contact.emailId
            } as FelloUserContactDetails,
            viewContainerRef: this.viewContainerRef
          });
        })
      )
      .subscribe();
  }
}
