import {AgencyAlert, AgencyAlertType} from "./AgencyAlert";

export enum AgencyBannerType {
  AUTO_RECHARGE_FAILURE = "AUTO_RECHARGE_FAILURE",
  FUTURE_START_DATE = "FUTURE_START_DATE",
  PAYMENT_DUE = "PAYMENT_DUE",
  MARKETING_CONTACT_LIMIT_WARNING = "MARKETING_CONTACT_LIMIT_WARNING",
  MARKETING_CONTACT_LIMIT_CRITICAL = "MARKETING_CONTACT_LIMIT_CRITICAL",
  MARKETING_CONTACT_LIMIT_EXCEEDED = "MARKETING_CONTACT_LIMIT_EXCEEDED"
}

export type MARKETING_CONTACT_LIMIT_BANNER =
  | AgencyBannerType.MARKETING_CONTACT_LIMIT_CRITICAL
  | AgencyBannerType.MARKETING_CONTACT_LIMIT_WARNING
  | AgencyBannerType.MARKETING_CONTACT_LIMIT_EXCEEDED;

export const AgencyBannerToAlertTypeMap: Record<AgencyBannerType, AgencyAlertType> = {
  [AgencyBannerType.FUTURE_START_DATE]: AgencyAlertType.FUTURE_START_DATE,
  [AgencyBannerType.PAYMENT_DUE]: AgencyAlertType.PAYMENT_DUE,
  [AgencyBannerType.AUTO_RECHARGE_FAILURE]: AgencyAlertType.AUTO_RECHARGE_FAILURE,
  [AgencyBannerType.MARKETING_CONTACT_LIMIT_WARNING]: AgencyAlertType.MARKETING_CONTACT_LIMIT_WARNING,
  [AgencyBannerType.MARKETING_CONTACT_LIMIT_CRITICAL]: AgencyAlertType.MARKETING_CONTACT_LIMIT_CRITICAL,
  [AgencyBannerType.MARKETING_CONTACT_LIMIT_EXCEEDED]: AgencyAlertType.MARKETING_CONTACT_LIMIT_EXCEEDED
};

export type AgencyBannerValue<T extends AgencyBannerType> = typeof AgencyBannerToAlertTypeMap[T];

export interface AgencyBanner<T extends AgencyBannerType = AgencyBannerType> {
  bannerType: AgencyBannerType;
  alertData: AgencyAlert<AgencyBannerValue<T>>;
  priority: number;
}

export const AgencyAlertTypeToBannerMap: Partial<Record<AgencyAlertType, AgencyBannerType>> = Object.entries(
  AgencyBannerToAlertTypeMap
).reduce((acc, [bannerType, alertType]) => {
  acc[alertType as AgencyAlertType] = bannerType as AgencyBannerType;
  return acc;
}, {} as {[key in AgencyAlertType]: AgencyBannerType});
