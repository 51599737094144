export enum OfferStatus {
  PRELIM_OFFER_IN_PROGRESS = "PRELIM_OFFER_IN_PROGRESS",
  PRELIM_OFFER_REVIEW = "PRELIM_OFFER_REVIEW",
  PRELIM_OFFER_READY = "PRELIM_OFFER_READY",
  PRELIM_OFFER_PUBLISH = "PRELIM_OFFER_PUBLISH",
  PRELIM_OFFER_EXPIRED = "PRELIM_OFFER_EXPIRED",
  WALKTHROUGH_IN_PROGRESS = "WALKTHROUGH_IN_PROGRESS",
  FINAL_OFFER_INPROGRESS = "FINAL_OFFER_INPROGRESS",
  FINAL_OFFER_REVIEW = "FINAL_OFFER_REVIEW",
  FINAL_OFFER_READY = "FINAL_OFFER_READY",
  FINAL_OFFER_PUBLISH = "FINAL_OFFER_PUBLISH",
  PA_GENERATION = "PA_GENERATION",
  PA_SIGNING = "PA_SIGNING",
  HA_IN_PROGRESS = "HA_IN_PROGRESS",
  HA_IN_REVIEW = "HA_IN_REVIEW",
  HA_READY = "HA_READY",
  HA_PUBLISHED = "HA_PUBLISHED",
  ROC_GENERATION = "ROC_GENERATION",
  ROC_SIGNING = "ROC_SIGNING",
  CLOSING = "CLOSING",
  CLOSED = "CLOSED",
  NOT_OFFERING = "NOT_OFFERING"
}

export const OfferStatusDisplayLabel: Record<OfferStatus, string> = {
  [OfferStatus.PRELIM_OFFER_IN_PROGRESS]: "Prelim Offer In Progress",
  [OfferStatus.PRELIM_OFFER_REVIEW]: "Prelim Offer In Progress",
  [OfferStatus.PRELIM_OFFER_READY]: "Prelim Offer In Progress",
  [OfferStatus.PRELIM_OFFER_PUBLISH]: "Prelim Offer Published",
  [OfferStatus.PRELIM_OFFER_EXPIRED]: "Prelim Offer Expired",
  [OfferStatus.WALKTHROUGH_IN_PROGRESS]: "Walkthrough In Progress",
  [OfferStatus.FINAL_OFFER_INPROGRESS]: "Final Offer In Progress",
  [OfferStatus.FINAL_OFFER_REVIEW]: "Final Offer In Progress",
  [OfferStatus.FINAL_OFFER_READY]: "Final Offer In Progress",
  [OfferStatus.FINAL_OFFER_PUBLISH]: "Final Offer Published",
  [OfferStatus.PA_GENERATION]: "PA Generation",
  [OfferStatus.PA_SIGNING]: "PA Signing",
  [OfferStatus.HA_IN_PROGRESS]: "HA In Progress",
  [OfferStatus.HA_IN_REVIEW]: "HA In Progress",
  [OfferStatus.HA_READY]: "HA In Progress",
  [OfferStatus.HA_PUBLISHED]: "HA Publish",
  [OfferStatus.ROC_GENERATION]: "ROC Generation",
  [OfferStatus.ROC_SIGNING]: "ROC Signing",
  [OfferStatus.CLOSING]: "Closing",
  [OfferStatus.CLOSED]: "Closed",
  [OfferStatus.NOT_OFFERING]: "Not Offering"
};

export const OfferStatusColor: Record<OfferStatus, string> = {
  [OfferStatus.PRELIM_OFFER_IN_PROGRESS]: "orange",
  [OfferStatus.PRELIM_OFFER_REVIEW]: "orange",
  [OfferStatus.PRELIM_OFFER_READY]: "orange",
  [OfferStatus.PRELIM_OFFER_PUBLISH]: "green",
  [OfferStatus.PRELIM_OFFER_EXPIRED]: "red",
  [OfferStatus.WALKTHROUGH_IN_PROGRESS]: "orange",
  [OfferStatus.FINAL_OFFER_INPROGRESS]: "orange",
  [OfferStatus.FINAL_OFFER_REVIEW]: "orange",
  [OfferStatus.FINAL_OFFER_READY]: "orange",
  [OfferStatus.FINAL_OFFER_PUBLISH]: "green",
  [OfferStatus.PA_GENERATION]: "blue",
  [OfferStatus.PA_SIGNING]: "blue",
  [OfferStatus.HA_IN_PROGRESS]: "orange",
  [OfferStatus.HA_IN_REVIEW]: "orange",
  [OfferStatus.HA_READY]: "orange",
  [OfferStatus.HA_PUBLISHED]: "green",
  [OfferStatus.ROC_GENERATION]: "blue",
  [OfferStatus.ROC_SIGNING]: "blue",
  [OfferStatus.CLOSING]: "green",
  [OfferStatus.CLOSED]: "secondary",
  [OfferStatus.NOT_OFFERING]: "red"
};

const OfferStatusSequence: Record<OfferStatus, number> = {
  [OfferStatus.PRELIM_OFFER_IN_PROGRESS]: 0,
  [OfferStatus.PRELIM_OFFER_REVIEW]: 1,
  [OfferStatus.PRELIM_OFFER_READY]: 2,
  [OfferStatus.PRELIM_OFFER_PUBLISH]: 3,
  [OfferStatus.PRELIM_OFFER_EXPIRED]: 4,
  [OfferStatus.WALKTHROUGH_IN_PROGRESS]: 5,
  [OfferStatus.FINAL_OFFER_INPROGRESS]: 6,
  [OfferStatus.FINAL_OFFER_REVIEW]: 7,
  [OfferStatus.FINAL_OFFER_READY]: 8,
  [OfferStatus.FINAL_OFFER_PUBLISH]: 9,
  [OfferStatus.PA_GENERATION]: 10,
  [OfferStatus.PA_SIGNING]: 11,
  [OfferStatus.HA_IN_PROGRESS]: 12,
  [OfferStatus.HA_IN_REVIEW]: 13,
  [OfferStatus.HA_READY]: 14,
  [OfferStatus.HA_PUBLISHED]: 15,
  [OfferStatus.ROC_GENERATION]: 16,
  [OfferStatus.ROC_SIGNING]: 17,
  [OfferStatus.CLOSING]: 18,
  [OfferStatus.CLOSED]: 19,
  [OfferStatus.NOT_OFFERING]: 20
};

export function isOfferStatusBefore(s1: OfferStatus, s2: OfferStatus): boolean {
  return OfferStatusSequence[s1] < OfferStatusSequence[s2];
}

export function isOfferStatusSameOrBefore(s1: OfferStatus, s2: OfferStatus): boolean {
  return OfferStatusSequence[s1] <= OfferStatusSequence[s2];
}

export function isOfferStatusAfter(s1: OfferStatus, s2: OfferStatus): boolean {
  return OfferStatusSequence[s1] > OfferStatusSequence[s2];
}

export function isOfferStatusSameOrAfter(s1: OfferStatus, s2: OfferStatus): boolean {
  return OfferStatusSequence[s1] >= OfferStatusSequence[s2];
}
