<div class="db-card" *ngIf="this.isLoading">
  <ngx-skeleton-loader count="1" [theme]="{  height: '30px', width: '100%' }"> </ngx-skeleton-loader>
  <div class="db-card-body">
    <ngx-skeleton-loader count="1" [theme]="{  height: '120px', width: '100%' }"> </ngx-skeleton-loader>
    <div class="line-break"></div>
    <ngx-skeleton-loader count="2" [theme]="{  height: '120px', width: '100%' }"> </ngx-skeleton-loader>
  </div>
</div>

<div class="db-card header-highlight" *ngIf="offer">
  <div class="db-card-header">
    <p class="db-card-title flex-box-align-start fw-500 text-size-14 line-height-1">
      <app-icon class="mr-1 print-d-none">info</app-icon>
      Contact your agent to proceed with listing or any offer.
    </p>
  </div>
  <div class="db-card-body">
    <div class="db-card offer" *ngIf="offer.listingOffer; let listingOffer">
      <div class="flex-box-center-between">
        <div class="flex-box-center">
          <lib-icon class="text-color-light-2 mr-2 text-size-20">home-listings</lib-icon>
          <p class="text-size-20 fw-700">{{listingOffer.buyerDisplayName}}</p>
        </div>
        <p class="text-size-20 fw-700">
          <ng-container
            *ngIf="listingOffer.isShowNetOfferOnly ? listingOffer.pricing.netProceeds : listingOffer.pricing.offerPrice; let amount"
          >
            {{listingOffer.pricing.isRange ? (amount | formatPriceRange) : (amount.min | formatPrice)}}
          </ng-container>
        </p>
      </div>
      <div class="flex-box-center-between mt-2">
        <p class="text-size-12 fw-500 mr-3">
          <span class="text-color-light-2" *ngIf="listingOffer.expiresAt | momentIsInFuture"
            >Expires on {{listingOffer.expiresAt | momentFormat:"MMM DD, YY":"date"}} ({{expiryInDays(listingOffer.expiresAt!)}}d)</span
          ><span class="text-color-red" *ngIf="listingOffer.expiresAt | momentIsInPast"
            >Expired on {{listingOffer.expiresAt | momentFormat:"MMM DD, YY":"date"}}</span
          >
        </p>
        <p class="text-size-12 fw-500 text-color-light-2">
          Net: {{listingOffer.pricing.isRange ? (listingOffer.pricing.netProceeds | formatPriceRange) :
          (listingOffer.pricing.netProceeds.min | formatPrice)}}
        </p>
      </div>
      <button class="link text-size-14 fw-500 mt-3" (click)="openListingOfferDetails(listingOffer)">View Offer Details</button>
    </div>
    <div class="line-break" *ngIf="offer.listingOffer && offer.offers.length"></div>
    <div class="db-card offer" *ngFor="let cashOffer of offer.offers">
      <div class="flex-box-center-between">
        <div class="flex-box-center">
          <lib-icon class="text-color-light-2 mr-2 text-size-20">dollar</lib-icon>
          <p class="text-size-20 fw-700">{{cashOffer.buyerDisplayName}}</p>
        </div>
        <p class="text-size-20 fw-700">
          <ng-container *ngIf="cashOffer.isShowNetOfferOnly ? cashOffer.pricing.netProceeds : cashOffer.pricing.offerPrice; let amount">
            {{cashOffer.pricing.isRange ? (amount | formatPriceRange) : (amount.min | formatPrice)}}
          </ng-container>
        </p>
      </div>
      <div class="flex-box-center-between mt-2">
        <p class="text-size-12 fw-500 mr-3">
          <span class="text-color-light-2" *ngIf="cashOffer.expiresAt | momentIsInFuture"
            >Expires on {{cashOffer.expiresAt | momentFormat:"MMM DD, YY":"date"}} ({{expiryInDays(cashOffer.expiresAt!)}}d)</span
          ><span class="text-color-red" *ngIf="cashOffer.expiresAt | momentIsInPast"
            >Expired on {{cashOffer.expiresAt | momentFormat:"MMM DD, YY":"date"}}</span
          >
          · {{cashOffer.agentOfferType! | enumToLabel:'CASH_OFFER_TYPE'}} Offer
        </p>
        <p class="text-size-12 fw-500 text-color-light-2">
          Net: {{ cashOffer.pricing.netProceeds.min | formatPrice }}
          <ng-container *ngIf="cashOffer.pricing.isRange"> - {{ cashOffer.pricing.netProceeds.max! | formatPrice }} </ng-container>
        </p>
      </div>
      <button class="link text-size-14 fw-500 mt-3" (click)="openCashOfferDetails(cashOffer)">View Offer Details</button>
    </div>
    <!--<p class="text-size-14 fw-400 text-color-light-2 mt-3">
      <span class="fw-600 d-block"> Diclaimer: </span>
      Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
      minim veniam Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna
      aliqua. Ut enim ad minim veniam
    </p>-->
  </div>
</div>
