import {Observable, of} from "rxjs";
import {AbstractConstructor, Constructor} from "../types";
import {switchMap, tap} from "rxjs/operators";
import {NgxSpinnerService} from "ngx-spinner";
import {StringUtils} from "../utils";

export interface CanShowSpinner {
  spinnerName: string;
  withSpinner<T>(observable: Observable<T>): Observable<T>;
  showSpinner(): void;
  hideSpinner(): void;
}

export interface HasSpinnerService {
  spinnerService: NgxSpinnerService;
}

type CanShowSpinnerCtor = Constructor<CanShowSpinner> & AbstractConstructor<CanShowSpinner>;

export function mixinSpinner<T extends AbstractConstructor<HasSpinnerService>>(base: T): CanShowSpinnerCtor & T;
export function mixinSpinner<T extends Constructor<HasSpinnerService>>(base: T): CanShowSpinnerCtor & T {
  return class extends base {
    spinnerName = StringUtils.uuid();

    withSpinner<T>(observable: Observable<T>): Observable<T> {
      return of(null).pipe(
        switchMap(() => {
          this.showSpinner();
          return observable;
        }),
        tap(
          () => this.hideSpinner(),
          () => this.hideSpinner()
        )
      );
    }

    showSpinner(): void {
      this.spinnerService.show(this.spinnerName);
    }
    hideSpinner(): void {
      this.spinnerService.hide(this.spinnerName);
    }
  };
}
