import {Component, Inject} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {ChangelogEventType, ContactAgentFromDashboardRequest, FelloUserContactDetails} from "fello-model";
import {MatSnackBar} from "@angular/material/snack-bar";
import {NgxSpinnerService} from "ngx-spinner";
import {FelloWebsites, StringUtils, ToastSpinnerBase} from "../../../../../lib";
import {tap} from "rxjs/operators";
import {phoneNumberPattern} from "../../../../fello-ui-utils";
import {AbstractDashboardService, DashboardSettingsService} from "../../../services";

@Component({
  selector: "lib-contact-agent",
  templateUrl: "./contact-agent.component.html",
  styleUrls: ["./contact-agent.component.scss"]
})
export class ContactAgentComponent extends ToastSpinnerBase {
  data: ContactAgentFromDashboardRequest = {
    firstName: "",
    lastName: "",
    phone: ""
  };
  phoneNumberPattern = phoneNumberPattern;
  settings$ = this.dashboardSettingsService.settings$;
  felloWebsites = FelloWebsites;

  constructor(
    private dialogRef: MatDialogRef<boolean>,
    @Inject(MAT_DIALOG_DATA) inputData: FelloUserContactDetails,
    private dashboardService: AbstractDashboardService,
    snackBar: MatSnackBar,
    spinner: NgxSpinnerService,
    private dashboardSettingsService: DashboardSettingsService
  ) {
    super(spinner, snackBar);
    if (inputData) {
      this.data.firstName = inputData.fullName ? StringUtils.splitFullName(inputData.fullName).first : "";
      this.data.lastName = inputData.fullName ? StringUtils.splitFullName(inputData.fullName).last : "";
      this.data.phone = inputData.phone ?? "";
    }
  }

  trackContactAgentEvent(): void {
    this.withSpinnerAndToast(this.dashboardService.submitContactAgentForm(ChangelogEventType.SELLER_CLICKED_CONTACT_AGENT, this.data), {
      successMsg: "Agent Contact Request Sent",
      failureMsg: "Something went wrong.Please Try Again.",
      snackBarConfig: {duration: 4000}
    })
      .pipe(
        tap(() => {
          this.dialogRef.close();
        })
      )
      .subscribe();
  }
}
