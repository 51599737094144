import {Directive, HostBinding, HostListener, Input} from "@angular/core";
import {SafeResourceUrl} from "@angular/platform-browser";

@Directive({
  selector: "img[libFallbackImage]",
  standalone: true
})
export class FallbackImageDirective {
  @Input()
  @HostBinding("src")
  src: string;
  static ngAcceptInputType_src: string | SafeResourceUrl | null | undefined;

  @Input() libFallbackImage: string;

  @HostListener("error") onError(): void {
    this.src = this.libFallbackImage;
  }
}
