<ngx-skeleton-loader *ngIf="(isLoading$ | async) || isLoading; else loaded" count="1" [theme]="{  height: '150px', width: '100%' }">
</ngx-skeleton-loader>
<ng-template #loaded>
  <ng-container *ngIf="(property$ | async); let property">
    <div *ngIf="contact$ | async;let contact">
      <div class="db-card">
        <div class="db-card-header">
          <p class="db-card-title">Own another home?</p>
          <p class="db-card-desc">Enter the address to view the dashboard</p>
        </div>
        <div class="db-card-body mt-3">
          <div [libTooltip]="disabledAddHomeToolTip" [showToolTip]="contact.properties.length >= 10" [tooltipClass]="'full-width'">
            <input
              type="text"
              [disabled]="contact.properties.length >= 10"
              placeholder="Enter the address"
              class="w-100"
              (focus)="addNewAddress()"
            />
          </div>
          <div class="line-break small"></div>
          <div class="flex-box-lg-between flex-box-direction-column flex-box-gap-2 flex-box-lg-direction-row">
            <p>{{ property.addressComponents.doorNumber }} {{ property.addressComponents.street }}, not your home?</p>
            <button class="link text-size-14 fw-500" (click)="unClaimProperty()">Unclaim this property</button>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</ng-template>

<ng-template #disabledAddHomeToolTip>
  You have reached the maximum limit of 10 addresses.
  <br />
  Please delete an existing address to add a new one
</ng-template>
