import {Component, Input, OnInit} from "@angular/core";
import {find} from "lodash-es";
import {DashboardHomeFactsRequest} from "../DashboardHomeFactsRequest";

export interface HomeFeatureOption {
  name: string;
  value: string;
  selected: boolean;
  reference: "hoa" | "wellWater" | "septicSystem" | "solarPanels" | "swimmingPool";
}

@Component({
  selector: "app-home-details-additional-info",
  templateUrl: "./home-details-additional-info.component.html",
  styleUrls: ["./home-details-additional-info.component.scss"]
})
export class HomeDetailsAdditionalInfoComponent implements OnInit {
  @Input() homeFacts: DashboardHomeFactsRequest;
  homeFeatureOptions: HomeFeatureOption[] = [
    {
      name: "HOA",
      value: "HOA",
      selected: false,
      reference: "hoa"
    },
    {
      name: "Well water",
      value: "Well water",
      selected: false,
      reference: "wellWater"
    },
    {
      name: "Septic system",
      value: "Septic system",
      selected: false,
      reference: "septicSystem"
    },
    {
      name: "Solar panels",
      value: "Solar panels",
      selected: false,
      reference: "solarPanels"
    },
    {
      name: "Swimming pool",
      value: "In-ground pool",
      selected: false,
      reference: "swimmingPool"
    }
  ];

  addValue(option: HomeFeatureOption): void {
    this.homeFacts[option.reference] = option.selected;
  }
  ngOnInit(): void {
    if (this.homeFacts) {
      this.updateFieldValue("HOA", this.homeFacts.hoa);
      this.updateFieldValue("Well water", this.homeFacts.wellWater);
      this.updateFieldValue("Septic system", this.homeFacts.septicSystem);
      this.updateFieldValue("Solar panels", this.homeFacts.solarPanels);
      this.updateFieldValue("Swimming pool", this.homeFacts.swimmingPool);
    }
  }

  updateFieldValue(fieldName: string, value?: boolean): void {
    if (value) {
      const field = find(this.homeFeatureOptions, {name: fieldName})!;
      field.selected = value;
    }
  }

  getFieldValue(fieldName: string): boolean {
    const field = find(this.homeFeatureOptions, {name: fieldName})!;
    return field.selected;
  }
}
