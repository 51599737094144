import {SellerRepairPoint} from "./SellerRepairPoint";

export enum InspectionPointGroupType {
  INTERIOR = "INTERIOR",
  EXTERIOR = "EXTERIOR",
  OTHER = "OTHER"
}

export const InspectionPointGroupTypeLabel: Record<InspectionPointGroupType, string> = {
  [InspectionPointGroupType.INTERIOR]: "Interior",
  [InspectionPointGroupType.EXTERIOR]: "Exterior",
  [InspectionPointGroupType.OTHER]: "Other"
};

export interface SellerRepairPointGroup {
  name: string;
  groupId: string;
  location: string;
  iconName?: string;
  type: InspectionPointGroupType;
  repairPoints: SellerRepairPoint[];
}
