import {Component, Inject, OnInit, ViewContainerRef} from "@angular/core";
import {DestroyableToastBase, FelloWebsites} from "../../../../lib";
import {DashboardModuleButtonAction, LandingPageLayoutType, LandingPageSettingsResponse} from "fello-model";
import {MatSnackBar} from "@angular/material/snack-bar";
import {map, takeUntil, tap} from "rxjs/operators";
import {AbstractPublicLandingPageService} from "../../services";
import {MatDialog} from "@angular/material/dialog";
import {ContactDialogComponent} from "../contact-dialog/contact-dialog.component";
import {ActivatedRoute, Router} from "@angular/router";
import {combineLatest} from "rxjs";
import {DOCUMENT} from "@angular/common";
import {defaultResponsiveStyleSheet, defaultStyleSheet} from "../landing-page-template-a/landing-page-template-a.component";
import {BreakpointObserver} from "@angular/cdk/layout";

@Component({
  selector: "lib-landing-page-tabbed-a",
  templateUrl: "./landing-page-tabbed-a.component.html",
  styleUrls: ["./landing-page-tabbed-a.component.scss", "../landing-page-template-a/landing-page-template-a.component.scss"]
})
export class LandingPageTabbedAComponent extends DestroyableToastBase implements OnInit {
  landingPageSetting?: LandingPageSettingsResponse<LandingPageLayoutType.TABBED_WEBSITE_1>;
  address: string;
  isLoading = false;
  isContactDialogOpen = false;
  private _selectedTabIndex = 0;
  felloWebsites = FelloWebsites;
  isDesktop$ = this.breakpointObserver.observe("(min-width: 992px)").pipe(map(state => state.matches));

  get selectedTabIndex(): number {
    return this._selectedTabIndex;
  }

  set selectedTabIndex(value: number) {
    this._selectedTabIndex = value;
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParamsHandling: "merge",
      queryParams: {t: value + 1}
    });
  }

  constructor(
    private publicLandingPageService: AbstractPublicLandingPageService<LandingPageLayoutType.TABBED_WEBSITE_1>,
    snackBar: MatSnackBar,
    private dialog: MatDialog,
    private breakpointObserver: BreakpointObserver,
    private activatedRoute: ActivatedRoute,
    private vcr: ViewContainerRef,
    private router: Router,
    @Inject(DOCUMENT) private document: Document
  ) {
    super(snackBar);
  }

  ngOnInit(): void {
    combineLatest([this.publicLandingPageService.landingPagePublicSetting$, this.activatedRoute.queryParamMap])
      .pipe(
        takeUntil(this.isDestroyed),
        tap(([landingPageSetting, queryParamMap]) => {
          this.landingPageSetting = landingPageSetting;
          const tabNumber = Number(queryParamMap.get("t"));
          if (!isNaN(tabNumber) && tabNumber > 0 && tabNumber <= this.landingPageSetting.config.layoutSettings.tabs.length) {
            this._selectedTabIndex = tabNumber - 1;
          }
        })
      )
      .subscribe();
  }

  submitForm(action: DashboardModuleButtonAction): void {
    this.isLoading = true;
    this.withToast(this.publicLandingPageService.performCTAAction(action, this.address), {
      failureMsg: "Unable to submit form. please try again later."
    }).subscribe(
      ({url}) => {
        window.location.href = url;
      },
      () => {
        this.isLoading = false;
      }
    );
  }

  openContactBlock(): void {
    this.isContactDialogOpen = true;
    this.dialog
      .open(ContactDialogComponent, {
        data: this.landingPageSetting?.signature,
        panelClass: "contact-block-dialog",
        backdropClass: "opacity-lg-0",
        viewContainerRef: this.vcr
      })
      .afterClosed()
      .pipe(tap(() => (this.isContactDialogOpen = false)))
      .subscribe();
  }

  get iframeSrc(): string {
    const scriptUrl = new URL(this.document.location.href);
    scriptUrl.pathname = "/assets/js/iframeResizer.contentWindow.min.js";
    scriptUrl.search = "";
    scriptUrl.hash = "";

    if (this.landingPageSetting?.config.layoutSettings.videoEmbedCode?.trim().startsWith("<div")) {
      return `
        ${defaultStyleSheet}
        <script src="${scriptUrl.toString()}"></script>
        ${this.landingPageSetting?.config.layoutSettings.videoEmbedCode}
    `;
    }
    return `
    ${defaultResponsiveStyleSheet}
    <script src="${scriptUrl.toString()}"></script>
    <div class="vc">
      ${this.landingPageSetting?.config.layoutSettings.videoEmbedCode}
    </div>
    `;
  }
}
