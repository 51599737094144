import {Component, ElementRef, HostListener, Inject, OnInit, ViewChild} from "@angular/core";
import {AddressComponent, DashboardModuleType} from "fello-model";
import {DashboardModuleDirective} from "../../directives/dashboard-module.directive";
import {DOCUMENT} from "@angular/common";
import {AbstractDashboardService} from "../../services";
import {combineLatest, of, Subject} from "rxjs";
import {catchError, switchMap, takeUntil, tap} from "rxjs/operators";
import {pick} from "lodash-es";
import {mixinDestroyable, mixinSpinnerAndToast, StringUtils} from "../../../../lib";
import {NgxSpinnerService} from "ngx-spinner";
import {MatSnackBar} from "@angular/material/snack-bar";

const HTMLModuleBaseComponent = mixinDestroyable(
  mixinSpinnerAndToast(
    class extends DashboardModuleDirective<DashboardModuleType.HTML> {
      constructor(public spinnerService: NgxSpinnerService, public snackBar: MatSnackBar, elementRef: ElementRef) {
        super(elementRef);
      }
    }
  )
);

@Component({
  selector: "lib-html-module",
  templateUrl: "./html-module.component.html",
  styleUrls: ["./html-module.component.css"]
})
export class HtmlModuleComponent extends HTMLModuleBaseComponent implements OnInit {
  iframeLoadSubject = new Subject<HTMLIFrameElement>();

  @ViewChild("frame") iframe?: ElementRef<HTMLIFrameElement>;

  constructor(
    elementRef: ElementRef,
    @Inject(DOCUMENT) private document: Document,
    private dashboardService: AbstractDashboardService,
    spinnerService: NgxSpinnerService,
    snackBar: MatSnackBar
  ) {
    super(spinnerService, snackBar, elementRef);
  }

  ngOnInit() {
    super.ngOnInit();
    this.iframeLoadSubject
      .pipe(
        takeUntil(this.isDestroyed),
        switchMap(frame => {
          return combineLatest([this.dashboardService.property$, this.dashboardService.contact$, this.dashboardService.homeEstimate$]).pipe(
            takeUntil(this.isDestroyed),
            tap(([property, contact, homeEstimate]) => {
              const {first, last} = StringUtils.splitFullName(contact.fullName);
              this._postMessage("dashboardInfo", {
                address: pick(property.addressComponents, [
                  "secondaryNumber",
                  "secondaryDesignator",
                  "county",
                  "doorNumber",
                  "street",
                  "city",
                  "state",
                  "zip",
                  "fullAddress",
                  "latitude",
                  "longitude"
                ] as (keyof AddressComponent)[]),
                firstName: first,
                lastName: last,
                email: contact.emailId,
                phone: contact.phone,
                propertyId: property.propertyId,
                contactId: contact._id,
                opdLite: property.homeFacts?.opdLite,
                homeValue: homeEstimate?.median
              });
            })
          );
        })
      )
      .subscribe();
  }

  get iframeSrc(): string {
    const scriptUrl = new URL(this.document.location.href);
    scriptUrl.pathname = "/assets/js/iframeResizer.contentWindow.min.js";
    scriptUrl.search = "";
    scriptUrl.hash = "";
    return `
    <script src="${scriptUrl.toString()}"></script>
    ${this.moduleSetting.htmlCode}
    `;
  }

  @HostListener("window:message", ["$event"])
  handleMessageFromIFrame(message: MessageEvent): void {
    // only handle message from the iframe elem
    if (!message.source || message.source !== this.iframe?.nativeElement?.contentWindow) {
      return;
    }
    const msgData = message.data;

    if (msgData?.type === "navigateTo" && !!msgData.url) {
      of(null)
        .pipe(
          switchMap(() => {
            if (typeof msgData?.trackingEventName === "string") {
              return this.withSpinner(
                this.dashboardService
                  .trackTPModuleClick({
                    eventName: msgData.trackingEventName,
                    moduleId: this.moduleId
                  })
                  .pipe(catchError(err => of(null)))
              );
            }
            return of(null);
          })
        )
        .subscribe(() => {
          if (msgData.target === "_blank") {
            window.open(msgData.url, "_blank");
          } else {
            window.location.href = msgData.url;
          }
        });
    }

    if (msgData?.type === "trackEvent" && !!msgData?.trackingEventName && typeof msgData?.trackingEventName === "string") {
      this.withSpinner(
        this.dashboardService
          .trackTPModuleClick({
            eventName: msgData.trackingEventName,
            moduleId: this.moduleId
          })
          .pipe(catchError(err => of(null)))
      ).subscribe(event => {
        this._postMessage("eventTrackResponse", {
          success: !!event,
          eventId: event ? event._id : null,
          msgId: msgData?.msgId
        });
      });
    }
  }

  private _postMessage(type: string, content: unknown) {
    this.iframe?.nativeElement?.contentWindow?.postMessage({
      source: "fello",
      type,
      content
    });
  }
}
