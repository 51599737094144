import {NgxSpinnerService} from "ngx-spinner";
import {mixinDestroyable} from "./destroy";
import {MatSnackBar} from "@angular/material/snack-bar";
import {mixinSpinnerAndToast} from "./spinnerToast";
import {mixinToast} from "./toast";

export const DestroyableBase = mixinDestroyable(class {});

export const ToastSpinnerBase = mixinSpinnerAndToast(
  class {
    constructor(public spinnerService: NgxSpinnerService, public snackBar: MatSnackBar) {}
  }
);
export const DestroyableToastSpinnerBase = mixinDestroyable(ToastSpinnerBase);

export const DestroyableToastBase = mixinDestroyable(
  mixinToast(
    class {
      constructor(public snackBar: MatSnackBar) {}
    }
  )
);
