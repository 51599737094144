export enum HomeConditionLevel {
  PERFECT = "perfect",
  EXCELLENT = "excellent",
  GOOD = "good",
  MINOR_WORK = "minor_work",
  MAJOR_WORK = "major_work"
}

export const HomeConditionLevelLabel: Record<HomeConditionLevel, string> = {
  [HomeConditionLevel.PERFECT]: "Perfect - Like new condition",
  [HomeConditionLevel.EXCELLENT]: "Excellent & well maintained",
  [HomeConditionLevel.GOOD]: "Good - In good shape & well maintained",
  [HomeConditionLevel.MINOR_WORK]: "Minor Work - Needs a few minor renovations",
  [HomeConditionLevel.MAJOR_WORK]: "Major Work - Needs major renovation"
};
