// search.pipe.ts
import {Pipe, PipeTransform} from "@angular/core";

@Pipe({
  name: "localSearch",
  standalone: true
})
export class LocalSearchPipe implements PipeTransform {
  transform<T extends object>(items: T[], searchTerm: string, searchFields: string[]): T[] {
    if (!items || !searchTerm || !searchFields || searchFields.length === 0 || searchTerm.length < 3) {
      return items;
    }

    return items.filter((item: any) => {
      return searchFields.some(field => {
        // Check if the specified search field is present in the item
        // eslint-disable-next-line no-prototype-builtins
        if (item.hasOwnProperty(field)) {
          const fieldValue = item[field].toLowerCase();
          return fieldValue.includes(searchTerm.toLowerCase());
        }
        return false;
      });
    });
  }
}
