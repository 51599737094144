<form class="card-wrapper" #form="ngForm" (ngSubmit)="form.invalid ? form.control.markAllAsTouched() : submitForm()" *ngIf="settings">
  <div class="card-header">
    <p class="heading">{{ settings.config.layoutSettings.heading }}</p>
    <p *ngIf="settings.config.layoutSettings.subHeading" class="text-size-16 fw-500 sub-heading text-color-light-2">
      {{ settings.config.layoutSettings.subHeading }}
    </p>
  </div>
  <div class="card-content">
    <div class="form-field mb-3">
      <lib-smarty-address-auto-complete
        [(ngModel)]="address"
        name="address"
        id="propertyAddress"
        #propertyAddress="ngModel"
        [placeholder]="settings.config.layoutSettings.searchBarText"
        maxlength="100"
        [required]="true"
      ></lib-smarty-address-auto-complete>
    </div>
    <button type="submit" class="w-100 mt-2" lib-loading-button [disabled]="form.invalid || isLoading" [isLoading]="isLoading">
      {{settings.config.layoutSettings.buttonLabel}}
    </button>
  </div>
</form>
