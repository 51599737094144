import {Injectable, Injector} from "@angular/core";
import {Observable} from "rxjs";
import {FieldCRMFilterDynamicListType, FieldCRMStatusToLabelMapping, FieldFilterAllowedValue} from "fello-model";
import {AgentConnectedAppsApiService, Constructor, CrmAppDetailsService} from "../../../lib";
import {BaseDynamicList} from "./fql-base-dynamic-list-factory.service";
import {map} from "rxjs/operators";
import {AbstractFqlDynamicListFactoryService} from "./abstract-fql-dynamic-list-factory.service";

@Injectable()
export class ConnectedAppsCRMStagesDynamicList extends BaseDynamicList {
  constructor(private agentConnectedAppsApiService: AgentConnectedAppsApiService, private crmAppDetailsService: CrmAppDetailsService) {
    super();
  }

  getAllListItems(): Observable<FieldFilterAllowedValue[]> {
    const appId = this.crmAppDetailsService.getAppId();
    const crm = this.crmAppDetailsService.getCrmName();
    return this.agentConnectedAppsApiService.getCrmFields(appId, crm, "stages").pipe(
      map(crmStages => {
        return crmStages
          .filter(t => !!t)
          .map(crmStage => {
            return {
              label: crmStage,
              value: crmStage
            };
          });
      })
    );
  }
}

@Injectable()
export class ConnectedAppsCRMUsersDynamicList extends BaseDynamicList {
  constructor(private agentConnectedAppsApiService: AgentConnectedAppsApiService, private crmAppDetailsService: CrmAppDetailsService) {
    super();
  }

  getAllListItems(): Observable<FieldFilterAllowedValue[]> {
    const appId = this.crmAppDetailsService.getAppId();
    return this.agentConnectedAppsApiService.getCrmUsers(appId).pipe(
      map(crmUsers => {
        return [
          ...crmUsers.map(member => ({
            label: member.fullName! || member.emailId!,
            value: member.crmUserId
          }))
        ];
      })
    );
  }
}

@Injectable()
export class ConnectedAppsCRMLeadStatusDynamicList extends BaseDynamicList {
  constructor(private agentConnectedAppsApiService: AgentConnectedAppsApiService, private crmAppDetailsService: CrmAppDetailsService) {
    super();
  }

  getAllListItems(): Observable<FieldFilterAllowedValue[]> {
    const appId = this.crmAppDetailsService.getAppId();
    const crm = this.crmAppDetailsService.getCrmName();
    return this.agentConnectedAppsApiService.getCrmFields(appId, crm, "lead-status").pipe(
      map(crmStages => {
        return crmStages
          .filter(t => !!t)
          .map(crmStage => {
            return {
              label: FieldCRMStatusToLabelMapping[crmStage] ?? crmStage,
              value: crmStage
            };
          });
      })
    );
  }
}

@Injectable()
export class ConnectedAppsCRMLeadSourcesDynamicList extends BaseDynamicList {
  constructor(private agentConnectedAppsApiService: AgentConnectedAppsApiService, private crmAppDetailsService: CrmAppDetailsService) {
    super();
  }

  getAllListItems(): Observable<FieldFilterAllowedValue[]> {
    const appId = this.crmAppDetailsService.getAppId();
    const crm = this.crmAppDetailsService.getCrmName();
    return this.agentConnectedAppsApiService.getCrmFields(appId, crm, "lead-sources").pipe(
      map(crmStages => {
        return crmStages
          .filter(t => !!t)
          .map(crmStage => {
            return {
              label: crmStage,
              value: crmStage
            };
          });
      })
    );
  }
}

@Injectable()
export class ConnectedAppsCRMLeadTagsDynamicList extends BaseDynamicList {
  constructor(private agentConnectedAppsApiService: AgentConnectedAppsApiService, private crmAppDetailsService: CrmAppDetailsService) {
    super();
  }

  getAllListItems(): Observable<FieldFilterAllowedValue[]> {
    const appId = this.crmAppDetailsService.getAppId();
    const crm = this.crmAppDetailsService.getCrmName();
    return this.agentConnectedAppsApiService.getCrmFields(appId, crm, "lead-tags").pipe(
      map(crmStages => {
        return crmStages
          .filter(t => !!t)
          .map(crmStage => {
            return {
              label: crmStage,
              value: crmStage
            };
          });
      })
    );
  }
}

@Injectable()
export class FqlCRMDynamicListFactoryService extends AbstractFqlDynamicListFactoryService {
  public static readonly listTypeToServiceMap: Record<FieldCRMFilterDynamicListType, Constructor<BaseDynamicList>> = {
    [FieldCRMFilterDynamicListType.fubStages]: ConnectedAppsCRMStagesDynamicList,
    [FieldCRMFilterDynamicListType.fubUsers]: ConnectedAppsCRMUsersDynamicList,
    [FieldCRMFilterDynamicListType.sierraListingUsers]: ConnectedAppsCRMUsersDynamicList,
    [FieldCRMFilterDynamicListType.sierraLeadStatus]: ConnectedAppsCRMLeadStatusDynamicList,
    [FieldCRMFilterDynamicListType.sierraLeadSources]: ConnectedAppsCRMLeadSourcesDynamicList,
    [FieldCRMFilterDynamicListType.sierraLeadTags]: ConnectedAppsCRMLeadTagsDynamicList
  };

  constructor(private injector: Injector) {
    super();
  }

  getDynamicList(dynamicListType: FieldCRMFilterDynamicListType): BaseDynamicList {
    return this.injector.get(FqlCRMDynamicListFactoryService.listTypeToServiceMap[dynamicListType]);
  }
}

export const FqlCRMDynamicListHelperProviders = [
  ConnectedAppsCRMStagesDynamicList,
  ConnectedAppsCRMUsersDynamicList,
  ConnectedAppsCRMLeadStatusDynamicList,
  ConnectedAppsCRMLeadSourcesDynamicList,
  ConnectedAppsCRMLeadTagsDynamicList,
  FqlCRMDynamicListFactoryService
];
