import {Observable} from "rxjs";
import {AbstractConstructor, Constructor} from "../types";
import {MatSnackBar, MatSnackBarConfig} from "@angular/material/snack-bar";
import {tap} from "rxjs/operators";
import {
  SnackbarWithIconComponent,
  SnackbarWithIconData
} from "../../modules/fello-ui-utils/components/snackbar-with-icon/snackbar-with-icon.component";
export interface ShowToastOption {
  successMsg?: string;
  failureMsg?: string;
  action?: string;
  snackBarConfig?: MatSnackBarConfig;
  showIcon?: boolean;
}

export interface CanShowToast {
  withToast<T>(observable: Observable<T>, options?: ShowToastOption): Observable<T>;
}

export interface HasMatSnackBar {
  snackBar: MatSnackBar;
}

type CanShowSpinnerCtor = Constructor<CanShowToast> & AbstractConstructor<CanShowToast>;

export function mixinToast<T extends AbstractConstructor<HasMatSnackBar>>(base: T): CanShowSpinnerCtor & T;
export function mixinToast<T extends Constructor<HasMatSnackBar>>(base: T): CanShowSpinnerCtor & T {
  return class extends base {
    withToast<T>(observable: Observable<T>, options?: ShowToastOption): Observable<T> {
      return observable.pipe(
        tap(
          () => {
            if (options?.successMsg) {
              if (options.showIcon) {
                this.snackBar.openFromComponent<SnackbarWithIconComponent, SnackbarWithIconData>(SnackbarWithIconComponent, {
                  ...options.snackBarConfig,
                  data: {
                    message: options.successMsg,
                    icon: {name: "tick-fill", classes: "text-color-green"}
                  }
                });
              } else {
                this.snackBar.open(options.successMsg, options.action, options.snackBarConfig);
              }
            }
          },
          () => {
            if (options?.failureMsg) {
              if (options.showIcon) {
                this.snackBar.openFromComponent<SnackbarWithIconComponent, SnackbarWithIconData>(SnackbarWithIconComponent, {
                  ...options.snackBarConfig,
                  data: {
                    message: options.failureMsg,
                    icon: {name: "cross-fill", classes: "text-color-red"}
                  }
                });
              } else {
                this.snackBar.open(options.failureMsg, options.action, options.snackBarConfig);
              }
            }
          }
        )
      );
    }
  };
}
