import {Inject, Injectable, NgZone, OnDestroy, PLATFORM_ID} from "@angular/core";
import {DOCUMENT, isPlatformServer} from "@angular/common";

import {NEVER, Observable, ReplaySubject} from "rxjs";

export interface Script {
  name: string;
  src: string;
  loaded: boolean;
}

@Injectable({
  providedIn: "root"
})
export class LoadScriptService implements OnDestroy {
  private scriptsMap = new Map<string, ReplaySubject<boolean>>();

  constructor(
    @Inject(DOCUMENT) private document: Document,
    // eslint-disable-next-line @typescript-eslint/ban-types
    @Inject(PLATFORM_ID) private platformId: Object,
    private ngZone: NgZone
  ) {}

  load(scriptSrc: string): Observable<boolean> {
    return this.ngZone.runOutsideAngular(() => {
      return this._load(scriptSrc);
    });
  }

  private _load(scriptSrc: string): Observable<boolean> {
    if (isPlatformServer(this.platformId)) {
      return NEVER;
    }
    if (!this.scriptsMap.has(scriptSrc)) {
      const subject = new ReplaySubject<boolean>(1);
      this.scriptsMap.set(scriptSrc, subject);

      const scriptElement = this.document.createElement("script");
      scriptElement.type = "text/javascript";
      scriptElement.src = scriptSrc;
      scriptElement.async = true;

      scriptElement.onload = () => {
        subject.next(true);
        subject.complete();
      };

      scriptElement.onerror = () => {
        subject.error(`Couldn't load script ${scriptSrc}`);
      };

      this.document.getElementsByTagName("body")[0].appendChild(scriptElement);
    }
    return this.scriptsMap.get(scriptSrc)!;
  }

  ngOnDestroy(): void {
    this.scriptsMap.clear();
    this.scriptsMap = null as unknown as Map<string, ReplaySubject<boolean>>;
  }
}
