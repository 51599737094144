import {Inject, Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {
  AgencyCMSSetting,
  AgencyCMSSettingUpdateRequest,
  CMSHistoryStatsResponse,
  CreditDefaults,
  CreditStatus,
  DailyAutomationCMSHistoryStatsRequest,
  ManualPurchaseCreditRequest,
  ManualPurchaseCreditResponse
} from "fello-model";
import {Observable} from "rxjs";
import {PAYMENT_API_SERVICE_BASE_URL} from "../payment";

@Injectable({
  providedIn: "root"
})
export class AgentCmsApiService {
  baseUrl: string;
  constructor(private http: HttpClient, @Inject(PAYMENT_API_SERVICE_BASE_URL) private paymentBaseUrl: string) {
    this.baseUrl = `${this.paymentBaseUrl}/payment/cms/agent`;
  }

  updateCMSSetting(updateRequest: AgencyCMSSettingUpdateRequest): Observable<unknown> {
    return this.http.patch(`${this.baseUrl}/setting`, updateRequest);
  }

  getAgencyCMSSetting(): Observable<AgencyCMSSetting> {
    return this.http.get<AgencyCMSSetting>(`${this.baseUrl}/setting`);
  }

  getCreditDefaults(): Observable<CreditDefaults> {
    return this.http.get<CreditDefaults>(`${this.baseUrl}/credit/defaults`);
  }

  getCreditStatus(): Observable<CreditStatus> {
    return this.http.get<CreditStatus>(`${this.baseUrl}/credit/status`);
  }

  purchaseExtraCredits(purchaseRequest: ManualPurchaseCreditRequest): Observable<ManualPurchaseCreditResponse> {
    return this.http.post<ManualPurchaseCreditResponse>(`${this.baseUrl}/credit/purchase`, purchaseRequest);
  }

  getAutomationCreditStats(automationDefId: string, params: DailyAutomationCMSHistoryStatsRequest): Observable<CMSHistoryStatsResponse[]> {
    return this.http.post<CMSHistoryStatsResponse[]>(`${this.baseUrl}/automation/${automationDefId}/credit/stats`, {
      params
    });
  }
}
