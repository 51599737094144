import {Inject, Injectable, InjectionToken, Optional, PLATFORM_ID} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {cacheResponse} from "../../utils";
import {Observable, of, ReplaySubject} from "rxjs";
import {AddressComponent} from "fello-model";
import {catchError, map} from "rxjs/operators";
import {isPlatformBrowser} from "@angular/common";

export const GOOGLE_STREET_VIEW_API_KEY = new InjectionToken<string>("Google street view api key");

@Injectable({
  providedIn: "root"
})
export class GoogleStreetViewImageService {
  constructor(
    private http: HttpClient,
    // eslint-disable-next-line @typescript-eslint/ban-types
    @Inject(PLATFORM_ID) private platformId: Object,
    @Optional() @Inject(GOOGLE_STREET_VIEW_API_KEY) private apiKey?: string
  ) {}

  public getImage(addressComponents?: AddressComponent | null): Observable<string | null> {
    return this.getImageFromAddress(addressComponents?.fullAddress);
  }

  public getImageFromAddress(address?: string): Observable<string | null> {
    if (!this.apiKey || !address || !isPlatformBrowser(this.platformId)) {
      return of(null);
    }
    const imagesSubject = new ReplaySubject<string | null>(1);
    const googleImageUrl = `https://maps.googleapis.com/maps/api/streetview?size=500x400&location=${encodeURIComponent(address)}&key=${
      this.apiKey
    }`;
    imagesSubject.next(googleImageUrl);
    this.isGoogleImageActive(address)
      .pipe(
        map(isActive => {
          if (isActive) {
            return googleImageUrl;
          }
          return null;
        }),
        catchError(() => of(null))
      )
      .subscribe(imagesSubject);
    return imagesSubject;
  }

  @cacheResponse
  private isGoogleImageActive(address: string): Observable<boolean> {
    return this.http
      .get<{status: string}>(
        `https://maps.googleapis.com/maps/api/streetview/metadata?size=500x400&location=${encodeURIComponent(address)}&key=${this.apiKey}`
      )
      .pipe(
        map(({status}) => {
          return status === "OK";
        })
      );
  }
}
