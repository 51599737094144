export enum NumberShorthandMode {
  MONETARY = "MONETARY",
  SIMPLE = "SIMPLE"
}
export class FormatNumberShorthandUtil {
  public static transform(value: number, prefix = "", mode = NumberShorthandMode.MONETARY): string {
    const formattedValue = FormatNumberShorthandUtil.nFormatter(Math.abs(value), mode);
    return `${value < 0 ? "-" : ""}${prefix}${formattedValue}`;
  }
  public static nFormatter(num: number, mode = NumberShorthandMode.MONETARY): string {
    switch (mode) {
      case NumberShorthandMode.MONETARY:
        return FormatNumberShorthandUtil.monetaryFormatter(num);
      case NumberShorthandMode.SIMPLE:
        return FormatNumberShorthandUtil.simpleFormatter(num);
      default:
        return FormatNumberShorthandUtil.monetaryFormatter(num);
    }
  }
  public static monetaryFormatter(num: number): string {
    const si = [
      {value: 1, symbol: "", digits: 0},
      {value: 1e3, symbol: "K", digits: 0},
      {value: 1e6, symbol: "M", digits: 2},
      {value: 1e9, symbol: "G", digits: 0},
      {value: 1e12, symbol: "T", digits: 0},
      {value: 1e15, symbol: "P", digits: 0},
      {value: 1e18, symbol: "E", digits: 0}
    ];
    let i;
    for (i = si.length - 1; i > 0; i--) {
      if (num >= si[i].value) {
        break;
      }
    }
    return (num / si[i].value).toFixed(si[i].digits) + si[i].symbol;
  }
  public static simpleFormatter(num: number): string {
    const si = [
      {value: 1, symbol: "", digits: 0},
      {value: 1e3, symbol: "K", digits: 2},
      {value: 1e6, symbol: "M", digits: 2},
      {value: 1e9, symbol: "G", digits: 2},
      {value: 1e12, symbol: "T", digits: 2},
      {value: 1e15, symbol: "P", digits: 2},
      {value: 1e18, symbol: "E", digits: 2}
    ];
    const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
    let i;
    for (i = si.length - 1; i > 0; i--) {
      if (num >= si[i].value) {
        break;
      }
    }
    return (num / si[i].value).toFixed(si[i].digits).replace(rx, "$1") + si[i].symbol;
  }
}
