import {Component, Input, OnInit} from "@angular/core";

@Component({
  selector: "lib-dashboard-easy-link",
  templateUrl: "./dashboard-easy-link.component.html",
  styleUrls: ["./dashboard-easy-link.component.scss"]
})
export class DashboardEasyLinkComponent implements OnInit {
  @Input() website: string;

  get websiteURLValue(): string {
    return this.website.replace(/^(https?:\/\/)?(www\.)?/i, "").split("?")[0];
  }

  constructor() {}

  ngOnInit(): void {}
}
