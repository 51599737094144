import {Pipe, PipeTransform} from "@angular/core";
import moment from "moment";
import {MomentInput} from "moment";
@Pipe({
  name: "timeAgo",
  pure: true,
  standalone: true
})
export class TimeAgoPipe implements PipeTransform {
  transform(value: MomentInput): string {
    return moment(value).fromNow();
  }
}
