import {Inject, Injectable, InjectionToken} from "@angular/core";
import {ActivatedRoute, Router} from "@angular/router";
import {BehaviorSubject, Observable} from "rxjs";
import {FelloConnectAppStatus} from "fello-model";
import {MatDialog} from "@angular/material/dialog";

interface CrmConfig {
  name: string;
}
export const CRM_CONFIG_TOKEN = new InjectionToken<CrmConfig>("config");

@Injectable()
export class CrmAppDetailsService {
  private appIdSubject = new BehaviorSubject<string>("");
  private appStatusSubject = new BehaviorSubject<FelloConnectAppStatus>(FelloConnectAppStatus.INACTIVE);
  private ownerDetails: any;
  private appConfig: any;
  appId$: Observable<string> = this.appIdSubject.asObservable();
  appStatus$: Observable<FelloConnectAppStatus> = this.appStatusSubject.asObservable();

  constructor(@Inject(CRM_CONFIG_TOKEN) private crmConfig: CrmConfig) {}

  setAppId(id: string) {
    this.appIdSubject.next(id);
  }

  getAppId(): string {
    return this.appIdSubject.getValue();
  }

  getCrmName() {
    return this.crmConfig.name;
  }

  setAppStatus(status: FelloConnectAppStatus) {
    this.appStatusSubject.next(status);
  }

  getAppStatus() {
    return this.appStatusSubject.getValue();
  }

  setOwnerDetails(details: any) {
    this.ownerDetails = details;
  }

  getOwnerDetails() {
    return this.ownerDetails;
  }
}
