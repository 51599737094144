import {Component, Inject} from "@angular/core";
import {CommonModule} from "@angular/common";
import {MAT_SNACK_BAR_DATA, MatSnackBarRef} from "@angular/material/snack-bar";
import {MatButtonModule} from "@angular/material/button";
import {IconComponent} from "../icon/icon.component";
export interface SnackbarWithIconData {
  message: string;
  icon: {name: string; classes: string};
}
@Component({
  selector: "lib-snackbar-with-icon",
  standalone: true,
  imports: [CommonModule, MatButtonModule, IconComponent],
  templateUrl: "./snackbar-with-icon.component.html",
  styleUrls: ["./snackbar-with-icon.component.scss"]
})
export class SnackbarWithIconComponent {
  constructor(
    private snackBarRef: MatSnackBarRef<SnackbarWithIconComponent>,
    @Inject(MAT_SNACK_BAR_DATA) public data: SnackbarWithIconData
  ) {}

  close(): void {
    this.snackBarRef.dismiss();
  }
}
