import {Component} from "@angular/core";
import {MatDialog} from "@angular/material/dialog";
import {AbstractDashboardService} from "../../services";
import {switchMap, take} from "rxjs/operators";

@Component({
  selector: "lib-own-another-home",
  templateUrl: "./own-another-home.component.html",
  styleUrls: ["./own-another-home.component.scss"]
})
export class OwnAnotherHomeComponent {
  isLoading$ = this.dashboardService.isPropertyLoading;
  isLoading = false;
  property$ = this.dashboardService.property$;
  contact$ = this.dashboardService.contact$;
  constructor(private dialog: MatDialog, private dashboardService: AbstractDashboardService) {}

  addNewAddress() {
    this.dashboardService.addNewAddress().subscribe();
  }

  unClaimProperty() {
    this.dashboardService.property$
      .pipe(
        take(1),
        switchMap(property => {
          return this.dashboardService.unClaimClaimedProperty(property);
        })
      )
      .subscribe();
  }
}
