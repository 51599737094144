import {Component, EventEmitter, forwardRef, Input, OnInit, Output} from "@angular/core";
import {BehaviorSubject, Observable, of} from "rxjs";
import {MatAutocompleteModule} from "@angular/material/autocomplete";
import {debounceTime, delay, distinctUntilChanged, map, retryWhen, switchMap, take, takeUntil, tap} from "rxjs/operators";
import {AsyncPipe, NgForOf, NgIf} from "@angular/common";
import {ControlValueAccessor, FormsModule, NG_VALUE_ACCESSOR} from "@angular/forms";
import {SmartyStreetsAutocompleteService, SmartyStreetsSuggestion} from "./smarty-streets-autocomplete.service";
import {IconComponent} from "../../../fello-ui-utils";
import {DestroyableBase} from "../../../../lib";

@Component({
  selector: "lib-smarty-address-auto-complete",
  templateUrl: "./smarty-address-auto-complete.component.html",
  styleUrls: ["./smarty-address-auto-complete.component.scss"],
  standalone: true,
  providers: [
    SmartyStreetsAutocompleteService,
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SmartyAddressAutoCompleteComponent),
      multi: true
    }
  ],
  imports: [MatAutocompleteModule, AsyncPipe, NgForOf, NgIf, FormsModule, IconComponent]
})
export class SmartyAddressAutoCompleteComponent extends DestroyableBase implements OnInit, ControlValueAccessor {
  suggestions: Observable<SmartyStreetsSuggestion[]>;
  search$ = new BehaviorSubject<string>("");
  selectedAddress$ = new BehaviorSubject<SmartyStreetsSuggestion | undefined>(undefined);

  @Input()
  maxSuggestionCount: number | undefined;
  @Output()
  optionSelected = new EventEmitter<SmartyStreetsSuggestion>();

  @Input()
  placeholder?: string;

  disabled: boolean;

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onChange: any = () => {};
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onTouched: any = () => {};

  constructor(private smartyStreetsAutocompleteService: SmartyStreetsAutocompleteService) {
    super();
  }

  writeValue(value: any): void {
    this.search$.next(value ?? "");
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
  ngOnInit() {
    this.suggestions = this.search$.pipe(
      debounceTime(200),
      switchMap(search => {
        const selected = this.selectedAddress$.value;
        if (!search) {
          return of([]);
        }
        if (!selected) {
          return this.smartyStreetsAutocompleteService.getAddressSuggestion(search).pipe(retryWhen(err => err.pipe(delay(5000))));
        }
        return this.getExtendedAddressSuggestion(search, selected).pipe(retryWhen(err => err.pipe(delay(5000))));
      }),
      map(suggestions => {
        if (this.maxSuggestionCount) {
          return suggestions.slice(0, this.maxSuggestionCount);
        }
        return suggestions;
      })
    );

    this.search$
      .pipe(
        takeUntil(this.isDestroyed),
        tap(search => {
          if (search.length < 2) {
            this.selectedAddress$.next(undefined);
          }
        })
      )
      .subscribe();
  }

  getExtendedAddressSuggestion(search: string, selected: SmartyStreetsSuggestion): Observable<SmartyStreetsSuggestion[]> {
    return this.smartyStreetsAutocompleteService.getAddressSuggestion(search).pipe(
      take(1),
      distinctUntilChanged(),
      switchMap(results => {
        const matches = results.filter(
          e => e.street_line === selected.street_line.trim() && e.secondary.includes(selected.secondary.trim())
        );
        if (matches.length > 0) {
          this.selectedAddress$.next(matches[0]);
          return this.smartyStreetsAutocompleteService.getAddressSuggestion(search, matches[0]);
        }
        this.selectedAddress$.next(undefined);
        return of(results);
      })
    );
  }

  entrySelected(event: MouseEvent, suggestion: SmartyStreetsSuggestion) {
    event.preventDefault();
    event.stopPropagation();
    this.selectedAddress$.next(suggestion);
  }

  buildAddress(suggestion: SmartyStreetsSuggestion) {
    let whiteSpace = "";
    if (suggestion.secondary) {
      whiteSpace = " ";
    }
    return (
      suggestion.street_line +
      whiteSpace +
      suggestion.secondary +
      " " +
      suggestion.city +
      ", " +
      suggestion.state +
      " " +
      suggestion.zipcode
    );
  }
  delayOptionSelection(suggestion: SmartyStreetsSuggestion): void {
    window.setTimeout(() => this.optionSelected.emit(suggestion));
  }
}
