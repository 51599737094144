export enum AgencyAlertState {
  HOT = "HOT",
  COLD = "COLD",
  WARM = "WARM"
}
export enum AgencyAlertType {
  AUTO_RECHARGE_FAILURE = "AUTO_RECHARGE_FAILURE",
  FUTURE_START_DATE = "FUTURE_START_DATE",
  PAYMENT_DUE = "PAYMENT_DUE",
  MARKETING_CONTACT_LIMIT_WARNING = "MARKETING_CONTACT_LIMIT_WARNING",
  MARKETING_CONTACT_LIMIT_CRITICAL = "MARKETING_CONTACT_LIMIT_CRITICAL",
  MARKETING_CONTACT_LIMIT_EXCEEDED = "MARKETING_CONTACT_LIMIT_EXCEEDED"
}

export type MarketingContactLimitAgencyAlert =
  | AgencyAlertType.MARKETING_CONTACT_LIMIT_EXCEEDED
  | AgencyAlertType.MARKETING_CONTACT_LIMIT_CRITICAL
  | AgencyAlertType.MARKETING_CONTACT_LIMIT_WARNING;

export interface AgencyAlertToDataMap {
  [AgencyAlertType.FUTURE_START_DATE]: {
    startDate?: Date;
  };
  [AgencyAlertType.PAYMENT_DUE]: {
    dueSince?: Date;
    dueInvoicesCount: number;
    totalDues: number;
  };
  [AgencyAlertType.AUTO_RECHARGE_FAILURE]: Record<string, never>;
  [AgencyAlertType.MARKETING_CONTACT_LIMIT_WARNING]: {
    included: number;
    consumed: number;
  };
  [AgencyAlertType.MARKETING_CONTACT_LIMIT_CRITICAL]: {
    included: number;
    consumed: number;
  };
  [AgencyAlertType.MARKETING_CONTACT_LIMIT_EXCEEDED]: {
    included: number;
    consumed: number;
  };
}

export type AvailableAgencyAlertTypes = keyof AgencyAlertToDataMap;
export type AgencyAlertValue<T extends AvailableAgencyAlertTypes> = AgencyAlertToDataMap[T];

export class AgencyAlert<T extends AvailableAgencyAlertTypes = AvailableAgencyAlertTypes> {
  alertType: AgencyAlertType;
  alertState: AgencyAlertState;
  data: AgencyAlertValue<T>;
}
