import {Injectable} from "@angular/core";
import {CookieService} from "ngx-cookie-service";

const BROWSER_ID_COOKIE_NAME = "bid";
@Injectable({
  providedIn: "root"
})
export class BrowserIdService {
  constructor(private cookieService: CookieService) {}

  getBrowserId(): string | undefined {
    const browserId = this.cookieService.get(BROWSER_ID_COOKIE_NAME);
    if (browserId) {
      return browserId.split(".")[0];
    }
    return undefined;
  }
}
