export enum BuyingWithSellingOptions {
  BUY_WTH_SELL = "BUY_WTH_SELL",
  JUST_SELL = "JUST_SELL",
  NA = "NA"
}

export const BuyingWithSellingOptionsLabel: Record<BuyingWithSellingOptions, string> = {
  [BuyingWithSellingOptions.BUY_WTH_SELL]: "Yes",
  [BuyingWithSellingOptions.JUST_SELL]: "No",
  [BuyingWithSellingOptions.NA]: "Still deciding"
};
