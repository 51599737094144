import {AgentLeadType} from "../../agent";

export enum DashboardModuleButtonActionType {
  FORM = "FORM",
  FORM_GROUP = "FORM_GROUP",
  POPUP_LEAD_FORM = "POPUP_LEAD_FORM",
  URL = "URL"
}

export const DashboardModuleButtonActionTypeLabel: Record<DashboardModuleButtonActionType, string> = {
  [DashboardModuleButtonActionType.FORM]: "Form",
  [DashboardModuleButtonActionType.FORM_GROUP]: "Form Group",
  [DashboardModuleButtonActionType.POPUP_LEAD_FORM]: "Pop-Up Form",
  [DashboardModuleButtonActionType.URL]: "External Url"
};

export enum DashboardModuleButtonType {
  PRIMARY = "PRIMARY",
  SECONDARY = "SECONDARY"
}

export interface DashboardModuleButtonAction {
  actionType: DashboardModuleButtonActionType;
  value?: string;
  leadType?: AgentLeadType;
}

export interface DashboardModuleButton {
  // @MaxLength(64)
  label: string;
  action: DashboardModuleButtonAction;
  type: DashboardModuleButtonType;
}

export interface TrackTPModuleClickRequest {
  eventName: string;
  moduleId: string;
}
