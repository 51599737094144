import {FelloTemplate, FelloTemplateSegmentType, FTSTokenType, TextSegmentFTS, TokenSegmentFTS} from "fello-model";
import {isEqual, uniqWith} from "lodash-es";

export class FelloTemplateUtil {
  public static getUniqueFelloTemplates(felloTemplates: FelloTemplate[]): FelloTemplate[] {
    const felloTemplateComparator = (a: FelloTemplate, b: FelloTemplate) => {
      if (a.segments.length !== b.segments.length) {
        return false;
      }
      for (let i = 0; i < a.segments.length; i++) {
        if (a.segments[i].type !== b.segments[i].type) {
          return false;
        }

        if (a.segments[i].type === FelloTemplateSegmentType.TEXT) {
          const textSegmentA = a.segments[i] as TextSegmentFTS;
          const textSegmentB = b.segments[i] as TextSegmentFTS;

          // Only compare the text value, ignoring other fields such as tags.
          if (textSegmentA.value !== textSegmentB.value) {
            return false;
          }
        } else if (a.segments[i].type === FelloTemplateSegmentType.TOKEN) {
          const tokenSegmentA = a.segments[i] as TokenSegmentFTS<FTSTokenType>;
          const tokenSegmentB = b.segments[i] as TokenSegmentFTS<FTSTokenType>;

          // Compare token type and field wrapper fields for equality.
          if (
            !isEqual(tokenSegmentA.tokenType, tokenSegmentB.tokenType) ||
            !isEqual(tokenSegmentA.fieldWrapper.field, tokenSegmentB.fieldWrapper.field)
          ) {
            return false;
          }
        }
      }
      return true;
    };

    return uniqWith(felloTemplates, felloTemplateComparator);
  }
}
