import {Component, ElementRef, forwardRef, Inject, OnInit, ViewContainerRef} from "@angular/core";
import {DashboardModuleButtonAction, DashboardModuleButtonActionType, DashboardModuleButtonType, DashboardModuleType} from "fello-model";
import {DashboardModuleDirective} from "../../directives/dashboard-module.directive";
import {NgxSpinnerService} from "ngx-spinner";
import {MatSnackBar} from "@angular/material/snack-bar";
import {mixinDestroyable, mixinSpinnerAndToast} from "../../../../lib";
import {AbstractDashboardService} from "../../services";
import {DOCUMENT} from "@angular/common";
import {PopupLeadFormComponent} from "../dialogs";
import {MatDialog} from "@angular/material/dialog";

const defaultResponsiveStyleSheet = `<style>
body {margin: 0;}
.vc {position: relative; padding-bottom: 56.25%; padding-top: 30px; height: 0; overflow: hidden;}
.vc iframe, .vc object, .vc embed { position: absolute; top: 0; left: 0; width: 100%; height: 100%; }
</style>`;

const defaultStyleSheet = `<style>
body {margin: 0;}
</style>`;

const VideoModuleBaseComponent = mixinDestroyable(
  mixinSpinnerAndToast(
    class extends DashboardModuleDirective<DashboardModuleType.VIDEO> {
      constructor(public spinnerService: NgxSpinnerService, public snackBar: MatSnackBar, elementRef: ElementRef) {
        super(elementRef);
      }
    }
  )
);

@Component({
  selector: "lib-video-module",
  templateUrl: "./video-module.component.html",
  styleUrls: ["./video-module.component.scss"],
  providers: [
    {
      provide: DashboardModuleDirective,
      useExisting: forwardRef(() => VideoModuleComponent)
    }
  ]
})
export class VideoModuleComponent extends VideoModuleBaseComponent implements OnInit {
  DashboardModuleButtonType = DashboardModuleButtonType;

  get iframeSrc(): string {
    const scriptUrl = new URL(this.document.location.href);
    scriptUrl.pathname = "/assets/js/iframeResizer.contentWindow.min.js";
    scriptUrl.search = "";
    scriptUrl.hash = "";

    if (this.moduleSetting.videoEmbedCode.trim().startsWith("<div")) {
      return `
        ${defaultStyleSheet}
        <script src="${scriptUrl.toString()}"></script>
        ${this.moduleSetting.videoEmbedCode}
    `;
    }
    return `
    ${defaultResponsiveStyleSheet}
    <script src="${scriptUrl.toString()}"></script>
    <div class="vc">
      ${this.moduleSetting.videoEmbedCode}
    </div>
    `;
  }

  constructor(
    @Inject(DOCUMENT) private document: Document,
    elementRef: ElementRef,
    private dashboardService: AbstractDashboardService,
    private viewContainerRef: ViewContainerRef,
    private dialog: MatDialog,
    spinnerService: NgxSpinnerService,
    snackBar: MatSnackBar
  ) {
    super(spinnerService, snackBar, elementRef);
  }

  ngOnInit(): void {}

  performCTAAction(action: DashboardModuleButtonAction): void {
    if (action.actionType === DashboardModuleButtonActionType.POPUP_LEAD_FORM) {
      this.dialog.open(PopupLeadFormComponent, {
        maxWidth: 580,
        data: {
          action
        },
        viewContainerRef: this.viewContainerRef,
        panelClass: ["mobile-bottom-sheet"]
      });
    } else {
      this.withSpinner(this.dashboardService.performCTAAction(action)).subscribe();
    }
  }
}
