import {Component, Input} from "@angular/core";
import {CompLite, OPDLite, PropertyListingCategory} from "fello-model";
import {of, ReplaySubject} from "rxjs";
import {switchMap} from "rxjs/operators";
import {GoogleStreetViewImageService} from "../../../../../lib";
import {FelloUiUtilsModule} from "../../../../fello-ui-utils";
import {NgxSkeletonLoaderModule} from "ngx-skeleton-loader";
import {CommonModule} from "@angular/common";
import {CompLabelComponent} from "../comp-label/comp-label.component";

@Component({
  selector: "app-comp-card",
  templateUrl: "./comp-card.component.html",
  styleUrls: ["./comp-card.component.scss"],
  imports: [CommonModule, FelloUiUtilsModule, NgxSkeletonLoaderModule, CompLabelComponent],
  standalone: true
})
export class CompCardComponent {
  private _comp: CompLite;
  private compSubject = new ReplaySubject<CompLite>();
  compImage = this.compSubject.pipe(
    switchMap(comp => {
      if (comp.imgs?.length) {
        return of(comp.imgs[0]);
      }
      return of(null);
    })
  );
  @Input()
  get comp(): CompLite {
    return this._comp;
  }

  set comp(value: CompLite) {
    this._comp = value;
    this.compSubject.next(value);
  }
  @Input() index: number;
  @Input() sourcePropertyOPDLite: OPDLite;
  PropertyListingCategory = PropertyListingCategory;

  constructor(private googleStreetViewImageService: GoogleStreetViewImageService) {}
}
