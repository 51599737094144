import {Component, Input} from "@angular/core";
import {AbstractDashboardService, DashboardSettingsService} from "../../services";
import {mixinDestroyable} from "../../../../lib";

@Component({
  selector: "app-dashboard-header",
  templateUrl: "./dashboard-header.component.html",
  styleUrls: ["./dashboard-header.component.scss"]
})
export class DashboardHeaderComponent extends mixinDestroyable(class {}) {
  @Input() isAgentDashboard = false;

  constructor(private dashboardService: AbstractDashboardService, private dashboardSettingsService: DashboardSettingsService) {
    super();
  }
  dashboardSettings$ = this.dashboardSettingsService.settings$;
}
