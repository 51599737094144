import {ContentMedium} from "./ContentMedium";
import {ContentConfigTemplateType} from "./template/ContentConfigTemplateType";
import {ContentConfigTemplateSettings} from "./template/ContentConfigTemplate";
import {ChangelogTriggeredBy} from "../deal/changelog/ChangelogEvent";
import {ContentSettingHintType} from "./setting-hint";
import {SmartContentVariation} from "./SmartContentVariation";

export enum ContentVersion {
  DRAFT = "DRAFT",
  PUBLISHED = "PUBLISHED"
}

export interface SmartContentSettings {
  smartContentConfigTemplateId: string;
}

export interface ContentConfig<TemplateType extends ContentConfigTemplateType> {
  _id: string;
  title: string;
  agencyId: string;
  templateType: TemplateType;
  medium: ContentMedium;
  settingHintType: ContentSettingHintType;
  publishedSettings?: ContentConfigTemplateSettings<TemplateType>;
  draftSettings: ContentConfigTemplateSettings<TemplateType>;
  smartContentSettings?: SmartContentSettings;
  createdBy: ChangelogTriggeredBy; // todo rename later
  status: ContentStatus;
  archived?: boolean;
  updatedAt: string;
  createdAt: string;
}
export interface ContentWithSmartContentVariation<TemplateType extends ContentConfigTemplateType> {
  content: ContentConfig<TemplateType>;
  smartContentVariation?: SmartContentVariation<TemplateType>;
}

export enum ContentStatus {
  DRAFT = "DRAFT",
  PUBLISHED = "PUBLISHED",
  ARCHIVED = "ARCHIVED"
}
