import {Component, forwardRef, Input} from "@angular/core";
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from "@angular/forms";
import {NgClass} from "@angular/common";

@Component({
  selector: "lib-amount-comma-separated",
  templateUrl: "./amount-comma-separated.component.html",
  styleUrls: ["./amount-comma-separated.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AmountCommaSeparatedComponent),
      multi: true
    }
  ],
  imports: [NgClass],
  standalone: true
})
export class AmountCommaSeparatedComponent implements ControlValueAccessor {
  @Input() placeholder?: string;
  @Input() isLeftAligned? = false;
  value: number | undefined;
  onChange: any = () => {};
  onTouch: any = () => {};

  writeValue(value: number | undefined): void {
    this.value = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  onInput(event: Event): void {
    const inputElement = event.target as HTMLInputElement;
    const rawValue = inputElement.value.replace(/[^0-9]/g, "");
    const parsedValue = parseFloat(rawValue);
    this.value = Number.isNaN(parsedValue) ? undefined : parsedValue;
    this.onChange(this.value);
    this.onTouch();
    inputElement.value = this.formatValue(this.value);
  }

  formatValue(value: number | undefined): string {
    if (value === undefined) {
      return "";
    }
    return Number(value).toLocaleString(undefined, {
      maximumFractionDigits: 0,
      minimumFractionDigits: 0
    });
  }
}
