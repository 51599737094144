import {Inject, Injectable, InjectionToken} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {
  AcceptAgentInviteRequest,
  AgencyAccessRolesTypes,
  AgencyRoleWisePermissions,
  Agent,
  AgentAuthSignInRequest,
  AgentCommission,
  AgentDeleteRequest,
  AgentEmulationStatus,
  AgentLicense,
  AgentNotifications,
  AgentPermissions,
  AgentPersonalDetailRequest,
  AgentProfile,
  AgentProfilePersonalDetails,
  AgentResetPasswordRequest,
  AgentRoleAndPermissions,
  AgentServiceEnum,
  AgentSignature,
  AgentSignUpRequest,
  AgentTestimonial,
  BackgroundTask,
  BackgroundTaskInput,
  BackgroundTaskOutput,
  BackgroundTaskSearchParam,
  ChangelogEvent,
  ChangelogSearchParams,
  ChangelogWithContact,
  ClientPreferences,
  CrmXAgencyApiKey,
  DashboardSearchParams,
  FelloDashboard,
  GetAgentStatsInput,
  GrantableRolesAndPermissions,
  GroupedCountStatsResultEntry,
  MediaWithType,
  PagedResult,
  ProfessionalDetailsRequest,
  StartEndTime,
  UploadMediaResponse,
  ValidateInviteCodeResp,
  WeekDaysEnum
} from "fello-model";
import {StringUtils} from "../../utils";
import {map} from "rxjs/operators";

export const AGENT_API_SERVICE_BASE_URL = new InjectionToken<string>("AGENT_API_SERVICE_BASE_URL");

@Injectable({
  providedIn: "root"
})
export class AgentApiService {
  baseUrl: string;

  constructor(private http: HttpClient, @Inject(AGENT_API_SERVICE_BASE_URL) private agentBaseUrl: string) {
    this.baseUrl = `${this.agentBaseUrl}/agent`;
  }

  getAgent(): Observable<Agent> {
    return this.http.get<Agent>(`${this.baseUrl}`);
  }

  getAgentStats(request: GetAgentStatsInput): Observable<GroupedCountStatsResultEntry[]> {
    return this.http.post<GroupedCountStatsResultEntry[]>(`${this.baseUrl}/agent/stats`, {request});
  }

  updateAccessRolePermission(accessRole: AgencyAccessRolesTypes, permissions: AgentPermissions[]): Observable<AgencyRoleWisePermissions> {
    return this.http.patch<AgencyRoleWisePermissions>(`${this.baseUrl}/role/permissions`, {
      accessRole,
      permissions
    });
  }

  getAgentPermissions(): Observable<AgentPermissions[]> {
    return this.http.get<AgentPermissions[]>(`${this.baseUrl}/permissions`);
  }

  getAgentById(agentId: string): Observable<Agent> {
    return this.http.get<Agent>(`${this.baseUrl}/id/${agentId}`);
  }

  getAgentProfile(agentId: string): Observable<AgentProfile> {
    return this.http.get<AgentProfile>(`${this.baseUrl}/agent/${agentId}/profile`);
  }

  getGrantableRolesAndPermissions(toAgentId?: string): Observable<GrantableRolesAndPermissions> {
    return this.http.post<GrantableRolesAndPermissions>(`${this.baseUrl}/grantable-access`, {toAgentId});
  }

  updatePersonalDetails(agentPersonalDetailRequest: AgentPersonalDetailRequest): Observable<void> {
    return this.http.patch<void>(`${this.baseUrl}/details/personal`, agentPersonalDetailRequest);
  }

  updateAgentProfilePersonalDetails(agentId: string, personalDetails: AgentProfilePersonalDetails): Observable<void> {
    return this.http.patch<void>(`${this.baseUrl}/agent/${agentId}/profile/personal`, {personalDetails});
  }

  getAgentSignature(): Observable<AgentSignature> {
    return this.http.get<AgentSignature>(`${this.baseUrl}/signature`);
  }

  updateProfilePhoto(photo: MediaWithType): Observable<void> {
    return this.http.patch<void>(`${this.baseUrl}/details/photo`, {photo});
  }

  updateAgentPermissions(agentId: string, access: AgentRoleAndPermissions): Observable<void> {
    return this.http.patch<void>(`${this.baseUrl}/agent/${agentId}/permissions`, {access});
  }

  updateProfessionalDetails(license: AgentLicense): Observable<void> {
    return this.http.patch<void>(`${this.baseUrl}/details/professional`, {license});
  }

  updateAgencyClientPreferences(clientPreferences: ClientPreferences): Observable<void> {
    return this.http.patch<void>(`${this.baseUrl}/client-preferences`, clientPreferences);
  }

  updateAgencyCommissionAndServices(
    commissions: AgentCommission[],
    services: AgentServiceEnum[],
    minContractLength: number,
    maxOpenHouses: number
  ): Observable<void> {
    return this.http.patch<void>(`${this.baseUrl}/commission-and-services`, {
      commissions,
      services,
      minContractLength,
      maxOpenHouses
    });
  }

  updatePasswordFromProfile(oldPassword: string, newPassword: string): Observable<void> {
    return this.http.patch<void>(`${this.baseUrl}/password`, {oldPassword, newPassword});
  }

  updateSchedule(workdays: {[key in WeekDaysEnum]: StartEndTime}): Observable<void> {
    return this.http.patch<void>(`${this.baseUrl}/schedule/workdays`, {workdays});
  }

  updateProfile(introduction: string, zillowURL: string): Observable<void> {
    return this.http.patch<void>(`${this.baseUrl}/profile`, {introduction, zillowURL});
  }

  addTestimonial(testimonial: AgentTestimonial): Observable<void> {
    return this.http.post<void>(`${this.baseUrl}/testimonial`, {testimonial});
  }

  removeTestimonial(index: number): Observable<void> {
    return this.http.delete<void>(`${this.baseUrl}/testimonial/${index}`);
  }

  getTestimonials(): Observable<AgentTestimonial[]> {
    return this.http.get<AgentTestimonial[]>(`${this.baseUrl}/testimonials`);
  }

  updateNotificationSubscriptions(notificationSubscriptions: AgentNotifications): Observable<void> {
    return this.http.patch<void>(`${this.baseUrl}/details/notification-subscriptions`, {notificationSubscriptions});
  }

  authenticateAgent(agentAuthSignInRequest: AgentAuthSignInRequest): Observable<{emailId: string}> {
    return this.http.post<{emailId: string}>(`${this.baseUrl}/auth/sign-in`, agentAuthSignInRequest);
  }

  signInWithGoogle(token: string): Observable<{emailId: string}> {
    return this.http.post<{emailId: string}>(`${this.baseUrl}/auth/google/sign-in`, {
      token
    });
  }

  signUpV2(signUpRequest: AgentSignUpRequest): Observable<unknown> {
    return this.http.post(`${this.baseUrl}/auth/sign-up/v2`, signUpRequest);
  }

  verifyEmail(token: string): Observable<{email: string}> {
    return this.http.post<{email: string}>(`${this.baseUrl}/auth/verify-email`, {token});
  }

  requestResetPassword(email: string): Observable<unknown> {
    return this.http.post(`${this.baseUrl}/auth/request-reset-password`, {email});
  }

  checkResetPasswordTokenValidity(
    token: string
  ): Observable<{valid: boolean; name?: string; agencyName?: string; agencyLogo?: MediaWithType}> {
    return this.http.post<{valid: boolean; name?: string; agencyName?: string; agencyLogo?: MediaWithType}>(
      `${this.baseUrl}/auth/validate-reset-password-token`,
      {token}
    );
  }

  resetPassword(request: AgentResetPasswordRequest): Observable<unknown> {
    return this.http.post(`${this.baseUrl}/auth/reset-password`, request);
  }

  checkInvitationCode(invitationCode: string): Observable<ValidateInviteCodeResp> {
    return this.http.post<ValidateInviteCodeResp>(`${this.baseUrl}/auth/validate-invite`, {invitationCode});
  }

  acceptInvite(acceptAgentInviteRequest: AcceptAgentInviteRequest): Observable<unknown> {
    return this.http.post(`${this.baseUrl}/auth/accept-invite`, acceptAgentInviteRequest);
  }

  signOut(): Observable<void> {
    return this.http.put<void>(`${this.baseUrl}/auth/sign-out`, {});
  }

  ping(): Observable<void> {
    return this.http.post<void>(`${this.baseUrl}/auth/ping`, {});
  }

  addProfessionalDetail(professionalDetailsRequest: ProfessionalDetailsRequest): Observable<void> {
    return this.http.patch<void>(`${this.baseUrl}/onboarding/professional-details`, professionalDetailsRequest);
  }

  getUploadMediaResponse(fileExtension: string): Observable<UploadMediaResponse> {
    return this.http.post<UploadMediaResponse>(`${this.baseUrl}/media/upload?ext=${fileExtension}`, {});
  }

  sendEmailVerificationEmail(): Observable<void> {
    return this.http.post<void>(`${this.baseUrl}/send-email-verification-email`, {});
  }

  sendPhoneVerificationSMS(): Observable<void> {
    return this.http.post<void>(`${this.baseUrl}/send-phone-verification-sms`, {});
  }

  verifySMSOTP(otp: string): Observable<{phoneVerificationStatus: boolean}> {
    return this.http.post<{phoneVerificationStatus: boolean}>(`${this.baseUrl}/verify-sms-otp`, {otp});
  }

  generateApiKey(): Observable<CrmXAgencyApiKey> {
    return this.http.post<CrmXAgencyApiKey>(`${this.baseUrl}/crm/apikey/generate`, {});
  }

  getApiKey(): Observable<CrmXAgencyApiKey | null> {
    return this.http.get<CrmXAgencyApiKey>(`${this.baseUrl}/crm/apikey`, {});
  }

  resendInvitation(invitedAgentId: string): Observable<void> {
    return this.http.post<void>(`${this.baseUrl}/resend-invitation`, {invitedAgentId});
  }

  searchChangelog(searchParams: ChangelogSearchParams, agentId?: string): Observable<PagedResult<ChangelogEvent>> {
    const body: ChangelogSearchParams & {agentId?: string} = searchParams ?? {};
    if (agentId) {
      body.agentId = agentId;
    }
    return this.http.post<PagedResult<ChangelogEvent>>(`${this.baseUrl}/changelog/search`, body);
  }

  searchBackgroundTask<Input extends BackgroundTaskInput, Output extends BackgroundTaskOutput>(
    searchParams: BackgroundTaskSearchParam
  ): Observable<PagedResult<BackgroundTask<Input, Output>>> {
    return this.http.post<PagedResult<BackgroundTask<Input, Output>>>(`${this.baseUrl}/background-task/`, searchParams);
  }

  cancelBackgroundTask(taskId: string): Observable<unknown> {
    return this.http.patch<unknown>(`${this.baseUrl}/background-task/${taskId}/cancel`, {});
  }

  getMedia(mediaId: string): Observable<{accessUrl: string}> {
    return this.http.get<{accessUrl: string}>(`${this.baseUrl}/background-task/media/${mediaId}`);
  }

  deleteAgent(agentIdToBeRemoved: string, agentDeleteRequest: AgentDeleteRequest): Observable<void> {
    return this.http.delete<void>(`${this.baseUrl}/agent/${agentIdToBeRemoved}`, {
      body: agentDeleteRequest
    });
  }

  deleteInvitation(agentInvitationToRemove: string, toAssignAgentId: string | null): Observable<void> {
    return this.http.delete<void>(`${this.baseUrl}/invitation/${agentInvitationToRemove}`, {
      body: {
        toAssignAgentId
      }
    });
  }

  searchDashboards(params: DashboardSearchParams): Observable<PagedResult<FelloDashboard>> {
    return this.http.post<PagedResult<FelloDashboard>>(`${this.baseUrl}/dashboard/search`, {params});
  }

  getAgentAffiliationAuthToken(): Observable<{authToken: string | null}> {
    return this.http.get<{authToken: string | null}>(`${this.baseUrl}/affiliation-program/auth-token`);
  }

  emulateAgent(agentId: string): Observable<unknown> {
    return this.http.post<unknown>(`${this.baseUrl}/auth/emulate`, {agentId});
  }

  emulationStatus(): Observable<AgentEmulationStatus> {
    return this.http.get<AgentEmulationStatus>(`${this.baseUrl}/auth/emulate/status`);
  }

  thinkficJwtToken(): Observable<{token: string}> {
    return this.http.get<{token: string}>(`${this.baseUrl}/auth/thinkfic/jwt-token`);
  }

  emulateAgentReturnToAccount(): Observable<unknown> {
    return this.http.post<unknown>(`${this.baseUrl}/auth/emulate/logout`, {});
  }

  searchChangelogWithContacts(searchParams: ChangelogSearchParams, agentId?: string): Observable<PagedResult<ChangelogWithContact>> {
    const body: ChangelogSearchParams & {agentId?: string} = searchParams ?? {};
    if (agentId) {
      body.agentId = agentId;
    }
    return this.http.post<PagedResult<ChangelogWithContact>>(`${this.baseUrl}/changelog/contact/search`, body);
  }

  makeAccountOwner(toOwnerId: string): Observable<unknown> {
    return this.http.patch<unknown>(`${this.baseUrl}/agency/owner`, {toOwnerId});
  }
}
