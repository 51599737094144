<div class="flex-box-center-between mb-3">
  <h3>Edit Photo</h3>
  <button class="button-icon button-primary-dark close ml-auto" mat-dialog-close>
    <app-icon class="text-size-28">close</app-icon>
  </button>
</div>

<mat-dialog-content>
  <image-cropper
    [imageFile]="originalFile"
    [cropper]="cropperPosition"
    [alignImage]="'center'"
    [aspectRatio]="1"
    [roundCropper]="roundCropper"
    [canvasRotation]="canvasRotation"
    [transform]="transform"
    [imageQuality]="100"
    [maintainAspectRatio]="maintainAspectRatio"
    (cropperReady)="cropperReady()"
  >
  </image-cropper>
  <!--  <div class="flex-box-lg-center-between flex-box-gap-3">-->
  <!--    <div class="w-100">-->
  <!--      <div class="text-size-16">Zoom</div>-->
  <!--      <div class="flex-box-center-between flex-box-gap-1">-->
  <!--        <div class="text-size-24" (click)="updateZoom(scale-scaleStep)">-</div>-->
  <!--        <mat-slider min="1" max="10" [step]="scaleStep" (change)="updateZoom($event?.value??0)" class="w-100">-->
  <!--          <input matSliderThumb />-->
  <!--        </mat-slider>-->
  <!--        <div class="text-size-24" (click)="updateZoom(scale+scaleStep)">+</div>-->
  <!--      </div>-->
  <!--    </div>-->
  <!--    <div class="w-100">-->
  <!--      <div class="text-size-16">Straighten</div>-->
  <!--      <div class="flex-box-center-between flex-box-gap-1">-->
  <!--        <div class="text-size-24" (click)="updateZoom(scale-scaleStep)">-</div>-->
  <!--        <mat-slider min="1" max="10" [step]="scaleStep" (change)="updateZoom($event?.value??0)" class="w-100">-->
  <!--          <input matSliderThumb />-->
  <!--        </mat-slider>-->
  <!--        <div class="text-size-24" (click)="updateZoom(scale+scaleStep)">+</div>-->
  <!--      </div>-->
  <!--    </div>-->
  <!--  </div>-->
</mat-dialog-content>

<div class="flex-box-center-end">
  <button (click)="submit()">Submit</button>
</div>
