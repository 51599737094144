import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from "@angular/router";
import {Observable, of} from "rxjs";
import {makeStateKey, StateKey, TransferState} from "@angular/platform-browser";
import {Inject, Injectable, PLATFORM_ID} from "@angular/core";
import {tap} from "rxjs/operators";
import {isPlatformBrowser, isPlatformServer} from "@angular/common";

@Injectable()
export abstract class StateTransferResolver<T> implements Resolve<T> {
  constructor(protected transferState: TransferState, @Inject(PLATFORM_ID) private platformId: Object) {}
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<T> | Promise<T> | T {
    const stateKey = this.buildStateKey(route);
    const existingState = this.transferState.get<T | null>(stateKey, null);
    if (existingState) {
      return this.transform(of(existingState), route, state).pipe(
        tap(() => {
          if (isPlatformBrowser(this.platformId)) {
            this.transferState.remove(stateKey);
          }
        })
      );
    }
    return this.transform(
      this.resolve2(route, state).pipe(
        tap(state => {
          if (isPlatformServer(this.platformId)) {
            this.transferState.set(stateKey, state);
          }
        })
      ),
      route,
      state
    );
  }

  protected buildStateKey(route: ActivatedRouteSnapshot): StateKey<T> {
    let stateKey = "";
    route.paramMap.keys.forEach(key => {
      const value = route.paramMap.get(key);
      stateKey += `${key}:${value}`;
    });

    return makeStateKey(stateKey);
  }

  protected transform(obs: Observable<T>, route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<T> {
    return obs;
  }

  abstract resolve2(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<T>;
}
