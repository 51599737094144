import {FelloMultiAvmResponseAdjustedAvm} from "./FelloMultiAvmResponse";

export enum HomeEstimateProvider {
  ATTOM = "AT",
  HJ = "HJ"
}

export enum HomeEstimateConfidence {
  VERY_LOW = "VERY LOW",
  LOW = "LOW",
  MEDIUM = "MEDIUM",
  HIGH = "HIGH",
  VERY_HIGH = "VERY HIGH"
}

export const HomeEstimateConfidenceLabel: Record<HomeEstimateConfidence, string> = {
  [HomeEstimateConfidence.VERY_LOW]: "Low",
  [HomeEstimateConfidence.LOW]: "Low",
  [HomeEstimateConfidence.MEDIUM]: "Medium",
  [HomeEstimateConfidence.HIGH]: "High",
  [HomeEstimateConfidence.VERY_HIGH]: "High"
};

export interface HomeEstimates {
  high: number;
  low: number;
  median: number;
  confidence: HomeEstimateConfidence;
  valueDate?: string;
  adjustment?: FelloMultiAvmResponseAdjustedAvm;
}
