import {NgModule} from "@angular/core";
import {
  AddressAutoCompleteComponent,
  ConfirmationDialogComponent,
  CustomTooltipComponent,
  ImageCropperModalComponent,
  InfiniteScrollLoaderDirective,
  InfiniteScrollNoDataDirective,
  SimpleInfiniteScrollComponent,
  SimpleInfiniteScrollItemDirective
} from "./components";
import {
  GroupedInfiniteScrollComponent,
  GroupedInfiniteScrollGroupSeparatorDirective,
  GroupedInfiniteScrollItemDirective
} from "./components/grouped-infinite-scroll/grouped-infinite-scroll.component";
import {ColorInputComponent} from "./components/color-input/color-input.component";
import {DropdownWithSearchComponent} from "./components/dropdown-with-search/dropdown-with-search.component";
import {ConfirmationWithTextDialogComponent} from "./components/confirmation-with-text-dialog/confirmation-with-text-dialog.component";
import {AmountCommaSeparatedComponent} from "./components/amount-comma-separated/amount-comma-separated.component";
import {MultiSelectDropdownWithSearchComponent} from "./components/multi-select-dropdown-with-search/multi-select-dropdown-with-search.component";
import {SmartyAddressAutoCompleteComponent} from "./components/smarty-address-auto-complete/smarty-address-auto-complete.component";
import {TooltipDirective, TrimRequiredDirective} from "./directives";

const standaloneItems = [
  ConfirmationDialogComponent,
  AddressAutoCompleteComponent,
  SimpleInfiniteScrollComponent,
  InfiniteScrollNoDataDirective,
  InfiniteScrollLoaderDirective,
  SimpleInfiniteScrollItemDirective,
  GroupedInfiniteScrollComponent,
  GroupedInfiniteScrollItemDirective,
  GroupedInfiniteScrollGroupSeparatorDirective,
  CustomTooltipComponent,
  ColorInputComponent,
  ImageCropperModalComponent,
  DropdownWithSearchComponent,
  MultiSelectDropdownWithSearchComponent,
  ConfirmationWithTextDialogComponent,
  AmountCommaSeparatedComponent,
  TooltipDirective,
  TrimRequiredDirective,
  SmartyAddressAutoCompleteComponent,
];

@NgModule({
  imports: standaloneItems,
  exports: standaloneItems
})
export class FelloUiLibModule {}
