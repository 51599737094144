import {Pipe, PipeTransform} from "@angular/core";
import HandleBars, {compile, RuntimeOptions} from "handlebars";
import {NumberUtils} from "../../../lib";

HandleBars.registerHelper("mul", (a, b) => a * b);
HandleBars.registerHelper("div", (a, b) => a / b);
HandleBars.registerHelper("equals", (a, b) => a === b);
HandleBars.registerHelper("roundDown", a => NumberUtils.roundDown(a));
HandleBars.registerHelper("ternary", (cond: boolean, a, b) => (cond ? a : b));
HandleBars.registerHelper("formatNumber", (value: number) =>
  Number(value).toLocaleString(undefined, {
    maximumFractionDigits: 0,
    minimumFractionDigits: 0
  })
);

@Pipe({
  name: "handlebars",
  standalone: true
})
export class HandlebarsPipe implements PipeTransform {
  transform(template: string, context: any, options?: RuntimeOptions): string {
    return compile(template)(context, options);
  }
}
