import {Component, Input, OnInit, ViewChild} from "@angular/core";
import {find, forEach} from "lodash-es";
import {BasicHomeFactsUpdateRequest} from "fello-model";
import {NgForm} from "@angular/forms";

export enum HomeInfoFieldType {
  NUMBER = "NUMBER",
  RADIO = "RADIO"
}

export interface HomeInfoField {
  name: string;
  value: number | null;
  type: HomeInfoFieldType;
  options?: number[];
  icon: string;
  reference: "beds" | "sqft" | "partialBaths" | "baths" | "storiesCount" | "yearBuilt" | "parkingCount";
  min: number;
  max?: number;
  required: boolean;
}

const CURRENT_YEAR = new Date().getFullYear();

@Component({
  selector: "app-home-details-stats",
  templateUrl: "./home-details-stats.component.html",
  styleUrls: ["./home-details-stats.component.scss"]
})
export class HomeDetailsStatsComponent implements OnInit {
  @Input() homeFacts: Partial<BasicHomeFactsUpdateRequest>;
  @ViewChild(NgForm) form: NgForm;
  homeInfoFields: HomeInfoField[] = [
    {
      name: "Bedrooms",
      value: null,
      type: HomeInfoFieldType.RADIO,
      options: [1, 2, 3, 4, 5],
      reference: "beds",
      icon: "bedroom",
      min: 0,
      max: 10,
      required: false
    },
    {
      name: "Full baths",
      value: null,
      type: HomeInfoFieldType.RADIO,
      options: [1, 2, 3, 4, 5],
      reference: "baths",
      icon: "bathroom",
      min: 0,
      max: 10,
      required: false
    },
    {
      name: "Half baths",
      value: null,
      type: HomeInfoFieldType.RADIO,
      options: [0, 1, 2, 3, 4, 5],
      reference: "partialBaths",
      icon: "half-bath",
      min: 0,
      max: 10,
      required: false
    },
    {
      name: "Stories",
      value: null,
      type: HomeInfoFieldType.RADIO,
      options: [1, 2, 3, 4, 5],
      reference: "storiesCount",
      icon: "stories-count",
      min: 0,
      max: 10,
      required: false
    },
    {
      name: "Square feet",
      value: null,
      type: HomeInfoFieldType.NUMBER,
      reference: "sqft",
      icon: "square-feet",
      min: 0,
      max: 50000,
      required: false
    },
    {
      name: "Parking",
      value: null,
      type: HomeInfoFieldType.NUMBER,
      reference: "parkingCount",
      icon: "parking-car-garage",
      min: 0,
      max: 10,
      required: false
    },
    {
      name: "Year built",
      value: null,
      type: HomeInfoFieldType.NUMBER,
      reference: "yearBuilt",
      icon: "year-built",
      min: 1700,
      max: CURRENT_YEAR,
      required: false
    }
  ];

  ngOnInit(): void {
    this.homeFacts.beds != null ? this.updateFieldValue("Bedrooms", this.homeFacts.beds) : this.updateFieldValue("Bedrooms", null);
    this.homeFacts.baths != null ? this.updateFieldValue("Full baths", this.homeFacts.baths) : this.updateFieldValue("Full baths", null);
    this.homeFacts.yearBuilt != null
      ? this.updateFieldValue("Year built", this.homeFacts.yearBuilt)
      : this.updateFieldValue("Year built", null);

    this.homeFacts.partialBaths != null
      ? this.updateFieldValue("Half baths", this.homeFacts.partialBaths)
      : this.updateFieldValue("Half baths", null);
    this.homeFacts.sqft != null ? this.updateFieldValue("Square feet", this.homeFacts.sqft) : this.updateFieldValue("Square feet", null);
    this.homeFacts.storiesCount != null
      ? this.updateFieldValue("Stories", this.homeFacts.storiesCount)
      : this.updateFieldValue("Stories", null);
    this.homeFacts.parkingCount != null
      ? this.updateFieldValue("Parking", this.homeFacts.parkingCount)
      : this.updateFieldValue("Parking", null);
  }

  get validForm(): boolean {
    let valid = true;
    forEach(this.homeInfoFields, field => {
      if (field.value === null || field.value < 0) {
        valid = false;
      }
    });
    return valid;
  }

  updateFieldValue(fieldName: string, value: number | null): void {
    const field = find(this.homeInfoFields, {name: fieldName})!;
    field.value = value;
  }

  getFieldValue(fieldName: string): number | null {
    const field = find(this.homeInfoFields, {name: fieldName})!;
    return field.value;
  }

  saveField(field: HomeInfoField): void {
    this.homeFacts[field.reference] = field.value ?? undefined;
  }

  public get isValid(): boolean {
    return this.form?.valid ?? false;
  }

  public asBasicHomeFactsUpdateRequest(): BasicHomeFactsUpdateRequest {
    return this.homeInfoFields.reduce(
      (previousValue, currentValue) => ({
        ...previousValue,
        [currentValue.reference]: currentValue.value
      }),
      {}
    ) as BasicHomeFactsUpdateRequest;
  }
}
