import {Meta} from "@angular/platform-browser";
import {Injectable} from "@angular/core";
import {TitleService} from "../../../lib";

export interface SocialSettings {
  title?: string;
  description?: string;
  image?: string;
}

@Injectable({
  providedIn: "root"
})
export class SocialSettingsService {
  constructor(private meta: Meta, private title: TitleService) {}

  applySocialSettings(settings: SocialSettings): void {
    this._applyTagByProperty("og:title", settings.title);
    this._applyTagByName("title", settings.title);
    this.title.setTitle(settings.title ?? "");

    this._applyTagByProperty("og:description", settings.description);
    this._applyTagByName("description", settings.description);

    this._applyTagByProperty("og:image", settings.image);
  }

  removeSocialSettings(): void {
    this.applySocialSettings({});
  }

  private _applyTagByProperty(property: string, value?: string): void {
    this._applyTagBy("property", property, value);
  }

  private _applyTagByName(name: string, value?: string): void {
    this._applyTagBy("name", name, value);
  }

  private _applyTagBy(idKey: "property" | "name", idValue: string, value?: string): void {
    this._removeAllTagsBy(idKey, idValue);
    if (!value) {
      return;
    }
    this.meta.addTag({
      [idKey]: idValue,
      content: value
    });
  }

  private _removeAllTagsBy(idKey: "property" | "name", idValue: string, leave = 0) {
    const tagElems = this.meta.getTags(`${idKey}='${idValue}'`);
    if (tagElems?.length) {
      for (let i = leave; i < tagElems.length; i++) {
        this.meta.removeTagElement(tagElems[i]);
      }
    }
  }
}
