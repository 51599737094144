import {Component, ElementRef, forwardRef, OnInit} from "@angular/core";
import {Color, ScaleType} from "@swimlane/ngx-charts";
import {takeUntil, tap} from "rxjs/operators";
import {NgxSpinnerService} from "ngx-spinner";
import {MatSnackBar} from "@angular/material/snack-bar";
import {mixinDestroyable, mixinSpinnerAndToast} from "../../../../lib";
import {DashboardModuleButtonAction, DashboardModuleButtonType, DashboardModuleType} from "fello-model";
import {DashboardModuleDirective} from "../../directives/dashboard-module.directive";
import {AbstractDashboardService} from "../../services";
import {BehaviorSubject, combineLatest} from "rxjs";
import {HomeEquityCalculator} from "./HomeEquityCalculator";

const HomeEquityBaseComponent = mixinDestroyable(
  mixinSpinnerAndToast(
    class extends DashboardModuleDirective<DashboardModuleType.HOME_EQUITY> {
      constructor(public spinnerService: NgxSpinnerService, public snackBar: MatSnackBar, elementRef: ElementRef) {
        super(elementRef);
      }
    }
  )
);

@Component({
  selector: "lib-home-equity",
  templateUrl: "./home-equity.component.html",
  styleUrls: ["./home-equity.component.scss"],
  providers: [
    {
      provide: DashboardModuleDirective,
      useExisting: forwardRef(() => HomeEquityComponent)
    }
  ]
})
export class HomeEquityComponent extends HomeEquityBaseComponent implements OnInit {
  calculator: HomeEquityCalculator;
  MAX_HOME_SALE_PRICE = 10000000;
  toolTipDisabled = new BehaviorSubject<boolean>(false);
  cardOpen = true;
  additionalCostsOpen = true;
  DashboardModuleButtonType = DashboardModuleButtonType;

  colorScheme: Color = {
    name: "color",
    selectable: true,
    group: ScaleType.Ordinal,
    domain: ["var(--color-primary)", "var(--slate-2)"]
  };

  constructor(
    private dashboardService: AbstractDashboardService,
    elementRef: ElementRef,
    spinnerService: NgxSpinnerService,
    snackBar: MatSnackBar
  ) {
    super(spinnerService, snackBar, elementRef);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.calculator = new HomeEquityCalculator(this.moduleSetting);
    combineLatest([this.dashboardService.homeEstimate$, this.dashboardService.homeEquity$])
      .pipe(
        takeUntil(this.isDestroyed),
        tap(([homeEstimate, equity]) => {
          if (homeEstimate.median) {
            this.calculator.homeSalePrice = homeEstimate.median;
            this.MAX_HOME_SALE_PRICE = Math.round(homeEstimate.median * 2);
          }
          if (equity) {
            if (equity.ltv < 100) {
              this.calculator.mortgage = equity.loanAmount;
            }
          }
        })
      )
      .subscribe();
  }

  performCTAAction(action: DashboardModuleButtonAction): void {
    this.withSpinner(this.dashboardService.performCTAAction(action)).subscribe();
  }
}
