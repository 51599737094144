<!--<header [ngClass]="{'open': headerOpen}">-->
<!--  <div class="flex-box-center-between fixed-top">-->
<!--    <a class="logo">-->
<!--      <svg width="71" height="30" viewBox="0 0 71 30" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--        <path-->
<!--          d="M7.59689 13.2985H10.9052C11.7629 13.2985 12.4981 12.6004 12.4981 11.7452C12.4981 10.8552 11.7629 10.1571 10.9052 10.1571H7.59689V8.42934C7.56188 5.98605 8.12202 3.54276 11.2028 3.54276H11.7279C12.7082 3.54276 13.5309 2.77488 13.5309 1.79756C13.5309 0.76789 12.7082 0 11.7279 0H11.2028C6.0565 0 3.88597 3.4206 3.88597 8.42934V10.1571H1.5929C0.735183 10.1571 0 10.8552 0 11.7103C0 12.6004 0.735183 13.2985 1.5929 13.2985H3.88597V27.9233C3.88597 28.9355 4.70867 29.7906 5.72392 29.7906C6.79169 29.7906 7.59689 28.9355 7.59689 27.9233V13.2985Z"-->
<!--        />-->
<!--        <path-->
<!--          d="M16.996 18.5856C17.4511 15.4093 19.9017 13.4896 23.0525 13.4896C26.1158 13.4896 28.4088 15.4093 28.864 18.5856H16.996ZM32.5224 20.0167C32.5224 13.9782 28.4789 10.1562 23.035 10.1562C17.5912 10.1562 13.2676 13.9782 13.2676 20.0167C13.2676 26.0551 17.6087 29.9992 23.035 29.9992C26.1508 29.9992 29.004 28.8648 30.8419 26.4914C31.4196 25.8456 31.2445 24.7462 30.5094 24.1702C29.7742 23.6467 28.7064 23.891 28.0237 24.6589C26.7109 26.09 25.4506 26.6484 23.1575 26.6484C19.9367 26.6484 17.4336 24.6589 16.9785 21.4652H31.0345C31.8397 21.4826 32.5224 20.8718 32.5224 20.0167Z"-->
<!--        />-->
<!--        <path-->
<!--          d="M39.2969 1.86737C39.2969 0.85515 38.4742 0 37.4239 0C36.4086 0 35.5859 0.85515 35.5859 1.86737V27.9058C35.5859 28.918 36.4086 29.7732 37.4239 29.7732C38.4917 29.7732 39.2969 28.918 39.2969 27.9058V1.86737Z"-->
<!--        />-->
<!--        <path-->
<!--          d="M47.2441 1.86737C47.2441 0.85515 46.4214 0 45.3712 0C44.3559 0 43.5332 0.85515 43.5332 1.86737V27.9058C43.5332 28.918 44.3559 29.7732 45.3712 29.7732C46.4389 29.7732 47.2441 28.918 47.2441 27.9058V1.86737Z"-->
<!--        />-->
<!--        <path-->
<!--          d="M56.4344 25.742C56.4344 26.9637 55.4366 27.9584 54.2113 27.9584C52.986 27.9584 51.9883 26.9637 51.9883 25.742C51.9883 24.5204 52.986 23.5256 54.2113 23.5256C55.4366 23.5082 56.4344 24.5029 56.4344 25.742Z"-->
<!--        />-->
<!--        <path-->
<!--          fill-rule="evenodd"-->
<!--          clip-rule="evenodd"-->
<!--          d="M54.0366 21.8317C53.8615 21.2558 53.774 20.6624 53.774 20.0341C53.774 16.5437 56.6097 13.7165 60.1106 13.7165C60.6882 13.7165 61.2484 13.8037 61.7735 13.9433C61.861 12.617 62.5962 11.4826 63.664 10.8194C62.5612 10.4006 61.3709 10.1562 60.1106 10.1562C54.6317 10.1562 50.2031 14.5716 50.2031 20.0341C50.2031 21.2907 50.4482 22.4949 50.8683 23.6118C51.5685 22.5821 52.7063 21.884 54.0366 21.8317Z"-->
<!--        />-->
<!--        <path-->
<!--          fill-rule="evenodd"-->
<!--          clip-rule="evenodd"-->
<!--          d="M69.2484 16.1953C68.6183 17.2773 67.498 18.0452 66.1677 18.1674C66.3427 18.7608 66.4477 19.3716 66.4477 20.0173C66.4477 23.5077 63.612 26.3349 60.1112 26.3349C59.446 26.3349 58.7983 26.2302 58.2032 26.0383C58.1332 27.3472 57.4155 28.4816 56.3652 29.1622C57.5205 29.6334 58.7808 29.8952 60.0937 29.8952C65.5725 29.8952 70.0011 25.4798 70.0011 20.0173C70.0186 18.6735 69.7386 17.3646 69.2484 16.1953Z"-->
<!--        />-->
<!--        <path-->
<!--          d="M68.0047 14.2069C68.0047 15.4285 67.007 16.4233 65.7816 16.4233C64.5563 16.4233 63.5586 15.4285 63.5586 14.2069C63.5586 12.9852 64.5563 11.9905 65.7816 11.9905C67.007 11.973 68.0047 12.9678 68.0047 14.2069Z"-->
<!--        />-->
<!--      </svg>-->
<!--    </a>-->
<!--    <div class="flex-box-center">-->
<!--      &lt;!&ndash; <div class="agent-info active">-->
<!--        <img src="/assets/images/offer-flow/user.svg" alt="agent-pic" />-->
<!--      </div> &ndash;&gt;-->
<!--      <ng-container *ngIf="!isAgentDashboard">-->
<!--        <div *ngIf="(felloContactProfile$ | async ) as felloContactprofile;else notLoggedIn">-->
<!--          <div class="user-dropdown mr-2" [matMenuTriggerFor]="menu">-->
<!--            <img src="/assets/images/common/user.svg" alt="user" class="user-img" />-->
<!--            <lib-icon class="ml-1 c-pointer" color="var(&#45;&#45;white)" fontSize="18">down</lib-icon>-->
<!--          </div>-->
<!--          <mat-menu #menu="matMenu">-->
<!--            <p class="fw-600 text-size-18 pl-3 pr-3 mb-2 mt-2">-->
<!--              Hi, {{(felloContactprofile.fullName ?? felloContactprofile.emailId.split('@')[0]) | titlecase}}-->
<!--            </p>-->
<!--            <button mat-menu-item (click)="logout()">Logout</button>-->
<!--          </mat-menu>-->
<!--        </div>-->
<!--      </ng-container>-->
<!--      <ng-container *ngIf="offer$ | async; else hamburger let offer">-->
<!--        <ng-container *ngIf="isOfferStatusBefore(offer.status, OfferStatus.PA_GENERATION)">-->
<!--          <ng-container *ngTemplateOutlet="hamburger"></ng-container>-->
<!--        </ng-container>-->
<!--      </ng-container>-->
<!--      <ng-template #hamburger>-->
<!--        <div class="hamburger-menu d-lg-none" (click)="headerOpen = !headerOpen" [ngClass]="{'open': headerOpen}">-->
<!--          <div class="bar1"></div>-->
<!--          <div class="bar2"></div>-->
<!--          <div class="bar3"></div>-->
<!--        </div>-->
<!--      </ng-template>-->
<!--    </div>-->
<!--  </div>-->
<!--  <ng-template #notLoggedIn> <a class="mr-2 text-color-white fw-600 login-bttn" [routerLink]="['/login']">Login</a></ng-template>-->

<!--  <div class="header-body">-->
<!--    <div class="header-bottom">-->
<!--      <ul class="main" *ngIf="listItems">-->
<!--        <ng-container *ngFor="let item of listItems">-->
<!--          <li>-->
<!--            <a class="text-size-20 fw-700" *ngIf="item.isVisible" (click)="goToItem(item)"> {{item.title}}</a>-->
<!--          </li>-->
<!--        </ng-container>-->
<!--      </ul>-->
<!--      <ng-container *ngIf="!isAgentDashboard">-->
<!--        <div *ngIf="!(felloContactProfile$ | async ) as felloContactprofile;">-->
<!--          <div class="line-break"></div>-->
<!--          <a class="mr-2 text-color-primary fw-600 text-size-20 fw-700" [routerLink]="['/login']">Login</a>-->
<!--        </div>-->
<!--      </ng-container>-->
<!--    </div>-->
<!--  </div>-->
<!--</header>-->

<header>
  <ng-container *ngIf="(dashboardSettings$ | async); let settings">
    <ng-container *ngIf="settings.logo; let logo">
      <ng-container *ngIf="settings.website; else justLogo">
        <a [href]="settings.website" target="_blank" class="flex-box">
          <img [src]="logo" alt="logo" class="img-logo" />
        </a>
      </ng-container>
      <ng-template #justLogo>
        <img [src]="logo" alt="logo" class="img-logo" />
      </ng-template>
    </ng-container>
  </ng-container>
  <div class="flex-box-center ml-auto">
    <p class="text-size-8 whitespace-nowrap">Powered by</p>
    <img src="/assets/images/svg/fello.svg" alt="fello" class="img-fello ml-1" />
  </div>
</header>
