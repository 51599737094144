export enum FeatureName {
  SUBDOMAIN = "SUBDOMAIN",
  MAIL_SENDING_DOMAIN = "MAIL_SENDING_DOMAIN",
  LEAD_ASSIGNMENT = "LEAD_ASSIGNMENT",
  LEAD_POND = "LEAD_POND",
  ACCOUNT_AVM_ADJUSTMENT = "ACCOUNT_AVM_ADJUSTMENT",
  NON_MARKETING_CONTACT_SETTINGS = "NON_MARKETING_CONTACT_SETTINGS",
  AUTOMATIONS = "AUTOMATIONS",
  EMAIL_CONTENT = "EMAIL_CONTENT",
  CRM_INBOUND_PREFERENCES = "CRM_INBOUND_PREFERENCES",
  CTA_CLICK_REPORT = "CTA_CLICK_REPORT",
  CONTACT_AI_SUMMARY = "CONTACT_AI_SUMMARY",
  WORKFLOWS = "WORKFLOWS"
}

export const FeatureNameLabel: Record<FeatureName, string> = {
  [FeatureName.SUBDOMAIN]: "Custom Brand Domain",
  [FeatureName.MAIL_SENDING_DOMAIN]: "Custom Email Sending Domain",
  [FeatureName.LEAD_ASSIGNMENT]: "Lead Assignment",
  [FeatureName.LEAD_POND]: "Lead Pond",
  [FeatureName.ACCOUNT_AVM_ADJUSTMENT]: "Bulk AVM Adjustment (Account Level)",
  [FeatureName.NON_MARKETING_CONTACT_SETTINGS]: "Non-Marketing Contact Settings",
  [FeatureName.AUTOMATIONS]: "Automations",
  [FeatureName.EMAIL_CONTENT]: "Email Content",
  [FeatureName.CRM_INBOUND_PREFERENCES]: "CRM Inbound Preferences",
  [FeatureName.CTA_CLICK_REPORT]: "CTA Click Report",
  [FeatureName.CONTACT_AI_SUMMARY]: "Contact AI Summary",
  [FeatureName.WORKFLOWS]: "Workflows"
};

export interface Feature {
  name: FeatureName;
}
