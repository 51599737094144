import {Pipe, PipeTransform} from "@angular/core";

@Pipe({
  name: "phone",
  standalone: true
})
export class PhonePipe implements PipeTransform {
  transform(phone: string): string {
    if (!phone) {
      return phone;
    }
    phone = phone.match(/\d+/g)?.join("") ?? "";
    if (phone.charAt(0) === "1") {
      phone = phone.substr(1);
    }
    if (phone.length !== 10) {
      return phone;
    }
    return `${phone.substr(0, 3)}-${phone.substr(3, 3)}-${phone.substr(6)}`;
  }
}
