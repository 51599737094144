export enum DataProviderType {
  HOMEJUNCTION = "HJ",
  ATTOM = "ATTOM",
  QUANTARIUM = "QUANTARIUM",
  ESTATED = "ESTATED",
  SELLER_HUBSPOT = "SELLER_HUBSPOT",
  SELLER_FELLO_OFFERJOURNEY = "SELLER_FELLO_OFFERJOURNEY",
  ADMIN = "ADMIN",
  FLASHHOUSE = "FLASHHOUSE",
  FLASHHOUSE_MX = "FLASHHOUSE_MX",
  ATTOM_TA = "ATTOM_TA",
  CLEAR_CAPITAL = "CLEAR_CAPITAL",
  BIFROST_MJ = "BIFROST_MJ",
  AI_V1 = "AI_V1",
  ATTOM_BULK = "ATTOM_BULK",
  CL_BULK = "CL_BULK",
  OVERRIDE = "OVERRIDE"
}

export const providerTypeLabel: Record<DataProviderType, string> = {
  [DataProviderType.HOMEJUNCTION]: "HJ",
  [DataProviderType.ATTOM]: "ATTOM",
  [DataProviderType.QUANTARIUM]: "Quantarium",
  [DataProviderType.SELLER_HUBSPOT]: "Seller",
  [DataProviderType.ESTATED]: "Estated",
  [DataProviderType.ADMIN]: "Admin",
  [DataProviderType.FLASHHOUSE]: "FlashHouse",
  [DataProviderType.FLASHHOUSE_MX]: "FlashHouse MX",
  [DataProviderType.ATTOM_TA]: "Bulk",
  [DataProviderType.CLEAR_CAPITAL]: "Bulk",
  [DataProviderType.BIFROST_MJ]: "AI - Mj",
  [DataProviderType.AI_V1]: "AI - V1",
  [DataProviderType.SELLER_FELLO_OFFERJOURNEY]: "Seller",
  [DataProviderType.OVERRIDE]: "Other",
  [DataProviderType.ATTOM_BULK]: "ATTOM",
  [DataProviderType.CL_BULK]: "CoreLogic"
};

export const providerTypeTooltip: Record<DataProviderType, string> = {
  [DataProviderType.HOMEJUNCTION]: "HJ",
  [DataProviderType.ATTOM]:
    "ATTOM AVM utilizes a comprehensive dataset, including public records and real estate data, to deliver precise property valuations. It is known for its detailed analytics and market insights.",
  [DataProviderType.QUANTARIUM]:
    "Quantarium AVM leverages advanced AI and machine learning to provide highly accurate home valuations. It integrates extensive property data and market trends to ensure reliable estimates.",
  [DataProviderType.SELLER_HUBSPOT]: "Seller",
  [DataProviderType.ESTATED]: "Estated",
  [DataProviderType.ADMIN]: "Admin",
  [DataProviderType.FLASHHOUSE]: "FlashHouse",
  [DataProviderType.FLASHHOUSE_MX]: "FlashHouse MX",
  [DataProviderType.ATTOM_TA]: "Bulk",
  [DataProviderType.CLEAR_CAPITAL]: "Bulk",
  [DataProviderType.BIFROST_MJ]: "AI - Mj",
  [DataProviderType.AI_V1]: "AI - V1",
  [DataProviderType.SELLER_FELLO_OFFERJOURNEY]: "Seller",
  [DataProviderType.OVERRIDE]: "Other",
  [DataProviderType.ATTOM_BULK]:
    "ATTOM AVM utilizes a comprehensive dataset, including public records and real estate data, to deliver precise property valuations. It is known for its detailed analytics and market insights.",
  [DataProviderType.CL_BULK]:
    "CoreLogic AVM combines vast amounts of property data and proprietary algorithms to offer accurate home value estimates. It is trusted for its thorough and data-driven approach to property valuation"
};
