import {Component, forwardRef, HostBinding} from "@angular/core";
import {
  AbstractControl,
  ControlValueAccessor,
  FormsModule,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
  ValidatorFn,
  Validators
} from "@angular/forms";
import {RegexUtils} from "../../../fello-ui-utils";

const colorHexCodePatternValidator = Validators.pattern(RegexUtils.COLOR_HEX_CODE);

export const colorValidator: ValidatorFn = control => {
  const errors = colorHexCodePatternValidator(control);
  if (errors) {
    return {
      invalidHexCode: errors.pattern
    };
  }
  return null;
};

@Component({
  selector: "lib-color-input",
  templateUrl: "./color-input.component.html",
  styleUrls: ["./color-input.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ColorInputComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => ColorInputComponent),
      multi: true
    }
  ],
  imports: [FormsModule],
  standalone: true
})
export class ColorInputComponent implements ControlValueAccessor, Validator {
  color: string;

  @HostBinding("class") classes = "flex-box-center";

  disabled: boolean;
  private _validator = colorValidator;
  _onChange: (value: any) => void = () => {
    return;
  };
  _onTouched = (): void => {
    return;
  };

  registerOnChange(fn: any): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this._onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  writeValue(color: any): void {
    this.color = color;
  }

  validate(control: AbstractControl): ValidationErrors | null {
    return colorValidator(control);
  }
}
