import {Directive, Inject, Input, OnInit, PLATFORM_ID, TemplateRef, ViewContainerRef} from "@angular/core";
import {isPlatformBrowser} from "@angular/common";

@Directive({
  selector: "[libIsPlatformBrowser]",
  standalone: true
})
export class IsPlatformBrowserDirective implements OnInit {
  @Input("libIsPlatformBrowser") dummy?: string;
  @Input("libIsPlatformBrowserElse") elseTemplate?: TemplateRef<unknown>;
  constructor(
    private template: TemplateRef<unknown>,
    // eslint-disable-next-line @typescript-eslint/ban-types
    @Inject(PLATFORM_ID) private platformId: Object,
    private viewContainer: ViewContainerRef
  ) {}

  ngOnInit(): void {
    this.viewContainer.clear();
    if (isPlatformBrowser(this.platformId)) {
      this.viewContainer.createEmbeddedView(this.template);
    } else if (this.elseTemplate) {
      this.viewContainer.createEmbeddedView(this.elseTemplate);
    }
  }
}
