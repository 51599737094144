<ngx-spinner [name]="spinnerName" [fullScreen]="true"></ngx-spinner>

<div class="selling-card mt-3" *ngIf="sellingOptionSettings.enabled">
  <div class="border-bottom-1">
    <ng-container *ngIf="accountLogo$ | async; let logo">
      <img [src]="logo" alt="logo" class="img-logo" />
    </ng-container>
  </div>
  <div class="flex-box-center-between flex-box-gap-3 flex-sm-wrap">
    <div>
      <p class="text-size-16 fw-600 mb-2" *ngIf="sellingOptionSettings.subheading">{{ sellingOptionSettings.subheading }}</p>
      <p class="text-size-28 fw-600" *ngIf="sellingOptionSettings.showAvm && sellingOptionWrapper.data.avm">
        <ng-container *ngIf="sellingOptionSettings.avmDisplayValueType === AvmDisplayValueType.RANGE; else avmValue">
          <span class="actual-data"
            >${{ sellingOptionWrapper.data.avm.low | numberShorthand }} - ${{ sellingOptionWrapper.data.avm.high | numberShorthand }}</span
          >
          <span class="dummy-data">$XXXK - $XXXK</span>
        </ng-container>
        <ng-template #avmValue>
          <span class="actual-data">{{ sellingOptionWrapper.data.avm.median | roundOff: 10 | formatPrice }}</span>
          <span class="dummy-data">$XXX,XXX</span>
        </ng-template>
      </p>
      <p class="text-size-14 fw-500 text-color-light-2 mt-1" *ngIf="sellingOptionSettings.showSubtext && sellingOptionSettings.subText">
        {{ sellingOptionSettings.subText }}
      </p>
    </div>
    <button
      class="whitespace-nowrap button-mid"
      [ngClass]="{'button-primary': sellingOptionSettings.ctaButton.type === DashboardModuleButtonType.SECONDARY}"
      libDashboardTrackButtonClick
      (click)="performCTAAction(sellingOptionSettings.ctaButton.action)"
    >
      {{ sellingOptionSettings.ctaButton.label }}
    </button>
  </div>
</div>
