import {FQLOrExpr} from "../../mvc/filtering";
import {ChangelogTriggeredBy} from "../../deal";

export enum ContactSegmentType {
  STANDARD = "STANDARD",
  SHARED = "SHARED",
  PRIVATE = "PRIVATE"
}

export const ContactSegmentTypeLabel: Record<ContactSegmentType, string> = {
  [ContactSegmentType.STANDARD]: "Standard",
  [ContactSegmentType.SHARED]: "Shared",
  [ContactSegmentType.PRIVATE]: "Private"
};

export const ContactSegmentTypeDescriptionLabel: Record<ContactSegmentType, string> = {
  [ContactSegmentType.STANDARD]: "Shared with all users",
  [ContactSegmentType.SHARED]: "Shared with all users",
  [ContactSegmentType.PRIVATE]: "Only Me"
};

export const ContactSegmentTypeIcon: Record<ContactSegmentType, string> = {
  [ContactSegmentType.STANDARD]: "doc-filter",
  [ContactSegmentType.SHARED]: "users-shared",
  [ContactSegmentType.PRIVATE]: "lock-3"
};

export interface AgencyContactSegmentUpdateRequest {
  name: string;
  description?: string;
  type: ContactSegmentType;
  fqlFilters: FQLOrExpr;
  featured?: boolean;
}

export type AgencyContactSegmentCreateRequest = AgencyContactSegmentUpdateRequest;

export interface AgencyContactSegment {
  _id: string;
  name: string;
  agencyId: string;
  description?: string;
  type: ContactSegmentType;
  fqlFilters: FQLOrExpr;
  internalSegmentKey: string;
  filtersLastUpdatedAt: string;
  featured?: boolean;
  createdBy: ChangelogTriggeredBy;
  updatedBy: ChangelogTriggeredBy;
  createdAt: string;
  updatedAt: string;
}

export interface CatalogSegment extends AgencyContactSegment {
  isPinned: boolean;
  isVisible: boolean;
  isWatched: boolean;
}

export interface WatchedSegment {
  segmentId: string;
  lastWebNotificationSentAt?: string;
  lastEmailNotificationSentAt?: string;
  startedWatchingAt: string;
}

export interface AgentSegmentPreference {
  _id: string; // agentId
  agencyId: string;
  visibleStandardSegmentIds: string[];
  pinnedSegmentIds: string[];
  visiblePrivateSegmentIds: string[];
  visibleSharedSegmentIds: string[];
  watchedSegments: WatchedSegment[];
  segmentsGroupOrder: ContactSegmentType[];
}
