import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {LandingPageTemplateAComponent} from "./components/landing-page-template-a/landing-page-template-a.component";
import {LandingPageJourneyTemplateComponent} from "./components/landing-page-journey-template/landing-page-journey-template.component";
import {LpJourneyAddressComponent} from "./components/landing-page-journey-template/lp-journey-address/lp-journey-address.component";
import {SignatureModule} from "../signature";
import {IconComponent, IframeResizeDirective, MediaWithTypePipe, SafeHtmlPipe} from "../fello-ui-utils";
import {FormsModule} from "@angular/forms";
import {LoadingButtonComponent, SmartyAddressAutoCompleteComponent} from "../fello-ui-lib";
import {MatSnackBarModule} from "@angular/material/snack-bar";
import {NgxSkeletonLoaderModule} from "ngx-skeleton-loader";
import {LandingPageTabbedAComponent} from "./components/landing-page-tabbed-a/landing-page-tabbed-a.component";
import {MatTabsModule} from "@angular/material/tabs";
import {MatSelectModule} from "@angular/material/select";
import {ContactDialogComponent} from "./components/contact-dialog/contact-dialog.component";
import {MatDialogModule} from "@angular/material/dialog";
import {NgxSpinnerModule} from "ngx-spinner";
import {MatAutocompleteModule} from "@angular/material/autocomplete";

@NgModule({
  declarations: [
    LandingPageTemplateAComponent,
    LandingPageJourneyTemplateComponent,
    LpJourneyAddressComponent,
    LandingPageTabbedAComponent,
    ContactDialogComponent
  ],
  imports: [
    CommonModule,
    SignatureModule,
    FormsModule,
    MatSnackBarModule,
    NgxSkeletonLoaderModule,
    MatTabsModule,
    MatSelectModule,
    MatDialogModule,
    NgxSpinnerModule,
    MatAutocompleteModule,
    IconComponent,
    SafeHtmlPipe,
    SmartyAddressAutoCompleteComponent,
    MediaWithTypePipe,
    IframeResizeDirective,
    LoadingButtonComponent
  ],
  exports: [
    LandingPageTemplateAComponent,
    LandingPageJourneyTemplateComponent,
    LandingPageTabbedAComponent,
    MatSnackBarModule,
    MatSelectModule
  ]
})
export class LandingPagesModule {}
