import {Pipe, PipeTransform} from "@angular/core";
import {RangedValue} from "fello-model";
import {FormatNumberShorthandUtil} from "../../../lib/utils/formatNumberShorthandUtil";
import {PriceUtil} from "../../../lib";

export function formatPriceRange(range: RangedValue, maximumFractionDigits?: number): string {
  if (range.max) {
    return `${FormatNumberShorthandUtil.transform(range.min, "$")} - ${FormatNumberShorthandUtil.transform(range.max, "$")}`;
  }
  return PriceUtil.formatPrice(range.min, undefined, maximumFractionDigits);
}

@Pipe({
  name: "formatPriceRange",
  standalone: true
})
export class FormatPriceRangePipe implements PipeTransform {
  transform(range: RangedValue, maximumFractionDigits?: number): string {
    return formatPriceRange(range, maximumFractionDigits);
  }
}
