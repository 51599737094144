import {AdditionalCosts, YourHomeEquityDashboardModuleSettings} from "fello-model";
import {BehaviorSubject} from "rxjs";

export class HomeEquityCalculator {
  private additionalCostAmounts: number[] = [];

  homeSalePrice$ = new BehaviorSubject<number>(0);
  mortgage$ = new BehaviorSubject<number>(0);

  constructor(private homeEquityDashboardModuleSettings: YourHomeEquityDashboardModuleSettings) {}

  get mortgage(): number {
    return this.mortgage$.value;
  }

  set mortgage(value: number) {
    this.mortgage$.next(value);
  }

  get homeSalePrice(): number {
    return this.homeSalePrice$.value;
  }

  set homeSalePrice(value: number) {
    this.homeSalePrice$.next(value);
    this.additionalCostAmounts = this.additionalCosts.map(cost => ((cost.defaultPerc ?? 0) / 100) * this.homeSalePrice);
  }

  get isAdditionalCostsEnabled(): boolean {
    return this.homeEquityDashboardModuleSettings.showAdditionalCosts;
  }

  get additionalCosts(): AdditionalCosts[] {
    return this.homeEquityDashboardModuleSettings.additionalCosts;
  }

  get homeEquity(): number {
    return this.homeSalePrice - this.mortgage - this.totalAdditionalCost;
  }

  get totalAdditionalCost(): number {
    if (this.isAdditionalCostsEnabled) {
      return this.additionalCosts
        .filter(cost => cost.showCost)
        .reduce((total, cost) => total + ((cost.defaultPerc ?? 0) / 100) * this.homeSalePrice, 0);
    }
    return 0;
  }

  // Get - Additional Percentage Value
  getPercentageValue(index: number): number {
    return Math.trunc((this.additionalCosts[index].defaultPerc ?? 0) * 100) / 100;
  }

  // Set - Additional Percentage Value
  setPercentageValue(index: number, value: number) {
    this.additionalCosts[index].defaultPerc = value;
    this.additionalCostAmounts[index] = ((this.additionalCosts[index].defaultPerc ?? 0) / 100) * this.homeSalePrice;
  }

  // Get - Additional Amount Value
  getAmountValue(index: number): number {
    return Math.trunc(this.additionalCostAmounts[index] ?? 0);
  }

  // Set - Additional Amount Value
  setAmountValue(index: number, value: number) {
    this.additionalCostAmounts[index] = value;
    this.additionalCosts[index].defaultPerc = ((this.additionalCostAmounts[index] ?? 0) / this.homeSalePrice) * 100;
  }
}
