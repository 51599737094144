import {Event, EventHint} from "@sentry/types";
import {HttpErrorResponse} from "@angular/common/http";

export class SentryUtils {
  public static beforeSend(event: Event, hint: EventHint): PromiseLike<Event | null> | Event | null {
    const error = hint.originalException;
    if (error && error instanceof HttpErrorResponse) {
      if (error.status === 0) {
        return null;
      }
    }
    return event;
  }
}
