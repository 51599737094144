import {Component, OnInit, TemplateRef, ViewChild} from "@angular/core";
import {CommonModule} from "@angular/common";
import {NgxSpinnerModule, NgxSpinnerService} from "ngx-spinner";
import {AgentCashOfferWrapper, AgentContactProperty} from "fello-model";
import {Observable} from "rxjs";
import {takeUntil, tap} from "rxjs/operators";
import {isEmpty} from "lodash-es";
import {MatSnackBar} from "@angular/material/snack-bar";
import {IsPlatformBrowserDirective} from "../../../fello-ui-utils";
import {DestroyableToastSpinnerBase} from "../../../../lib/mixins";
import {AbstractDashboardService} from "../../services";
import {NgxSkeletonLoaderModule} from "ngx-skeleton-loader";
import {RouterLink} from "@angular/router";

@Component({
  selector: "lib-offer-availability",
  standalone: true,
  imports: [CommonModule, NgxSpinnerModule, IsPlatformBrowserDirective, NgxSkeletonLoaderModule, RouterLink],
  templateUrl: "./offer-availability.component.html",
  styleUrls: ["./offer-availability.component.scss"]
})
export class OfferAvailabilityComponent extends DestroyableToastSpinnerBase implements OnInit {
  @ViewChild("noOffer") noOfferTemplate: TemplateRef<any>;
  @ViewChild("offersReady") offersReadyTemplate: TemplateRef<any>;

  property: AgentContactProperty;
  templateRef: TemplateRef<any> | null = null;

  offer$: Observable<AgentCashOfferWrapper> = this.dashboardService.offer$.pipe(
    takeUntil(this.isDestroyed),
    tap(offer => {
      // if there are any active offer, show them
      if (!isEmpty(offer.offers) || offer.listingOffer) {
        this.templateRef = this.offersReadyTemplate;
      } else {
        this.templateRef = this.noOfferTemplate;
      }
    })
  );

  constructor(private dashboardService: AbstractDashboardService, spinnerService: NgxSpinnerService, snackBar: MatSnackBar) {
    super(spinnerService, snackBar);
  }

  ngOnInit(): void {
    this.dashboardService.property$
      .pipe(
        takeUntil(this.isDestroyed),
        tap(dashboard => {
          this.property = dashboard;
        })
      )
      .subscribe();
  }
}
