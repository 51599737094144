import {Directive, Input} from "@angular/core";
import {NG_VALIDATORS, Validator, AbstractControl, ValidationErrors} from "@angular/forms";

@Directive({
  selector: "[libMax]",
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: MaxValidatorDirective,
      multi: true
    }
  ],
  standalone: true
})
export class MaxValidatorDirective implements Validator {
  @Input("libMax") maxValue: number | null;

  validate(control: AbstractControl): ValidationErrors | null {
    const value = control.value as number;
    if (value != null && !isNaN(value) && this.maxValue != null) {
      if (value > this.maxValue) {
        return {max: true};
      }
    }
    return null;
  }
}
