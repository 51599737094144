import {Component, HostListener, Input, OnDestroy, TemplateRef} from "@angular/core";
import {TooltipPosition} from "../../directives";
import {NgClass, NgTemplateOutlet} from "@angular/common";
import {Subject} from "rxjs";

@Component({
  selector: "lib-custom-tooltip",
  templateUrl: "./custom-tooltip.component.html",
  styleUrls: ["./custom-tooltip.component.scss"],
  imports: [NgClass, NgTemplateOutlet],
  standalone: true
})
export class CustomTooltipComponent implements OnDestroy {
  /**
   * This is simple text which is to be shown in the tooltip
   */
  @Input() text: string;

  /**
   * This provides finer control on the content to be visible on the tooltip
   * This template will be injected in McToolTipRenderer directive in the consumer template
   * <ng-template #template>
   *  content.....
   * </ng-template>
   */
  @Input() contentTemplate: TemplateRef<any>;

  @Input() tooltipClass: string;
  @Input() tooltipPosition: TooltipPosition = "below";
  @Input() tooltipContext?: any;

  /**
   * Subject emitting the mouseenter & mouseleave events on the tooltip
   */
  mouseEnterAndLeave$: Subject<"mouseenter" | "mouseleave"> = new Subject();

  @HostListener("mouseenter")
  mouseEnter() {
    this.mouseEnterAndLeave$.next("mouseenter");
  }

  @HostListener("mouseleave")
  mouseLeave() {
    this.mouseEnterAndLeave$.next("mouseleave");
  }

  ngOnDestroy() {
    this.mouseEnterAndLeave$.complete();
  }
}
