import {Component, ElementRef, forwardRef, Inject, OnInit, PLATFORM_ID, ViewContainerRef} from "@angular/core";
import {MatDialog} from "@angular/material/dialog";
import {BehaviorSubject} from "rxjs";
import {AgentSignatureType, CompLite, DashboardModuleType, FelloUserContactDetails} from "fello-model";
import {shareReplay, take, takeUntil, tap} from "rxjs/operators";
import {mixinDestroyable} from "../../../../lib/mixins/destroy";
import {ContactAgentComponent} from "../dialogs";
import {DashboardModuleDirective, DashboardModuleDirectiveInputs} from "../../directives/dashboard-module.directive";
import {AbstractDashboardService} from "../../services";
import {isPlatformBrowser} from "@angular/common";

declare type MapCenter = {lat: number; lng: number};

@Component({
  selector: "app-comparables",
  templateUrl: "./comparables.component.html",
  styleUrls: ["./comparables.component.scss"],
  // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
  inputs: DashboardModuleDirectiveInputs,
  providers: [
    {
      provide: DashboardModuleDirective,
      useExisting: forwardRef(() => ComparablesComponent)
    }
  ]
})
export class ComparablesComponent
  extends mixinDestroyable(class extends DashboardModuleDirective<DashboardModuleType.COMPS> {})
  implements OnInit
{
  isLoading = true;
  cardOpen = true;
  mapZoom = 13;
  sourcePropertyMarkerIcon = "/assets/images/svg/source-marker.svg";
  mapCenter = new BehaviorSubject<MapCenter>({lat: 0, lng: 0});
  comparables$ = this.dashboardService.comps$.pipe(takeUntil(this.isDestroyed), shareReplay(1));
  property$ = this.dashboardService.property$;
  agentSignature$ = this.dashboardService.agentSignature$;
  AgentSignatureType = AgentSignatureType;

  constructor(
    private dialog: MatDialog,
    private dashboardService: AbstractDashboardService,
    private viewContainerRef: ViewContainerRef,
    elementRef: ElementRef,
    // eslint-disable-next-line @typescript-eslint/ban-types
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    super(elementRef);
  }

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.property$
        .pipe(
          takeUntil(this.isDestroyed),
          tap(currentProperty => {
            this.isLoading = true;
            if (currentProperty.addressComponents) {
              this.mapCenter.next({
                lat: currentProperty.addressComponents.latitude,
                lng: currentProperty.addressComponents.longitude
              });
            }
          })
        )
        .subscribe();
      this.comparables$.pipe(takeUntil(this.isDestroyed)).subscribe(
        () => (this.isLoading = false),
        () => (this.isLoading = false)
      );
    }
  }

  focusComp(comp: CompLite | null): void {
    if (comp) {
      this.mapCenter.next({lat: comp.opd.lat!, lng: comp.opd.long!});
    }
  }

  getMapIcons(comp: CompLite): string {
    return "/assets/images/svg/comp-marker.svg";
  }

  compSelected(comp: CompLite): void {
    this.focusComp(comp);
  }

  openContactAgent() {
    this.dashboardService.contact$
      .pipe(
        take(1),
        tap(contact => {
          this.dialog.open(ContactAgentComponent, {
            maxWidth: 580,
            data: {
              fullName: contact.fullName,
              phone: contact.phone,
              email: contact.emailId
            } as FelloUserContactDetails,
            viewContainerRef: this.viewContainerRef,
            panelClass: ["mobile-bottom-sheet"]
          });
        })
      )
      .subscribe();
  }
}
