import {FelloVisitorVariables} from "./FelloVisitorVariables";

export interface FelloWorkflowMeta {
  currentUserTask: string;
  debug?: {
    _currTask?: string;
    lastCompletedUserTask?: string;
    error?: Record<string, {count: number; err: any}>;
  };
  userTaskTrail?: string[];
  lastUpdated?: Date;
}
export interface FelloOfferJourneyInfo {
  browserId?: string;
  variables: FelloVisitorVariables;
  workflow: FelloWorkflowMeta;
}

export const FelloVisitorWorkflowTask: Record<string, string> = {
  $nia_user_address_entry: "Address Entry",
  user_address_confirmation: "Journey Decision",
  ho_update_home_facts: "Home Details",
  ho_update_basement: "Home Details",
  ho_home_condition_kitchen: "Home Details",
  ho_home_condition_bathroom: "Home Details",
  ho_home_condition_additional_bathroom: "Home Details",
  ho_home_condition_remodel: "Home Details",
  ho_home_condition_flooring: "Home Details",
  ho_home_condition_home_paint: "Home Details",
  ho_home_condition_wall_paint_intensity: "Home Details",
  ho_home_condition_overall: "Home Details",
  ho_home_extra_facts: "Home Details",
  ho_home_remarks: "Message",
  ho_login: "Email Entry",
  ho_phone: "Contact Details",
  ho_sale_timeline: "Sale Timeline",
  ho_home_worth: "Seller Value Estimate",
  ho_buying_with_selling_ques: "Buying Preferences",
  ho_buying_preferences_ques: "Buying Preferences",
  ho_reach_survey: "Source",
  ho_end: "Form Submitted",
  sl_end: "Form Submitted",
  co_end: "Form Submitted"
};
const prefixes = ["hv", "sl"];
const tasks = Object.keys(FelloVisitorWorkflowTask);
prefixes.forEach(prefix => {
  tasks.forEach(task => {
    FelloVisitorWorkflowTask[`${prefix}.${task}`] = FelloVisitorWorkflowTask[task];
  });
});
