import {Inject, Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {AgentContactProperty, LeadRequestFromPostcard, PostcardDashboardSettingResponse} from "fello-model";
import {AGENT_API_SERVICE_BASE_URL} from "../agent";

@Injectable({
  providedIn: "root"
})
export class PostcardDashboardApiService {
  private readonly baseUrl: string;
  constructor(private http: HttpClient, @Inject(AGENT_API_SERVICE_BASE_URL) private agentBaseUrl: string) {
    this.baseUrl = `${this.agentBaseUrl}/agent/postcard-dashboard`;
  }

  getPostcardDashboardSettings(postcardId: string): Observable<PostcardDashboardSettingResponse> {
    return this.http.get<PostcardDashboardSettingResponse>(`${this.baseUrl}/${postcardId}/settings`);
  }

  addAddress(postcardId: string, rawAddress: string, leadRequestFromPostcard: LeadRequestFromPostcard): Observable<AgentContactProperty> {
    return this.http.post<AgentContactProperty>(`${this.baseUrl}/${postcardId}/add-address`, {
      rawAddress,
      leadRequestFromPostcard
    });
  }
}
