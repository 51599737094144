<svg
        xmlns="http://www.w3.org/2000/svg"
        [ngStyle]="{
    height: containerHeight + 'px',
    width: containerWidth + 'px',
    left: containerLeft + 'px',
    top: containerTop + 'px',
    opacity: opacity
  }"
        class="ngflowchart-pad-arrow">
    <defs>
        <marker
                id="padarrowhead"
                viewBox="0 0 10 10"
                refX="8"
                refY="5"
                markerWidth="5"
                markerHeight="5"
                orient="auto"
                fill="black">
            <path d="M 0 0 L 10 5 L 0 10 z"/>
        </marker>
    </defs>
    <g
            id="arrowpath"
            fill="none"
            stroke="black"
            stroke-width="2"
            marker-end="url(#padarrowhead)">
        <path id="arrow" #arrow/>
    </g>
</svg>
