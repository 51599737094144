import {Directive, HostBinding, OnInit} from "@angular/core";
import {NgControl} from "@angular/forms";
import {takeUntil, tap} from "rxjs/operators";
import {DestroyableBase, NumberUtils} from "../../../lib";

@Directive({
  selector: "[libFormatPhone][formControlName], [libFormatPhone][ngModel]",
  standalone: true
})
export class FormatPhoneDirective extends DestroyableBase implements OnInit {
  @HostBinding("attr.autocomplete") autocomplete = "one-time-code";

  constructor(public ngControl: NgControl) {
    super();
  }

  ngOnInit(): void {
    this.ngControl.valueChanges
      ?.pipe(
        takeUntil(this.isDestroyed),
        tap(value => {
          const newValue = NumberUtils.formatPhone(value);
          if (newValue != value) {
            this.ngControl.control?.setValue(newValue);
          }
        })
      )
      .subscribe();
  }
}
