export enum MlsStatus {
  Sale = "Sale",
  Active = "Active",
  Expired = "Expired",
  Pending = "Pending",
  Unknown = "Unknown",
  Deleted = "Deleted"
}

export enum TransactionStatus {
  Active = "Active",
  Taking_Backup_Offers = "Taking Backup Taking_Backup_Offers",
  Back_On_Market = "Back_On_Market",
  Contingent = "Contingent",
  Coming_Soon = "Coming_Soon",
  Cancelled = "Cancelled",
  Hold = "Hold",
  Inactive = "Inactive",
  Leased = "Leased",
  Pending = "Pending",
  Sold = "Sold",
  New = "New",
  Off_Market = "Off_Market",
  Withdrawn = "Withdrawn",
  Expired = "Expired",
  Deleted = "Deleted",
  Terminated = "Terminated",
  Under_Contract = "Under_Contract",
  Unknown = "Unknown"
}
export enum TransactionSource {
  PR = "PR",
  MLS = "MLS"
}
export const TransactionSourceToLabel: Record<TransactionSource, string> = {
  ...TransactionSource
};
export const MlsStatusToLabel: Record<MlsStatus, string> = {
  ...MlsStatus,
  [MlsStatus.Sale]: "Sold"
};

export const TransactionStatusToLabel: Record<TransactionStatus, string> = {
  Active: "Active",
  [TransactionStatus.Taking_Backup_Offers]: "Backup Offers",
  Back_On_Market: "Back on Market",
  Contingent: "Contingent",
  Coming_Soon: "Coming Soon",
  Cancelled: "Cancelled",
  Hold: "Hold",
  Inactive: "Inactive",
  Leased: "Leased",
  Pending: "Pending",
  Sold: "Sold",
  New: "New",
  Off_Market: "Off Market",
  Withdrawn: "Withdrawn",
  Expired: "Expired",
  Deleted: "Deleted",
  Terminated: "Terminated",
  Under_Contract: "Under Contract",
  Unknown: "Unknown"
};

export const MlsStatusToColor: Record<MlsStatus, string> = {
  [MlsStatus.Sale]: "yellow-1",
  [MlsStatus.Active]: "blue-light",
  [MlsStatus.Expired]: "red-light",
  [MlsStatus.Pending]: "purple-1",
  [MlsStatus.Unknown]: "slate-1",
  [MlsStatus.Deleted]: "red"
};

export enum MlsStatusDetailed {
  Active = "Active",
  Taking_Backup_Offers = "Taking Backup Taking_Backup_Offers",
  Back_On_Market = "Back_On_Market",
  Contingent = "Contingent",
  Coming_Soon = "Coming_Soon",
  Cancelled = "Cancelled",
  Hold = "Hold",
  Inactive = "Inactive",
  Leased = "Leased",
  Pending = "Pending",
  Sold = "Sold",
  New = "New",
  Off_Market = "Off_Market",
  Withdrawn = "Withdrawn",
  Expired = "Expired",
  Deleted = "Deleted",
  Terminated = "Terminated",
  Under_Contract = "Under_Contract",
  Unknown = "Unknown"
}
