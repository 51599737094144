import {Pipe, PipeTransform} from "@angular/core";
import {ChangelogEvent, ExtendedChangelogEvent} from "fello-model";

@Pipe({
  name: "asExtendedChangelog",
  standalone: true
})
export class AsExtendedChangelogPipe implements PipeTransform {
  transform(event: ChangelogEvent): ExtendedChangelogEvent {
    return ExtendedChangelogEvent.fromChangelogEvent(event);
  }
}
