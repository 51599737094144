export enum MediaType {
  LINK = "LINK",
  MEDIA_ID = "MEDIA_ID"
}
export interface MediaWithType {
  type: MediaType;
  value: string;
}

export interface ImageMeta {
  height: number;
  width: number;
}
